import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { apiCall } from '../../services/apiCall';
import { showDialog, hideDialog } from '../../actions/loading';
import { showFixedLabel } from '../../actions/fixedLabel';
import { endRequest } from '../../actions/apiCall';

const ApiCall = () => {
	const dispatch = useDispatch();
	const apicall = useSelector((state) => state.apicall);

	useEffect(
		() => {
			if (apicall.requestOptions.length > 0) {
				let lastRequest = apicall.requestOptions[apicall.requestOptions.length - 1];
				const props = {
					showDialog: () => dispatch(showDialog()),
					hideDialog: () => dispatch(hideDialog()),
					showFixedLabel: (message, type) => dispatch(showFixedLabel(message, type))
				};
				apiCall(lastRequest, props);
				dispatch(endRequest(lastRequest));
			}
		},
		[ apicall ]
	);

	return null;
};

export default ApiCall;
