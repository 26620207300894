import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import { startRequest } from '../../../../actions/apiCall';
import { validateEmail } from '../../../../common/validations/common';
import { ROUTE } from '../../../../common/constants/routes';
import { Input, Button } from '../../../../components/FormComponents/index';
import { showFixedLabel } from '../../../../actions/fixedLabel';
import { postForgotPasswordParams } from './ForgotPasswordCalls';
import './ForgotPasswordForm.scss';

const ForgotPasswordForm = ({ Error, ErrorResponse, changePage, setForgotPassword }) => {
	const [ email, setEmail ] = useState('');
	const [ error, setError ] = useState(Error);
	const [ emailError, setEmailError ] = useState(false);
	const [ errorResponse, setErrorResponse ] = useState(ErrorResponse);
	const history = useHistory();
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		document.title = t('ForgotPasswordTitle');
	});

	const submitForgotPassword = (e) => {
		e.preventDefault();
		const data = {
			email
		};
		dispatch(
			startRequest(
				postForgotPasswordParams(
					data,
					() => {
						dispatch(showFixedLabel('EmailSend', 'success'));
						history.push(ROUTE.LOGIN);
					},
					(error) => {
						setError(true);
						dispatch(showFixedLabel(t(error.data.errors[0].errorMessageKey), 'error'));
						setErrorResponse(error);
					}
				)
			)
		);
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			if (!emailError && email !== '') {
				submitForgotPassword(event);
			}
		}
	};

	return (
		<form className="forgot-password">
			<h1 className="forgot-password-title" data-testid="lblForgotPasswordTitle">
				{t('ForgotPasswordTitle')}
			</h1>
			<div>
				<div className="form-wrapper">
					<Input
						inputID="txtEmail"
						inputName="txtEmail"
						dataTestID="txtForgotPasswordEmail"
						inputType="text"
						inputLabel={t('Email')}
						isDisabled={false}
						onHandleKeyPress={handleKeyPress}
						onHandleChange={(e) => {
							let val = e.currentTarget.value;
							setEmail(val);
							setEmailError(validateEmail(val).error);
						}}
						value={email}
						isError={emailError}
						errorMessage={email === '' ? t('IsRequired') : t('EmailFormatIncorrect')}
						isRequired={true}
					/>
				</div>

				<p className="link-forgotPass" id="lnkForgotPassword">
					<Link
						onClick={(e) => {
							e.preventDefault();
							setForgotPassword(false);
						}}
					>
						{t('DoYouHaveAnAccount')}
					</Link>
				</p>
				<div className="button-container">
					<Button
						btnText={t('Send')}
						btnType="button"
						onHandleBtnClick={submitForgotPassword}
						isDisabled={emailError || email === ''}
						btnClass="btn-base_normal"
						dataTestID="btnSubmitForgotPassword"
						id="btnSubmitForgotPassword"
					/>
				</div>
			</div>
		</form>
	);
};

export default ForgotPasswordForm;
