const getBookingByIdParams = (id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/TenantBookingSpace/Detail/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const cancelBookingParams = (id, SuccessFn) => {
	const options = {
		method: 'PUT',
		endpoint: '/TenantBookingSpace/Cancel/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export { getBookingByIdParams, cancelBookingParams };
