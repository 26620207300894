import React, { useEffect, useState, Fragment, useRef, forwardRef } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { hideFixedLabel } from '../../actions/fixedLabel';
import './FixedLabel.scss';

const FixedLabel = ({ parentType }) => {
	const { t } = useTranslation();

	const [ isHide, setIsHide ] = useState(false);
	const [ iconName, setIconName ] = useState('');
	const [ type, setType ] = useState('');
	const [ message, setMessage ] = useState('');
	const dispatch = useDispatch();

	const fixedLabel = useSelector((state) => state.fixedLabel);
	const popupReducer = useSelector((state) => state.popup);
	const timerRef = useRef();

	useEffect(
		() => {
			if (
				(popupReducer.popUpCount > 0 && parentType === 'popup') ||
				(popupReducer.popUpCount === 0 && parentType === 'general')
			) {
				setIsHide(!fixedLabel.showLabel);
				setMessage(fixedLabel.messageLabel);
				setType(fixedLabel.typeLabel);

				switch (fixedLabel.typeLabel) {
					case 'success':
						setIconName('checkmark-circle-outline');
						break;
					case 'error':
						setIconName('close-circle-outline');
						break;
					case 'warning':
						setIconName('alert-circle-outline');
						break;
					case 'information':
						setIconName('information-circle');
						break;
					default:
						break;
				}

				if (fixedLabel.typeLabel === 'success') {
					setTimeout(() => {
						dispatch(hideFixedLabel());
					}, 8000);
				}
				if (fixedLabel.typeLabel === 'error') {
					timerRef.current = setTimeout(() => {
						dispatch(hideFixedLabel());
					}, 5000);
				}
				if (fixedLabel.typeLabel === 'information') {
					timerRef.current = setTimeout(() => {
						dispatch(hideFixedLabel());
					}, 5000);
				}

				return () => {
					clearInterval(timerRef.current);
				};
			}
		},
		[
			fixedLabel.showLabel,
			fixedLabel.messageLabel,
			fixedLabel.typeLabel,
			popupReducer.popUpCount,
			parentType,
			dispatch
		]
	);

	const hiddenBannerAlertHandler = (evt) => {
		setIsHide(true);
	};

	const BannerAlertContent = forwardRef((props, ref) => (
		<div className="fixed-label_container">
			<div className={`fixed-label ${type}`} data-testid={`fixed-label-${type}`}>
				<span aria-hidden={true}>
					<ion-icon ref={ref} name={iconName} onClick={hiddenBannerAlertHandler} />
				</span>
				<p>{t(message)}</p>
			</div>
		</div>
	));
	return (
		<Fragment>
			{!isHide &&
				ReactDOM.createPortal(<BannerAlertContent ref={timerRef} />, document.getElementById('overlay-root'))}
		</Fragment>
	);
};

export default FixedLabel;
