const getIncidentByIdParams = (id, SuccessFn, ErrorFn) => {
	let endpoint = '/IncidentProvider/' + id;
	const options = {
		method: 'GET',
		endpoint: endpoint,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		},
		onError: (response) => {
			ErrorFn(response.data);
		}
	};
	return options;
};

const declineIncidentParams = (id, funSucc) => {
	const options = {
		method: 'PUT',
		endpoint: '/IncidentProvider/JobRejected/' + id,
		onSuccess: (response) => {
			funSucc(response.data.data);
		}
	};
	return options;
};

const acceptIncidentParams = (id, funSucc) => {
	const options = {
		method: 'PUT',
		endpoint: '/IncidentProvider/JobAccepted/' + id,
		onSuccess: (response) => {
			funSucc(response.data.data);
		}
	};
	return options;
};

const changeAmountParams = (data, funSucc) => {
	const options = {
		method: 'PUT',
		data,
		endpoint: '/IncidentProvider/ChangeIncidentBudget',
		onSuccess: (response) => {
			funSucc(response.data.data);
		}
	};
	return options;
};

const postMediaParams = (data, funSucc) => {
	const options = {
		method: 'POST',
		data,
		contentType: 'multipart/form-data',
		endpoint: '/IncidentProvider/CreateMultipleMedia',
		onSuccess: (response) => {
			funSucc(response.data.data);
		}
	};
	return options;
};

const postCommentParams = (data, funSucc) => {
	const options = {
		method: 'POST',
		data,
		endpoint: '/IncidentProvider/CreateComment',
		onSuccess: (response) => {
			funSucc(response.data.data);
		}
	};
	return options;
};

const getPrivateCommentParams = (id, forBialto, funSucc) => {
	const options = {
		method: 'GET',
		endpoint: '/IncidentProvider/GetPrivateMessages/' + id + '/' + forBialto,
		onSuccess: (response) => {
			funSucc(response.data.data);
		}
	};
	return options;
};

const finishIncidentByIdParams = (id, SuccessFn) => {
	const options = {
		method: 'PUT',
		endpoint: '/IncidentProvider/FinishJob/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};

	return options;
};

const getMediaByIdParams = (id, incidentId, SuccessFn, ErrorFn) => {
	const options = {
		method: 'GET',
		hideSpinner: true,
		endpoint: '/IncidentProvider/GetDocument/' + id + '/' + incidentId,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		},
		onError: (response) => {
			ErrorFn(response.data);
		}
	};
	return options;
};

const downloadDocumentByIdParams = (id, incidentId, SuccessFn, ErrorFn) => {
	const options = {
		method: 'GET',
		hideSpinner: true,
		endpoint: '/IncidentProvider/DownloadDocument/' + id + '/' + incidentId,
		onSuccess: (response) => {
			SuccessFn(response.data);
		},
		onError: (response) => {
			ErrorFn(response.data);
		}
	};
	return options;
};

const getMediaImageBiggerByIdParams = (id, incidentId, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/IncidentProvider/GetDocumentAnnotation/' + id + '/' + incidentId,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export {
	getIncidentByIdParams,
	acceptIncidentParams,
	declineIncidentParams,
	changeAmountParams,
	postMediaParams,
	postCommentParams,
	finishIncidentByIdParams,
	getMediaByIdParams,
	downloadDocumentByIdParams,
	getMediaImageBiggerByIdParams,
	getPrivateCommentParams
};
