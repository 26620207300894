import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import {
  parseDateTime,
  parseDate,
} from "../../../../../common/utils/parseUtils";
import { Input } from "@backoffice/components/FormComponents/index";
import {
  postCommentParams,
  getPrivateCommentParams,
} from "../IncidentsTenantDetailCalls/IncidentsTenantDetailCalls";
import { startRequest } from "../../../../../actions/apiCall";
import { showFixedLabel } from "../../../../../actions/fixedLabel";
import "./TabComments.scss";

const TabComments = ({ incident, handleSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [messageList, setMessageList] = useState([]);

  const [forBialto, setForBialto] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);

  const [isInConversation, setIsInConversation] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 990px)" });

  useEffect(() => {
    // dispatch(showFixedLabel("CommentsAlertMessage", "information"));
    setMessageList(incident.incidentComments);
  }, []);

  useEffect(() => {
    var elmntToView = document.getElementById("lastComment");
    if (elmntToView) {
      elmntToView.scrollIntoView();
    }
  }, [messageList]);

  const sendMessage = () => {
    let newMessage = {};
    if (message !== "") {
      let newMessageList = [...messageList];
      newMessage = {
        creationDate: new Date(),
        operatorName: "CommentTenant",
        id: incident.dynamicId,
        text: message,
        isPrivate: isPrivate,
        forBialto: forBialto,
      };
      newMessageList.push(newMessage);
      setMessageList(newMessageList);
      setMessage("");
    }
    return newMessage;
  };

  const postComment = () => {
    const newProviderMessage = sendMessage();
    dispatch(
      startRequest(
        postCommentParams(newProviderMessage, postCommentParamsSuccess)
      )
    );
  };

  const postCommentParamsSuccess = (response) => {
    dispatch(showFixedLabel("CommentSendSuccess", "success"));
    handleSubmit();
  };

  const loadCommonConversation = () => {
    setForBialto(false);
    setIsPrivate(false);
    setMessageList(incident.incidentComments);
  };

  const loadBialtoConversation = () => {
    dispatch(
      startRequest(
        getPrivateCommentParams(
          incident.dynamicId,
          true,
          loadBialtoConversationSuccess
        )
      )
    );
  };

  const loadBialtoConversationSuccess = (res) => {
    setForBialto(true);
    setIsPrivate(true);

    setMessageList(res.sort(compare));
  };

  const loadPrivateConversation = () => {
    dispatch(
      startRequest(
        getPrivateCommentParams(
          incident.dynamicId,
          false,
          loadPrivateConversationSuccess
        )
      )
    );
  };

  const loadPrivateConversationSuccess = (res) => {
    setForBialto(false);
    setIsPrivate(true);
    setMessageList(res.sort(compare));
  };

  function compare(a, b) {
    if (a.creationDate < b.creationDate) {
      return -1;
    }
    if (a.creationDate > b.creationDate) {
      return 1;
    }
    return 0;
  }

  const renderCard = (fnOnClick, title, date, lastMessage) => {
    return (
      <div
        className="tab-comments-tenant-conversations-card"
        onClick={() => {
          setIsInConversation(true);
          fnOnClick();
        }}
      >
        <div className="tab-comments-tenant-conversations-card-content">
          <div className="tab-comments-tenant-conversations-card-content-top">
            <h3 className="tab-comments-tenant-conversations-card-content-top-title">
              {title}
            </h3>
            <h3 className="tab-comments-tenant-conversations-card-content-top-title">
              {date}
            </h3>
          </div>

          {lastMessage ? (
            <div className="tab-comments-tenant-conversations-card-content-subtitle">
              {lastMessage}
            </div>
          ) : (
            <div className="tab-comments-tenant-conversations-card-content-subtitle-empty">
              No hay mensaje aún...
            </div>
          )}
        </div>

        <div className="tab-comments-tenant-conversations-card-icon">
          <span className="material-icons-round">east</span>
        </div>
      </div>
    );
  };

  const renderConversationTitle = () => {
    let msg = "";

    if (!isPrivate) {
      msg = t("CommonConversation");
    } else if (forBialto) {
      msg = t("ConversationWithBialto");
    } else {
      msg = t("ConversationWithProvider");
    }

    if (isMobile && isInConversation) {
      return (
        <>
          <span
            className="material-icons-round"
            onClick={() => setIsInConversation(false)}
          >
            west
          </span>
          <span>{msg}</span>
          <span style={{ width: "24px" }}></span>
        </>
      );
    } else {
      return <span>{msg}</span>;
    }
  };

  const renderComments = () => {
    let html = [];
    if (messageList.length > 0) {
      messageList.forEach((incidentComment, i) => {
        html.push(
          <div
            id={i === messageList.length - 1 ? "lastComment" : i + "-comments"}
            key={i + "-comments"}
            className={
              incidentComment.operatorName === "CommentTenant"
                ? "tab-comments-tenant-conversation-mesages-tenant"
                : "tab-comments-tenant-conversation-mesages-external"
            }
          >
            <div className="comment-wrapper">
              <div className="header-wrapper">
                <h2 className="date-comment">
                  {incidentComment.creationDate &&
                    parseDateTime(incidentComment.creationDate, true).replace(
                      " ",
                      " - "
                    )}
                </h2>
                {!isPrivate &&
                incidentComment.operatorName === "CommentProvider" ? (
                  <div className="user-comment">{t("Provider")}</div>
                ) : (
                  incidentComment.operatorName === "CommentBackOffice" && (
                    <div className="user-comment">{"Bialto"}</div>
                  )
                )}
              </div>
              <p className="comment-text">{incidentComment.text}</p>
            </div>
          </div>
        );
      });
    }

    return html;
  };

  return (
    <div className="tab-comments-tenant">
      <div
        className={
          isMobile
            ? isInConversation
              ? "displayNone"
              : "tab-comments-tenant-conversations removeBorder"
            : "tab-comments-tenant-conversations"
        }
      >
        <div className="tab-comments-tenant-conversations-titleHeader">
          <h1 className="tab-comments-tenant-conversations-titleHeader-title">
            {t("CommonConversation")}
          </h1>
          <h1
            className={
              isMobile
                ? "displayNone"
                : "tab-comments-tenant-conversations-titleHeader-information"
            }
          >
            <span className="tooltiptext">{t("CommonTooltip")}</span>
            <span className="material-icons-round">info_outline</span>
          </h1>
        </div>
        {renderCard(
          loadCommonConversation,
          t("WithBialtoAndProvider"),
          incident.incidentComments.length > 0 &&
            parseDate(incident.incidentComments.at(-1).creationDate),
          incident.incidentComments.length > 0 &&
            incident.incidentComments.at(-1).text
        )}
        <div className="tab-comments-tenant-conversations-space" />

        <div className="tab-comments-tenant-conversations-titleHeader">
          <h1 className="tab-comments-tenant-conversations-titleHeader-title">
            {t("PrivateConversation")}
          </h1>
          <h1
            className={
              isMobile
                ? "displayNone"
                : "tab-comments-tenant-conversations-titleHeader-information"
            }
          >
            <span className="tooltiptext">{t("PrivateTooltip")}</span>
            <span className="material-icons-round">info_outline</span>
          </h1>
        </div>

        {renderCard(
          loadBialtoConversation,
          t("WithBialto"),
          incident.lastBialtoComent &&
            parseDate(incident.lastBialtoComent.creationDate),
          incident.lastBialtoComent && incident.lastBialtoComent.text
        )}
        {incident.hasProvider &&
          renderCard(
            loadPrivateConversation,
            t("WithProvider"),
            incident.lastProviderComent &&
              parseDate(incident.lastProviderComent.creationDate),
            incident.lastProviderComent && incident.lastProviderComent.text
          )}
      </div>
      <div
        className={
          isMobile
            ? isInConversation
              ? "tab-comments-tenant-conversation"
              : "displayNone"
            : "tab-comments-tenant-conversation"
        }
      >
        <div className="tab-comments-tenant-conversation-information">
          <p className="tab-comments-tenant-conversation-information-title">
            <span className="material-icons-round">info_outline</span>{" "}
            {t("CommentsAlertMessage")}
          </p>
        </div>
        <div className="tab-comments-tenant-conversation-header">
          <h1 className="tab-comments-tenant-conversation-header-title">
            {renderConversationTitle()}
          </h1>
        </div>
        <div className="tab-comments-tenant-conversation-mesages">
          {renderComments()}
        </div>
        <div className="tab-comments-tenant-conversation-input">
          <Input
            dataTestID="txtMessage"
            inputName="txtMessage"
            inputType={"text"}
            inputLabel={""}
            inputMaxLength={1200}
            inputID="message"
            isDisabled={false}
            onHandleKeyPress={(e) => {
              if (e.charCode === 13) {
                e.preventDefault();
                postComment();
              }
            }}
            onHandleChange={(e) => setMessage(e.currentTarget.value)}
            value={message}
            icon={"send"}
            isRequired={false}
            onHandleClick={() => postComment()}
          />
        </div>
      </div>
    </div>
  );
};
export default TabComments;
