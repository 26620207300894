import React from 'react';
import {
	RichTextEditorComponent,
	Toolbar,
	Inject,
	Image,
	Link,
	Resize,
	HtmlEditor,
	Count,
	QuickToolbar,
	Table
} from '@syncfusion/ej2-react-richtexteditor';
import './RichText.scss';

const RichText = (props) => {
	const { id, placeholder, uploadImage, imageSettings, maxLength, enableResize, isDisabled, onChange, value } = props;

	const getToolbarSettings = () => {
		let newItems = [];
		if (uploadImage) {
			newItems = [
				'Bold',
				'Italic',
				'Underline',
				'StrikeThrough',
				'FontName',
				'FontSize',
				'FontColor',
				'BackgroundColor',
				'LowerCase',
				'UpperCase',
				'|',
				'Formats',
				'Alignments',
				'OrderedList',
				'UnorderedList',
				'Outdent',
				'Indent',
				'SuperScript',
				'SubScript',
				'|',
				'CreateTable',
				'CreateLink',
				'Image',
				'|',
				'ClearFormat',
				'Print',
				'SourceCode',
				'FullScreen',
				'|',
				'Undo',
				'Redo'
			];
		} else {
			newItems = [
				'Bold',
				'Italic',
				'Underline',
				'StrikeThrough',
				'FontName',
				'FontSize',
				'FontColor',
				'BackgroundColor',
				'LowerCase',
				'UpperCase',
				'|',
				'Formats',
				'Alignments',
				'OrderedList',
				'UnorderedList',
				'Outdent',
				'Indent',
				'SuperScript',
				'SubScript',
				'|',
				'CreateTable',
				'CreateLink',
				'|',
				'ClearFormat',
				'Print',
				'SourceCode',
				'FullScreen',
				'|',
				'Undo',
				'Redo'
			];
		}

		return {
			items: newItems
		};
	};

	const getImageSettings = () => {
		return {
			saveUrl: imageSettings.saveUrl,
			path: imageSettings.path
		};
	};

	return (
		<div className="control-panel">
			<div className="control-section" id="rteTools">
				<div className="rte-control-section">
					<RichTextEditorComponent
						width={'100%'}
						height={'200px'}
						placeholder={placeholder}
						enableResize={enableResize}
						disabled={isDisabled}
						insertImageSettings={getImageSettings()}
						readOnly={true}
						change={(e) => {
							onChange(e.value);
						}}
						id={id}
						value={value}
						showCharCount={true}
						maxLength={maxLength}
						toolbarSettings={getToolbarSettings()}
					>
						<Inject services={[ Toolbar, Image, Resize, Link, HtmlEditor, Count, QuickToolbar, Table ]} />
					</RichTextEditorComponent>
				</div>
			</div>
		</div>
	);
};
export default RichText;
