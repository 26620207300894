import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { startRequest } from "../../../../actions/apiCall";
import { Combo, Switch } from "@backoffice/components/FormComponents/index";

import {
  getContactSchedule,
  postContactSchedule,
  getNotifications,
  postNotifications,
} from "./ConfigurationTenantCalls/ConfigurationTenantCalls";
import "./ConfigurationTenant.scss";

const ConfigurationTenant = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tipologies, setTipologies] = useState([]);
  const [tipologyValue, setTipologyValue] = useState("");

  const [newNewsNot, setNewNewsNot] = useState();
  const [bookingReminderNot, setBookingReminderNot] = useState();
  const [bookingConfirmNot, setBookingConfirmNot] = useState();
  const [bookingCancelNot, setBookingCancelNot] = useState();
  const [changeIncidentStatusNot, setChangeIncidentStatusNot] = useState();

  useEffect(() => {
    dispatch(startRequest(getContactSchedule(getContactScheduleSuccess)));
    dispatch(startRequest(getNotifications(getNotificationsSuccess)));
  }, []);

  const getContactScheduleSuccess = (response) => {
    if (response) {
      setTipologyValue(response.value);
      setTipologies(response.tipology);
    }
  };

  const getNotificationsSuccess = (response) => {
    if (response) {
      setNewNewsNot(response.find((x) => x.pushType == "NewNews"));

      setBookingReminderNot(
        response.find((x) => x.pushType == "BookingReminder")
      );
      setBookingConfirmNot(
        response.find((x) => x.pushType == "BookingConfirm")
      );
      setBookingCancelNot(response.find((x) => x.pushType == "BookingCancel"));
      setChangeIncidentStatusNot(
        response.find((x) => x.pushType == "ChangeIncidentStatus")
      );
    }
  };

  const comboHandleChange = async (e) => {
    if (tipologyValue != e.value) {
      let data = {
        value: e.value,
      };
      await dispatch(startRequest(postContactSchedule(data)));
      setTipologyValue(e.value);
    }
  };

  const switchHandleChange = (value, pushType, notificationType) => {
    let data = {
      pushType: pushType,
      notificationType: notificationType,
      value: value,
    };

    dispatch(startRequest(postNotifications(data, postNotificationsSuccess)));
  };

  const postNotificationsSuccess = () => {
    dispatch(startRequest(getNotifications(getNotificationsSuccess)));
  };

  const renderNotification = (x) => {
    return (
      <div className="notifications">
        <p className="notifications-title">{t(x.pushType)}</p>
        <div className="notifications-switches">
          <div className="custom-switch-control">
            <Switch
              className={
                x.canEmail ? (x.haveEmail ? "active" : "notActive") : "disable"
              }
              isRequired={false}
              inputValue={x.haveEmail}
              handleChange={(y) => {
                if (x.canEmail) {
                  switchHandleChange(y, x.pushType, "Email");
                }
              }}
            />
          </div>
          <div className="custom-switch-control">
            <Switch
              className={
                x.canPush ? (x.havePush ? "active" : "notActive") : "disable"
              }
              isRequired={false}
              inputValue={x.havePush}
              handleChange={(y) => {
                if (x.canPush) {
                  switchHandleChange(y, x.pushType, "Push");
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="configuration-tenant">
      <h2 className="title">{t("Settings")}</h2>
      {/* <div className="content">
        {tipologies  && (
          <Combo
            id="cmbTipologies"
            dataTestID="cmbTipologies"
            comboLabel={t("ContactSchedule")}
            inputValue={tipologyValue}
            comboPlaceholder={t(
              tipologies.find((x) => x.value == tipologyValue)?.name
            )}
            defaultInputValue=""
            isSearchable={false}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => t(option.name)}
            options={tipologies}
            isMultiple={false}
            handleChange={comboHandleChange}
          />
        )}
      </div>
      <div className="divider" /> */}
      <h2 className="subtitle">{t("Notifications")}</h2>

      <div key={Math.random()} className="notifications">
        <p className="notifications-title"></p>
        <div className="notifications-switches">
          <div className="switch-icon">
            <span className="material-icons-round" aria-hidden={true}>
              email
            </span>
          </div>
          <div className="switch-icon">
            <span className="material-icons-round" aria-hidden={true}>
              notifications_active
            </span>
          </div>
        </div>
      </div>

      {newNewsNot && renderNotification(newNewsNot)}
      {bookingReminderNot && renderNotification(bookingReminderNot)}
      {bookingConfirmNot && renderNotification(bookingConfirmNot)}
      {bookingCancelNot && renderNotification(bookingCancelNot)}
      {changeIncidentStatusNot && renderNotification(changeIncidentStatusNot)}
    </div>
  );
};
export default ConfigurationTenant;
