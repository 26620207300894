import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	ColumnDirective,
	ColumnsDirective,
	Filter,
	GridComponent,
	Group,
	Resize,
	Inject,
	Page,
	Sort,
	ExcelExport
} from '@syncfusion/ej2-react-grids';
import { startRequest } from '@backoffice/actions/apiCall';
import { Input, Button } from '@backoffice/components/FormComponents/index';
import ResponsiveGridWrapper from '../../../../shared/ResponsiveGridWrapper/ResponsiveGridWrapper';
import { cleanStringFromAccents, parseDateTime } from '../../../../common/utils/parseUtils';
import { ROUTE } from '../../../../common/constants/routes';
import { getBuildingsParams } from './BuildingsCalls/BuildingsCalls';
import './Buildings.scss';

const Buildings = () => {
	const { t } = useTranslation();
	const pageSettings = { pageSize: 15 };
	const history = useHistory();
	const grid = useRef(null);
	const dispatch = useDispatch();
	const [ buildings, setBuildings ] = useState([]);
	const [ search, setSearch ] = useState('');
	const [ allBuildings, setAllBuildings ] = useState([]);

	useEffect(() => {
		getBuildings();
	}, []);

	useEffect(
		() => {
			filterBuildings();
		},
		[ search ]
	);

	const getBuildings = () => {
		dispatch(startRequest(getBuildingsParams(getBuildingsParamsSuccess)));
	};

	const getBuildingsParamsSuccess = (response) => {
		setBuildings(response.list);
		setAllBuildings(response.list);
	};

	const filterBySearch = (data = [], searching = '') => {
		const result = data.filter((element) => {
			return cleanStringFromAccents(element.name.toLowerCase()).includes(searching.toLowerCase());
		});
		return result;
	};

	const filterBuildings = () => {
		if (search === '') {
			setBuildings(allBuildings);
		} else {
			const newData = filterBySearch(allBuildings, search);
			if (newData === '') {
				setBuildings(allBuildings);
			}
			setBuildings(newData);
		}
	};

	const actionsTemplate = (rowData) => {
		return (
			<div className="action-template">
				<span
					className="material-icons-round"
					data-testid={`btnEditStandar_${rowData.index}`}
					id={`btnEditStandar_${rowData.index}`}
					onClick={() =>
						history.push({
							pathname: ROUTE.BUILDINGSDETAIL.replace(':id', rowData.id)
						})}
					type="button"
				>
					mode_edit
				</span>
			</div>
		);
	};

	// let searchBy = t('Search') + ' ' + t('by') + ' ' + t('Name');
	let searchBy = t('Search') + ' ' + t('by') + ' ' + t('WordMin');
	return (
		<div className="buildings">
			<h2 className="title">{t('Buildings')}</h2>
			<div className="filters-wrapper">
				<div className="col-xl-3 building-search" data-bs-toggle="tooltip" title={searchBy}>
					<Input
						dataTestID="txtSearch"
						inputName="txtSearch"
						clearInput={true}
						inputPlaceholder={searchBy}
						inputType="text"
						value={search}
						inputOutline={true}
						icon={'search'}
						iconLeft={true}
						isRequired={false}
						onHandleChange={(e) => setSearch(e.target.value)}
					/>
				</div>
			</div>
			<div className="btn-grid-end">
				<Button
					btnText={t('ExportExcel')}
					btnType="button"
					icon="download"
					btnClass="btn-base_outline export-excel-button"
					dataTestID="export-excel-button"
					onHandleBtnClick={() => {
						grid.current.excelExport({ includeHiddenColumn: true });
					}}
				/>
			</div>
			<ResponsiveGridWrapper>
				<GridComponent
					className="gridTable"
					dataSource={buildings}
					width="100%"
					toolbar={false}
					ref={grid}
					allowExcelExport={true}
					allowPaging={true}
					enableAdaptiveUI={true}
					allowSorting={true}
					rowRenderingMode={'Vertical'}
					pageSettings={pageSettings}
				>
					<ColumnsDirective>
						<ColumnDirective field="id" width="0" visible={false} />
						<ColumnDirective field="name" headerText={t('Building')} textAlign="Left" />
						<ColumnDirective field="address" headerText={t('Address')} textAlign="Left" />
						<ColumnDirective field="email" headerText={t('Email')} textAlign="Left" />
						<ColumnDirective field="contactPerson" headerText={t('ContactPerson')} textAlign="Left" />
						<ColumnDirective field="phoneNumber" headerText={t('Phone')} textAlign="Left" />
						<ColumnDirective
							field="creationDate"
							headerText={t('CreationDate')}
							valueAccessor={(field, data, column) => {
								return parseDateTime(data.creationDate, true);
							}}
							textAlign="Left"
						/>
						<ColumnDirective
							field="actions"
							headerText={''}
							template={actionsTemplate}
							textAlign="Right"
							width="60"
						/>
					</ColumnsDirective>
					<Inject services={[ ExcelExport, Page, Sort, Filter, Group, Resize ]} />
				</GridComponent>
			</ResponsiveGridWrapper>
		</div>
	);
};
export default Buildings;
