import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FilePreview from '../../../../shared/FilePreview/FilePreview';
import { Button, Combo } from '@backoffice/components/FormComponents/index';
import { ColorPickerComponent } from '@syncfusion/ej2-react-inputs';
import { GetCookie_culture } from '@backoffice/common/utils/cookiesManager';
import { changeAppearance } from '../../../../actions/appearance';
import './Settings.scss';

const Settings = () => {
	const appearanceReducer = useSelector((state) => state.appearance);
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [ file, setFile ] = useState('');
	const [ fileName, setFileName ] = useState('');
	const [ imgUrl, setImgUrl ] = useState(appearanceReducer.appearance.imgUrl);

	const [ faviconFile, setFaviconFile ] = useState('');
	const [ faviconFileName, setFaviconFileName ] = useState('');
	const [ faviconImgUrl, setFaviconImgUrl ] = useState(appearanceReducer.appearance.faviconImgUrl);

	const [ colorPrimary, setColorPrimary ] = useState(appearanceReducer.appearance.colorPrimary);
	const [ colorPrimaryVariant, setColorPrimaryVariant ] = useState(appearanceReducer.appearance.colorPrimaryVariant);
	const [ colorPrimaryVariant2, setColorPrimaryVariant2 ] = useState(
		appearanceReducer.appearance.colorPrimaryVariant2
	);
	const [ colorPrimaryPressed, setColorPrimaryPressed ] = useState(appearanceReducer.appearance.colorPrimaryPressed);
	const [ colorPrimaryHover, setColorPrimaryHover ] = useState(appearanceReducer.appearance.colorPrimaryHover);
	const [ colorPrimaryDisabled, setColorPrimaryDisabled ] = useState(
		appearanceReducer.appearance.colorPrimaryDisabled
	);

	const [ colorSecondary, setColorSecondary ] = useState(appearanceReducer.appearance.colorSecondary);
	const [ colorSecondaryVariant, setColorSecondaryVariant ] = useState(
		appearanceReducer.appearance.colorSecondaryVariant
	);
	const [ colorSecondaryVariant2, setColorSecondaryVariant2 ] = useState(
		appearanceReducer.appearance.colorSecondaryVariant2
	);

	const [ colorText, setColorText ] = useState(appearanceReducer.appearance.colorText);
	const [ colorError, setColorError ] = useState(appearanceReducer.appearance.colorError);
	const [ colorErrorBG, setColorErrorBG ] = useState(appearanceReducer.appearance.colorErrorBG);
	const [ colorWarning, setColorWarning ] = useState(appearanceReducer.appearance.colorWarning);
	const [ colorWarningBG, setColorWarningBG ] = useState(appearanceReducer.appearance.colorWarningBG);
	const [ colorSuccess, setColorSuccess ] = useState(appearanceReducer.appearance.colorSuccess);
	const [ colorSuccessBG, setColorSuccessBG ] = useState(appearanceReducer.appearance.colorSuccessBG);

	const [ colorMenuBG, setColorMenuBG ] = useState(appearanceReducer.appearance.colorMenuBG);
	const [ colorMenu, setColorMenu ] = useState(appearanceReducer.appearance.colorMenu);
	const [ colorHeaderBG, setColorHeaderBG ] = useState(appearanceReducer.appearance.colorHeaderBG);
	const [ colorHeader, setColorHeader ] = useState(appearanceReducer.appearance.colorHeader);
	const [ colorMainBG, setColorMainBG ] = useState(appearanceReducer.appearance.colorMainBG);

	const deleteImage = () => {
		// dispatch(
		// 	startRequest(
		// 		deleteImageProductParams({ imageId: productImgId, productId: productId }, deleteImageSuccess)
		// 	)
		// );
	};

	const deleteImageSuccess = (response) => {
		// dispatch(showFixedLabel('ImageDeleted', 'success'));
	};

	const postAppearance = () => {
		let createUrlImg = file ? URL.createObjectURL(file) : imgUrl;
		URL.revokeObjectURL(file);
		let createUrlFaviconImg = faviconFile ? URL.createObjectURL(faviconFile) : faviconImgUrl;
		URL.revokeObjectURL(faviconFile);
		let app = {
			imgUrl: createUrlImg,
			faviconImgUrl: createUrlFaviconImg,
			colorPrimary: colorPrimary,
			colorPrimaryVariant: colorPrimaryVariant,
			colorPrimaryVariant2: colorPrimaryVariant2,
			colorPrimaryPressed: colorPrimaryPressed,
			colorPrimaryHover: colorPrimaryHover,
			colorPrimaryDisabled: colorPrimaryDisabled,
			colorSecondary: colorSecondary,
			colorSecondaryVariant: colorSecondaryVariant,
			colorSecondaryVariant2: colorSecondaryVariant2,
			colorText: colorText,
			colorError: colorError,
			colorErrorBG: colorErrorBG,
			colorWarning: colorWarning,
			colorWarningBG: colorWarningBG,
			colorSuccess: colorSuccess,
			colorSuccessBG: colorSuccessBG,
			colorMenuBG: colorMenuBG,
			colorMenu: colorMenu,
			colorHeaderBG: colorHeaderBG,
			colorHeader: colorHeader,
			colorMainBG: colorMainBG
		};
		dispatch(changeAppearance(app));
	};

	return (
		<div className="settings">
			<h2 className="title">{t('Settings')}</h2>
			<div className="settings-form">
				<div className="row">
					<div className="col-lg-6">
						<h3 className="subtitle">{t('LogoMenuImage')}</h3>
						<p className="info">{t('RecomendedSettingsImageSize') + ' (300x75)'}</p>
						<div className="settings-filePreview-wrapper mb-5">
							{file !== '' || imgUrl !== '' ? (
								<div className="img-wrapper-horizontal">
									<FilePreview file={file} url={imgUrl} fileType={'Image'} />
									<div className="file-name text-center">{fileName}</div>
								</div>
							) : (
								<div className="img-wrapper-horizontal">
									<FilePreview file={''} url={'./empty-image.png'} fileType={'Image'} />
								</div>
							)}
							<div className="button-upload m-2">
								<input
									type="file"
									id="btnLoad"
									data-testid="btnLoadImage"
									onChange={(e) => {
										setFileName(e.target.files[0].name);
										setFile(e.target.files[0]);
									}}
									accept="image/png, image/jpeg"
									name="btnLoad"
									className="inputfile"
								/>
								<label htmlFor="btnLoad" className="btn-base_normal">
									<i className="material-icons-round">file_upload</i>
									<p>{file || imgUrl !== '' ? t('ChangeImage') : t('UploadImage')}</p>
								</label>
							</div>
							{file !== '' || imgUrl !== '' ? (
								<div className="m-2">
									<Button
										dataTestID="btnDeleteImg"
										btnText={t('Delete')}
										btnType="button"
										btnClass="btn-base_danger mr-2"
										onHandleBtnClick={() => {
											setFileName('');
											setFile('');
											setImgUrl('');
											deleteImage();
										}}
									/>
								</div>
							) : (
								''
							)}
						</div>
					</div>
					<div className="col-lg-6">
						<h3 className="subtitle">{t('FavIconImage')}</h3>
						<p className="info">{t('RecomendedSettingsFaviconSize') + ' (48x48)'}</p>
						<div className="settings-filePreview-wrapper mb-5">
							{faviconFile !== '' || faviconImgUrl !== '' ? (
								<div className="img-wrapper">
									<FilePreview file={faviconFile} url={faviconImgUrl} fileType={'Image'} />
									<div className="file-name text-center">{faviconFileName}</div>
								</div>
							) : (
								<div className="img-wrapper">
									<FilePreview file={''} url={'./empty-image.png'} fileType={'Image'} />
								</div>
							)}
							<div className="button-upload m-2">
								<input
									type="file"
									id="btnLoadFavicon"
									data-testid="btnLoadFavicon"
									onChange={(e) => {
										setFaviconFileName(e.target.files[0].name);
										setFaviconFile(e.target.files[0]);
									}}
									accept="image/*"
									name="btnLoad"
									className="inputfile"
								/>
								<label htmlFor="btnLoadFavicon" className="btn-base_normal">
									<i className="material-icons-round">file_upload</i>
									<p>{faviconFile || faviconImgUrl !== '' ? t('ChangeImage') : t('UploadImage')}</p>
								</label>
							</div>
							{file !== '' || faviconImgUrl !== '' ? (
								<div className="m-2">
									<Button
										dataTestID="btnDeleteImg"
										btnText={t('Delete')}
										btnType="button"
										btnClass="btn-base_danger mr-2"
										onHandleBtnClick={() => {
											setFaviconFileName('');
											setFaviconFile('');
											setFaviconImgUrl('');
											deleteImage();
										}}
									/>
								</div>
							) : (
								''
							)}
						</div>
					</div>
				</div>

				<h3 className="subtitle">{t('Colors')}</h3>
				<div className="row mb-5">
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="colorPrimary">
								{t('PrimaryColor')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="colorPrimary"
									name="colorPrimary"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorPrimary}
									change={(e) => {
										setColorPrimary(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="colorPrimaryVariant">
								{t('PrimaryColorVariant')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="colorPrimaryVariant"
									name="colorPrimaryVariant"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorPrimaryVariant}
									change={(e) => {
										setColorPrimaryVariant(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="colorPrimaryVariant2">
								{t('PrimaryColorVariant2')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="colorPrimaryVariant2"
									name="colorPrimaryVariant2"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorPrimaryVariant2}
									change={(e) => {
										setColorPrimaryVariant2(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="PrimaryColorPressed">
								{t('PrimaryColorPressed')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="PrimaryColorPressed"
									name="PrimaryColorPressed"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorPrimaryPressed}
									change={(e) => {
										setColorPrimaryPressed(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="PrimaryColorHover">
								{t('PrimaryColorHover')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="PrimaryColorHover"
									name="PrimaryColorHover"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorPrimaryHover}
									change={(e) => {
										setColorPrimaryHover(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="PrimaryColorDisabled">
								{t('PrimaryColorDisabled')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="PrimaryColorDisabled"
									name="PrimaryColorDisabled"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorPrimaryDisabled}
									change={(e) => {
										setColorPrimaryDisabled(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="row mb-5">
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="colorSecondary">
								{t('SecondaryColor')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="colorSecondary"
									name="colorSecondary"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorSecondary}
									change={(e) => {
										setColorSecondary(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="colorSecondaryVariant">
								{t('SecondaryColorVariant')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="colorSecondaryVariant"
									name="colorSecondaryVariant"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorSecondaryVariant}
									change={(e) => {
										setColorSecondaryVariant(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="colorSecondaryVariant2">
								{t('SecondaryColorVariant2')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="colorSecondaryVariant2"
									name="colorSecondaryVariant2"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorSecondaryVariant2}
									change={(e) => {
										setColorSecondaryVariant2(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="row mb-5">
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="ColorText">
								{t('ColorText')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="ColorText"
									name="ColorText"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorText}
									change={(e) => {
										setColorText(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="ColorError">
								{t('ColorError')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="ColorError"
									name="ColorError"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorError}
									change={(e) => {
										setColorError(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="ColorErrorBG">
								{t('ColorErrorBG')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="ColorErrorBG"
									name="ColorErrorBG"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorErrorBG}
									change={(e) => {
										setColorErrorBG(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="ColorWarning">
								{t('ColorWarning')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="ColorWarning"
									name="ColorWarning"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorWarning}
									change={(e) => {
										setColorWarning(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="ColorWarningBG">
								{t('ColorWarningBG')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="ColorWarningBG"
									name="ColorWarningBG"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorWarningBG}
									change={(e) => {
										setColorWarningBG(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="ColorSuccess">
								{t('ColorSuccess')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="ColorSuccess"
									name="ColorSuccess"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorSuccess}
									change={(e) => {
										setColorSuccess(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="ColorSuccessBG">
								{t('ColorSuccessBG')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="ColorSuccessBG"
									name="ColorSuccessBG"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorSuccessBG}
									change={(e) => {
										setColorSuccessBG(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="colorMenuBG">
								{t('ColorMenuBG')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="colorMenuBG"
									name="colorMenuBG"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorMenuBG}
									change={(e) => {
										setColorMenuBG(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="colorMenu">
								{t('ColorMenu')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="colorMenu"
									name="colorMenu"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorMenu}
									change={(e) => {
										setColorMenu(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="colorHeaderBG">
								{t('ColorHeaderBG')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="colorHeaderBG"
									name="colorHeaderBG"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorHeaderBG}
									change={(e) => {
										setColorHeaderBG(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
					<div className="col-md-3">
						<div className="input-container">
							<label className="inputLabel" htmlFor="colorHeader">
								{t('ColorHeader')}:
							</label>
							<div className="hexColor-picker">
								<ColorPickerComponent
									id="colorSecondaryVariant2"
									name="colorSecondaryVariant2"
									mode={'Palette'}
									modeSwitcher={false}
									showButtons={false}
									locale={GetCookie_culture()}
									enableOpacity={false}
									type="rgb"
									value={colorHeader}
									change={(e) => {
										setColorHeader(e.value + '');
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-3">
					<div className="input-container">
						<label className="inputLabel" htmlFor="colorMainBG">
							{t('ColorMainBG')}:
						</label>
						<div className="hexColor-picker">
							<ColorPickerComponent
								id="colorMainBG"
								name="colorMainBG"
								mode={'Palette'}
								modeSwitcher={false}
								showButtons={false}
								locale={GetCookie_culture()}
								enableOpacity={false}
								type="rgb"
								value={colorMainBG}
								change={(e) => {
									setColorMainBG(e.value + '');
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="btn-end">
				<Button
					dataTestID="btnSaveAppearance"
					btnText={t('SaveChanges')}
					btnType="button"
					btnClass="btn-base_normal"
					onHandleBtnClick={() => postAppearance()}
				/>
			</div>
		</div>
	);
};
export default Settings;
