import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button } from "@backoffice/components/FormComponents/index";
import { startRequest } from "@backoffice/actions/apiCall";
import { useTranslation } from "react-i18next";
import { ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import FilePreview from "../../../../../shared/FilePreview/FilePreview";
import { GetCookie_culture } from "../../../../../common/utils/cookiesManager";
import { showFixedLabel } from "../../../../../actions/fixedLabel";
import {
  postApperanceParams,
  deleteMediaAppearanceParams,
  getApperanceByIdParams,
} from "../BuildingDetailCalls/BuildingDetailCalls";
import "./TabAppearance.scss";

const TabAppearance = ({ building }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [backgroundFile, setBackgroundFile] = useState("");
  const [backgroundFileName, setBackgroundFileName] = useState("");
  const [backgroundImgUrl, setBackgroundImgUrl] = useState("");

  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [logoImgUrl, setLogoImgUrl] = useState("");

  const [faviconFile, setFaviconFile] = useState("");
  const [faviconFileName, setFaviconFileName] = useState("");
  const [faviconImgUrl, setFaviconImgUrl] = useState("");

  const [headerColorBG, setHeaderColorBG] = useState("");
  const [secondaryColor, setSecondaryColor] = useState("");

  useEffect(() => {
    dispatch(
      startRequest(getApperanceByIdParams(building.id, getAppearanceSuccess))
    );
  }, [building]);

  const getAppearanceSuccess = (response) => {
    if (response.mediaBackGround) {
      //   setBackgroundFile(response.mediaBackGround);
      setBackgroundImgUrl(response.mediaBackGround.mediaUrl);
    }

    if (response.mediaLogo) {
      //   setFile(response.mediaLogo);
      setLogoImgUrl(response.mediaLogo.mediaUrl);
    }

    if (response.mediaFavicon) {
      //   setFaviconFile(response.mediaFavicon);
      setFaviconImgUrl(response.mediaFavicon.mediaUrl);
    }

    setHeaderColorBG(response.colorHeaderBG);
    setSecondaryColor(response.colorSecondary);
  };

  const postAppearance = () => {
    let appearanceData = getAppearanceData();
    dispatch(
      startRequest(postApperanceParams(appearanceData, postAppearanceSuccess))
    );
  };

  const getAppearanceData = () => {
    let app = {
	  BackGround: backgroundFile,
      Logo: file,
      Favicon: faviconFile,

      BuildingId: building.id,
	  
      ColorSecondary:
        secondaryColor.length > 7
          ? secondaryColor.substring(0, secondaryColor.length - 2)
          : secondaryColor,
      ColorHeaderBG:
        headerColorBG.length > 7
          ? headerColorBG.substring(0, headerColorBG.length - 2)
          : headerColorBG,
    };
    return app;
  };

  const postAppearanceSuccess = (response) => {
    dispatch(showFixedLabel(t("AppearancePostSuccess"), "success"));
  };

  const deleteMediaAppearance = (appearanceMedia) => {
    dispatch(
      startRequest(
        deleteMediaAppearanceParams(
          { id: building.id, appearanceMedia: appearanceMedia },
          deleteMediaAppearanceSuccess
        )
      )
    );
  };

  const deleteMediaAppearanceSuccess = (response) => {
    dispatch(showFixedLabel(t("AppearanceMediaDeleteSuccess"), "success"));
  };

  const validateForm = () => {
    let formOK = true;
    if (false) {
      formOK = false;
    }
    return !formOK;
  };

  return (
    <div className="tab-appearance">
      <div className="appearance-form">
        <div className="row">
          <div className="col-lg-12">
            <h3 className="subtitle">{t("BackgroundImage")}</h3>
            <div className="appearance-filePreview-wrapper">
              {backgroundFile !== "" || backgroundImgUrl !== "" ? (
                <div className="background-img-wrapper">
                  <FilePreview
                    file={backgroundFile}
                    url={backgroundImgUrl}
                    fileType={"Image"}
                  />
                  <div className="file-name text-center">
                    {backgroundFileName}
                  </div>
                </div>
              ) : (
                <div className="background-img-wrapper">
                  <FilePreview
                    file={""}
                    url={"/empty-image.png"}
                    fileType={"Image"}
                  />
                </div>
              )}
              <div className="col-lg-3">
                <div className="button-upload">
                  <input
                    type="file"
                    id="btnBackgroudnImg"
                    data-testid="btnBackgroudnImg"
                    onChange={(e) => {
                      setBackgroundFileName(e.target.files[0].name);
                      setBackgroundFile(e.target.files[0]);
                    }}
                    accept="image/png, image/jpeg"
                    name="btnBackgroudnImg"
                    className="inputfile"
                  />
                  <label htmlFor="btnBackgroudnImg" className="btn-base_normal">
                    <i className="material-icons-round">file_upload</i>
                    <p>
                      {backgroundFile || backgroundImgUrl !== ""
                        ? t("ChangeImage")
                        : t("UploadImage")}
                    </p>
                  </label>
                </div>
              </div>
              {backgroundFile !== "" || backgroundImgUrl !== "" ? (
                <div className="col-lg-3">
                  <div className="button-delete">
                    <Button
                      dataTestID="btnDeleteImg"
                      btnText={t("Delete")}
                      btnType="button"
                      btnClass="btn-base_danger mr-2"
                      onHandleBtnClick={() => {
                        if (backgroundImgUrl !== "") {
                          deleteMediaAppearance(3);
                        }
                        setBackgroundFileName("");
                        setBackgroundFile("");
                        setBackgroundImgUrl("");
                      }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <h3 className="subtitle">{t("LogoMenuImage")}</h3>
            <p className="info">
              {t("RecomendedSettingsImageSize") + " (300x75)"}
            </p>
            <div className="appearance-filePreview-wrapper">
              {file !== "" || logoImgUrl !== "" ? (
                <div className="img-wrapper-horizontal">
                  <FilePreview
                    file={file}
                    url={logoImgUrl}
                    fileType={"Image"}
                  />
                  <div className="file-name text-center">{fileName}</div>
                </div>
              ) : (
                <div className="img-wrapper-horizontal">
                  <FilePreview
                    file={""}
                    url={"/empty-image.png"}
                    fileType={"Image"}
                  />
                </div>
              )}
              <div className="col-lg-3">
                <div className="button-upload">
                  <input
                    type="file"
                    id="btnLoad"
                    data-testid="btnLoadImage"
                    onChange={(e) => {
                      setFileName(e.target.files[0].name);
                      setFile(e.target.files[0]);
                    }}
                    accept="image/png, image/jpeg"
                    name="btnLoad"
                    className="inputfile"
                  />
                  <label htmlFor="btnLoad" className="btn-base_normal">
                    <i className="material-icons-round">file_upload</i>
                    <p>
                      {file || logoImgUrl !== ""
                        ? t("ChangeImage")
                        : t("UploadImage")}
                    </p>
                  </label>
                </div>
              </div>
              {file !== "" || logoImgUrl !== "" ? (
                <div className="col-lg-3">
                  <div className="button-delete">
                    <Button
                      dataTestID="btnDeleteImg"
                      btnText={t("Delete")}
                      btnType="button"
                      btnClass="btn-base_danger mr-2"
                      onHandleBtnClick={() => {
                        if (logoImgUrl !== "") {
                          deleteMediaAppearance(2);
                        }
                        setFileName("");
                        setFile("");
                        setLogoImgUrl("");
                      }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <h3 className="subtitle">{t("FavIconImage")}</h3>
            <p className="info">
              {t("RecomendedSettingsFaviconSize") + " (48x48)"}
            </p>
            <div className="appearance-filePreview-wrapper">
              {faviconFile !== "" || faviconImgUrl !== "" ? (
                <div className="img-wrapper">
                  <FilePreview
                    file={faviconFile}
                    url={faviconImgUrl}
                    fileType={"Image"}
                  />
                  <div className="file-name text-center">{faviconFileName}</div>
                </div>
              ) : (
                <div className="img-wrapper">
                  <FilePreview
                    file={""}
                    url={"/empty-image.png"}
                    fileType={"Image"}
                  />
                </div>
              )}
              <div className="col-lg-3">
                <div className="button-upload">
                  <input
                    type="file"
                    id="btnLoadFavicon"
                    data-testid="btnLoadFavicon"
                    onChange={(e) => {
                      setFaviconFileName(e.target.files[0].name);
                      setFaviconFile(e.target.files[0]);
                    }}
                    accept="image/*"
                    name="btnLoad"
                    className="inputfile"
                  />
                  <label htmlFor="btnLoadFavicon" className="btn-base_normal">
                    <i className="material-icons-round">file_upload</i>
                    <p>
                      {faviconFile || faviconImgUrl !== ""
                        ? t("ChangeImage")
                        : t("UploadImage")}
                    </p>
                  </label>
                </div>
              </div>
              {file !== "" || faviconImgUrl !== "" ? (
                <div className="col-lg-3">
                  <div className="button-delete">
                    <Button
                      dataTestID="btnDeleteImg"
                      btnText={t("Delete")}
                      btnType="button"
                      btnClass="btn-base_danger mr-2"
                      onHandleBtnClick={() => {
                        if (faviconImgUrl !== "") {
                          deleteMediaAppearance(1);
                        }
                        setFaviconFileName("");
                        setFaviconFile("");
                        setFaviconImgUrl("");
                      }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <div className="input-container">
              <label className="inputLabel" htmlFor="HeaderColorBG">
                {t("HeaderColorBG")}:
              </label>
              <div className="hexColor-picker">
                <ColorPickerComponent
                  id="HeaderColorBG"
                  name="HeaderColorBG"
                  mode={"Palette"}
                  modeSwitcher={false}
                  showButtons={false}
                  locale={GetCookie_culture()}
                  enableOpacity={false}
                  type="rgb"
                  value={headerColorBG}
                  change={(e) => {
                    setHeaderColorBG(e.value + "");
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-md-2">
            <div className="input-container">
              <label className="inputLabel" htmlFor="SecondaryColor">
                {t("SecondaryColor")}:
              </label>
              <div className="hexColor-picker">
                <ColorPickerComponent
                  id="SecondaryColor"
                  name="SecondaryColor"
                  mode={"Palette"}
                  modeSwitcher={false}
                  showButtons={false}
                  locale={GetCookie_culture()}
                  enableOpacity={false}
                  type="rgb"
                  value={secondaryColor}
                  change={(e) => {
                    setSecondaryColor(e.value + "");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btn-end">
        <Button
          dataTestID="btnSaveSpace"
          btnText={t("SaveChanges")}
          btnType="button"
          btnClass="btn-base_normal"
          isDisabled={validateForm()}
          onHandleBtnClick={() => postAppearance()}
        />
      </div>
    </div>
  );
};
export default TabAppearance;
