const ROUTE = {
	ROOT: '/',
	LOGIN: '/login',
	HOME: '/home',
	PROFILE: '/profile',
	USERS: '/users',
	USERDETAIL: '/users/:id',
	SETTINGS: '/settings',
	NEWS: '/news',
	NEWSDETAIL: '/news/:id',
	BOOKINGS: '/bookings',
	BOOKINGDETAIL: '/bookings/:id',
	MYBOOKINGS: '/mybookings',
	TENANTS: '/tenants',
	TENANTSDETAIL: '/tenants/:id',
	BUILDINGS: '/buildings',
	BUILDINGSDETAIL: '/buildings/:id',
	SPACES: '/spaces',
	SPACESDETAIL: '/spaces/:id',
	RESETPASSWORD: '/resetpassword',
	CONFIRMEMAIL: '/confirmemail',
	HOMETENANT: '/hometenant',
	BUILDINGTENANT: '/buildingstenant',
	DOCUMENTSTENANT: '/buildingdocumentenant',
	INCIDENTSTENANT: '/incidentstenant',
	INCIDENTSTENANTDETAIL: '/incidentstenant/:id',
	INCIDENTSTENANTCREATE: '/incidentstenantcreate',
	INCIDENTSTENANTCREATECUSTOM: '/incidentstenantcreate/:type',
	CONFIGURATIONTENANT: '/configurationtenant',
	CONFIGURATIONPROVIDER: '/configurationprovider',
	BOOKINGSTENANT: '/bookingstenant',
	BOOKINGSTENANTDETAIL: '/bookingstenant/:id',
	BOOKINGSTENANTCREATE: '/bookingstenantcreate',
	NEWSTENANT: '/newstenant',
	NEWSTENANTDETAIL: '/newstenantdetail/:id',
	SERVICESTENANT: '/servicetenant',
	SERVICESTENANTDETAIL: '/servicestenant/:id',
	CONTACTSTENANT: '/contacttenant',
	CONTACTSTENANTDETAIL: '/contacttenant/:id',
	PROFILETENANT: '/profiletenant',
	HOMEPROVIDER: '/homeprovider',
	INCIDENTSPROVIDER: '/incidentsprovider',
	INCIDENTSPROVIDERDETAIL: '/incidentsprovider/:id',
	LOGOUT: '/logout'
};
export { ROUTE };
