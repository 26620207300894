const getTenantByIdParams = (id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tenant/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const lockTenantAccess = (params, SuccessFn) => {
	const options = {
		method: 'POST',
		params,
		endpoint: '/Tenant/LockAppAccess',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};

	return options;
};

const resendTenantAccess =(data, SuccessFn) => {

	const options = {
		method: 'POST',
		data,
		endpoint: '/Tenant/ResendAccess',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};

	return options;
};

export { getTenantByIdParams, lockTenantAccess, resendTenantAccess };
