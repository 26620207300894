import moment from "moment";
import { GetCookie_formatDate } from "./cookiesManager";

const createURL = (file) => {
  let el = URL.createObjectURL(file);
  URL.revokeObjectURL(file);
  return el;
};

const getFileExtension = (fileName) => {
  if (fileName.length == 0) return "";
  var patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
  var fileExtension = fileName.match(patternFileExtension);
  return fileExtension.length > 2
    ? fileExtension[fileExtension.length - 1]
    : "";
};

const getMediaType = (file) => {
  let mediaType = 1;
  var patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
  var fileExtension = file.name.match(patternFileExtension);
  switch (fileExtension[1]) {
    case "jpg":
    case "jpeg":
    case "gif":
    case "bmp":
    case "png":
      mediaType = "Image";
      break;
    case "m4v":
    case "avi":
    case "mpg":
    case "mp4":
      mediaType = "Video";
      break;
    case "doc":
    case "docx":
    case "pdf":
      mediaType = "Document";
      break;

    default:
      mediaType = "Other";
      break;
  }
  return mediaType;
};

const cleanStringFromAccents = (value) => {
  var chars = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    à: "a",
    è: "e",
    ì: "i",
    ò: "o",
    ù: "u",
    ñ: "n",
    Á: "A",
    É: "E",
    Í: "I",
    Ó: "O",
    Ú: "U",
    À: "A",
    È: "E",
    Ì: "I",
    Ò: "O",
    Ù: "U",
  };
  var expr = /[áàéèíìóòúùñ]/gi;
  var res = value.replace(expr, function (e) {
    return chars[e];
  });
  return res;
};

const getMediaExtension = (file) => {
  var patternFileExtension = /\.([0-9a-z]+)(?:[\?#]|$)/i;
  var fileExtension = file.name.match(patternFileExtension);
  return fileExtension[1];
};

const isWeekend = (date) => {
  let format = GetCookie_formatDate();
  if (date !== null && date !== "") {
    let parsedDate = moment(date, format);
    let day = parsedDate.day();
    if (day === 6 || day === 0) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

const parsePrice = (price) => {
  let parsedPrice = parseFloat(price).toFixed(2);
  return parsedPrice.toString().replace(".", ",");
};

const parseDate = (date) => {
  let format = GetCookie_formatDate();
  let newDate = "";
  if (date !== "") {
    newDate = moment(date, "YYYY-MM-DD").format(format);
  }
  return newDate;
};

const parseDateWithFormat = (date, format) => {
  let newDate = "";
  if (date !== "") {
    newDate = moment(date, "YYYY-MM-DD").format(format);
  }
  return newDate;
};

const parseDateTime = (date, toLocal) => {
  let format = GetCookie_formatDate() + " HH:mm";
  let newDate = "";
  if (date !== "") {
    if (toLocal) {
      newDate = moment.utc(date, "YYYY-MM-DD HH:mm").local().format(format);
    } else {
      newDate = moment(date, "YYYY-MM-DD HH:mm").format(format);
    }
  }
  return newDate;
};

const parseTime = (time) => {
  let newTime = "";
  if (time !== "") {
    newTime = moment(time).format("HH:mm");
  }
  return newTime;
};

const parseDateGetTime = (date) => {
  let newTime = "";
  if (date !== "") {
    newTime = moment(date, "YYYY-MM-DD HH:mm").format("HH:mm");
  }
  return newTime;
};

const parseDateBE = (date) => {
  let format = GetCookie_formatDate();

  let newDate = "";
  if (date !== "") {
    newDate = moment(date, format).format("YYYY-MM-DD");
  }
  return newDate;
};

const parseDateTimeBE = (date, toUTC) => {
  let format = GetCookie_formatDate() + " HH:mm";
  let newDate = "";
  if (date.trim() !== "") {
    if (toUTC) {
      newDate = moment(date, format)
        .utc()
        .format("YYYY-MM-DD HH:mm:00.0000000Z");
    } else {
      newDate = moment(date, format).format("YYYY-MM-DD HH:mm");
    }
  }
  return newDate;
};

const parseDateToUtc = (date) => {
  let newDate = new Date(date);
  let res = "";
  if (date.trim() !== "") {
    res = newDate.toISOString();
  }
  return res;
};


const parsePriceCurrency = (price) => {
  let priceParsed = "";
  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
  });

  priceParsed = formatter.format(price);
  return priceParsed;
};

const parsePriceCurrencyNoDecimal = (price) => {
  let priceParsed = "";
  const formatter = new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 0,
  });

  priceParsed = formatter.format(price);
  return priceParsed;
};

const validateIban = (IBAN) => {
  //Se pasa a Mayusculas
  IBAN = IBAN.toUpperCase();
  //Se quita los blancos de principio y final.
  IBAN = IBAN.trim();
  IBAN = IBAN.replace(/\s/g, ""); //Y se quita los espacios en blanco dentro de la cadena

  var letra1, letra2, num1, num2;
  var isbanaux;
  var numeroSustitucion;
  //La longitud debe ser siempre de 24 caracteres
  if (IBAN.length != 24) {
    return false;
  }

  // Se coge las primeras dos letras y se pasan a números
  letra1 = IBAN.substring(0, 1);
  letra2 = IBAN.substring(1, 2);
  num1 = getnumIBAN(letra1);
  num2 = getnumIBAN(letra2);
  //Se sustituye las letras por números.
  isbanaux = String(num1) + String(num2) + IBAN.substring(2);
  // Se mueve los 6 primeros caracteres al final de la cadena.
  isbanaux = isbanaux.substring(6) + isbanaux.substring(0, 6);

  //Se calcula el resto, llamando a la función modulo97, definida más abajo
  let resto = modulo97(isbanaux);
  if (resto == 1) {
    return true;
  } else {
    return false;
  }
};

function modulo97(iban) {
  var parts = Math.ceil(iban.length / 7);
  var remainer = "";

  for (var i = 1; i <= parts; i++) {
    remainer = String(parseFloat(remainer + iban.substr((i - 1) * 7, 7)) % 97);
  }

  return remainer;
}

const getnumIBAN = (letra) => {
  let ls_letras = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  return ls_letras.search(letra) + 10;
};

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const dateIsBetween = (date, from, to, inclusivity, format) => {
  let isBetween = moment(date, format).isBetween(
    from,
    to,
    undefined,
    inclusivity
  );
  return isBetween;
};

const saveByteArray = (reportName, byte, type) => {
  var blob = new Blob([byte], { type: type });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  var fileName = reportName;
  link.download = fileName;
  link.click();
};

const base64ToArrayBuffer = (base64) => {
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    var ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export {
  createURL,
  cleanStringFromAccents,
  getMediaType,
  getFileExtension,
  getMediaExtension,
  isWeekend,
  parsePrice,
  parsePriceCurrencyNoDecimal,
  parseTime,
  parseDate,
  parseDateWithFormat,
  parseDateGetTime,
  parseDateTime,
  parseDateTimeBE,
  formatBytes,
  parseDateBE,
  validateIban,
  parsePriceCurrency,
  dateIsBetween,
  saveByteArray,
  base64ToArrayBuffer,
  parseDateToUtc
};
