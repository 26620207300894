import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { startRequest } from "../../../../actions/apiCall";
import { parseDate } from "../../../../common/utils/parseUtils";
import {
  getTenantByIdParams,
  lockTenantAccess,
  resendTenantAccess,
} from "./TenantDetailCalls/TenantDetailCalls";
import PopUpGenericDelete from "../../../../components/PopUpGenericDelete/PopUpGenericDelete";
import { closeModal, openModal } from "../../../../actions/popup";
import { showFixedLabel } from "../../../../actions/fixedLabel";
import { Button } from "@backoffice/components/FormComponents/index";
import { ROUTE } from "../../../../common/constants/routes";
import "./TenantDetail.scss";

const TenantDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [tenantId, setTenantId] = useState(id);
  const [tenantName, setTenantName] = useState("");
  const [firstSurname, setFirstSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [cohabitants, setCohabitants] = useState("");
  const [appAccess, setAppAccess] = useState(true);
  const [numberAppAccess, setNumberAppAccess] = useState("");
  const [lastAppAccess, setLastAppAccess] = useState("");
  const [totalIncidents, setTotalIncidents] = useState("");
  const [totalBookings, setTotalBookings] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [address, setAddress] = useState("");
  const [startContractDate, setStartContractDate] = useState("");
  const [endContractDate, setEndContractDate] = useState("");
  const [yearsLiving, setYearsLiving] = useState("");
  const [bookingNumber, setBookingNumber] = useState("");
  const [spaceMoreBooked, setSpaceMoreBooked] = useState("");
  const [lastBooking, setLastBooking] = useState("");
  const [openedIncidents, setOpenedIncidents] = useState("");
  const [closedIncidents, setClosedIncidents] = useState("");
  const [lastIncidentCreated, setLastIncidentCreated] = useState("");
  const [tenantDocuments, setTenantDocuments] = useState([]);

  useEffect(() => {
    getTenantById();
  }, []);

  const getTenantById = () => {
    dispatch(startRequest(getTenantByIdParams(tenantId, getTenantByIdSuccess)));
  };

  const getTenantByIdSuccess = (response) => {
    setTenantName(response.tenantName);
    setFirstSurname(response.firstSurname);
    setEmail(response.email);
    setPhone(response.phone);
    setDocumentId(response.documentId);
    setCohabitants(response.cohabitants);
    setAppAccess(response.appAccess);
    setNumberAppAccess(response.numberAppAccess);
    if (response.lastAppAccess) {
      setLastAppAccess(parseDate(response.lastAppAccess));
    }
    setTotalIncidents(response.totalIncidents);
    setTotalBookings(response.totalBookings);
    setBuildingName(response.livingPlaceInfo.buildingName);
    setAddress(response.livingPlaceInfo.address);
    setStartContractDate(parseDate(response.livingPlaceInfo.startContractDate));
    setEndContractDate(parseDate(response.livingPlaceInfo.endContractDate));
    setYearsLiving(response.livingPlaceInfo.yearsLiving);
    setBookingNumber(response.tenantBookingResource.bookingNumber);
    setSpaceMoreBooked(response.tenantBookingResource.spaceMoreBooked);
    setLastBooking(parseDate(response.tenantBookingResource.lastBooking));
    setOpenedIncidents(response.tenantIncidents.openedIncidents);
    setClosedIncidents(response.tenantIncidents.closedIncidents);
    setLastIncidentCreated(
      parseDate(response.tenantIncidents.lastIncidentCreated)
    );
    setTenantDocuments(response.media);
  };

  const openLockAppAccess = (action, tenant) => {
    const optionValues = {
      id: "lock-tenant" + tenantId + "-" + Math.random(),
      dataTestId: "lock-tenant",
      cssClass: "modal-header__danger",
      title: appAccess ? t("Lock") : t("Unlock"),
    };

    const options = {
      ...optionValues,
      body: (
        <PopUpGenericDelete
          action={action}
          id={optionValues.id}
          buttonDeleteName={appAccess ? t("Lock") : t("Unlock")}
          deleteMessage={
            appAccess ? t("LockConfirmMessage") : t("UnlockConfirmMessage")
          }
          handleButtonDelete={() => lockAppAccess(tenant)}
          handleButtonCancel={() => dispatch(closeModal())}
        />
      ),
    };

    dispatch(openModal(options));
  };

  const lockAppAccess = (tenant) => {
    dispatch(
      startRequest(lockTenantAccess({ id: tenantId }, lockTenantSuccess))
    );
  };

  const lockTenantSuccess = (response) => {
    if (appAccess) {
      dispatch(showFixedLabel("UserLockedSuccess", "success"));
    } else {
      dispatch(showFixedLabel("UserUnlockSuccess", "success"));
    }
  };

  const openResendAccess = (action, tenant) => {
    const optionValues = {
      id: "access-tenant" + tenantId + "-" + Math.random(),
      cssClass: "modal-header__danger",
      dataTestId: "access-tenant",
      title: t("ResendAppAccess"),
    };

    const options = {
      ...optionValues,
      body: (
        <PopUpGenericDelete
          action={action}
          id={optionValues.id}
          buttonDeleteName={t("ResendAppAccess")}
          deleteMessage={"ResendAccessConfirmMessage"}
          handleButtonDelete={() => resendAppAccess(tenant)}
          handleButtonCancel={() => dispatch(closeModal())}
        />
      ),
    };

    dispatch(openModal(options));
  };

  const resendAppAccess = (tenant) => {
    dispatch(
      startRequest(
        resendTenantAccess({ email: "email" }, resendTenantAccessSuccess)
      )
    );
  };

  const resendTenantAccessSuccess = (response) => {
    dispatch(showFixedLabel("ResendAppAccessSuccess", "success"));
  };

  const renderTenantDocuments = () => {
    let html = [];
    if (tenantDocuments.length > 0) {
      tenantDocuments.forEach((document, i) => {
        html.push(
          <div className="container-document" key={i + "-document"}>
            <a
              className="download-link"
              id={i + "-link-document"}
              href={document.mediaURL}
              target="_blank"
              rel="noreferrer"
            >
              {document.name}
            </a>
          </div>
        );
      });
      return html;
    }
  };

  return (
    <div className="tenantDetail">
      <div className="return">
        <div className="col-lg-2 offsett-lg-10">
          <Link className="go-back" to={ROUTE.TENANTS} role="link">
            <span
              className="material-icons-round"
              data-testid={"btn-goback"}
              id={"btn-goback"}
              aria-hidden={true}
            >
              arrow_back
            </span>
            <div className="go-back__text">{t("GoBack")}</div>
          </Link>
        </div>
      </div>
      <h2 className="title">{t("TenantFile")}</h2>
      <div className="row-wrapper-main">
        <div className="row-wrapper">
          <div className="col-lg-6">
            <div className="tenant-personal-information">
              <div className="tenant-personal-information-row">
                <h3 className="tenant-personal-information-row-title">
                  {t("Name")}
                </h3>
                <p className="tenant-personal-information-row-value">
                  {tenantName}{" "}
                </p>
              </div>
              <div className="tenant-personal-information-row">
                <h3 className="tenant-personal-information-row-title">
                  {t("FirstSurname")}
                </h3>
                <p className="tenant-personal-information-row-value">
                  {firstSurname}{" "}
                </p>
              </div>
              <div className="tenant-personal-information-row">
                <h3 className="tenant-personal-information-row-title">
                  {t("Email")}
                </h3>
                <p className="tenant-personal-information-row-value">
                  {email}{" "}
                </p>
              </div>

              <div className="tenant-personal-information-row">
                <h3 className="tenant-personal-information-row-title">
                  {t("DocumentId")}
                </h3>
                <p className="tenant-personal-information-row-value">
                  {documentId}{" "}
                </p>
              </div>

              <div className="tenant-personal-information-row">
                <h3 className="tenant-personal-information-row-title">
                  {t("Cohabitants")}
                </h3>
                <p className="tenant-personal-information-row-value">
                  {cohabitants}{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row-wrapper">
              <div className="column-wrapper content-info">
                <div className="card-tenant center">
                  <p className="total-number">{numberAppAccess}</p>
                  {lastAppAccess ? (
                    <p className="app-access">{t("AppAccess")}</p>
                  ) : (
                    <p className="total-app-access">{t("AppAccess")}</p>
                  )}
                  {lastAppAccess && (
                    <p className="last-app-access">
                      {t("LastAppAccess")}: {lastAppAccess}
                    </p>
                  )}
                </div>
              </div>
              <div className="column-wrapper content-info">
                <div className="card-tenant center">
                  <p className="total-number">{totalIncidents}</p>
                  <p className="total-incidents">{t("IncidentTenant")}</p>
                </div>
              </div>
              <div className="column-wrapper content-info">
                <div className="card-tenant center">
                  <p className="total-number">{totalBookings}</p>
                  <p className="total-bookings">{t("AppBookings")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row-wrapper">
          <div className="col-lg-6">
            <div className="header-wrapper">
              <h3 className="subtitle">{t("HouseInformation")}</h3>
              <div className="card-tenant">
                <div className="card-tenant-row">
                  <h3 className="card-tenant-row-title">{t("BuildingName")}</h3>
                  <p className="card-tenant-row-value">{buildingName} </p>
                </div>

                <div className="card-tenant-row">
                  <h3 className="card-tenant-row-title">{t("Address")}</h3>
                  <p className="card-tenant-row-value">{address} </p>
                </div>

                <div className="card-tenant-row">
                  <h3 className="card-tenant-row-title">
                    {t("StartContractDate")}
                  </h3>
                  <p className="card-tenant-row-value">{startContractDate} </p>
                </div>
                <div className="card-tenant-row">
                  <h3 className="card-tenant-row-title">
                    {t("EndContractDate")}
                  </h3>
                  <p className="card-tenant-row-value">{endContractDate} </p>
                </div>

                <div className="card-tenant-row">
                  <h3 className="card-tenant-row-title">{t("YearsLiving")}</h3>
                  <p className="card-tenant-row-value">{yearsLiving} </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="header-wrapper">
              <h3 className="subtitle">
                {t("AppBookings")}
                {" (" + totalBookings + ")"}
              </h3>
              <Link className="view-all" to={ROUTE.BOOKINGS}>
                <span>{t("ViewAll")}</span>
              </Link>
              <div className="card-tenant">
                <div className="card-tenant-row">
                  <h3 className="card-tenant-row-title">
                    {t("BookingNumber")}
                  </h3>
                  <p className="card-tenant-row-value">{bookingNumber} </p>
                </div>

                <div className="card-tenant-row">
                  <h3 className="card-tenant-row-title">
                    {t("SpaceMoreBooked")}
                  </h3>
                  <p className="card-tenant-row-value">{spaceMoreBooked} </p>
                </div>

                <div className="card-tenant-row">
                  <h3 className="card-tenant-row-title">{t("LastBooking")}</h3>
                  <p className="card-tenant-row-value">
                    {lastBooking ? lastBooking : ""}{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row-wrapper">
          <div className="col-lg-6">
            <div className="header-wrapper">
              <h3 className="subtitle">
                {t("Documentation")}
                {" (" + tenantDocuments.length + ")"}
              </h3>
              <div className="card-tenant">
                <div className="row">{renderTenantDocuments()}</div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="header-wrapper">
              <h3 className="subtitle">
                {t("IncidentTenant")}
                {" (" + totalIncidents + ")"}
              </h3>
              <div className="card-tenant">
                <div className="card-tenant-row">
                  <h3 className="card-tenant-row-title">
                    {t("OpenedIncidents")}
                  </h3>
                  <p className="card-tenant-row-value">{openedIncidents}</p>
                </div>

                <div className="card-tenant-row">
                  <h3 className="card-tenant-row-title">
                    {t("ClosedIncidents")}
                  </h3>
                  <p className="card-tenant-row-value">{closedIncidents}</p>
                </div>

                <div className="card-tenant-row">
                  <h3 className="card-tenant-row-title">
                    {t("LastIncidentCreated")}
                  </h3>
                  <p className="card-tenant-row-value">{lastIncidentCreated}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="end-button-wrapper">
        <Button
          dataTestId="btn-lock-access"
          btnText={appAccess ? t("LockAppAccess") : t("UnlockAppAccess")}
          btnType="button"
          btnClass="btn-base_outline btn-lock-access"
          onHandleBtnClick={() => {
            openLockAppAccess();
          }}
        />

        <Button
          dataTestId="btn-resend-access"
          btnText={t("ResendAppAccess")}
          btnType="button"
          btnClass="btn-base_normal btn-resend-access"
          onHandleBtnClick={() => {
            openResendAccess();
          }}
        />
      </div>
    </div>
  );
};
export default TenantDetail;
