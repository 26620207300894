const getTenantsParams = (SuccessFn) => {
    const options = {
        method: 'GET',
        endpoint: '/Tenant/GetAll',
        onSuccess: (response) => {
            SuccessFn(response.data.data);
        }
    };
    return options;
};

const getBuildingsParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Building/GetNameId',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export {getTenantsParams, getBuildingsParams};