import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { startRequest } from '@backoffice/actions/apiCall';
import { Input, Button, Combo } from '@backoffice/components/FormComponents/index';
import {
	ColumnDirective,
	ColumnsDirective,
	Filter,
	GridComponent,
	Group,
	Resize,
	Inject,
	Page,
	Sort,
	ExcelExport
} from '@syncfusion/ej2-react-grids';
import { openModal, closeModal } from '../../../../actions/popup';
import ResponsiveGridWrapper from '../../../../shared/ResponsiveGridWrapper/ResponsiveGridWrapper';
import { validateEmail, validateDNI } from '../../../../common/validations/common';
import { cleanStringFromAccents } from '../../../../common/utils/parseUtils';
import { showFixedLabel } from '../../../../actions/fixedLabel';
import { ROUTE } from '../../../../common/constants/routes';
import { validCIF } from '../../../../common/utils/validationSpanishID';
import PopUpGenericDelete from '../../../../components/PopUpGenericDelete/PopUpGenericDelete';
import { getRolesParams, getUserByIdParams, postUserParams, putUserParams, deleteUserParams } from './UserDetailCalls';
import PopUpContactStaff from './PopUpContactStaff';

import './UserDetail.scss';

const UserDetail = () => {
	const pageSettings = { pageSize: 50 };
	const grid = useRef(null);

	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const { id } = useParams();
	const [ userId, setUserId ] = useState(id);
	const [ search, setSearch ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ emailError, setEmailError ] = useState(false);
	const [ nif, setNif ] = useState('');
	const [ errorNif, setErrorNif ] = useState(false);
	const [ name, setName ] = useState('');
	const [ surnames, setSurnames ] = useState('');
	const [ role, setRole ] = useState('');
	const [ phone, setPhone ] = useState('');
	const [ phoneCountry, setPhoneCountry ] = useState('');
	const [ roleOptions, setRoleOptions ] = useState([]);
	const [ companyName, setCompanyName ] = useState('');
	const [ companyCIF, setCompanyCIF ] = useState('');
	const [ companyCIFError, setCompanyCIFError ] = useState(false);
	const [ companyPhone, setCompanyPhone ] = useState('');
	const [ companyEmail, setCompanyEmail ] = useState('');
	const [ companyEmailError, setCompanyEmailError ] = useState(false);
	const [ contactData, setContactData ] = useState([]);
	const [ allContactData, setAllContactData ] = useState([]);
	const [ companySector, setCompanySector ] = useState('');

	useEffect(() => {
		getRoles();
	}, []);

	const getRoles = () => {
		dispatch(startRequest(getRolesParams(getRolesSuccess)));
	};

	const getRolesSuccess = (response) => {
		setRoleOptions(response);
		if (userId !== '0') {
			getUserById();
		}
	};

	const getUserById = () => {
		dispatch(startRequest(getUserByIdParams(userId, getUserByIdSuccess)));
	};

	const getUserByIdSuccess = (response) => {
		setName(response.name);
		setSurnames(response.surnames);
		setEmail(response.email);
		setPhoneCountry(response.prefixPhoneNumber);
		setNif(response.documentId);
		setRole(response.userRoles[0]);
		setPhone(response.phoneNumber);
	};

	const getUserForm = () => {
		return {
			id: userId,
			email: email,
			name: name,
			surnames: surnames,
			prefixPhoneNumber: phoneCountry,
			phoneNumber: phone,
			documentId: nif,
			rolId: role !== '' ? role.id : ''
		};
	};

	const validateForm = () => {
		let formOK = true;
		if (email === '' || emailError || name === '' || surnames === '' || role === '' || errorNif || nif === '') {
			formOK = false;
		}
		return !formOK;
	};

	const postUser = () => {
		let user = getUserForm();
		if (userId === '0') {
			dispatch(
				startRequest(
					postUserParams(
						user,
						(response) => postUserSuccess('add', response),
						(response) => postUserError('add', response)
					)
				)
			);
		} else {
			dispatch(
				startRequest(
					putUserParams(
						user,
						(response) => postUserSuccess('edit', response),
						(response) => postUserError('edit', response)
					)
				)
			);
		}
	};

	const postUserSuccess = (action, response) => {
		history.push(ROUTE.USERS);
		if (action === 'add') {
			dispatch(showFixedLabel('UserAddSuccess', 'success'));
		} else {
			dispatch(showFixedLabel('UserEditSuccess', 'success'));
		}
	};

	const postUserError = (action, response) => {
		dispatch(showFixedLabel(response.data.errors[0].errorMessageKey, 'error'));
	};

	const openPopUpContactStaff = (action, contactStaff) => {
		const optionValues = {
			id: 'contactPopUp' + Math.random(),
			dataTestId: 'contactPopUp' + Math.random(),
			cssClass: 'modal-header__add',
			title: t('ContactPerson')
		};

		const options = {
			...optionValues,
			body: (
				<PopUpContactStaff
					action={action}
					optionValues={optionValues}
					contactStaff={contactStaff}
					addContactStaff={addContactStaff}
					editContactStaff={editContactStaff}
				/>
			)
		};

		dispatch(openModal(options));
	};

	const GetDataBySearch = (data = [], searching = '') => {
		searching = cleanStringFromAccents(searching);
		const result = data.filter((element) => {
			return (
				cleanStringFromAccents(element.name).toLowerCase().includes(searching.toLowerCase()) ||
				cleanStringFromAccents(element.surnames).toLowerCase().includes(searching.toLowerCase()) ||
				element.nif.toLowerCase().includes(searching.toLowerCase()) ||
				element.email.toLowerCase().includes(searching.toLowerCase())
			);
		});
		return result;
	};

	const filterContactStaff = () => {
		if (search === '') {
			setContactData(allContactData);
		}
		const newData = GetDataBySearch(allContactData, search);
		if (newData === '') {
			setContactData(allContactData);
		}
		setContactData(newData);
	};

	const addContactStaff = (contact) => {
		setContactData([ ...grid.current.dataSource, contact ]);
		setAllContactData([ ...grid.current.dataSource, contact ]);
		dispatch(closeModal());
	};

	const editContactStaff = (contact) => {
		let newContactData = grid.current.dataSource;
		newContactData[contact.index] = contact;
		setContactData(newContactData);
		setAllContactData(newContactData);
		grid.current.refresh();
		dispatch(closeModal());
	};

	const deleteContactStaff = (contact) => {
		let newContactData = [ ...grid.current.dataSource ];
		newContactData.splice(contact.index, 1);
		setContactData(newContactData);
		setAllContactData(newContactData);
		grid.current.refresh();
	};

	const openDeleteUsers = (action, user) => {
		const optionValues = {
			id: 'delete-users-' + user.id + '-' + Math.random(),
			dataTestId: 'delete-user',
			cssClass: 'modal-header__danger',
			title: t('Delete')
		};

		const options = {
			...optionValues,
			body: (
				<PopUpGenericDelete
					action={action}
					id={optionValues.id}
					deleteMessage={'DeleteConfirmMessage'}
					handleButtonDelete={() => deleteUsers(user)}
					handleButtonCancel={() => dispatch(closeModal())}
				/>
			)
		};

		dispatch(openModal(options));
	};

	const deleteUsers = (user) => {
		dispatch(startRequest(deleteUserParams({ id: user.id }, deleteUsersSuccess)));
	};

	const deleteUsersSuccess = (response) => {
		history.push(ROUTE.USERS);
		dispatch(showFixedLabel('UserDeleteSuccess', 'success'));
	};

	const actionsTemplate = (rowData) => {
		return (
			<div className="action-template">
				<span
					className="material-icons-round"
					data-testid={`btnEditStandar_${rowData.index}`}
					id={`btnEditStandar_${rowData.index}`}
					onClick={() => openPopUpContactStaff('edit', rowData)}
					type="button"
				>
					mode_edit
				</span>
				<span
					className="material-icons-round"
					id={`btnDeleteStandar_${rowData.index}`}
					data-testid={`btnDeleteStandar_${rowData.index}`}
					onClick={() => {
						openDeleteContactStaff('delete', rowData);
					}}
					type="button"
				>
					delete
				</span>
			</div>
		);
	};

	const openDeleteContactStaff = (action, contactStaff) => {
		const optionValues = {
			id: 'delete-contactStaff-' + contactStaff.index + '-' + Math.random(),
			dataTestId: 'delete-contactStaff',
			cssClass: 'modal-header__danger',
			title: t('Delete')
		};

		const options = {
			...optionValues,
			body: (
				<PopUpGenericDelete
					action={action}
					id={optionValues.id}
					deleteMessage={'DeleteConfirmMessage'}
					handleButtonDelete={() => deleteContactStaff(contactStaff)}
					handleButtonCancel={() => dispatch(closeModal())}
				/>
			)
		};

		dispatch(openModal(options));
	};

	return (
		<div className="userdetail">
			<div className="return">
				<div className="col-lg-2 offsett-lg-10">
					<Link className="go-back" to={ROUTE.USERS} role="link">
						<span
							className="material-icons-round"
							data-testid={'btn-goback'}
							id={'btn-goback'}
							aria-hidden={true}
						>
							arrow_back
						</span>
						<div className="go-back__text">{t('GoBack')}</div>
					</Link>
				</div>
			</div>

			<h2 className="title">{userId === '0' ? t('CreateUser') : t('UserDetail')}</h2>

			<div className="userdetail-form">
				<div className="row">
					<div className="col-lg-3">
						<Input
							dataTestID="txtName"
							inputName="txtName"
							inputType="text"
							isReadOnly={userId !== '0' && role.name === 'Tenant'}
							inputLabel={t('Name')}
							inputID="user-name"
							isDisabled={false}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setName(val);
							}}
							value={name}
							isRequired={true}
						/>
					</div>
					<div className="col-lg-3">
						<Input
							dataTestID="txtSurnames"
							inputName="txtSurnames"
							inputType="text"
							inputLabel={t('Surnames')}
							isReadOnly={userId !== '0' && role.name === 'Tenant'}
							inputID="user-firstsurname"
							isDisabled={false}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setSurnames(val);
							}}
							value={surnames}
							isRequired={true}
						/>
					</div>
					<div className="col-lg-3">
						<Input
							dataTestID="txtEmail"
							inputName="txtEmail"
							inputType="text"
							inputLabel={t('Email')}
							inputID="user-email"
							isReadOnly={userId !== '0'}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setEmail(val);
								setEmailError(validateEmail(val).error);
							}}
							value={email}
							isError={emailError}
							errorMessage={email === '' ? t('IsRequired') : t('EmailFormatIncorrect')}
							isRequired={true}
						/>
					</div>
					<div className="col-lg-3">
						<Input
							dataTestID="txtDni"
							inputName="txtDni"
							inputType="text"
							isReadOnly={userId !== '0' && role.name === 'Tenant'}
							inputLabel={t('DNI')}
							inputID="contact-dni"
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setNif(val);
							}}
							onHandleBlur={(e) => {
								let val = e.currentTarget.value;
								if (validateDNI(val)) {
									setErrorNif(false);
								} else {
									setErrorNif(true);
								}
							}}
							isError={errorNif}
							errorMessage={t('DNIFormatIncorrect')}
							value={nif}
							isRequired={true}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-3">
						<Combo
							id="cmbRol"
							dataTestID="cmbRol"
							comboLabel={t('Rol')}
							inputValue={role}
							isDisabled={userId !== '0' && role.name === 'Tenant'}
							defaultInputValue=""
							isSearchable={false}
							getOptionValue={(option) => option['id']}
							getOptionLabel={(option) => t(option['name'])}
							options={roleOptions}
							isMultiple={false}
							isRequired={true}
							handleChange={(e) => {
								setRole(e);
							}}
						/>
					</div>
					<div className="col-lg-1">
						<Input
							dataTestID="txtPhoneCountry"
							inputName="txtPhoneCountry"
							inputType="text"
							inputLabel={t('Prefix')}
							isReadOnly={userId !== '0' && role.name === 'Tenant'}
							inputID="user-phone-country"
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setPhoneCountry(val);
							}}
							value={phoneCountry}
							isRequired={false}
						/>
					</div>
					<div className="col-lg-2">
						<Input
							dataTestID="txtPhone"
							inputName="txtPhone"
							inputType="text"
							inputLabel={t('Phone')}
							isReadOnly={userId !== '0' && role.name === 'Tenant'}
							inputID="user-phone"
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setPhone(val);
							}}
							value={phone}
							isRequired={false}
						/>
					</div>
				</div>
			</div>

			{/* {role.name === 'Operator' ? renderOperatorForm() : ''}
			{role.name === 'Operator' ? renderContactPersonForm() : ''} */}

			<div className="btn-end">
				{userId !== '0' && role && role.name !== 'Tenant' ? (
					<Button
						dataTestID="btnConfirmarDeleteAceptar"
						btnText={t('Delete')}
						btnType="button"
						btnClass="btn-base_danger"
						onHandleBtnClick={() => {
							openDeleteUsers('delete', getUserForm());
						}}
					/>
				) : (
					''
				)}
				<div className="button-save" hidden={userId !== '0' && role.name === 'Tenant'}>
					<Button
						dataTestID="btnSaveAddUser"
						btnText={t('SaveChanges')}
						btnType="button"
						btnClass="btn-base_normal"
						isDisabled={validateForm()}
						onHandleBtnClick={() => postUser()}
					/>
				</div>
			</div>
		</div>
	);
};
export default UserDetail;
