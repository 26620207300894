import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { startRequest } from '@backoffice/actions/apiCall';
import { ROUTE } from '../../../../common/constants/routes';
import { openModal, closeModal } from '../../../../actions/popup';
import PopUpGenericDelete from '../../../../components/PopUpGenericDelete/PopUpGenericDelete';
import { showFixedLabel } from '../../../../actions/fixedLabel';
import { Input, Button, Textarea, Combo, Switch, Checkbox } from '@backoffice/components/FormComponents/index';
import FilePreview from '../../../../shared/FilePreview/FilePreview';
import PopUpMedia from './PopUpMedia/PopUpMedia';
import PopUpImage from './PopUpImage/PopUpImage';
import {
	getSpacesByIdParams,
	getSpaceTypesParams,
	getBuildingsParams,
	postSpaceParams,
	putSpaceParams,
	deleteMediaParams
} from './SpacesDetailCalls/SpacesDetailCalls';
import './SpacesDetail.scss';
import Uploader from '../../../../shared/Uploader/Uploader';

const SpacesDetail = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const { id } = useParams();
	const [ spaceId, setSpaceId ] = useState(id);
	const dispatch = useDispatch();
	const [ building, setBuilding ] = useState('');
	const [ uploader, setUploader ] = useState('');
	const [ name, setName ] = useState('');
	const [ buildingOptions, setBuildingOptions ] = useState([]);
	const [ capacity, setCapacity ] = useState('');
	// const [ spaceType, setSpaceType ] = useState('');
	// const [ spaceTypeOptions, setSpaceTypeOptions ] = useState([]);
	const [ bookingAvailable, setBookingAvailable ] = useState(true);
	const [ description, setDescription ] = useState('');
	const [ instructions, setInstructions ] = useState('');
	const [ weekdaysCheck, setWeekdaysCheck ] = useState([
		{ name: 'Monday', checked: false },
		{ name: 'Tuesday', checked: false },
		{ name: 'Wednesday', checked: false },
		{ name: 'Thursday', checked: false },
		{ name: 'Friday', checked: false },
		{ name: 'Saturday', checked: false },
		{ name: 'Sunday', checked: false }
	]);

	const [ minTime, setMinTime ] = useState('');
	const [ maxTime, setMaxTime ] = useState('');
	const [ rangeTime, setRangeTime ] = useState([
		{ id: '0', name: '0h' },
		{ id: '1', name: '1h' },
		{ id: '2', name: '2h' },
		{ id: '3', name: '3h' }
	]);

	const [ startTime, setStartTime ] = useState('');
	const [ endTime, setEndTime ] = useState('');
	const [ isPartialTime, setIsPartialTime ] = useState(false);
	const [ partialStartTime, setPartialStartTime ] = useState('');
	const [ partialEndTime, setPartialEndTime ] = useState('');

	const [ media, setMedia ] = useState([]);

	useEffect(
		() => {
			if (buildingOptions.length > 0) {
				if (spaceId !== '0') {
					getSpaceById();
				}
			}
		},
		[ buildingOptions ]
	);

	useEffect(() => {
		getBuildings();

		// getSpaceTypes();
	}, []);

	// const getSpaceTypes = () => {
	// 	dispatch(startRequest(getSpaceTypesParams(getSpaceTypesSuccess)));
	// };

	// const getSpaceTypesSuccess = (response) => {
	// 	setSpaceTypeOptions(response);
	// 	getBuildings();
	// };

	const getBuildings = () => {
		dispatch(startRequest(getBuildingsParams(getBuildingsSuccess)));
	};

	const getBuildingsSuccess = (response) => {
		setBuildingOptions(response);
	};

	const getSpaceById = () => {
		dispatch(startRequest(getSpacesByIdParams(spaceId, getSpaceByIdSuccess)));
	};

	const getSpaceByIdSuccess = (response) => {
		setName(response.name);
		setBookingAvailable(response.canBooking);
		setCapacity(response.capacity);
		setDescription(response.description);
		setInstructions(response.instructions);
		setMaxTime(rangeTime.filter((time) => parseInt(time.id) === parseInt(response.maxTime))[0]);
		setMinTime(rangeTime.filter((time) => parseInt(time.id) === parseInt(response.minTime))[0]);
		setStartTime(response.from);
		setEndTime(response.to);
		setIsPartialTime(response.usePartialTime);
		setPartialStartTime(response.fromPartial);
		setPartialEndTime(response.toPartial);
		let weekdays = [
			{ name: 'Monday', checked: response.monday },
			{ name: 'Tuesday', checked: response.tuesday },
			{ name: 'Wednesday', checked: response.wednesday },
			{ name: 'Thursday', checked: response.thursday },
			{ name: 'Friday', checked: response.friday },
			{ name: 'Saturday', checked: response.saturday },
			{ name: 'Sunday', checked: response.sunday }
		];
		setWeekdaysCheck(weekdays);
		setBuilding(buildingOptions.filter((building) => building.id === response.buildingId)[0]);
		// setSpaceType(spaceTypeOptions.filter((space) => space.id === response.spaceTypeId)[0]);
		setMedia(response.media);
	};

	const getSpaceForm = () => {
		return {
			id: spaceId,
			name: name,
			buildingId: building.id,
			// spaceTypeId: spaceType.id,
			capacity: capacity,
			canBooking: bookingAvailable,
			description: description,
			instructions: instructions,
			monday: weekdaysCheck[0].checked,
			tuesday: weekdaysCheck[1].checked,
			wednesday: weekdaysCheck[2].checked,
			thursday: weekdaysCheck[3].checked,
			friday: weekdaysCheck[4].checked,
			saturday: weekdaysCheck[5].checked,
			sunday: weekdaysCheck[6].checked,
			minTime: minTime.id,
			maxTime: maxTime.id,
			usePartialTime: isPartialTime,
			fromPartial: partialStartTime,
			toPartial: partialEndTime,
			from: startTime,
			to: endTime
		};
	};

	const validateForm = () => {
		let formOK = true;
		if (
			building === '' ||
			// spaceType === '' ||
			((bookingAvailable && startTime === '') ||
				(bookingAvailable && endTime === '') ||
				(bookingAvailable && maxTime === '') ||
				(bookingAvailable && minTime === '')) ||
			capacity === '' ||
			parseInt(capacity) === 0 ||
			((isPartialTime && partialStartTime === '') || (isPartialTime && partialEndTime === ''))
		) {
			formOK = false;
		}
		return !formOK;
	};

	const postSpace = () => {
		let space = getSpaceForm();
		if (spaceId === '0') {
			dispatch(
				startRequest(
					postSpaceParams(
						space,
						(response) => postSpaceSuccess('add', response),
						(response) => postSpaceError('add', response)
					)
				)
			);
		} else {
			dispatch(
				startRequest(
					putSpaceParams(
						space,
						(response) => postSpaceSuccess('edit', response),
						(response) => postSpaceError('edit', response)
					)
				)
			);
		}
	};

	const postSpaceSuccess = (action, response) => {
		history.push(ROUTE.SPACES);
		if (action === 'add') {
			dispatch(showFixedLabel('SpaceAddSuccess', 'success'));
		} else {
			dispatch(showFixedLabel('SpaceEditSuccess', 'success'));
		}
	};

	const postSpaceError = (action, response) => {
		dispatch(showFixedLabel(response.data.errors[0].errorMessageKey, 'error'));
	};

	const handleWeekdaysChecks = (e, el) => {
		const newWeekdaysCheck = [ ...weekdaysCheck ];
		newWeekdaysCheck.forEach((check) => {
			if (check.name === el.name) {
				check.checked = e.target.checked;
			}
		});
		setWeekdaysCheck(newWeekdaysCheck);
	};

	const renderWeekdaysCheck = () => {
		let html = [];

		weekdaysCheck.forEach((check, i) => {
			html.push(
				<div className="weekdays-check" key={'chk-' + i}>
					<Checkbox
						inputID={'chck-' + check.name}
						inputName={'chck-' + check.name}
						inputLabel={t(check.name)}
						inputChecked={check.checked}
						onHandleChange={(e) => {
							handleWeekdaysChecks(e, check);
						}}
					/>
				</div>
			);
		});

		return html;
	};

	const popUpUploadMedia = () => {
		const optionValues = {
			id: 'mediaPopUp',
			dataTestId: 'mediaPopUp',
			cssClass: 'modal-header__add',
			title: t('AddMedia')
		};

		const options = {
			...optionValues,
			body: <PopUpMedia optionValues={optionValues} spaceId={spaceId} handleSubmit={() => getSpaceById()} />
		};

		dispatch(openModal(options));
	};

	const openDeleteMedia = (media) => {
		const optionValues = {
			id: 'delete-media-' + media.id + '-' + Math.random(),
			cssClass: 'modal-header__danger',
			title: t('Delete')
		};

		const options = {
			...optionValues,
			body: (
				<PopUpGenericDelete
					action={'delete'}
					id={optionValues.id}
					deleteMessage={'DeleteConfirmMessage'}
					handleButtonDelete={() => deleteMedia(media)}
					handleButtonCancel={() => dispatch(closeModal())}
				/>
			)
		};

		dispatch(openModal(options));
	};

	const deleteMedia = (media) => {
		dispatch(startRequest(deleteMediaParams({ mediaId: media.id, spaceId: spaceId }, deleteMediaSuccess)));
	};

	const deleteMediaSuccess = (response) => {
		dispatch(showFixedLabel('MediaDeleteSuccess', 'success'));
		getSpaceById();
	};

	const imageBigger = (md) => {
		const optionValues = {
			id: 'imageBigger' + Math.random(),
			dataTestId: 'imageBigger' + Math.random(),
			cssClass: 'modal-header__add',
			title: t('Multimedia')
		};

		const options = {
			...optionValues,
			body: <PopUpImage action={'image'} optionValues={optionValues} md={md} />
		};

		dispatch(openModal(options));
	};

	const renderMedia = () => {
		let html = [];
		media.forEach((md, i) => {
			html.push(
				<div className="media-card" key={i}>
					<div className="img-wrapper" onClick={() => imageBigger(md)}>
						<FilePreview file={''} url={md.mediaThumbnailUrl} fileType={'Image'} />
					</div>
					<div className="media-body-wrapper">
						<div className="media-name">{md.originalFileName}</div>
						<span
							className="material-icons-round delete-btn"
							id={`btnDelete`}
							data-testid={`btnDelete`}
							type="button"
							onClick={() => openDeleteMedia(md)}
						>
							delete
						</span>
					</div>
				</div>
			);
		});

		return html;
	};

	return (
		<div className="spacesdetail">
			<div className="return">
				<div className="col-lg-2 offsett-lg-10">
					<Link className="go-back" to={ROUTE.SPACES} role="link">
						<span
							className="material-icons-round"
							data-testid={'btn-goback'}
							id={'btn-goback'}
							aria-hidden={true}
						>
							arrow_back
						</span>
						<div className="go-back__text">{t('GoBack')}</div>
					</Link>
				</div>
			</div>
			<h2 className="title">{spaceId === '0' ? t('CreateSpaces') : t('SpacesDetail')}</h2>
			<div className="spacesdetail-form">
				<p className="subtitle">{t('Information')}</p>
				<div className="row">
					<div className="col-lg-3">
						<Input
							dataTestID="txtName"
							inputName="txtName"
							inputType="text"
							inputLabel={t('Name')}
							inputID="space-name"
							isDisabled={true}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setName(val);
							}}
							value={name}
							isRequired={true}
						/>
					</div>
					<div className="col-lg-3">
						<Combo
							isDisabled={true}
							id="cmbBuilding"
							dataTestID="cmbBuilding"
							comboLabel={t('Building')}
							inputValue={building}
							comboPlaceholder={t('Select') + ' ' + t('Building') + '...'}
							defaultInputValue=""
							isRequired={true}
							isSearchable={false}
							getOptionValue={(option) => option['id']}
							getOptionLabel={(option) => t(option['name'])}
							options={buildingOptions}
							isMultiple={false}
							handleChange={(e) => {
								setBuilding(e);
							}}
						/>
					</div>
					{/* <div className="col-lg-3">
						<Combo
							id="cmbSpaceType"
							dataTestID="cmbSpaceType"
							comboLabel={t('SpaceType')}
							inputValue={spaceType}
							comboPlaceholder={t('Select') + ' ' + t('SpaceType') + '...'}
							defaultInputValue=""
							isRequired={true}
							isSearchable={false}
							getOptionValue={(option) => option['id']}
							getOptionLabel={(option) => t(option['name'])}
							options={spaceTypeOptions}
							isMultiple={false}
							handleChange={(e) => {
								setSpaceType(e);
							}}
						/>
					</div> */}
					<div className="col-lg-2">
						<Input
							dataTestID="txtCapacity"
							inputName="txtCapacity"
							inputType="number"
							inputLabel={t('Capacity')}
							inputID="space-capacity"
							isDisabled={false}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setCapacity(val);
							}}
							value={capacity}
							isRequired={true}
						/>
					</div>
					<div className="col-lg-1">
						<Switch
							inputLabel={t('Booking')}
							isRequired={false}
							inputValue={bookingAvailable}
							handleChange={(value) => {
								setBookingAvailable(value);
							}}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-xl-6">
						<Textarea
							dataTestID="txtDescription"
							inputName="txtDescription"
							inputLabel={t('Description')}
							inputType="text"
							inputValue={description}
							isDisabled={false}
							inputOutline={true}
							onHandleChange={(e) => {
								setDescription(e.currentTarget.value);
							}}
						/>
					</div>
					<div className="col-xl-6">
						<Textarea
							dataTestID="txtInstructions"
							inputName="txtInstructions"
							inputLabel={t('Instructions')}
							inputType="text"
							inputValue={instructions}
							isDisabled={false}
							inputOutline={true}
							onHandleChange={(e) => {
								setInstructions(e.currentTarget.value);
							}}
						/>
					</div>
				</div>
				{bookingAvailable ? (
					<React.Fragment>
						<p className="subtitle">{t('BookingSettings')}</p>
						<div className="weekdays-checbox-wrapper">{renderWeekdaysCheck()}</div>
						<div className="row">
							<div className="col-lg-3">
								<Combo
									id="cmbMinTime"
									dataTestID="cmbMinTime"
									comboLabel={t('MinTime')}
									inputValue={minTime}
									comboPlaceholder={t('Select') + ' ' + t('MinTime') + '...'}
									defaultInputValue=""
									isRequired={true}
									isSearchable={false}
									getOptionValue={(option) => option['id']}
									getOptionLabel={(option) => t(option['name'])}
									options={rangeTime}
									isMultiple={false}
									handleChange={(e) => {
										setMinTime(e);
									}}
								/>
							</div>
							<div className="col-lg-3">
								<Combo
									id="cmbMaxTime"
									dataTestID="cmbMaxTime"
									comboLabel={t('MaxTime')}
									inputValue={maxTime}
									comboPlaceholder={t('Select') + ' ' + t('MaxTime') + '...'}
									defaultInputValue=""
									isRequired={true}
									isSearchable={false}
									getOptionValue={(option) => option['id']}
									getOptionLabel={(option) => t(option['name'])}
									options={rangeTime}
									isMultiple={false}
									handleChange={(e) => {
										setMaxTime(e);
									}}
								/>
							</div>
							<div className="col-lg-3">
								<div className="hours-wrapper">
									<Input
										inputID="startTime"
										inputName="startTime"
										inputOutline={true}
										inputType="time"
										inputLabel={t('Since')}
										isRequired={true}
										isDisabled={false}
										isError={false}
										value={startTime}
										inputStep={15}
										minTime="00:00"
										maxTime="23:45"
										onHandleChange={(e) => setStartTime(e.target.value)}
									/>
								</div>
							</div>
							<div className="col-lg-3">
								<div className="hours-wrapper">
									<Input
										inputID="endTime"
										inputName="endTime"
										inputOutline={true}
										inputType="time"
										inputLabel={t('Until')}
										isRequired={true}
										isDisabled={false}
										isError={false}
										value={endTime}
										inputStep={15}
										minTime="00:00"
										maxTime="23:45"
										onHandleChange={(e) => setEndTime(e.target.value)}
									/>
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-3">
								<div className="partialTime-check">
									<Checkbox
										inputID={'chck-partialTime'}
										inputName={'chck-partialTime'}
										inputLabel={t('PartialTime')}
										inputChecked={isPartialTime}
										onHandleChange={(e) => {
											setIsPartialTime(e.target.checked);
											setPartialStartTime('');
											setPartialEndTime('');
										}}
									/>
								</div>
							</div>
						</div>
						{isPartialTime ? (
							<div className="row">
								<div className="col-lg-3">
									<div className="hours-wrapper">
										<Input
											inputID="startTime"
											inputName="startTime"
											inputOutline={true}
											inputType="time"
											inputLabel={t('Since')}
											isRequired={true}
											isDisabled={false}
											isError={false}
											value={partialStartTime}
											inputStep={15}
											minTime="00:00"
											maxTime="23:45"
											onHandleChange={(e) => setPartialStartTime(e.target.value)}
										/>
									</div>
								</div>
								<div className="col-lg-3">
									<div className="hours-wrapper">
										<Input
											inputID="endTime"
											inputName="endTime"
											inputOutline={true}
											inputType="time"
											inputLabel={t('Until')}
											isRequired={true}
											isDisabled={false}
											isError={false}
											value={partialEndTime}
											inputStep={15}
											minTime="00:00"
											maxTime="23:45"
											onHandleChange={(e) => setPartialEndTime(e.target.value)}
										/>
									</div>
								</div>
							</div>
						) : (
							''
						)}
					</React.Fragment>
				) : (
					''
				)}
				<div hidden={spaceId === '0'}>
					<p className="subtitle">{t('Multimedia')}</p>
					<p className="subtitle-media">{t('MultimediaContent')}</p>
					<div className="col-lg-3">
						<Button
							dataTestID="btnUploadMedia"
							btnText={t('UploadMedia')}
							btnType="button"
							btnClass="btn-base_normal upload-button"
							onHandleBtnClick={() => popUpUploadMedia()}
						/>
					</div>
					<div className="media-wrapper">{renderMedia()}</div>
					{/* <div className="row">
						<div className="col-lg-6">
							<div className="uploader-wrapper">
								<Uploader
									isDisabled={false}
									allowedExtensions={'.jpg,.png'}
									setUploader={setUploader}
								/>
							</div>
						</div>
						<div className="col-lg-6">
							<div className="media-wrapper">{renderMedia()}</div>
						</div>
					</div> */}
				</div>
			</div>
			<div className="btn-end">
				<Button
					dataTestID="btnSaveSpace"
					btnText={t('SaveChanges')}
					btnType="button"
					btnClass="btn-base_normal"
					isDisabled={validateForm()}
					onHandleBtnClick={() => postSpace()}
				/>
			</div>
		</div>
	);
};
export default SpacesDetail;
