import React from 'react';
import { useTranslation } from 'react-i18next';
import { Player } from 'video-react';
import { getMediaType } from '../../common/utils/parseUtils';
import { Button } from '@backoffice/components/FormComponents/index';

import './FilePreview.scss';

const FilePreview = ({ file, url, fileType }) => {
	const { t } = useTranslation();

	const createURL = (file) => {
		if (file !== '') {
			let el = URL.createObjectURL(file);
			URL.revokeObjectURL(file);
			return el;
		} else {
			return '/empty-image.png';
		}
	};

	const renderFromFile = () => {
		let htmlFile = [];
		switch (getMediaType(file)) {
			case 'Image':
				htmlFile.push(
					<img
						id={'render-file' + Math.random()}
						key={'render-file' + Math.random()}
						aria-hidden={true}
						className="img-item-popup"
						alt={' '}
						src={createURL(file)}
					/>
				);
				break;
			case 'Video':
				htmlFile.push(
					<div
						id={'render-file' + Math.random()}
						key={'render-file' + Math.random()}
						className="col-md-12"
						aria-hidden={true}
					>
						<Player>
							<source src={createURL(file)} />
						</Player>
					</div>
				);
				break;
			default:
				htmlFile.push(
					<div
						id={'render-file' + Math.random()}
						key={'render-file' + Math.random()}
						className="col-md-10 m-auto"
						aria-hidden={true}
					>
						<img className="img-item-popup" alt={' '} src={'/doc.png'} />
					</div>
				);
				break;
		}
		return htmlFile;
	};

	const renderFromUrl = () => {
		let htmlUrl = [];
		switch (fileType) {
			case 'Image':
			case 'image/jpeg':
			case 'image/png':
				htmlUrl.push(
					<img
						id={'render-url-' + Math.random()}
						key={'render-url-' + Math.random()}
						className="img-item-popup"
						aria-hidden={true}
						alt={' '}
						src={url}
					/>
				);
				break;
			case 'Video':
				htmlUrl.push(
					<div
						id={'render-url-' + Math.random()}
						key={'render-url-' + Math.random()}
						className="col-md-12"
						aria-hidden={true}
					>
						<Player>
							<source src={url} />
						</Player>
					</div>
				);
				break;

			case 'Document':
				htmlUrl.push(
					<Button
						btnText={t('Download')}
						btnType="button"
						icon="download"
						btnClass="btn-base_outline export-excel-button"
						dataTestID="export-excel-button"
						onHandleBtnClick={() => {
							window.open(url, '_blank').focus();
						}}
					/>
				);
				break;
			default:
				htmlUrl.push(
					<img
						id={'render-url-' + Math.random()}
						key={'render-url-' + Math.random()}
						className="img-item-popup"
						alt={' '}
						aria-hidden={true}
						src={url}
					/>
				);
				break;
		}
		return htmlUrl;
	};

	return <div className="file-preview">{file !== '' ? renderFromFile() : url !== '' ? renderFromUrl() : ''}</div>;
};

export default FilePreview;
