import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input, Button, Checkbox } from '@backoffice/components/FormComponents/index';
import {
	postContactParams,
	getContactDetailByIdParams,
	getRolesListParams
} from '../BuildingDetailCalls/BuildingDetailCalls';
import { startRequest } from '../../../../../actions/apiCall';
import { showFixedLabel } from '../../../../../actions/fixedLabel';
import { Combo, Textarea } from '../../../../../components/FormComponents';
import { closeModal } from '../../../../../actions/popup';
import './PopUpContact.scss';
import { validateEmail } from '../../../../../common/validations/common';

const PopUpContact = ({ action, contactId, buildingId, handleSubmit }) => {
	const { t } = useTranslation();
	const [ name, setName ] = useState('');
	const [ phoneCountry, setPhoneCountry ] = useState('');
	const [ phone, setPhone ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ emailError, setEmailError ] = useState(false);
	const [ role, setRole ] = useState('');
	const [ url, setUrl ] = useState('');
	const [ address, setAddress ] = useState('');
	const [ rolesOptions, setRolesOptions ] = useState([]);
	const [ observations, setObservations ] = useState('');
	const dispatch = useDispatch();

	const [ weekdaysCheck, setWeekdaysCheck ] = useState([
		{ name: 'Monday', checked: false },
		{ name: 'Tuesday', checked: false },
		{ name: 'Wednesday', checked: false },
		{ name: 'Thursday', checked: false },
		{ name: 'Friday', checked: false },
		{ name: 'Saturday', checked: false },
		{ name: 'Sunday', checked: false }
	]);
	const [ startTime, setStartTime ] = useState('');
	const [ endTime, setEndTime ] = useState('');
	const [ isPartialTime, setIsPartialTime ] = useState(false);
	const [ partialStartTime, setPartialStartTime ] = useState('');
	const [ partialEndTime, setPartialEndTime ] = useState('');

	useEffect(() => {
		getRoles();
	}, []);

	useEffect(
		() => {
			if (contactId !== '' && rolesOptions.length > 0) {
				getContactById();
			}
		},
		[ rolesOptions ]
	);

	const getRoles = () => {
		dispatch(startRequest(getRolesListParams(getRolesListParamsSuccess)));
	};

	const getRolesListParamsSuccess = (response) => {
		setRolesOptions(response);
	};

	const getContactById = () => {
		dispatch(startRequest(getContactDetailByIdParams(buildingId, contactId, getContactDetailByIdSuccess)));
	};

	const getContactDetailByIdSuccess = (response) => {
		setRole({ id: response.positionTypeId, name: response.positionTypeName });
		setName(response.name);
		setPhone(response.phoneNumber);
		setPhoneCountry(response.prefixPhoneNumber);
		setEmail(response.email);
		setObservations(response.observations);
		setWeekdaysCheck([
			{ name: 'Monday', checked: response.monday },
			{ name: 'Tuesday', checked: response.tuesday },
			{ name: 'Wednesday', checked: response.wednesday },
			{ name: 'Thursday', checked: response.thursday },
			{ name: 'Friday', checked: response.friday },
			{ name: 'Saturday', checked: response.saturday },
			{ name: 'Sunday', checked: response.sunday }
		]);
		setStartTime(response.from);
		setEndTime(response.to);
		setIsPartialTime(response.usePartialTime);
		setPartialStartTime(response.fromPartial);
		setPartialEndTime(response.toPartial);
		setAddress(response.address);
		setUrl(response.url);
	};

	const validateForm = () => {
		let formOK = true;
		if (
			name === '' ||
			phone === '' ||
			emailError ||
			email === '' ||
			role === '' ||
			startTime === '' ||
			endTime === ''
		) {
			formOK = false;
		}
		return !formOK;
	};

	const getContactForm = () => {
		return {
			contactId: contactId,
			buildingId: buildingId,
			positionTypeId: role.id,
			name: name,
			email: email,
			observations: observations,
			prefixPhoneNumber: phoneCountry,
			PhoneNumber: phone,
			monday: weekdaysCheck[0].checked,
			tuesday: weekdaysCheck[1].checked,
			wednesday: weekdaysCheck[2].checked,
			thursday: weekdaysCheck[3].checked,
			friday: weekdaysCheck[4].checked,
			saturday: weekdaysCheck[5].checked,
			sunday: weekdaysCheck[6].checked,
			usePartialTime: isPartialTime,
			fromPartial: partialStartTime,
			toPartial: partialEndTime,
			from: startTime,
			to: endTime,
			url: url,
			address: address
		};
	};

	const addContact = () => {
		let contact = getContactForm();
		dispatch(startRequest(postContactParams(contact, postContactSuccess, postContactError)));
	};

	const postContactSuccess = (response) => {
		dispatch(closeModal());
		dispatch(showFixedLabel('ContactAddSuccess', 'success'));
		handleSubmit();
	};

	const postContactError = (response) => {
		dispatch(showFixedLabel('ContactAddError', 'success'));
	};

	const handleWeekdaysChecks = (e, el) => {
		const newWeekdaysCheck = [ ...weekdaysCheck ];
		newWeekdaysCheck.forEach((check) => {
			if (check.name === el.name) {
				check.checked = e.target.checked;
			}
		});
		setWeekdaysCheck(newWeekdaysCheck);
	};

	const renderWeekdaysCheck = () => {
		let html = [];

		weekdaysCheck.forEach((check, i) => {
			html.push(
				<div className="weekdays-check" key={'chk-' + i}>
					<Checkbox
						inputID={'chck-' + check.name}
						inputName={'chck-' + check.name}
						inputLabel={t(check.name)}
						inputChecked={check.checked}
						onHandleChange={(e) => {
							handleWeekdaysChecks(e, check);
						}}
					/>
				</div>
			);
		});

		return html;
	};

	return (
		<div className="contact-popup">
			<div className="contact-form">
				<div className="row">
					<div className="col-lg-3">
						<Combo
							id="cmbPosition"
							dataTestID="cmbPosition"
							comboLabel={t('Position')}
							inputValue={role}
							comboPlaceholder={t('Select') + ' ' + t('Position') + '...'}
							defaultInputValue=""
							isRequired={true}
							isSearchable={false}
							getOptionValue={(option) => option['id']}
							getOptionLabel={(option) => t(option['name'])}
							options={rolesOptions}
							isMultiple={false}
							handleChange={(e) => {
								setRole(e);
							}}
						/>
					</div>
					<div className="col-lg-3">
						<Input
							dataTestID="txtName"
							inputName="txtName"
							inputType="text"
							inputLabel={t('Name')}
							inputID="contact-name"
							isDisabled={false}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setName(val);
							}}
							value={name}
							isRequired={true}
						/>
					</div>
					<div className="col-lg-1 prefix">
						<Input
							dataTestID="txtPhoneCountry"
							inputName="txtPhoneCountry"
							inputType="text"
							inputLabel={t('Prefix')}
							inputID="user-phone-country"
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setPhoneCountry(val);
							}}
							value={phoneCountry}
							isRequired={false}
						/>
					</div>
					<div className="col-lg-2">
						<Input
							dataTestID="txtPhone"
							inputName="txtPhone"
							inputType="text"
							inputLabel={t('Phone')}
							inputID="contact-phone"
							isDisabled={false}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setPhone(val);
							}}
							value={phone}
							isRequired={true}
						/>
					</div>
					<div className="col-lg-3">
						<Input
							dataTestID="txtEmail"
							inputName="txtEmail"
							inputType="text"
							inputLabel={t('Email')}
							inputID="contact-email"
							isDisabled={false}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setEmail(val);
								setEmailError(validateEmail(val).error);
							}}
							value={email}
							errorMessage={email === '' ? t('IsRequired') : t('EmailFormatIncorrect')}
							isRequired={true}
						/>
					</div>
				</div>
				<div className="weekdays-checbox-wrapper">{renderWeekdaysCheck()}</div>
				<div className="row">
					<div className="col-lg-3">
						<div className="hours-wrapper">
							<Input
								inputID="startTime"
								inputName="startTime"
								inputOutline={true}
								inputType="time"
								inputLabel={t('Since')}
								isRequired={true}
								isDisabled={false}
								isError={false}
								value={startTime}
								inputStep={15}
								minTime={'08:00'}
								maxTime={endTime !== '' ? endTime : '22:00'}
								onHandleChange={(e) => setStartTime(e.target.value)}
							/>
						</div>
					</div>
					<div className="col-lg-3">
						<div className="hours-wrapper">
							<Input
								inputID="endTime"
								inputName="endTime"
								inputOutline={true}
								inputType="time"
								inputLabel={t('Until')}
								isRequired={true}
								isDisabled={false}
								isError={false}
								value={endTime}
								inputStep={15}
								minTime={startTime !== '' ? startTime : '08:00'}
								maxTime="22:00"
								onHandleChange={(e) => setEndTime(e.target.value)}
							/>
						</div>
					</div>
					<div className="col-lg-3">
						<div className="partialTime-check">
							<Checkbox
								inputID={'chck-partialTime'}
								inputName={'chck-partialTime'}
								inputLabel={t('PartialTime')}
								inputChecked={isPartialTime}
								onHandleChange={(e) => {
									setIsPartialTime(e.target.checked);
									setPartialStartTime('');
									setPartialEndTime('');
								}}
							/>
						</div>
					</div>
				</div>
				{isPartialTime ? (
					<div className="row">
						<div className="col-lg-3">
							<div className="hours-wrapper">
								<Input
									inputID="startTime"
									inputName="startTime"
									inputOutline={true}
									inputType="time"
									inputLabel={t('Since')}
									isRequired={true}
									isDisabled={false}
									isError={false}
									value={partialStartTime}
									inputStep={15}
									minTime={endTime}
									maxTime={partialEndTime !== '' ? partialEndTime : '22:00'}
									onHandleChange={(e) => setPartialStartTime(e.target.value)}
								/>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="hours-wrapper">
								<Input
									inputID="endTime"
									inputName="endTime"
									inputOutline={true}
									inputType="time"
									inputLabel={t('Until')}
									isRequired={true}
									isDisabled={false}
									isError={false}
									value={partialEndTime}
									inputStep={30}
									minTime={partialStartTime !== '' ? partialStartTime : '08:00'}
									maxTime="22:00"
									onHandleChange={(e) => setPartialEndTime(e.target.value)}
								/>
							</div>
						</div>
					</div>
				) : (
					''
				)}
			</div>
			<div className="row">
				<div className="col-xl-6">
					<Textarea
						dataTestID="txtObservations"
						inputName="txtObservations"
						inputLabel={t('Observations')}
						inputType="text"
						inputValue={observations}
						isDisabled={false}
						inputOutline={true}
						onHandleChange={(e) => {
							setObservations(e.currentTarget.value);
						}}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-lg-3">
					<Input
						dataTestID="txtUrl"
						inputName="txtUrl"
						inputType="text"
						inputLabel={t('URL')}
						inputID="contact-url"
						isDisabled={false}
						onHandleChange={(e) => {
							let val = e.currentTarget.value;
							setUrl(val);
						}}
						value={url}
						isRequired={false}
					/>
				</div>
				<div className="col-lg-3">
					<Input
						dataTestID="txtAddress"
						inputName="txtAddress"
						inputType="text"
						inputLabel={t('Address')}
						inputID="contact-address"
						isDisabled={false}
						onHandleChange={(e) => {
							let val = e.currentTarget.value;
							setAddress(val);
						}}
						value={address}
						isRequired={false}
					/>
				</div>
			</div>
			<div className="btn-end">
				<Button
					dataTestID="btnSaveContact"
					btnText={t('SaveChanges')}
					btnType="button"
					btnClass="btn-base_normal"
					isDisabled={validateForm()}
					onHandleBtnClick={() => addContact()}
				/>
			</div>
		</div>
	);
};

export default PopUpContact;
