import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { getNewsParams } from './NewsTenantCalls/NewsTenantCalls';
import { startRequest } from '@backoffice/actions/apiCall';
import FilePreview from '../../../../shared/FilePreview/FilePreview';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import { Input } from '@backoffice/components/FormComponents/index';
import { cleanStringFromAccents, parseDate } from '../../../../common/utils/parseUtils';
import './NewsTenant.scss';
import { ROUTE } from '../../../../common/constants/routes';

const NewsTenant = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [ news, setNews ] = useState([]);
	const [ newsCount, setNewsCount ] = useState(0);
	const [ allNews, setAllNews ] = useState([]);
	const [ search, setSearch ] = useState('');
	const isMobile = useMediaQuery({ query: '(max-width: 450px)' });
	const isSmallMobile = useMediaQuery({ query: '(max-width: 300px)' });

	useEffect(() => {
		getAllNews();
	}, []);

	useEffect(
		() => {
			filterNews();
		},
		[ search ]
	);

	const getAllNews = () => {
		dispatch(startRequest(getNewsParams(getNewsSuccess)));
	};

	const getNewsSuccess = (response) => {
		setNews(response.list);
		setNewsCount(response.count);
		setAllNews(response.list);
	};

	const filterBySearch = (data = [], searching = '') => {
		const result = data.filter((element) => {
			return (
				cleanStringFromAccents(element.title.toLowerCase()).includes(searching.toLowerCase()) ||
				cleanStringFromAccents(element.shortDescription.toLowerCase()).includes(searching.toLowerCase())
			);
		});
		return result;
	};

	const filterNews = () => {
		if (search === '') {
			setNews(allNews);
		} else {
			const newData = filterBySearch(allNews, search);
			if (newData === '') {
				setNews(allNews);
			}
			setNews(newData);
		}
	};

	const renderNews = () => {
		let html = [];
		news.forEach((newDetail, i) => {
			html.push(
				<Link
					key={i + '-news'}
					className="card news-tenant"
					to={ROUTE.NEWSTENANTDETAIL.replace(':id', newDetail.id)}
				>
					{newDetail.fixed ? (
						<div className="header-card-wrapper">
							<div className="fixed-new-wrapper">
								<div className="fixed">
									<span
										className="material-icons-round"
										data-testid={'btn-goback'}
										id={'btn-goback'}
										aria-hidden={true}
									>
										push_pin
									</span>
								</div>
							</div>
						</div>
					) : (
						''
					)}
					<div className="card-wrapper news">
						{isSmallMobile ? (
							''
						) : (
							<div className="img-wrapper">
								<FilePreview
									file={''}
									url={
										newDetail.mediaNameThumbnailURL ? (
											newDetail.mediaNameThumbnailURL
										) : (
											'/empty-image.png'
										)
									}
									fileType={'Image'}
								/>
							</div>
						)}
						<div className="body-wrapper">
							<h2 className="body-card-title">{newDetail.title}</h2>
							<p className="date-card">{newDetail.date ? parseDate(newDetail.date, true) : ''}</p>
							<p className="description-card">{newDetail.shortDescription}</p>
						</div>
						{!isMobile ? (
							<div className="arrow-wrapper">
								<span
									className="material-icons-round"
									data-testid={'btn-goback'}
									id={'btn-goback'}
									aria-hidden={true}
								>
									arrow_forward
								</span>
							</div>
						) : (
							''
						)}
					</div>
				</Link>
			);
		});

		return html;
	};

	let searchBy = t('Search') + ' ' + t('by') + ' ' + t('WordMin');

	return (
		<div className="news-tenant">
			<h1 className="title">{t('NewsTenant')}</h1>
			<div className="filters-wrapper">
				<div className="col-xl-3 search" data-bs-toggle="tooltip" title={searchBy}>
					<Input
						dataTestID="txtSearch"
						inputName="txtSearch"
						clearInput={true}
						inputPlaceholder={searchBy}
						inputType="text"
						value={search}
						inputOutline={true}
						icon={'search'}
						iconLeft={true}
						isRequired={false}
						onHandleChange={(e) => setSearch(e.target.value)}
					/>
				</div>
			</div>
			<article
				className="card-list-wrapper"
				aria-live="assertive"
				aria-atomic={true}
				aria-relevant="additions"
				aria-label={t('NewsTenant') + ' (' + news.length + ')'}
			>
				{renderNews()}
			</article>
		</div>
	);
};
export default NewsTenant;
