import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Resize,
  Inject,
  Page,
  Sort,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";
import { startRequest } from "@backoffice/actions/apiCall";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  GetCookie_weekStart,
  GetCookie_formatDate,
  GetCookie_culture,
} from "@backoffice/common/utils/cookiesManager";
import { openModal, closeModal } from "../../../../actions/popup";
import { showFixedLabel } from "../../../../actions/fixedLabel";
import PopUpGenericDelete from "../../../../components/PopUpGenericDelete/PopUpGenericDelete";
import { ROUTE } from "../../../../common/constants/routes";
import {
  Combo,
  Input,
  Button,
} from "@backoffice/components/FormComponents/index";
import ResponsiveGridWrapper from "../../../../shared/ResponsiveGridWrapper/ResponsiveGridWrapper";
import { parseDate } from "@backoffice/common/utils/parseUtils";
import {
  deleteBookingsParams,
  getMyBookingsParams,
} from "./MyBookingsCalls/MyBookingsCalls";
import {
  getBuildingsParams,
  getSpaceTypesParams,
} from "../SpacesDetail/SpacesDetailCalls/SpacesDetailCalls";
import {
  cleanStringFromAccents,
  parseDateTime,
} from "../../../../common/utils/parseUtils";
import "./MyBookings.scss";

const MyBookings = () => {
  const { t } = useTranslation();
  const pageSettings = { pageSize: 15 };
  const history = useHistory();
  const grid = useRef(null);
  const dispatch = useDispatch();
  const [bookings, setBookings] = useState([]);
  const [search, setSearch] = useState("");
  const [allBookings, setAllBookings] = useState([]);
  const [building, setBuilding] = useState({ id: "All", name: "All" });
  const [type, setType] = useState({ id: "All", name: "All" });
  const [typeOptions, setTypeOptions] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [date, setDate] = useState("");

  useEffect(() => {
    getBuildings();
  }, []);

  useEffect(() => {
    filterBookings();
  }, [building, type, date, search]);

  const getMyBookings = () => {
    dispatch(startRequest(getMyBookingsParams(getMyBookingsSuccess)));
  };

  const getMyBookingsSuccess = (response) => {
    setAllBookings(response.list);
    setBookings(response.list);
  };

  const filterBookings = () => {
    let arrayFiltered = allBookings;
    let arrayToFilter = allBookings;
    if (building.id === "All" || type.id === "All") {
      arrayToFilter = allBookings;
    }
    if (building.id !== "All") {
      arrayFiltered = arrayToFilter.filter((element) => {
        return element.buildingId === building.id;
      });
    }
    if (type.id !== "All") {
      arrayFiltered = arrayFiltered.filter((element) => {
        return element.spaceTypeId === type.id;
      });
    }
    if (date !== "") {
      arrayFiltered = arrayFiltered.filter((element) => {
        return parseDate(element.startDate) === date;
      });
    }
    const arrayFilteredBySearch = filterBySearch(arrayFiltered, search);
    setBookings(arrayFilteredBySearch);
  };

  const filterBySearch = (data = [], searching = "") => {
    const result = data.filter((element) => {
      return cleanStringFromAccents(element.spaceName.toLowerCase()).includes(
        searching.toLowerCase()
      );
    });
    return result;
  };

  // const getSpaceTypes = () => {
  // 	dispatch(startRequest(getSpaceTypesParams(getSpaceTypesSuccess)));
  // };

  // const getSpaceTypesSuccess = (response) => {
  // 	response.unshift({ id: 'All', name: 'All' });
  // 	setTypeOptions(response);
  // 	getBuildings();
  // };

  const getBuildings = () => {
    dispatch(startRequest(getBuildingsParams(getBuildingsSuccess)));
  };

  const getBuildingsSuccess = (response) => {
    response.unshift({ id: "All", name: "All" });
    setBuildings(response);
    getMyBookings();
  };

  const openDeleteBookings = (action, space) => {
    const optionValues = {
      id: "delete-space-" + space.id + "-" + Math.random(),
      cssClass: "modal-header__danger",
      title: t("DeleteBooking"),
    };

    const options = {
      ...optionValues,
      body: (
        <PopUpGenericDelete
          action={action}
          id={optionValues.id}
          deleteMessage={"DeleteConfirmMessageBooking"}
          handleButtonDelete={() => deleteSpace(space)}
          handleButtonCancel={() => dispatch(closeModal())}
        />
      ),
    };

    dispatch(openModal(options));
  };

  const deleteSpace = (booking) => {
    dispatch(
      startRequest(
        deleteBookingsParams({ id: booking.id }, deleteBookingsSuccess)
      )
    );
  };

  const deleteBookingsSuccess = (response) => {
    dispatch(showFixedLabel("BookingsDeleteSuccess", "success"));
    // getSpaceTypes();
  };

  const actionsTemplate = (rowData) => {
    return (
      <div className="action-template">
        <span
          className="material-icons-round"
          data-testid={`btnEditStandar_${rowData.index}`}
          id={`btnEditStandar_${rowData.index}`}
          onClick={() =>
            history.push({
              pathname: ROUTE.BOOKINGDETAIL.replace(":id", rowData.id),
            })
          }
          type="button"
        >
          mode_edit
        </span>
        <span
          className="material-icons-round"
          id={`btnDeleteStandar_${rowData.index}`}
          data-testid={`btnDeleteStandar_${rowData.index}`}
          onClick={() => {
            openDeleteBookings("delete", rowData);
          }}
          type="button"
        >
          delete
        </span>
      </div>
    );
  };

  const parseTime = (duration) => {
		let num = duration * 60;
		let hours = Math.floor(num / 60);
		let minutes = num % 60;
		let min = minutes === 0 ? '00' : minutes;
		return hours + ':' + min + 'h ';
	};

  // let searchBy = t('Search') + ' ' + t('by') + ' ' + t('Name');
  let searchBy = t("Search") + " " + t("by") + " " + t("WordMin");

  return (
    <div className="mybookings">
      <h2 className="title">{t("MyBookings")}</h2>
      <div className="filters-wrapper">
        <div
          className="col-xl-3 mybooking-search"
          data-bs-toggle="tooltip"
          title={searchBy}
        >
          <Input
            dataTestID="txtSearch"
            inputName="txtSearch"
            clearInput={true}
            inputPlaceholder={searchBy}
            inputType="text"
            value={search}
            inputOutline={true}
            icon={"search"}
            iconLeft={true}
            isRequired={false}
            onHandleChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="col-xl-3 types">
          <Combo
            id="cmbBuilding"
            dataTestID="cmbBuilding"
            comboLabel={t("Building")}
            inputValue={building}
            comboPlaceholder={t("Select") + " " + t("Building") + "..."}
            defaultInputValue=""
            isSearchable={false}
            getOptionValue={(option) => option["id"]}
            getOptionLabel={(option) => t(option["name"])}
            options={buildings}
            isMultiple={false}
            handleChange={(e) => {
              setBuilding(e);
            }}
          />
        </div>
        {/* <div className="col-xl-3 types">
					<Combo
						id="cmbType"
						dataTestID="cmbType"
						comboLabel={t('SpaceType')}
						inputValue={type}
						comboPlaceholder={t('Select') + ' ' + t('Type') + '...'}
						defaultInputValue=""
						isSearchable={false}
						getOptionValue={(option) => option['id']}
						getOptionLabel={(option) => t(option['name'])}
						options={typeOptions}
						isMultiple={false}
						handleChange={(e) => {
							setType(e);
						}}
					/>
				</div> */}
        <div className="col-xl-2">
          <div className="input-container">
            <label className="inputLabel label-date" htmlFor={"txtBookingDate"}>
              {t("BookingDate") + ":"}
            </label>
            <div data-testid="txtBookingDate" className="date-wrapper readonly">
              <DatePickerComponent
                showTodayButton={false}
                locale={GetCookie_culture()}
                firstDayOfWeek={GetCookie_weekStart()}
                dataTestID="txtBookingDate"
                // format={GetCookie_formatDate()}
                cssClass={"e-custom-datepicker"}
                value={date}
                allowEdit={false}
                readonly={false}
                openOnFocus={false}
                showClearButton={true}
                cleared={() => {
                  setDate("");
                }}
                onChange={(e) => {
                  if (e.value && e.value != "") {
                    setDate(parseDate(e.value));
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="btn-grid-end">
        <Button
          btnText={t("ExportExcel")}
          btnType="button"
          icon="download"
          btnClass="btn-base_outline export-excel-button"
          dataTestID="export-excel-button"
          onHandleBtnClick={() => {
            grid.current.excelExport({ includeHiddenColumn: true });
          }}
        />
        <Button
          btnText={t("Add")}
          icon="add"
          btnType="button"
          btnClass="btn-base_normal add-button"
          dataTestID="users-add-button"
          onHandleBtnClick={() =>
            history.push(ROUTE.BOOKINGDETAIL.replace(":id", 0))
          }
        />
      </div>
      {buildings.length > 0 ? (
        <ResponsiveGridWrapper>
          <GridComponent
            className="gridTable"
            dataSource={bookings}
            width="100%"
            toolbar={false}
            ref={grid}
            allowExcelExport={true}
            allowPaging={true}
            enableAdaptiveUI={true}
            allowSorting={true}
            rowRenderingMode={"Vertical"}
            pageSettings={pageSettings}
          >
            <ColumnsDirective>
              <ColumnDirective field="id" width="0" visible={false} />
              <ColumnDirective
                field="spaceName"
                headerText={t("Name")}
                textAlign="Left"
              />
              <ColumnDirective
                field="buildingName"
                headerText={t("Building")}
                textAlign="Left"
              />
              {/* <ColumnDirective
								field="spaceType"
								headerText={t('SpaceType')}
								textAlign="Left"
								valueAccessor={(field, data, column) => {
									let typeFound = {};
									typeOptions.forEach((t) => {
										if (t.id === data['spaceTypeId']) {
											typeFound = t;
										}
									});
									return typeFound.name;
								}}
							/> */}
              <ColumnDirective
                field="peopleNumber"
                headerText={t("PeopleNumber")}
                textAlign="Right"
              />
              <ColumnDirective
                field="startDate"
                headerText={t("BookingDate")}
                valueAccessor={(field, data, column) => {
                  return parseDateTime(data.startDate, true);
                }}
                textAlign="Left"
              />
              <ColumnDirective
                field="duration"
                headerText={t("BookingDuration")}
                textAlign="Left"
                valueAccessor={(field, data, column) => {
									return parseTime(data.duration);
								}}
              />
              <ColumnDirective
                field="actions"
                headerText={""}
                template={actionsTemplate}
                textAlign="Right"
                width="60"
              />
            </ColumnsDirective>
            <Inject
              services={[ExcelExport, Page, Sort, Filter, Group, Resize]}
            />
          </GridComponent>
        </ResponsiveGridWrapper>
      ) : (
        ""
      )}
    </div>
  );
};
export default MyBookings;
