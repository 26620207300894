import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { startRequest } from '@backoffice/actions/apiCall';
import { Button } from '@backoffice/components/FormComponents/index';
import { getIncidentsParams } from './IncidentsCalls/IncidentsCalls';
import { getIncidentsWithParams } from './IncidentsCalls/IncidentsCalls';
import { parseDate, parseDateBE, parseDateTime } from '../../../../common/utils/parseUtils';
import { ROUTE } from '../../../../common/constants/routes';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { GetCookie_weekStart, GetCookie_formatDate, GetCookie_culture } from '@backoffice/common/utils/cookiesManager';
import './IncidentsTenant.scss';

const IncidentsTenant = () => {
	const history = useHistory();
	const { t } = useTranslation();
	const [ incidents, setIncidents ] = useState([]);
	const dispatch = useDispatch();
	const isMobile = useMediaQuery({ query: '(max-width: 450px)' });
	const [ startDate, setStartDate ] = useState('');
	const [ endDate, setEndDate ] = useState('');

	useEffect(
		() => {
			getIncidents();
		},
		[ startDate, endDate ]
	);

	const getIncidents = () => {
		if (startDate === '' && endDate === '') {
			dispatch(startRequest(getIncidentsParams(getIncidentsSuccess)));
		} else {
			dispatch(
				startRequest(
					getIncidentsWithParams(
						{
							filters: {
								StartDate: parseDateBE(startDate),
								EndDate: parseDateBE(endDate)
							}
						},
						getIncidentsSuccess
					)
				)
			);
		}
	};

	const getIncidentsSuccess = (response) => {
		setIncidents(response);
	};

	return (
		<div className="incidents-tenant">
			<h1 className="title">{t('Incidents')}</h1>
			<div className="filters-wrapper">
				<div className="col-xl-3">
					<div className="date-wrapper">
						<div className="input-container">
							<label className="inputLabel label-date" htmlFor={'txtBookingDate'}>
								{t('Since') + ':'}
							</label>
							<DatePickerComponent
								value={startDate}
								cssClass={'e-custom-datepicker'}
								// format={GetCookie_formatDate()}
								showTodayButton={false}
								dataTestID="txtStartDate"
								firstDayOfWeek={GetCookie_weekStart()}
								locale={GetCookie_culture()}
								allowEdit={true}
								readonly={false}
								openOnFocus={false}
								showClearButton={true}
								cleared={() => {
									setStartDate('');
								}}
								onChange={(e) => {
									if (e.value && e.value != '') {
										setStartDate(parseDate(e.value));
									}
								}}
							/>
						</div>
					</div>
				</div>
				<div className="col-xl-3">
					<div className="input-container">
						<label className="inputLabel label-date" htmlFor={'txtBookingDate'}>
							{t('Until') + ':'}
						</label>
						<div className="date-wrapper">
							<DatePickerComponent
								value={endDate}
								cssClass={'e-custom-datepicker'}
								// format={GetCookie_formatDate()}
								showTodayButton={false}
								locale={GetCookie_culture()}
								firstDayOfWeek={GetCookie_weekStart()}
								dataTestID="txtEndDate"
								allowEdit={true}
								readonly={false}
								openOnFocus={false}
								showClearButton={true}
								cleared={() => {
									setEndDate('');
								}}
								onChange={(e) => {
									if (e.value && e.value != '') {
										setEndDate(parseDate(e.value));
									}
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="card-list-wrapper">
				{incidents.map((incident, i) => (
					<Link
						key={i + '-incidents'}
						className="card incidents-tenant"
						to={'/incidentstenant/' + incident.id}
					>
						<div className="header-card-wrapper">
							<div className="title-wrapper">
								<h2
									className="title-card"
									aria-label={t('IncidentTitle') + ': ' + incident.title + '.'}
								>
									{incident.title}
								</h2>
							</div>
							<div className="status-wrapper">
								<div
									className={
										incident.statusCodeValue === 'InProgress' ? (
											'status progress'
										) : incident.statusCodeValue === 'Resolved' ? (
											'status resolved'
										) : (
											'status pending'
										)
									}
									aria-label={t('Status') + ': ' + t(incident.statusCodeValue) + '.'}
								>
									{t(incident.statusCodeValue)}
								</div>
							</div>
						</div>
						<div className="card-wrapper incidents">
							<div className="body-wrapper without-img">
								<p
									className="incident-type"
									aria-label={t('IncidentType') + ': ' + t(incident.incidentTypeValue) + '.'}
								>
									{t(incident.incidentTypeValue)}
								</p>
								<p className="date-card">
									{t('OpenOn') + ' ' + parseDateTime(incident.creationDate, true)}
								</p>
								<p className="description-card">{incident.shortDescription}</p>
							</div>
							{!isMobile ? (
								<div className="arrow-wrapper">
									<span
										className="material-icons-round"
										data-testid={'btn-goback'}
										id={'btn-goback'}
										aria-hidden={true}
									>
										arrow_forward
									</span>
								</div>
							) : (
								''
							)}
						</div>
					</Link>
				))}
			</div>
			<div className="btn-end">
				<Button
					dataTestID="btnCreateIncident"
					btnText={t('CreateIncident')}
					icon="add"
					btnType="button"
					btnClass="btn-base_normal create-button"
					onHandleBtnClick={() => {
						history.push(ROUTE.INCIDENTSTENANTCREATE);
					}}
				/>
			</div>
		</div>
	);
};
export default IncidentsTenant;
