import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { updateProfile } from '@backoffice/actions/profile';
import { Input } from '@backoffice/components/FormComponents/index';
import { validateEmail, validateDNI } from '../../../../common/validations/common';
import { showFixedLabel } from '../../../../actions/fixedLabel';
import { startRequest } from '@backoffice/actions/apiCall';
import { getAccountParams } from './ProfileCalls/ProfileCalls';

import './Profile.scss';

const Profile = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [ name, setName ] = useState('');
	const [ surnames, setSurnames ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ nif, setNif ] = useState('');
	const [ errorNif, setErrorNif ] = useState(false);
	const [ file, setFile ] = useState('');
	const [ phone, setPhone ] = useState('');
	const [ imgUrl, setImgUrl ] = useState('');
	const [ emailError, setEmailError ] = useState(false);
	const profileReducer = useSelector((state) => state.profile);

	useEffect(() => {
		getAccount();
	}, []);

	const getAccount = () => {
		dispatch(startRequest(getAccountParams(getAccountSuccess)));
	};

	const getAccountSuccess = (response) => {
		setName(response.name);
		setSurnames(response.surnames);
		setEmail(response.email);
		setNif(response.documentId);
		setPhone(response.phoneNumber);
		setImgUrl(profileReducer.profile.imgUrl);
	};

	const getUserForm = () => {
		return {
			email: email,
			name: name,
			surnames: surnames,
			file: file,
			imgUrl: file ? URL.createObjectURL(file) : imgUrl
		};
	};
	const validateForm = () => {
		let formOK = true;
		if (email === '' || emailError || name === '' || surnames === '') {
			formOK = false;
		}
		return !formOK;
	};

	const editProfileUser = () => {
		dispatch(updateProfile(getUserForm()));
		dispatch(showFixedLabel('UpdatedProfile', 'success'));
	};

	return (
		<div className="profile">
			<h2 className="title">
				{t('WelcomeTitle')} {profileReducer.profile.name}
			</h2>
			<div className="user-profile-form">
				{/* <div className="profile-filePreview-wrapper">
					{file !== '' || imgUrl !== '' ? (
						<div className="profile-img-wrapper">
							<FilePreview file={file} url={imgUrl} fileType={'Image'} />
						</div>
					) : (
						<div className="profile-img-wrapper">
							<FilePreview file={''} url={'./empty-image.png'} fileType={'Image'} />
						</div>
					)}
					<div className="button-upload m-2">
						<input
							type="file"
							id="btnLoad"
							data-testid="btnLoadImage"
							onChange={(e) => {
								setFile(e.target.files[0]);
							}}
							accept="image/png, image/jpeg"
							name="btnLoad"
							className="inputfile"
						/>
						<label htmlFor="btnLoad" className="btn-base_normal">
							<i className="material-icons-round">file_upload</i>
							<p>{file || imgUrl !== '' ? t('ChangeImage') : t('UploadImage')}</p>
						</label>
					</div>
				</div> */}
				<div className="row">
					<div className="col-lg-3">
						<Input
							dataTestID="txtName"
							inputName="txtName"
							inputType="text"
							inputLabel={t('Name')}
							inputID="user-name"
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setName(val);
							}}
							value={name}
							isDisabled={true}
							isRequired={true}
						/>
					</div>
					<div className="col-lg-3">
						<Input
							dataTestID="txtSurnames"
							inputName="txtSurnames"
							inputType="text"
							inputLabel={t('Surnames')}
							inputID="user-surnames"
							isDisabled={true}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setSurnames(val);
							}}
							value={surnames}
							isRequired={true}
						/>
					</div>
					<div className="col-lg-3">
						<Input
							dataTestID="txtEmail"
							inputName="txtEmail"
							inputType="text"
							inputLabel={t('Email')}
							inputID="user-email"
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setEmail(val);
								setEmailError(validateEmail(val).error);
							}}
							isDisabled={true}
							value={email}
							isError={emailError}
							errorMessage={email === '' ? t('IsRequired') : t('EmailFormatIncorrect')}
							isRequired={true}
						/>
					</div>
					<div className="col-lg-3">
						<Input
							dataTestID="txtDni"
							inputName="txtDni"
							inputType="text"
							inputLabel={t('DNI')}
							inputID="contact-dni"
							isDisabled={true}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setNif(val);
							}}
							onHandleBlur={(e) => {
								let val = e.currentTarget.value;
								if (validateDNI(val)) {
									setErrorNif(false);
								} else {
									setErrorNif(true);
								}
							}}
							isError={errorNif}
							errorMessage={t('DNIFormatIncorrect')}
							value={nif}
							isRequired={true}
						/>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-3">
						<Input
							dataTestID="txtPhone"
							inputName="txtPhone"
							inputType="text"
							inputLabel={t('Phone')}
							inputID="user-phone"
							isDisabled={true}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setPhone(val);
							}}
							value={phone}
							isRequired={false}
						/>
					</div>
				</div>
				{/* <div className="btn-end">
					<div className="col-lg-3">
						<Button
							dataTestID="btnUpdatedProfile"
							btnText={t('SaveChanges')}
							btnType="button"
							btnClass="btn-base_normal"
							isDisabled={validateForm()}
							onHandleBtnClick={() => {
								editProfileUser();
							}}
						/>
					</div>
				</div> */}
			</div>
		</div>
	);
};
export default Profile;
