import React from 'react';
import { useMediaQuery } from 'react-responsive';
import './ResponsiveGridWrapper.scss';

const ResponsiveGridWrapper = ({ children }) => {
	const isTabletOrMobile = useMediaQuery({ query: '(max-width: 992px)' });

	return isTabletOrMobile ? (
		<div className="e-bigger">
			<div className="e-mobile-layout">
				<div className="e-mobile-content">{children}</div>
			</div>
		</div>
	) : (
		<div className="grid-wrapper">{children}</div>
	);
};

export default ResponsiveGridWrapper;
