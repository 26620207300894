const SHOW_LOADING = 'SHOW_LOADING';
const HIDE_LOADING = 'HIDE_LOADING';

const defaultConst = {
    SHOW_LOADING,
    HIDE_LOADING,
};

export default defaultConst;
export const showDialog = () => ({ type: SHOW_LOADING });
export const hideDialog = () => ({ type: HIDE_LOADING });
