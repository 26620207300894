const OPEN_MODAL = 'OPEN_MODAL';
const CLOSE_MODAL = 'CLOSE_MODAL';

const defaultConst = {
    OPEN_MODAL,
    CLOSE_MODAL,
};

export default defaultConst;
export const closeModal = (options) => ({
    type: CLOSE_MODAL,
    options: options,
});
export const openModal = (options) => ({ type: OPEN_MODAL, options: options });
