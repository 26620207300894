import React from 'react';
import './PopUpImage.scss';

const PopUpImage = ({ file, url }) => {
	const createURL = (file) => {
		let el = URL.createObjectURL(file);
		URL.revokeObjectURL(file);
		return el;
	};

	return (
		<div className="new-image-popup">
			{file !== '' ? (
				<img
					key={'render-file'}
					className="img-item-popup"
					alt={file ? ' ' + file.name : ''}
					src={createURL(file)}
				/>
			) : url !== '' ? (
				<img
					key={'render-url'}
					className="img-item-popup"
					alt={file ? ' ' + file.name : ''}
					src={url}
				/>
			) : (
				''
			)}
		</div>
	);
};

export default PopUpImage;
