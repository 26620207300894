import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import { startRequest } from "@backoffice/actions/apiCall";
import { postProfileChangePassword } from "../ProfileTenantCalls/ProfileTenantsCalls";
import { Input } from "@backoffice/components/FormComponents/index";
import { Button } from "@backoffice/components/FormComponents/index";
import { showFixedLabel } from "../../../../../actions/fixedLabel";

import "./PopUpChangePass.scss";

const PopUpChangePass = ({ name }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [currentPass, setCurrentPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [repeatNewPass, setRepeatNewPass] = useState("");

  const [error, setError] = useState("");

  useEffect(() => {
    if (newPass != repeatNewPass) {
      setError(t("ValidationPasswordComparer"));
    } else {
      setError("");
    }
  }, [currentPass, newPass, repeatNewPass]);

  const postChangePassword = () => {
    const data = {
      currentPassword: currentPass,
      password: newPass,
      confirmPassword: repeatNewPass,
    };
    dispatch(startRequest(postProfileChangePassword(data, onError)));
  };

  const onError = () => {
    setError(t("ChangePasswordApplicationIncorrect"));
  };

  return (
    <React.Fragment>
      <div className="PopUpChangePass">
        <h1 className="title">{t("OldPassword")}</h1>
        <div className="input-pass">
          <Input
            dataTestID="txtMessage"
            inputName="txtMessage"
            inputType={"message"}
            inputLabel={""}
            inputMaxLength={1200}
            inputID="message"
            isDisabled={false}
            onHandleChange={(e) => setCurrentPass(e.currentTarget.value)}
            value={currentPass}
            isRequired={false}
          />
        </div>
        <div className="divider"></div>
        <h1 className="title">{t("CreateNewPassword")}</h1>
        <div className="input-pass">
          <Input
            dataTestID="txtMessage"
            inputName="txtMessage"
            inputType={"message"}
            inputLabel={""}
            inputMaxLength={1200}
            inputID="message"
            isDisabled={false}
            onHandleChange={(e) => setNewPass(e.currentTarget.value)}
            value={newPass}
            isRequired={false}
          />
        </div>

        <h1 className="title">{t("RepeatNewPassword")}</h1>
        <div className="input-pass">
          <Input
            dataTestID="txtMessage"
            inputName="txtMessage"
            inputType={"message"}
            inputLabel={""}
            inputMaxLength={1200}
            inputID="message"
            isDisabled={false}
            onHandleChange={(e) => setRepeatNewPass(e.currentTarget.value)}
            value={repeatNewPass}
            isRequired={false}
          />
        </div>
        <h1 className="title-error">{error}</h1>
        <div className="changePass-btn" title={error}>
          <Button
            isDisabled={
              currentPass == "" ||
              newPass == "" ||
              repeatNewPass == "" ||
              error != ""
            }
            dataTestID="btnSaveSpace"
            btnText={name}
            btnType="button"
            btnClass="btn-base_normal"
            onHandleBtnClick={postChangePassword}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PopUpChangePass;
