import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useDispatch } from 'react-redux';
import Link from '@material-ui/core/Link';
import { useHistory } from 'react-router-dom';
import { createToken, createRefreshToken } from '@backoffice/actions/token';
import { changeWeekStart, changeFormat, changeLanguage } from '@backoffice/actions/language';
import { startRequest } from '@backoffice/actions/apiCall';
import { changeErrorPageNumber } from '@backoffice/actions/error';
import { updateProfile } from '@backoffice/actions/profile';
import { updateMenu } from '@backoffice/actions/menu';
import { Input, Button } from '@backoffice/components/FormComponents/index';
import { validateEmail } from '../../../../common/validations/common';
import { postLoginParams } from './LoginCalls';
import { loadLanguage } from '@backoffice/locales/SyncfusionTranslationService';
import { changeAppearance, resetAppearance } from '@backoffice/actions/appearance';
import './LoginForm.scss';

const LoginForm = ({ setForgotPassword, redirect, successFn, errorFn }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();

	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ passwordHidden, setPasswordHidden ] = useState(true);
	const [ culture, setCulture ] = useState('es');

	const [ emailError, setEmailError ] = useState(false);

	useEffect(() => {
		document.title = t('LogIn');
	});

	const mySuccessLogin = (response) => {
		dispatch(
			updateProfile({
				buildingId: response.userData.buildingId,
				name: response.userData.name,
				firstSurname: response.userData.surnames,
				secondSurname: '',
				email: response.userData.email,
				imgUrl:
					'https://www.hibridosyelectricos.com/asset/thumbnail,992,558,center,center//media/hibridos/images/2022/03/21/2022032121372239483.jpg',

				isTenant: response.userData.isTenant,
				isProvider: response.userData.isProvider
			})
		);
		dispatch(createToken(response.tokenData.accessToken));
		dispatch(createRefreshToken(response.tokenData.refreshToken));
		dispatch(changeErrorPageNumber(0));
		dispatch(updateMenu(response.menuData));
		changeCulture(response.userData.currentLanguage ? response.userData.currentLanguage : 'es');
		dispatch(createRefreshToken(response.tokenData.refreshToken));
		if (response.appearance) {
			dispatch(
				changeAppearance({
					backgroundUrl: response.appearance.mediaBackGround
						? response.appearance.mediaBackGround.mediaUrl
						: process.env.PUBLIC_URL + '/login-bg.jpg',
					logoImgUrl: response.appearance.mediaLogo
						? response.appearance.mediaLogo.mediaUrl
						: process.env.PUBLIC_URL + '/bialto_horizontal.png',
					faviconImgUrl: response.appearance.mediaFavicon
						? response.appearance.mediaFavicon.mediaUrl
						: process.env.PUBLIC_URL + '/bialto-logo_white.png',
					colorPrimary: '#000000',
					colorPrimaryVariant: '#333333',
					colorPrimaryVariant2: '#777777',
					colorPrimaryHover: '#434747',
					colorPrimaryPressed: '#1F0F7B',
					colorPrimaryDisabled: '#d8d8d8',
					colorSecondary: response.appearance.colorSecondary ? response.appearance.colorSecondary : '#1F0F7B',
					colorSecondaryVariant: '#D64443',
					colorSecondaryVariant2:
						'background: linear-gradient(90deg, rgba(214,68,67,1) 0%, rgba(31,15,123,1) 100%);',
					colorText: '#000000',
					colorError: '#D64443',
					colorErrorBG: '#ccaca6',
					colorWarning: '#d9d2aa',
					colorWarningBG: '#dad3a8',
					colorSuccess: '#207E5D',
					colorSuccessBG: '#43D6A1',
					colorMenuBG: '#FFFFFF',
					colorMenu: '#000',
					colorHeader: '#ffffff',
					colorHeaderBG: response.appearance.colorHeaderBG ? response.appearance.colorHeaderBG : '#000000',
					colorMainBG: '#FFFFFF'
				})
			);
		} else {
			dispatch(resetAppearance());
		}
		if (successFn) {
			successFn(response);
		} else {
			history.push(response.menuData[0].link);
		}
	};

	const changeCulture = (lng) => {
		i18n.changeLanguage(lng);
		dispatch(changeLanguage(lng));
		loadLanguage();
		let newFormat = '';
		let newWeekStart = '';
		switch (lng) {
			case 'es':
				newFormat = 'DD/MM/YYYY';
				newWeekStart = 1;
				break;
			case 'en':
				newFormat = 'MM/DD/YYYY';
				newWeekStart = 0;
				break;
			case 'ca':
				newFormat = 'DD/MM/YYYY';
				newWeekStart = 1;
				break;
			default:
				newFormat = 'DD/MM/YYYY';
				break;
		}
		dispatch(changeFormat(newFormat));
		dispatch(changeWeekStart(newWeekStart));
	};

	const defaultLink = (menuArray) => {
		menuArray.forEach((menuElement) => {
			if (menuElement.isDefault && redirect) {
				// history.push(menuElement.link);
			}
		});
	};

	const getLoginData = () => {
		const myData = {
			email,
			password,
			culture
		};
		return myData;
	};

	const submitLogin = (e) => {
		e.preventDefault();
		const data = getLoginData();
		dispatch(startRequest(postLoginParams(data, mySuccessLogin)));
	};

	const forgotPasswordHandler = () => {
		if (setForgotPassword) {
			setForgotPassword(true);
		}
	};

	return (
		<div className="login-form">
			<form className="form-signin" name="loginForm" title={t('LogIn')} onSubmit={submitLogin}>
				<div className="form-wrapper">
					<Input
						dataTestID="txtLoginEmail"
						inputName="txtLoginEmail"
						inputType="text"
						inputLabel={t('Email')}
						inputID="user-loginEmail"
						isDisabled={false}
						onHandleChange={(e) => {
							let val = e.currentTarget.value;
							setEmail(val);
							setEmailError(validateEmail(val).error);
						}}
						value={email}
						isError={emailError}
						errorMessage={email === '' ? t('IsRequired') : t('EmailFormatIncorrect')}
						isRequired={true}
					/>
				</div>

				<div className="form-wrapper">
					<Input
						dataTestID="txtPassword"
						inputName="txtPassword"
						inputType={passwordHidden ? 'password' : 'text'}
						inputLabel={t('Password')}
						inputID="user-loginPassword"
						isDisabled={false}
						onHandleChange={(e) => setPassword(e.currentTarget.value)}
						value={password}
						icon={passwordHidden ? 'visibility' : 'visibility_off'}
						isRequired={true}
						onHandleClick={() => setPasswordHidden(!passwordHidden)}
					/>
				</div>
				<p className="link-forgotPass" data-testid="lnkForgotPassword" id="lnkForgotPassword">
					<Link onClick={forgotPasswordHandler}>{t('RecoverPassword')}</Link>
				</p>
				<div className="button-container">
					<Button
						btnText={t('Access')}
						btnType="submit"
						isDisabled={emailError || email === '' || password === ''}
						btnClass="btn-base_normal"
						dataTestID="btnLoginButton"
					/>
				</div>
			</form>
		</div>
	);
};

export default LoginForm;
