import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ScheduleComponent,
  Inject,
  ViewsDirective,
  ViewDirective,
  WorkWeek,
  Month,
  Day,
} from "@syncfusion/ej2-react-schedule";
import { startRequest } from "@backoffice/actions/apiCall";
import { Combo, Button } from "@backoffice/components/FormComponents/index";
import { openModal } from "../../../../actions/popup";
import ModalCalendar from "./ModalCalendar/ModalCalendar";
import FilePreview from "../../../../shared/FilePreview/FilePreview";
import {
  dateIsBetween,
  parseDate,
  parseDateBE,
  parseDateTime,
  parseDateTimeBE,
  parseDateWithFormat,
  parseDateToUtc,
  parseTime,
} from "../../../../common/utils/parseUtils";
import {
  GetCookie_formatDate,
  GetCookie_weekStart,
} from "../../../../common/utils/cookiesManager";
import {
  postBookingParams,
  putBookingParams,
} from "./BookingTenantCreateCalls/BookingTenantCreateCalls";
import { ROUTE } from "../../../../common/constants/routes";
import { showFixedLabel } from "../../../../actions/fixedLabel";
import {
  getBuildingsParams,
  getSpaceTypesParams,
  getSpacesParams,
  getSpacesByIdParams,
} from "../../Backoffice/SpacesDetail/SpacesDetailCalls/SpacesDetailCalls";
import { getBookingsParams } from "../BookingTenant/BookingTenantCalls/BookingTenantCalls";
import {
  addHours,
  getDiffHours,
  removeHours,
} from "../../../../common/utils/dateUtils";
import { GetSessionStorage } from "@backoffice/common/utils/storageManager";
import "./BookingTenantCreate.scss";

const BookingTenantCreate = () => {
  const [bookingId, setBookingId] = useState("0");
  const [bookingCode, setBookingCode] = useState("");
  const { t } = useTranslation();
  const scheduleMonth = useRef(ScheduleComponent);
  const scheduleDay = useRef(ScheduleComponent);
  const history = useHistory();
  const [minHourView, setMinHourView] = useState("00:00");
  const [maxHourView, setMaxHourView] = useState("00:00");
  const [step, setStep] = useState(1);
  const [peopleNumber, setPeopleNumber] = useState("");
  const [peopleNumberOptions, setPeopleNumberOptions] = useState([]);
  const [spaceEditId, setSpaceEditId] = useState("");
  const [peopleNumberEdit, setPeopleNumberEdit] = useState("");
  const [space, setSpace] = useState("");
  const [events, setEvents] = useState([]);
  const [originalEvents, setOriginalEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const dispatch = useDispatch();
  const [showCalendar, setShowCalendar] = useState(false);
  const buildingId = GetSessionStorage("PROFILE_").buildingId;
  const [slotAvailable, setSlotAvailable] = useState(false);

  const [spaceOptions, setSpaceOptions] = useState([]);
  const [spaceSelected, setSpaceSelected] = useState("");

  let firstDate = new Date();
  let firstDayMonth = new Date();

  //----------------------------------------------------------------------------------------------------------------------------------------
  //--------  STEP 1 FUNCTIONS  ------------------------------------------------------------------------------------------------------------
  //----------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    getSpaces();

    let date = new Date();
    date.setDate(date.getDate() + 1);
    setSelectedDate(getOnlyDate(date));
  }, []);

  useEffect(() => {
    getSpaceById();
  }, [spaceSelected]);

  const getSpaces = () => {
    dispatch(
      startRequest(
        getSpacesParams(
          { filters: { buildingId: buildingId } }, false,
          getSpacesSuccess
        )
      )
    );
  };

  const getSpacesSuccess = (response) => {
    setSpaceOptions(response.list);

    // if (spaceEditId !== '') {
    // 	setSpaceSelected(response.list.filter((space) => space.id === spaceEditId)[0]);
    // }
  };

  const getSpaceById = () => {
    if (spaceSelected && spaceSelected.id !== "") {
      dispatch(
        startRequest(getSpacesByIdParams(spaceSelected.id, getSpaceByIdSuccess))
      );
    }
  };

  const getSpaceByIdSuccess = (response) => {
    setSpace(response);
    setMinHourView(removeHours(response.from, "1"));
    setMaxHourView(
      addHours(response.usePartialTime ? response.toPartial : response.to, "1")
    );
    let options = [];
    for (let index = 0; index < response.capacity; index++) {
      options.push({ id: index + 1, name: index + 1 });
    }
    setPeopleNumberOptions(options);
    if (peopleNumberEdit !== "") {
      setPeopleNumber(
        options.filter((pNoptions) => pNoptions.id === peopleNumberEdit)[0]
      );
    }
  };

  const renderSpaces = () => {
    let html = [];
    spaceOptions.forEach((space, i) => {
      html.push(
        <div
          key={i}
          className={
            space.id === spaceSelected.id ? "space-img active" : "space-img"
          }
          onClick={() => {
            setSpaceSelected(space);
          }}
        >
          <div className="img-wrapper">
            <FilePreview
              file={""}
              url={
                space.media && space.media.length > 0
                  ? space.media[0].mediaThumbnailUrl
                  : "/empty-image.png"
              }
              fileType={
                space.media && space.media.length > 0
                  ? space.media[0].mediaContentType
                  : ""
              }
            />
          </div>
          <h3 className="space-name">{space.name}</h3>
        </div>
      );
    });
    return html;
  };

  //----------------------------------------------------------------------------------------------------------------------------------------
  //--------  STEP 2 FUNCTIONS  ------------------------------------------------------------------------------------------------------------
  //----------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (step === 2 && !showCalendar && startTime === "" && endTime === "") {
      firstDayMonth.setDate(1);
      firstDate.setDate(1);
      if (firstDate.getDay() > 1) {
        firstDate.setDate(firstDate.getDate() - (firstDate.getDay() - 1));
      }

      getBookings(firstDate);
    }
  }, [step]);

  const openPopUp = (type, args, maxTime) => {
    if (args.element.classList.contains("e-work-cells")) {
      const optionValues = {
        id: "popup-calendar-" + Math.random(),
        title:
          type === "edit"
            ? t("EditBooking")
            : type === "add"
            ? t("AddBooking")
            : t("DeleteBooking"),
        cssClass: "modal-header__add",
        actionSubmit: type.toUpperCase(),
      };
      let obj = {
        startTime: parseTime(args.data.StartTime),
        endTime: parseTime(args.data.EndTime),
        startDate: parseDate(args.data.StartTime),
        endDate: parseDate(new Date(args.data.EndTime)),
        maxTime: maxTime,
      };
      let dates = {
        startDate: parseDateWithFormat(args.data.StartTime, "YYYY-MM-DD"),
        endDate: parseDateWithFormat(new Date(args.data.EndTime), "YYYY-MM-DD"),
      };

      const options = {
        ...optionValues,
        body: (
          <ModalCalendar
            element={obj}
            action={optionValues.actionSubmit}
            handleSubmit={(startTime, endTime) => {
              let newEvent = [...originalEvents];
              newEvent.push({
                EventId: Math.random(),
                IsAllDay: false,
                StartTime: dates.startDate + " " + startTime,
                EndTime: dates.endDate + " " + endTime,
                Id: Math.random(),
                Type: "Selection",
                Subject: t("YourSelection"),
              });
              setEvents(newEvent);
              setStartTime(dates.startDate + " " + startTime);
              setEndTime(dates.endDate + " " + endTime);
            }}
          />
        ),
        footer: null,
      };
      dispatch(openModal(options));
    } else {
      args.cancel = true;
    }
  };

  const handleSelect = (args) => {
    if (
      args.requestType === "cellSelect" &&
      args.element.classList.contains("e-work-hours")
    ) {
      if (isSlotAvailable(args)) {
        let bookingDuration =
          args.data.EndTime.getHours() - args.data.StartTime.getHours();
        if (bookingDuration >= space.minTime) {
          if (bookingDuration <= space.maxTime) {
            openPopUp("add", args, space.maxTime);
          } else {
            dispatch(
              showFixedLabel(
                t("MaxTimeBookingSpace") + space.maxTime + "h",
                "error"
              )
            );
          }
        } else {
          dispatch(
            showFixedLabel(
              t("MinTimeBookingSpace") + space.minTime + "h",
              "error"
            )
          );
        }
      } else {
        args.cancel = true;
        dispatch(showFixedLabel(t("SpaceCapacityExceeded"), "error"));
      }
    }
  };

  const isSlotAvailable = (args) => {
    let isAvailable = false;
    let count = 0;
    events.forEach((event) => {
      if (
        dateIsBetween(
          parseDateTimeBE(parseDateTime(args.data.StartTime, false), false),
          event.StartTime,
          event.EndTime,
          "[]",
          "YYYY-MM-DD HH:mm"
        )
      ) {
        count = count + event.PeopleNumber;
      }
    });
    let newPeople = peopleNumber === "" ? 0 : peopleNumber.name + count;
    if (newPeople <= space.capacity) {
      isAvailable = true;
    } else {
      isAvailable = false;
    }
    setSlotAvailable(isAvailable);
    return isAvailable;
  };

  const handleScheduleRendered = (args) => {
    switch (args.data.Type) {
      case "Selection":
        args.element.style.backgroundColor = "#512da8";
        args.element.style.color = "white";
        args.element.style.width = "unset";
        break;
      case "Reserved":
        args.element.style.backgroundColor = "#DFDFDE";
        args.element.style.width = "unset";

        break;

      default:
        args.element.style.width = "unset";

        break;
    }
  };

  const onRenderCell = (args) => {
    if (args.elementType === "monthCells") {
      if (args.element.classList.contains("e-work-cells")) {
        if (args.date < new Date()) {
          args.element.setAttribute("aria-readonly", "true");
          args.element.classList.add("e-read-only-cells");
        }
        if (getOnlyDate(args.date) === getOnlyDate(new Date())) {
          args.element.classList.add("e-currentday-color");
        }
        if (getOnlyDate(args.date) === selectedDate) {
          args.element.classList.add("selected-date");
        }
      }
    }
  };

  const setWorkDays = (args) => {
    let workDays = [];
    let nonWorkDays = [];

    if (space.monday === true && args === "workDays") {
      workDays.push(1);
    } else if (space.monday === false && args === "readOnly") {
      nonWorkDays.push(1);
    }
    if (space.tuesday === true && args === "workDays") {
      workDays.push(2);
    } else if (space.tuesday === false && args === "readOnly") {
      nonWorkDays.push(2);
    }
    if (space.wednesday === true && args === "workDays") {
      workDays.push(3);
    } else if (space.wednesday === false && args === "readOnly") {
      nonWorkDays.push(3);
    }
    if (space.thursday === true && args === "workDays") {
      workDays.push(4);
    } else if (space.thursday === false && args === "readOnly") {
      nonWorkDays.push(4);
    }
    if (space.friday === true && args === "workDays") {
      workDays.push(5);
    } else if (space.friday === false && args === "readOnly") {
      nonWorkDays.push(5);
    }
    if (space.saturday === true && args === "workDays") {
      workDays.push(6);
    } else if (space.saturday === false && args === "readOnly") {
      nonWorkDays.push(6);
    }
    if (space.sunday === true && args === "workDays") {
      workDays.push(0);
    } else if (space.sunday === false && args === "readOnly") {
      nonWorkDays.push(0);
    }

    if (args === "workDays") {
      return workDays;
    } else if (args === "readOnly") {
      return nonWorkDays;
    }
  };

  const changeSelectedDay = (args) => {
    if (
      !args.element.classList.contains("e-read-only-cells") &&
      args.element.classList.contains("e-work-days")
    ) {
      args.element.classList.add("e-selected-cell");
      setSelectedDate(getOnlyDate(args.startTime));
    }
  };

  const onActionComplete = (args) => {
    if (args.requestType === "dateNavigate") {
      if (space.usePartialTime) {
        scheduleDay.current.setWorkHours(
          scheduleDay.current.getCurrentViewDates(),
          space.fromPartial,
          space.toPartial
        );
      }
      firstDayMonth = new Date(
        scheduleMonth.current.activeView.monthDates.start
      );
      firstDayMonth.setDate(1);
      firstDate = new Date(scheduleMonth.current.activeView.monthDates.start);
      if (firstDate.getDay() > 1) {
        firstDate.setDate(firstDate.getDate() - (firstDate.getDay() - 1));
      } else if (firstDate.getDay() < 1) {
        firstDate.setDate(firstDate.getDate() - (firstDate.getDay() + 6));
      }
      getBookings(firstDate);
    }
  };

  const getBookings = (date) => {
    dispatch(
      startRequest(
        getBookingsParams(
          {
            filters: {
              buildingId: buildingId,
              spaceTypeId: space.spaceTypeId,
              spaceId: space.id,
              startDate: parseDateBE(date),
            },
          },
          (response) => getBookingsSuccess(response, date)
        )
      )
    );
  };

  const getBookingsSuccess = (response, date) => {
    const newEvents = [];
    const newOriginalEvents = [];
    response.list.forEach((event) => {
      let eventEndTime = new Date(event.startDate);
      let eventEndHours = eventEndTime.getHours();
      eventEndHours = eventEndHours + Math.ceil(event.duration);
      eventEndTime.setHours(eventEndHours);
      let newEvent = {
        EventId: Math.random(),
        IsAllDay: false,
        PeopleNumber: event.peopleNumber,
        StartTime: parseDateTimeBE(parseDateTime(event.startDate, true), false),
        EndTime: parseDateTimeBE(parseDateTime(eventEndTime, true), false),
        Type: startTime === event.startDate ? "Selection" : "Reserved",
        Id: Math.random(),
        Subject:
          startTime === event.startDate ? t("YourSelection") : t("Reserved"),
      };
      if (startTime === event.startDate) {
        newEvents.push(newEvent);
      } else {
        newEvents.push(newEvent);
        newOriginalEvents.push(newEvent);
      }
    });
    setEvents(newEvents);
    setOriginalEvents(newOriginalEvents);
  };

  const onNavigating = (args) => {
    if (args.requestType === "dateNavigate") {
      if (space.usePartialTime) {
        scheduleDay.current.setWorkHours(
          scheduleDay.current.getCurrentViewDates(),
          space.fromPartial,
          space.toPartial
        );
      }
    }
    if (args.action === "date") {
      setSelectedDate(getOnlyDate(args.currentDate));
    }
  };

  const onDataBound = (args) => {
    if (space.usePartialTime) {
      scheduleDay.current.setWorkHours(
        scheduleDay.current.getCurrentViewDates(),
        space.fromPartial,
        space.toPartial
      );
    }
    scheduleDay.current.setWorkHours(
      scheduleDay.current.getCurrentViewDates(),
      space.from,
      space.to
    );
  };

  //----------------------------------------------------------------------------------------------------------------------------------------
  //--------  STEP 3 FUNCTIONS  ------------------------------------------------------------------------------------------------------------
  //----------------------------------------------------------------------------------------------------------------------------------------

  const postBooking = () => {
    let booking = getBookingData();
    booking.bookingStart = parseDateToUtc(booking.bookingStart);
    booking.bookingEnd = parseDateToUtc(booking.bookingEnd);

    if (bookingId === "0") {
      dispatch(startRequest(postBookingParams(booking, postBookingSuccess)));
    } else {
      dispatch(startRequest(putBookingParams(booking, postBookingSuccess)));
    }
  };

  const getBookingData = () => {
    var durationTime = getDiffHours(startTime, endTime);
    return {
      id: bookingId,
      bookingCode: bookingCode,
      buildingId: buildingId,
      spaceTypeId: space.spaceTypeId,
      spaceId: space.id,
      peopleNumber: peopleNumber.id,
      bookingStart: startTime,
      bookingEnd: endTime,
      duration: durationTime,
    };
  };

  const postBookingSuccess = (response) => {
    history.push(ROUTE.BOOKINGSTENANT);
    if (bookingId === "0") {
      dispatch(showFixedLabel("BookingAddSuccess", "success"));
    } else {
      dispatch(showFixedLabel("BookingEditSuccess", "success"));
    }
  };

  //----------------------------------------------------------------------------------------------------------------------------------------
  //--------  COMMON FUNCTIONS  ------------------------------------------------------------------------------------------------------------
  //----------------------------------------------------------------------------------------------------------------------------------------

  const getOnlyDate = (date) => {
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var year = date.getFullYear();
    let newdate = year + "/" + month + "/" + day;

    return newdate;
  };

  //----------------------------------------------------------------------------------------------------------------------------------------
  //--------  RENDER  ----------------------------------------------------------------------------------------------------------------------
  //----------------------------------------------------------------------------------------------------------------------------------------

  const renderStep = () => {
    let html = [];
    switch (step) {
      case 1:
        html.push(
          <React.Fragment key={Math.random()}>
            <div className="row">
              <div className="col-xl-3"></div>
            </div>
            <div className="space-img-wrapper">{renderSpaces()}</div>
          </React.Fragment>
        );
        break;

      case 2:
        html.push(
          <React.Fragment key={Math.random()}>
            <div className="row">
              {selectedDate !== "" ? (
                <div className="calendar-wrapper">
                  <div className="col-md-9 scheduleMonthComponent">
                    <ScheduleComponent
                      ref={scheduleMonth}
                      width="100%"
                      height="calc(100vh - 300px)"
                      selectedDate={selectedDate}
                      actionComplete={onActionComplete}
                      navigating={onNavigating}
                      dataBound={onDataBound}
                      showTimeIndicator={false}
                      workDays={setWorkDays("workDays")}
                      eventRendered={handleScheduleRendered}
                      renderCell={onRenderCell}
                      readOnly={setWorkDays("readOnly")}
                      allowMultiRowSelection={false}
                      allowMultiCellSelection={false}
                      eventSettings={{
                        dataSource: events,
                        allowEditing: true,
                        allowDeleting: true,
                        allowAdding: true,
                        enableTooltip: true,
                      }}
                      showWeekNumber={false}
                      showQuickInfo={false}
                      firstDayOfWeek={GetCookie_weekStart()}
                      currentView={"Month"}
                      popupOpen={(e) => {
                        e.cancel = true;
                      }}
                      cellClick={changeSelectedDay}
                    >
                      <ViewsDirective>
                        <ViewDirective option="Month" />
                      </ViewsDirective>
                      <Inject services={[WorkWeek, Month]} />
                    </ScheduleComponent>
                  </div>
                  <div className="col-md-3">
                    <ScheduleComponent
                      className={"scheduleDayComponent"}
                      height="calc(100vh - 300px)"
                      ref={scheduleDay}
                      width="100%"
                      selectedDate={selectedDate}
                      actionComplete={onActionComplete}
                      navigating={onNavigating}
                      dataBound={onDataBound}
                      workHours={{
                        highlight: true,
                        start: space.from,
                        end: space.to,
                      }}
                      startHour={minHourView}
                      endHour={maxHourView}
                      select={handleSelect}
                      showTimeIndicator={false}
                      eventRendered={handleScheduleRendered}
                      allowMultiRowSelection={false}
                      allowMultiCellSelection={false}
                      renderCell={onRenderCell}
                      timeScale={{
                        enable: true,
                        interval: 60,
                        slotCount: space.minTime,
                      }}
                      eventSettings={{
                        dataSource: events,
                        allowEditing: true,
                        allowDeleting: true,
                        allowAdding: true,
                        enableTooltip: true,
                        enableMaxHeight: false,
                        isBlock: true,
                      }}
                      showWeekNumber={false}
                      showQuickInfo={false}
                      firstDayOfWeek={GetCookie_weekStart()}
                      currentView={"Day"}
                    >
                      <ViewsDirective>
                        <ViewDirective option="Day" />
                      </ViewsDirective>
                      <Inject services={[WorkWeek, Month, Day]} />
                    </ScheduleComponent>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </React.Fragment>
        );
        break;
      case 3:
        html.push(
          <React.Fragment key={Math.random()}>
            <div className="booking-tenant-detail-info">
              <div className="col-xl-4 booking-space-img">
                <div className="img-wrapper">
                  <FilePreview
                    file={""}
                    url={
                      space !== "" && space.media.length > 0
                        ? space.media[0].mediaThumbnailUrl
                        : "/empty-image.png"
                    }
                    fileType={
                      space !== "" && space.media.length > 0
                        ? space.media[0].mediaContentType
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="col-xl-6 booking-info">
                <div className="icon-wrapper">
                  <div className="icon-row">
                    <div className="icon">
                      <span className="material-icons">place</span>
                    </div>
                    <p className="icon-text">{space.name}</p>
                  </div>
                  <div className="icon-row">
                    <div className="icon">
                      <span className="material-icons">calendar_today</span>
                    </div>
                    <p className="icon-text">{parseDate(startTime, true)}</p>
                  </div>
                  <div className="icon-row">
                    <div className="icon">
                      <span className="material-icons">schedule</span>
                    </div>
                    <p className="icon-text">
                      {parseTime(startTime, true) +
                        " - " +
                        parseTime(endTime, true)}
                    </p>
                  </div>
                  <div className="icon-row">
                    <div className="icon">
                      <span className="material-icons">hourglass_bottom</span>
                    </div>
                    <p className="icon-text">
                      {getDiffHours(startTime, endTime) + "h"}
                    </p>
                  </div>
                  <div className="icon-row">
                    <div className="icon">
                      <span className="material-icons">emoji_people</span>
                    </div>
                    <p className="icon-text">
                      {peopleNumber.id + " " + t("People")}
                    </p>
                  </div>
                </div>
                <h4 className="title">{t("Description")}</h4>
                <p className="description">{space.description}</p>
                <h4 className="title">{t("Instructions")}</h4>
                <p className="instructions">{space.instructions}</p>
              </div>
            </div>
          </React.Fragment>
        );
      default:
        break;
    }
    return html;
  };

  let titleStep =
    step === 1
      ? t("SelectSpace")
      : step === 2
      ? t("SelectDayHour")
      : step === 3
      ? t("BookingDetails")
      : "NoTitle";

  const isButtonDisabled = () => {
    return (
      (step === 1 && space === "") ||
      (step === 2 &&
        (startTime === "" || peopleNumber === "" || !slotAvailable))
    );
  };

  const markComboEnter = () => {
    if (!peopleNumber && step === 2 && isButtonDisabled()) {
      dispatch(
        showFixedLabel(
          t("InsertPeople"),
          "error"
        )
      );

    }
  };

  return (
    <div className="booking-tenant-create">
      <div className="return">
        <div className="col-lg-2 offsett-lg-10">
          {step === 1 ? (
            <Link className="go-back" to={ROUTE.BOOKINGSTENANT} role="link">
              <span
                className="material-icons-round"
                data-testid={"btn-goback"}
                id={"btn-goback"}
                aria-hidden={true}
              >
                arrow_back
              </span>
              <div className="go-back__text">{t("GoBack")}</div>
            </Link>
          ) : (
            <span
              className="go-back"
              to={ROUTE.BOOKINGSTENANTCREATE}
              onClick={() => {
                setStep(step - 1);
              }}
              role="link"
            >
              <span
                className="material-icons-round"
                data-testid={"btn-goback"}
                id={"btn-goback"}
              >
                arrow_back
              </span>
              <div className="go-back__text">{t("GoBack")}</div>
            </span>
          )}
        </div>
        <div className="step">
          <span className="step-title">{t("Step") + " " + step + "/3 "} </span>
        </div>
      </div>
      <div className="booking-detail-form">
        <h2 className="row">
          <div className="title">
            {titleStep}
            {step == 2 && (
              <Combo
                className="combo"
                id="cmbPeopleNumber"
                dataTestID="cmbPeopleNumber"
                inputValue={peopleNumber}
                comboPlaceholder={t("PeopleNumber") + "..."}
                defaultInputValue=""
                isSearchable={false}
                getOptionValue={(option) => option["id"]}
                getOptionLabel={(option) => t(option["name"])}
                options={peopleNumberOptions}
                isMultiple={false}
                handleChange={(e) => {
                  setPeopleNumber(e);
                }}
              />
            )}
          </div>
          {step == 2 && (
            <div className="legend-wrapper">
              <div className="legend-square-wrapper">
                <div className="legend-square today" />
                <div className="legend-text">{t("Today")}</div>
              </div>
              <div className="legend-square-wrapper">
                <div className="legend-square selected" />
                <div className="legend-text">{t("YourSelection")}</div>
              </div>
              <div className="legend-square-wrapper">
                <div className="legend-square blocked" />
                <div className="legend-text">{t("Blocked")}</div>
              </div>
            </div>
          )}
        </h2>
        {renderStep()}
      </div>
      <div
        className="btn-end"
        onMouseEnter={markComboEnter}
      >
        <Button
          dataTestID="btnStep"
          btnText={step === 3 ? t("Book") : t("Next")}
          btnType="button"
          btnClass="btn-base_normal"
          isDisabled={isButtonDisabled()}
          onHandleBtnClick={() => {
            if (step < 3) {
              setStep(step + 1);
            } else {
              postBooking();
            }
          }}
        />
      </div>
    </div>
  );
};
export default BookingTenantCreate;
