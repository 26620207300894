import React, { useState, useEffect } from 'react';
import './Checkbox.scss';

export default function Checkbox(props) {
	const { dataTestID, inputID, inputName, inputLabel, inputChecked, isDisabled, onHandleChange } = props;
	const [checked, setChecked] = useState(inputChecked);

	useEffect(
		() => {
			setChecked(inputChecked);
		},
		[inputChecked]
	);

	return (
		<div className={`checkbox ${isDisabled ? 'isDisabled' : ''}`}>
			<input
				className="inputCheck"
				id={inputID}				
				type="checkbox"
				name={inputName}
				onChange={(e) => {
					onHandleChange(e);
				}}
				checked={checked}
				autoComplete="off"
				disabled={isDisabled}
			/>
			<label data-testid={dataTestID} htmlFor={inputID}>{inputLabel}</label>
		</div>
	);
}