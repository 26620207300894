const getMyBookingsParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/BookingSpace/GetMyBookings',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getMyBookingsByIdParams = (id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Bookings/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const deleteBookingsParams = (params, SuccessFn) => {
	const options = {
		method: 'DELETE',
		params,
		endpoint: '/BookingSpace',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export { getMyBookingsParams, getMyBookingsByIdParams, deleteBookingsParams };
