import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getTenantsParams,
  getBuildingsParams,
} from "./TenantsCalls/TenantsCalls";
import { startRequest } from "@backoffice/actions/apiCall";
import { ROUTE } from "../../../../common/constants/routes";
import ResponsiveGridWrapper from "../../../../shared/ResponsiveGridWrapper/ResponsiveGridWrapper";
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  Inject,
  ExcelExport,
  Page,
  Sort,
  Filter,
  Group,
  Resize,
} from "@syncfusion/ej2-react-grids";
import {
  cleanStringFromAccents,
  parseDateTime,
} from "../../../../common/utils/parseUtils";
import {
  Input,
  Button,
  Combo,
} from "@backoffice/components/FormComponents/index";
import "./Tenants.scss";

const Tenants = () => {
  const { t } = useTranslation();
  const pageSettings = { pageSize: 15 };
  const history = useHistory();
  const grid = useRef(null);
  const dispatch = useDispatch();
  const [tenants, setTenants] = useState([]);
  const [allTenants, setAllTenants] = useState([]);
  const [search, setSearch] = useState("");
  const [building, setBuilding] = useState({ id: "All", name: "All" });
  const [buildings, setBuildings] = useState([]);

  useEffect(() => {
    getTenants();
    getBuildings();
  }, []);

  useEffect(() => {
    filterTenantsbyBuilding();
  }, [building]);

  useEffect(() => {
    filterTenants();
  }, [search]);

  const getTenants = () => {
    dispatch(startRequest(getTenantsParams(getTenantsSuccess)));
  };

  const getTenantsSuccess = (response) => {
    setTenants(response.list);
    setAllTenants(response.list);
  };

  const filterBySearch = (data = [], searching = "") => {
    const result = data.filter((element) => {
      return (
        (element.tenantName &&
          cleanStringFromAccents(element.tenantName.toLowerCase()).includes(
            searching.toLowerCase()
          )) ||
        (element.tenantSurName &&
          cleanStringFromAccents(element.tenantSurName.toLowerCase()).includes(
            searching.toLowerCase()
          )) ||
        (element.email &&
          cleanStringFromAccents(element.email.toLowerCase()).includes(
            searching.toLowerCase()
          ))
      );
    });
    return result;
  };

  const filterTenants = () => {
    if (search === "") {
      setTenants(allTenants);
    } else {
      const newData = filterBySearch(allTenants, search);
      if (newData === "") {
        setTenants(allTenants);
      }
      setTenants(newData);
    }
  };

  const filterTenantsbyBuilding = () => {
    let arrayFiltered = allTenants;
    let arrayToFilter = allTenants;
    if (building.id === "All") {
      arrayToFilter = allTenants;
    }
    if (building.id !== "All") {
      arrayFiltered = arrayToFilter.filter((element) => {
        return element.buildingId === building.id;
      });
    }
    const arrayFilteredBySearch = filterBySearch(arrayFiltered, search);
    setTenants(arrayFilteredBySearch);
  };

  const getBuildings = () => {
    dispatch(startRequest(getBuildingsParams(getBuildingsSuccess)));
  };

  const getBuildingsSuccess = (response) => {
    response.unshift({ id: "All", name: "All" });
    setBuildings(response);
  };
  const actionsTemplate = (rowData) => {
    return (
      <div className="action-template">
        {rowData.status ? (
          <span
            className="material-icons-round"
            data-testid={`btnEditStandar_${rowData.index}`}
            id={`btnEditStandar_${rowData.index}`}
            onClick={() =>
              history.push({
                pathname: ROUTE.TENANTSDETAIL.replace(":id", rowData.id),
              })
            }
            type="button"
          >
            visibility
          </span>
        ) : (
          <span
            className="material-icons-round disabled-icon"
            data-testid={`btnEditStandar_${rowData.index}`}
            id={`btnEditStandar_${rowData.index}`}
            type="button"
          >
            visibility_off
          </span>
        )}
      </div>
    );
  };

  // let searchBy = t('Search') + ' ' + t('by') + ' ' + t('Name') + ', ' + t('Surnames') + ', ' + t('Email');
  let searchBy = t("Search") + " " + t("by") + " " + t("WordMin");
  return (
    <div className="tenants">
      <h2 className="title">{t("Tenants")}</h2>
      <div className="filters-wrapper">
        <div
          className="col-xl-3 tenant-search"
          data-bs-toggle="tooltip"
          title={searchBy}
        >
          <Input
            dataTestID="txtSearch"
            inputName="txtSearch"
            clearInput={true}
            inputPlaceholder={searchBy}
            inputType="text"
            value={search}
            inputOutline={true}
            icon={"search"}
            iconLeft={true}
            isRequired={false}
            onHandleChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <div className="col-xl-3">
          <Combo
            id="cmbBuilding"
            dataTestID="cmbBuilding"
            comboLabel={t("Building")}
            inputValue={building}
            comboPlaceholder={t("Select") + " " + t("Building") + "..."}
            defaultInputValue=""
            isSearchable={false}
            getOptionValue={(option) => option["id"]}
            getOptionLabel={(option) => t(option["name"])}
            options={buildings}
            isMultiple={false}
            handleChange={(e) => {
              setBuilding(e);
            }}
          />
        </div>
      </div>
      <div className="btn-grid-end">
        <Button
          btnText={t("ExportExcel")}
          btnType="button"
          icon="download"
          btnClass="btn-base_outline export-excel-button"
          dataTestID="export-excel-button"
          onHandleBtnClick={() => {
            grid.current.excelExport({ includeHiddenColumn: true });
          }}
        />
      </div>

      <ResponsiveGridWrapper>
        <GridComponent
          className="gridTable"
          dataSource={tenants}
          width="100%"
          toolbar={false}
          ref={grid}
          allowExcelExport={true}
          allowPaging={true}
          enableAdaptiveUI={true}
          allowSorting={true}
          rowRenderingMode={"Vertical"}
          pageSettings={pageSettings}
        >
          <ColumnsDirective>
            <ColumnDirective field="id" width="0" visible={false} />
            <ColumnDirective
              field="buildingName"
              headerText={t("Building")}
              textAlign="Left"
            />
            <ColumnDirective
              field="tenantName"
              headerText={t("Name")}
              textAlign="Left"
            />
            <ColumnDirective
              field="tenantSurName"
              headerText={t("Surnames")}
              textAlign="Left"
            />
            <ColumnDirective
              field="email"
              headerText={t("Email")}
              textAlign="Left"
            />
            <ColumnDirective
              field="phone"
              headerText={t("Phone")}
              textAlign="Left"
            />
            <ColumnDirective
              field="creationDate"
              headerText={t("CreationDate")}
              valueAccessor={(field, data, column) => {
                return data.creationDate
                  ? parseDateTime(data.creationDate, true)
                  : "";
              }}
              textAlign="Left"
            />
            <ColumnDirective
              field="lastAppAccess"
              headerText={t("LastAppAccess")}
              valueAccessor={(field, data, column) => {
                return data.lastAppAccess
                  ? parseDateTime(data.lastAppAccess, true)
                  : "";
              }}
              textAlign="Left"
            />
            <ColumnDirective
              field="numberAppAccess"
              headerText={t("NumberAppAccess")}
              textAlign="Left"
            />
            <ColumnDirective
              field="status.name"
              headerText={t("Status")}
              valueAccessor={(field, data, column) => {
                return data.status ? t(data.status.name) : "";
              }}
              textAlign="Left"
            />
            <ColumnDirective
              field="actions"
              headerText={""}
              template={actionsTemplate}
              textAlign="Right"
              width="60"
            />
          </ColumnsDirective>
          <Inject services={[ExcelExport, Page, Sort, Filter, Group, Resize]} />
        </GridComponent>
      </ResponsiveGridWrapper>
    </div>
  );
};
export default Tenants;
