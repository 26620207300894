const SHOW_LABEL = 'SHOW_LABEL';
const HIDE_LABEL = 'HIDE_LABEL';

const defaultConst = {
    SHOW_LABEL,
    HIDE_LABEL,
};

export default defaultConst;
export const showFixedLabel = (messageLabel, typeLabel) => ({
    type: SHOW_LABEL,
    messageLabel: messageLabel,
    typeLabel: typeLabel,
});
export const hideFixedLabel = () => ({ type: HIDE_LABEL });
