import React from 'react';
import { useTranslation } from 'react-i18next';
import './BuildingTenant.scss';

const BuildingTenant = () => {
	const { t } = useTranslation();

	return (
		<div className="building-tenant">
			<h2 className="title">{t('Building')}</h2>
		</div>
	);
};
export default BuildingTenant;
