import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { startRequest } from "../../../../actions/apiCall";
import { Switch } from "@backoffice/components/FormComponents/index";

import {
  getNotifications,
  postNotifications,
} from "./ConfigurationProviderCalls/ConfigurationProviderCalls";
import "./ConfigurationProvider.scss";

const ConfigurationProvider = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [changeIncidentStatusNot, setChangeIncidentStatusNot] = useState({});

  useEffect(() => {
    dispatch(startRequest(getNotifications(getNotificationsSuccess)));
  }, []);

  const getNotificationsSuccess = (response) => {
    if (response) {
      setChangeIncidentStatusNot(
        response.find((x) => x.pushType == "ChangeIncidentStatus")
      );
    }
  };

  const switchHandleChange = (value, pushType, notificationType) => {
    let data = {
      pushType: pushType,
      notificationType: notificationType,
      value: value,
    };

    dispatch(startRequest(postNotifications(data, postNotificationsSuccess)));
  };

  const postNotificationsSuccess = () => {
    dispatch(startRequest(getNotifications(getNotificationsSuccess)));
  };

  const renderNotification = (x) => {
    return (
      <div className="notifications">
        <p className="notifications-title">{t(x.pushType)}</p>
        <div className="notifications-switches">
          <div className="custom-switch-control">
            <Switch
              className={
                x.canEmail ? (x.haveEmail ? "active" : "notActive") : "disable"
              }
              isRequired={false}
              inputValue={x.haveEmail}
              handleChange={(y) => {
                if (x.canEmail) {
                  switchHandleChange(y, x.pushType, "Email");
                }
              }}
            />
          </div>
          <div className="custom-switch-control">
            <Switch
              className={
                x.canPush ? (x.havePush ? "active" : "notActive") : "disable"
              }
              isRequired={false}
              inputValue={x.havePush}
              handleChange={(y) => {
                if (x.canPush) {
                  switchHandleChange(y, x.pushType, "Push");
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="configuration-provider">
      <h2 className="title">{t("Settings")}</h2>
      <h2 className="subtitle">{t("Notifications")}</h2>
      <div key={Math.random()} className="notifications">
        <p className="notifications-title"></p>
        <div className="notifications-switches">
          <div className="switch-icon">
            <span className="material-icons-round" aria-hidden={true}>
              email
            </span>
          </div>
          <div className="switch-icon">
            <span className="material-icons-round" aria-hidden={true}>
              notifications_active
            </span>
          </div>
        </div>
      </div>
      {changeIncidentStatusNot && renderNotification(changeIncidentStatusNot)}
    </div>
  );
};
export default ConfigurationProvider;
