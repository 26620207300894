import React, { useState, useEffect } from 'react';
import FilePreview from '../../../../../../../shared/FilePreview/FilePreview';
import './PopUpImage.scss';

const PopUpImage = ({ md }) => {
	const [ media, setMedia ] = useState('');
	useEffect(
		() => {
			setMedia(md);
		},
		[ md ]
	);

	return (
		<div className="service-image-popup">
			{media !== '' ? <FilePreview file={''} url={media.mediaUrl} fileType={media.mediaContentType} /> : ''}
			<div className="media-name">{md.originalFileName}</div>
		</div>
	);
};

export default PopUpImage;
