import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	ColumnDirective,
	ColumnsDirective,
	Filter,
	GridComponent,
	Group,
	Resize,
	Inject,
	Page,
	Sort,
	ExcelExport
} from '@syncfusion/ej2-react-grids';
import { startRequest } from '@backoffice/actions/apiCall';
import { openModal, closeModal } from '../../../../actions/popup';
import { showFixedLabel } from '../../../../actions/fixedLabel';
import PopUpGenericDelete from '../../../../components/PopUpGenericDelete/PopUpGenericDelete';
import ResponsiveGridWrapper from '../../../../shared/ResponsiveGridWrapper/ResponsiveGridWrapper';
import { ROUTE } from '../../../../common/constants/routes';
import { Input, Button, Combo } from '@backoffice/components/FormComponents/index';
import { cleanStringFromAccents } from '../../../../common/utils/parseUtils';
import {
	getSpacesParams,
	getSpaceTypesParams,
	deleteSpaceParams,
	getBuildingsParams
} from '../SpacesDetail/SpacesDetailCalls/SpacesDetailCalls';
import './Spaces.scss';

const Spaces = () => {
	const pageSettings = { pageSize: 15 };
	const grid = useRef(null);
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [ spaces, setSpaces ] = useState([]);
	const [ search, setSearch ] = useState('');
	const [ allSpaces, setAllSpaces ] = useState([]);
	const [ building, setBuilding ] = useState({ id: 'All', name: 'All' });
	const [ type, setType ] = useState({ id: 'All', name: 'All' });
	const [ typeOptions, setTypeOptions ] = useState([]);
	const [ buildings, setBuildings ] = useState([]);

	useEffect(() => {
		// getSpaceTypes();
		getBuildings();
	}, []);

	
	// const getSpaceTypes = () => {
	// 	dispatch(startRequest(getSpaceTypesParams(getSpaceTypesSuccess)));
	// };

	// const getSpaceTypesSuccess = (response) => {
	// 	response.unshift({ id: 'All', name: 'All' });
	// 	setTypeOptions(response);
	// 	getBuildings();
	// };

	useEffect(
		() => {
			filterSpaces();
		},
		[ building, type, search ]
	);

	const filterSpaces = () => {
		let arrayFiltered = allSpaces;
		let arrayToFilter = allSpaces;
		if (building.id === 'All' || type.id === 'All') {
			arrayToFilter = allSpaces;
		}
		if (building.id !== 'All') {
			arrayFiltered = arrayToFilter.filter((element) => {
				return element.buildingId === building.id;
			});
		}
		if (type.id !== 'All') {
			arrayFiltered = arrayFiltered.filter((element) => {
				return element.spaceTypeId === type.id;
			});
		}
		const arrayFilteredBySearch = filterBySearch(arrayFiltered, search);
		setSpaces(arrayFilteredBySearch);
	};

	const filterBySearch = (data = [], searching = '') => {
		const result = data.filter((element) => {
			return cleanStringFromAccents(element.name.toLowerCase()).includes(searching.toLowerCase());
		});
		return result;
	};

	const getBuildings = () => {
		dispatch(startRequest(getBuildingsParams(getBuildingsSuccess)));
	};

	const getBuildingsSuccess = (response) => {
		response.unshift({ id: 'All', name: 'All' });
		setBuildings(response);
		getSpaces();
	};

	const getSpaces = () => {
		dispatch(startRequest(getSpacesParams({}, false, getSpacesSuccess)));
	};

	const getSpacesSuccess = (response) => {
		setAllSpaces(response.list);
		setSpaces(response.list);
	};

	const openDeleteSpaces = (action, space) => {
		const optionValues = {
			id: 'delete-space-' + space.id + '-' + Math.random(),
			cssClass: 'modal-header__danger',
			title: t('Delete')
		};

		const options = {
			...optionValues,
			body: (
				<PopUpGenericDelete
					action={action}
					id={optionValues.id}
					deleteMessage={'DeleteConfirmMessage'}
					handleButtonDelete={() => deleteSpace(space)}
					handleButtonCancel={() => dispatch(closeModal())}
				/>
			)
		};

		dispatch(openModal(options));
	};

	const deleteSpace = (space) => {
		dispatch(startRequest(deleteSpaceParams({ id: space.id }, deleteSpaceSuccess)));
	};

	const deleteSpaceSuccess = (response) => {
		dispatch(showFixedLabel('SpaceDeleteSuccess', 'success'));
		// getSpaceTypes();
	};

	const actionsTemplate = (rowData) => {
		return (
			<div className="action-template">
				<span
					className="material-icons-round"
					data-testid={`btnEditStandar_${rowData.index}`}
					id={`btnEditStandar_${rowData.index}`}
					onClick={() =>
						history.push({
							pathname: ROUTE.SPACESDETAIL.replace(':id', rowData.id)
						})}
					type="button"
				>
					mode_edit
				</span>
				{/* <span
					className="material-icons-round"
					id={`btnDeleteStandar_${rowData.index}`}
					data-testid={`btnDeleteStandar_${rowData.index}`}
					onClick={() => {
						openDeleteSpaces('delete', rowData);
					}}
					type="button"
				>
					delete
				</span> */}
			</div>
		);
	};

	const commaSeparator = (weekdays) => {
		if (weekdays !== '') {
			return ', ';
		} else {
			return ' ';
		}
	};

	// let searchBy = t('Search') + ' ' + t('by') + ' ' + t('Name');
	let searchBy = t('Search') + ' ' + t('by') + ' ' + t('WordMin');
	return (
		<div className="spaces">
			<h2 className="title">{t('Spaces')}</h2>
			<div className="filters-wrapper">
				<div className="col-xl-3 space-search" data-bs-toggle="tooltip" title={searchBy}>
					<Input
						dataTestID="txtSearch"
						inputName="txtSearch"
						clearInput={true}
						inputPlaceholder={searchBy}
						inputType="text"
						value={search}
						inputOutline={true}
						icon={'search'}
						iconLeft={true}
						isRequired={false}
						onHandleChange={(e) => setSearch(e.target.value)}
					/>
				</div>
				<div className="col-xl-3 types">
					<Combo
						id="cmbBuilding"
						dataTestID="cmbBuilding"
						comboLabel={t('Building')}
						inputValue={building}
						comboPlaceholder={t('Select') + ' ' + t('Building') + '...'}
						defaultInputValue=""
						isSearchable={false}
						getOptionValue={(option) => option['id']}
						getOptionLabel={(option) => t(option['name'])}
						options={buildings}
						isMultiple={false}
						handleChange={(e) => {
							setBuilding(e);
						}}
					/>
				</div>
				{/* <div className="col-xl-3 types">
					<Combo
						id="cmbType"
						dataTestID="cmbType"
						comboLabel={t('SpaceType')}
						inputValue={type}
						comboPlaceholder={t('Select') + ' ' + t('Type') + '...'}
						defaultInputValue=""
						isSearchable={false}
						getOptionValue={(option) => option['id']}
						getOptionLabel={(option) => t(option['name'])}
						options={typeOptions}
						isMultiple={false}
						handleChange={(e) => {
							setType(e);
						}}
					/>
				</div> */}
			</div>
			<div className="btn-grid-end">
				<Button
					btnText={t('ExportExcel')}
					btnType="button"
					icon="download"
					btnClass="btn-base_outline export-excel-button"
					dataTestID="export-excel-button"
					onHandleBtnClick={() => {
						grid.current.excelExport({ includeHiddenColumn: true });
					}}
				/>
				{/* <Button
					btnText={t('Add')}
					icon="add"
					btnType="button"
					btnClass="btn-base_normal add-button"
					dataTestID="users-add-button"
					onHandleBtnClick={() => history.push(ROUTE.SPACESDETAIL.replace(':id', 0))}
				/> */}
			</div>
			{buildings.length > 0 ? (
				<ResponsiveGridWrapper>
					<GridComponent
						className="gridTable"
						dataSource={spaces}
						width="100%"
						toolbar={false}
						ref={grid}
						allowExcelExport={true}
						allowPaging={true}
						enableAdaptiveUI={true}
						allowSorting={true}
						rowRenderingMode={'Vertical'}
						pageSettings={pageSettings}
					>
						<ColumnsDirective>
							<ColumnDirective field="id" width="0" visible={false} />
							<ColumnDirective field="name" headerText={t('Name')} textAlign="Left" />
							<ColumnDirective
								field="building"
								headerText={t('Building')}
								valueAccessor={(field, data, column) => {
									let buildingFound = {};
									buildings.forEach((b) => {
										if (b.id === data['buildingId']) {
											buildingFound = b;
										}
									});
									return buildingFound.name;
								}}
								textAlign="Left"
							/>
							{/* <ColumnDirective
								field="spaceType"
								headerText={t('SpaceType')}
								textAlign="Left"
								valueAccessor={(field, data, column) => {
									let typeFound = {};
									typeOptions.forEach((t) => {
										if (t.id === data['spaceTypeId']) {
											typeFound = t;
										}
									});
									return typeFound.name;
								}}
							/> */}
							<ColumnDirective field="capacity" headerText={t('Capacity')} textAlign="Left" />
							<ColumnDirective
								field="week_schedule"
								headerText={t('WorkingDays')}
								textAlign="Left"
								valueAccessor={(field, data, column) => {
									let worrking_days = '';
									if (data.monday) {
										worrking_days = worrking_days + t('Monday');
									}
									if (data.tuesday) {
										worrking_days = worrking_days + commaSeparator(worrking_days) + t('Tuesday');
									}
									if (data.wednesday) {
										worrking_days = worrking_days + commaSeparator(worrking_days) + t('Wednesday');
									}
									if (data.thursday) {
										worrking_days = worrking_days + commaSeparator(worrking_days) + t('Thursday');
									}
									if (data.friday) {
										worrking_days = worrking_days + commaSeparator(worrking_days) + t('Friday');
									}
									if (data.saturday) {
										worrking_days = worrking_days + commaSeparator(worrking_days) + t('Saturday');
									}
									if (data.sunday) {
										worrking_days = worrking_days + commaSeparator(worrking_days) + t('Sunday');
									}
									return worrking_days;
								}}
							/>
							<ColumnDirective
								field="hours_schedule"
								headerText={t('WorkingHours')}
								textAlign="Left"
								valueAccessor={(field, data, column) => {
									let working_hours = data.from + '-' + data.to;
									if (data.usePartialTime) {
										working_hours = working_hours + ' ' + data.fromPartial + '-' + data.toPartial;
									}
									return working_hours;
								}}
							/>
							<ColumnDirective
								field="actions"
								headerText={''}
								template={actionsTemplate}
								textAlign="Right"
								width="50"
							/>
						</ColumnsDirective>
						<Inject services={[ ExcelExport, Page, Sort, Filter, Group, Resize ]} />
					</GridComponent>
				</ResponsiveGridWrapper>
			) : (
				''
			)}
		</div>
	);
};
export default Spaces;
