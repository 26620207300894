import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { startRequest } from '@backoffice/actions/apiCall';
import {
	getMediaImageBiggerByIdParams,
	getDocumentByIdParams
} from '../../IncidentsTenantDetailCalls/IncidentsTenantDetailCalls';
import { closeModal } from '../../../../../../actions/popup';
import { showFixedLabel } from '../../../../../../actions/fixedLabel';
import { base64ToArrayBuffer, saveByteArray } from '../../../../../../common/utils/parseUtils';
import './PopUpImage.scss';

const PopUpImage = ({ md, incidentId }) => {
	const [ media, setMedia ] = useState('');
	const dispatch = useDispatch();

	useEffect(() => {
		if (md.mediaContentType !== 'Document') {
			getMediaBiggerById(md.id);
		} else {
			dispatch(closeModal());
			downloadDocument(md.id);
		}
	}, []);

	const getMediaBiggerById = (mediaId) => {
		dispatch(
			startRequest(
				getMediaImageBiggerByIdParams(mediaId, incidentId, (response) => getMediaBiggerByIdSuccess(response))
			)
		);
	};

	const getMediaBiggerByIdSuccess = (response) => {
		setMedia(response);
	};

	const downloadDocument = (mediaId) => {
		dispatch(
			startRequest(
				getDocumentByIdParams(
					mediaId,
					incidentId,
					(response) => downloadDocumentSuccess(response),
					downloadDocumentError
				)
			)
		);
	};

	const downloadDocumentError = (response) => {
		dispatch(showFixedLabel('DownloadDocumentError', 'error'));
	};

	const downloadDocumentSuccess = (response) => {
		var sampleArr = base64ToArrayBuffer(response);
		saveByteArray(md.fileName, sampleArr, 'application/pdf');
	};

	return (
		<div className="incident-image-popup">
			{media !== '' ? (
				<React.Fragment>
					<div className="img-wrapper">
						<img
							id={'render-file' + Math.random()}
							key={'render-file' + Math.random()}
							className="img-item-popup"
							alt={md ? media.fileName : ' '}
							src={
								md.mediaContentType !== 'Document' ? (
									'data:image/jpg;base64,' + media.documentBody
								) : (
									media.commonDocumentURL
								)
							}
						/>
					</div>
					<div className="media-body-wrapper">
						<div className="media-name">{media.fileName}</div>
					</div>
				</React.Fragment>
			) : (
				''
			)}
		</div>
	);
};

export default PopUpImage;
