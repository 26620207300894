const getHomeStatsParams = (params, SuccessFn) => {
	let endpoint = '/Home';
	if (params.Filters.StartDate !== '') {
		endpoint = endpoint + '?Filters.StartDate=' + params.Filters.StartDate;
	}

	if (params.Filters.EndDate !== '') {
		endpoint = endpoint + '&Filters.EndDate=' + params.Filters.EndDate;
	}

	if (params.Filters.BuildingId !== '') {
		endpoint = endpoint + '&Filters.BuildingId=' + params.Filters.BuildingId;
	}

	const options = {
		method: 'GET',
		params,
		endpoint: endpoint,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getHomeTotalStatsParams = (params, SuccessFn) => {
	let endpoint = '/Home/GetTotals';
	if (params.Filters.StartDate !== '') {
		endpoint = endpoint + '?Filters.StartDate=' + params.Filters.StartDate;
	}

	if (params.Filters.EndDate !== '') {
		endpoint = endpoint + '&Filters.EndDate=' + params.Filters.EndDate;
	}

	if (params.Filters.BuildingId !== '') {
		endpoint = endpoint + '&Filters.BuildingId=' + params.Filters.BuildingId;
	}

	const options = {
		method: 'GET',
		params,
		endpoint: endpoint,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getAppAccessStatsParams = (params, SuccessFn) => {
	let endpoint = '/Home/GetAccess';
	if (params.Filters.StartDate !== '') {
		endpoint = endpoint + '?Filters.StartDate=' + params.Filters.StartDate;
	}

	if (params.Filters.EndDate !== '') {
		endpoint = endpoint + '&Filters.EndDate=' + params.Filters.EndDate;
	}

	if (params.Filters.BuildingId !== '') {
		endpoint = endpoint + '&Filters.BuildingId=' + params.Filters.BuildingId;
	}

	if (params.Filters.RolId !== '') {
		endpoint = endpoint + '&Filters.RolId=' + params.Filters.RolId;
	}

	const options = {
		method: 'GET',
		params,
		endpoint: endpoint,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getNewRegisterStatsParams = (params, SuccessFn) => {
	let endpoint = '/Home/GetNewRegisters';
	if (params.Filters.StartDate !== '') {
		endpoint = endpoint + '?Filters.StartDate=' + params.Filters.StartDate;
	}

	if (params.Filters.EndDate !== '') {
		endpoint = endpoint + '&Filters.EndDate=' + params.Filters.EndDate;
	}

	if (params.Filters.BuildingId !== '') {
		endpoint = endpoint + '&Filters.BuildingId=' + params.Filters.BuildingId;
	}

	if (params.Filters.RolId !== '') {
		endpoint = endpoint + '&Filters.RolId=' + params.Filters.RolId;
	}

	const options = {
		method: 'GET',
		params,
		endpoint: endpoint,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getClickNewsStatsParams = (params, SuccessFn) => {
	let endpoint = '/Home/GetNewsClicks';
	if (params.Filters.StartDate !== '') {
		endpoint = endpoint + '?Filters.StartDate=' + params.Filters.StartDate;
	}

	if (params.Filters.EndDate !== '') {
		endpoint = endpoint + '&Filters.EndDate=' + params.Filters.EndDate;
	}

	if (params.Filters.BuildingId !== '') {
		endpoint = endpoint + '&Filters.BuildingId=' + params.Filters.BuildingId;
	}
	if (params.Filters.NewsId !== '') {
		endpoint = endpoint + '&Filters.NewsId=' + params.Filters.NewsId;
	}

	const options = {
		method: 'GET',
		params,
		endpoint: endpoint,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getNewsParams = (params, SuccessFn) => {
	let endpoint = '/News/GetNameId';
	if (params.Filters.StartDate !== '') {
		endpoint = endpoint + '?Filters.StartDate=' + params.Filters.StartDate;
	}

	if (params.Filters.EndDate !== '') {
		endpoint = endpoint + '&Filters.EndDate=' + params.Filters.EndDate;
	}

	if (params.Filters.BuildingId !== '') {
		endpoint = endpoint + '&Filters.BuildingId=' + params.Filters.BuildingId;
	}

	const options = {
		method: 'GET',
		params,
		endpoint: endpoint,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export {
	getHomeStatsParams,
	getHomeTotalStatsParams,
	getAppAccessStatsParams,
	getNewRegisterStatsParams,
	getClickNewsStatsParams,
	getNewsParams
};
