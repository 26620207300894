const CHANGEERRORPAGENUMBER = 'CHANGEERRORPAGENUMBER';

const defaultConst = {
    CHANGEERRORPAGENUMBER,
};

export default defaultConst;
export const changeErrorPageNumber = (errorPageNumber) => ({
    type: CHANGEERRORPAGENUMBER,
    errorPageNumber: errorPageNumber,
});
