const getIncidentsParams = (params, SuccessFn) => {
	let endpoint = '/IncidentProvider/GetAllIncidents';
	let countFilter = 0;
	if (params.filter) {
		if (params.filter.Text !== '' && params.filter.Text !== null && params.filter.Text !== undefined ) {
		endpoint =
			endpoint +
			'?Filters.Text=' + 
			params.filter.Text 
			countFilter = 1;
		}
		if (params.filter.BuildingId && params.filter.BuildingId !== '0') {
			endpoint = countFilter === 0 ? endpoint + '?' : endpoint + '&'; 
			endpoint = endpoint +
			'Filters.BuildingId=' +
			params.filter.BuildingId 
			countFilter = 1;
		}
		if (params.filter.StatusId && params.filter.StatusId !== 'All') {
			endpoint = countFilter === 0 ? endpoint + '?' : endpoint + '&'; 
			endpoint = endpoint +
 			'Filters.StatusId=' +
			params.filter.StatusId;
			countFilter = 1
		}
		if (params.filter.CaseTypeId && params.filter.CaseTypeId !== 'All') {
			endpoint = countFilter === 0 ? endpoint + '?' : endpoint + '&'; 
			endpoint = endpoint +
 			'Filters.CaseTypeId=' +
			params.filter.CaseTypeId;
			countFilter = 1
		}
		if (params.filter.PriorityId && params.filter.PriorityId !== 'All') {
			endpoint = countFilter === 0 ? endpoint + '?' : endpoint + '&'; 
			endpoint = endpoint +
 			'Filters.PriorityId=' +
			params.filter.PriorityId;
		}
	}
	const options = {
		method: 'GET',
		endpoint: endpoint,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};
const getFullIncidentsParams = (params, SuccessFn) => {
	let endpoint = '/IncidentProvider/GetFullIncidents';
	let countFilter = 0;
	if (params.filter) {
		if (params.filter.Text !== '' && params.filter.Text !== null && params.filter.Text !== undefined ) {
		endpoint =
			endpoint +
			'?Filters.Text=' + 
			params.filter.Text 
			countFilter = 1;
		}
		if (params.filter.BuildingId && params.filter.BuildingId !== '0') {
			endpoint = countFilter === 0 ? endpoint + '?' : endpoint + '&'; 
			endpoint = endpoint +
			'Filters.BuildingId=' +
			params.filter.BuildingId 
			countFilter = 1;
		}
		if (params.filter.StatusId && params.filter.StatusId !== 'All') {
			endpoint = countFilter === 0 ? endpoint + '?' : endpoint + '&'; 
			endpoint = endpoint +
 			'Filters.StatusId=' +
			params.filter.StatusId;
		}
		if (params.filter.CaseTypeId && params.filter.CaseTypeId !== 'All') {
			endpoint = countFilter === 0 ? endpoint + '?' : endpoint + '&'; 
			endpoint = endpoint +
 			'Filters.CaseTypeId=' +
			params.filter.CaseTypeId;
		}
		if (params.filter.PriorityId && params.filter.PriorityId !== 'All') {
			endpoint = countFilter === 0 ? endpoint + '?' : endpoint + '&'; 
			endpoint = endpoint +
 			'Filters.PriorityId=' +
			params.filter.PriorityId;
		}
	}
	const options = {
		method: 'GET',
		endpoint: endpoint,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getBuildingsParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Building/GetDynamicNameId',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getIncidentStatusParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tipology/GetAllNameId/IncidentProviderStatusCodeType',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};

	return options;
};

const getIncidentTypesParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tipology/GetAllNameId/IncidentCaseTypeCodeType',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};

	return options;
};

const getIncidentPriorityParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tipology/GetAllNameId/IncidentPriorityType',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};

	return options;
}

export { getIncidentsParams, getBuildingsParams, getIncidentStatusParams, getFullIncidentsParams, getIncidentTypesParams, getIncidentPriorityParams };
