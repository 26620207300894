import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { startRequest } from "@backoffice/actions/apiCall";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { ROUTE } from "../../../../common/constants/routes";
import TabContact from "./TabContact/TabContact";
import TabAdministrator from "./TabAdministrator/TabAdministrator";
import TabServices from "./TabServices/TabServices";
import TabDocumentation from "./TabDocumentation/TabDocumentation";
import TabAppearance from "./TabAppearance/TabAppearance";
import { getBuildingByIdParams } from "./BuildingDetailCalls/BuildingDetailCalls";
import "./BuildingDetail.scss";

const BuildingDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [buildingId, setBuildingId] = useState(id);
  const [building, setBuilding] = useState("");
  const [currentTab, setCurrentTab] = useState("Contact");
  const [nextCurrentTab, setNextCurrentTab] = useState("Contact");

  useEffect(() => {
    getBuildingById();
  }, []);

  useEffect(() => {
    setCurrentTab(nextCurrentTab);
  }, [nextCurrentTab]);

  const getBuildingById = () => {
    dispatch(
      startRequest(getBuildingByIdParams(buildingId, getBuildingByIdSuccess))
    );
  };

  const getBuildingByIdSuccess = (response) => {
    setBuilding(response);
  };

  const buildingName = building.name ? building.name : "";
  const buildingAlias = building.alias ? building.alias : "";

  return (
    <div className="building-detail">
      <div className="return">
        <div className="col-lg-2 offsett-lg-10">
          <Link className="go-back" to={ROUTE.BUILDINGS} role="link">
            <span
              className="material-icons-round"
              data-testid={"btn-goback"}
              id={"btn-goback"}
              aria-hidden={true}
            >
              arrow_back
            </span>
            <div className="go-back__text">{t("GoBack")}</div>
          </Link>
        </div>
      </div>
      <div className="header-wrapper">
        <div className="left col-lg-6">
          <h2 className="title">{t("BuildingDetail") + " " + buildingName}</h2>
          <h3 className="subtitle">{buildingAlias}</h3>
        </div>
        <div className="right col-lg-6">
          <p className="location">{building.address}</p>
          <a
            href={
              "https://www.google.es/maps/place/" +
              building.address +
              "/@" +
              building.latitude +
              "," +
              building.longitude +
              "z/"
            }
            className="maps"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("ViewIn") + " Google maps"}
          </a>
        </div>
      </div>
      <div className="row">
        <div className="tabs-wrapper">
          <div id="tabs" className="tabs">
            <button
              className={`tab-button ${
                currentTab === "Contact" ? "active" : ""
              }`}
              data-testid="btnTabContact"
              onClick={() => setNextCurrentTab("Contact")}
            >
              {t("Contact")}
            </button>
            <button
              className={`tab-button ${
                currentTab === "Administrator" ? "active" : ""
              }`}
              data-testid="btnAdministrator"
              onClick={() => {
                setNextCurrentTab("Administrator");
              }}
            >
              {t("Administrator")}
            </button>
            <button
              className={`tab-button ${
                currentTab === "Services" ? "active" : ""
              }`}
              data-testid="btnServices"
              onClick={() => {
                setNextCurrentTab("Services");
              }}
            >
              {t("Services")}
            </button>
            <button
              className={`tab-button ${
                currentTab === "Documentation" ? "active" : ""
              }`}
              data-testid="btnDocumentation"
              onClick={() => {
                setNextCurrentTab("Documentation");
              }}
            >
              {t("Documentation")}
            </button>
            <button
              className={`tab-button ${
                currentTab === "Appearance" ? "active" : ""
              }`}
              data-testid="btnAppearance"
              onClick={() => {
                setNextCurrentTab("Appearance");
              }}
            >
              {t("Appearance")}
            </button>
          </div>
          <form className="tabs_form">
            <div className="form-content">
              {currentTab === "Contact" ? (
                <div
                  className="tab-content-item"
                  id="ContactTabContent"
                  hidden={currentTab !== "Contact"}
                >
                  <TabContact key="contact" building={building} />
                </div>
              ) : currentTab === "Administrator" ? (
                <div
                  className="tab-content-item"
                  id="AdministratorTabContent"
                  hidden={currentTab !== "Administrator"}
                >
                  <TabAdministrator key="administrator" building={building}/>
                </div>
              ) : currentTab === "Services" ? (
                <div
                  className="tab-content-item"
                  id="ServicesTabContent"
                  hidden={currentTab !== "Services"}
                >
                  <TabServices key="services" building={building} />
                </div>
              ) : currentTab === "Documentation" ? (
                <div
                  className="tab-content-item"
                  id="DocumentationTabContent"
                  hidden={currentTab !== "Documentation"}
                >
                  <TabDocumentation key="documentation" building={building} />
                </div>
              ) : currentTab === "Appearance" ? (
                <div
                  className="tab-content-item"
                  id="AppearanceTabContent"
                  hidden={currentTab !== "Appearance"}
                >
                  <TabAppearance key="appearance" building={building} />
                </div>
              ) : (
                "no tab selected"
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
export default BuildingDetail;
