import React from 'react';
import { useTranslation } from 'react-i18next';
import { parseDateTime } from '../../../../../common/utils/parseUtils';
import './TabHistoric.scss';

const TabHistoric = ({ incident }) => {
	const { t } = useTranslation();

	const renderIncidents = () => {
		let html = [];
		if (incident.incidentsHistory.length > 0) {
			incident.incidentsHistory.forEach((incidentHistory, i) => {
				html.push(
					<div key={i + '-incidents'}>
						<div className="row">
							<div className="content">
								<div className="left col-6">
									<div className="history-incident-date">
										{parseDateTime(incidentHistory.creationDate)}
									</div>
								</div>
								<div className="right col-6">
									<div className="history-incident-status">
										<div
											className={
												incident.statusCodeValue === 'InProgress' ? (
													'status progress'
												) : incident.statusCodeValue === 'Resolved' ? (
													'status resolved'
												) : (
													'status pending'
												)
											}
										>
											{t(incidentHistory.statusCodeName)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			});
		}

		return html;
	};

	return (
		<div className="tab-historic">
			<div className="incident-provider-historic">{renderIncidents()}</div>
		</div>
	);
};
export default TabHistoric;
