import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	ColumnDirective,
	ColumnsDirective,
	Filter,
	GridComponent,
	Group,
	Resize,
	Inject,
	Page,
	Sort,
	ExcelExport
} from '@syncfusion/ej2-react-grids';
import { Button } from '@backoffice/components/FormComponents/index';
import ResponsiveGridWrapper from '../../../../../shared/ResponsiveGridWrapper/ResponsiveGridWrapper';
import { closeModal, openModal } from '../../../../../actions/popup';
import PopUpAddPerson from '../PopUpAddPerson/PopUpAddPerson';
import PopUpContact from '../PopUpContact/PopUpContact';
import PopUpGenericDelete from '../../../../../components/PopUpGenericDelete/PopUpGenericDelete';
import { startRequest } from '@backoffice/actions/apiCall';
import { getContactsByIdParams, deleteContactParams } from '../BuildingDetailCalls/BuildingDetailCalls';
import { showFixedLabel } from '../../../../../actions/fixedLabel';
import './TabContact.scss';

const TabContact = () => {
	const { t } = useTranslation();
	const [ contacts, setContacts ] = useState([]);
	const pageSettings = { pageSize: 50 };
	const dispatch = useDispatch();
	const grid = useRef(null);
	const { id } = useParams();
	const [ buildingId, setBuildingId ] = useState(id);

	useEffect(() => {
		getContactsBuilding();
	}, []);

	const getContactsBuilding = () => {
		dispatch(startRequest(getContactsByIdParams(buildingId, getContactsByIdParamsSuccess)));
	};

	const getContactsByIdParamsSuccess = (response) => {
		setContacts(response);
	};

	const openDeleteContact = (action, contactId, buildingId) => {
		const optionValues = {
			id: 'delete-service-' + contactId + '-' + Math.random(),
			cssClass: 'modal-header__danger',
			title: t('Delete')
		};

		const options = {
			...optionValues,
			body: (
				<PopUpGenericDelete
					action={action}
					id={optionValues.id}
					deleteMessage={'DeleteConfirmMessage'}
					handleButtonDelete={() => deleteContact(contactId, buildingId)}
					handleButtonCancel={() => dispatch(closeModal())}
				/>
			)
		};

		dispatch(openModal(options));
	};

	const deleteContact = (contactId, buildingId) => {
		dispatch(startRequest(deleteContactParams({ buildingId : buildingId, contactId: contactId }, deleteContactParamsSuccess)));
	};

	const deleteContactParamsSuccess = (response) => {
		dispatch(showFixedLabel('ContactDeleteSuccess', 'success'));
		getContactsBuilding();
	};

	const actionsTemplate = (rowData) => {
		return (
			<div className="action-template">
				<span
					className="material-icons-round"
					data-testid={`btnEditStandar_${rowData.index}`}
					id={`btnEditStandar_${rowData.index}`}
					onClick={() => {
						openEditContact('edit', rowData.contactId);
					}}
					type="button"
				>
					mode_edit
				</span>
				<span
					className="material-icons-round"
					id={`btnDeleteStandar_${rowData.index}`}
					data-testid={`btnDeleteStandar_${rowData.index}`}
					onClick={() => {
						openDeleteContact('delete', rowData.contactId, buildingId);
					}}
					type="button"
				>
					delete
				</span>
			</div>
		);
	};

	const openEditContact = (action, contactId) => {
		const optionValues = {
			id: 'edit-contact' + Math.random(),
			dataTestId: 'edit-contact' + Math.random(),
			cssClass: 'modal-header__add',
			title: t('EditContact')
		};

		const options = {
			...optionValues,
			body: (
				<PopUpContact
					buildingId={buildingId}
					contactId={contactId}
					action={action}
					handleSubmit={getContactsBuilding}
					optionValues={optionValues}
				/>
			)
		};
		dispatch(openModal(options));
	};

	const addExistingContact = () => {
		const optionValues = {
			id: 'add-admin' + Math.random(),
			dataTestId: 'add-admin' + Math.random(),
			cssClass: 'modal-header__add',
			title: t('AddExistContact')
		};

		const options = {
			...optionValues,
			body: (
				<PopUpAddPerson
					buildingId={buildingId}
					handleSubmit={getContactsBuilding}
					optionValues={optionValues}
				/>
			)
		};

		dispatch(openModal(options));
	};

	const addContact = (action, contactId) => {
		const optionValues = {
			id: 'add-admin' + Math.random(),
			dataTestId: 'add-admin' + Math.random(),
			cssClass: 'modal-header__add',
			title: t('AddContact')
		};

		const options = {
			...optionValues,
			body: (
				<PopUpContact
					action={action}
					contactId={contactId}
					buildingId={buildingId}
					handleSubmit={getContactsBuilding}
					optionValues={optionValues}
				/>
			)
		};

		dispatch(openModal(options));
	};

	return (
		<div className="tab-contact">
			<div className="btn-grid-end">
				<Button
					btnText={t('ExportExcel')}
					btnType="button"
					icon="download"
					btnClass="btn-base_outline export-excel-button"
					dataTestID="export-excel-button"
					onHandleBtnClick={() => {
						grid.current.excelExport({ includeHiddenColumn: true });
					}}
				/>
				<Button
					btnText={t('AddExistContact')}
					btnType="button"
					icon="add"
					btnClass="btn-base_outline add-contact-button"
					dataTestID="add-exist-contact-button"
					onHandleBtnClick={() => {
						addExistingContact();
					}}
				/>
				<Button
					btnText={t('AddContact')}
					btnType="button"
					icon="add"
					btnClass="btn-base add-contact-button"
					dataTestID="add-contact-button"
					onHandleBtnClick={() => {
						addContact('add', '');
					}}
				/>
			</div>
			<ResponsiveGridWrapper>
				<GridComponent
					className="gridTable"
					dataSource={contacts}
					width="100%"
					toolbar={false}
					ref={grid}
					allowExcelExport={true}
					allowPaging={true}
					enableAdaptiveUI={true}
					allowSorting={true}
					rowRenderingMode={'Vertical'}
					pageSettings={pageSettings}
				>
					<ColumnsDirective>
						<ColumnDirective field="id" width="0" visible={false} />
						<ColumnDirective field="name" headerText={t('Name')} textAlign="Left" />
						<ColumnDirective field="phoneNumber" headerText={t('Phone')} textAlign="Left" />
						<ColumnDirective field="email" headerText={t('Email')} textAlign="Left" />
						<ColumnDirective field="observations" headerText={t('Observations')} textAlign="Left" />
						<ColumnDirective
							field="actions"
							headerText={''}
							template={actionsTemplate}
							textAlign="Right"
							width="60"
						/>
					</ColumnsDirective>
					<Inject services={[ ExcelExport, Page, Sort, Filter, Group, Resize ]} />
				</GridComponent>
			</ResponsiveGridWrapper>
		</div>
	);
};
export default TabContact;
