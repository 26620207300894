import React from 'react';
import Root from './services/Root';
import { render } from 'react-dom';

import './locales/i18n';
import * as serviceWorker from './serviceWorker';
import './index.scss';
import crypto from 'crypto';

Object.defineProperty(global, 'crypto', {
	value: {
		getRandomValues: (arr) => crypto.randomBytes(arr.length)
	}
});

render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
