import language from '@backoffice/actions/language';
import { SaveCookies } from '@backoffice/common/utils/cookiesManager';
import { COOKIES_CONSTANTS } from '@backoffice/common/constants/cookies';
import { GetCookie_culture, GetCookie_weekStart } from '@backoffice/common/utils/cookiesManager';
/* Redux state init */
const initialState = {
	lang: GetCookie_culture() ? GetCookie_culture() : 'es',
	weekStart: GetCookie_weekStart() ? GetCookie_weekStart() : 1
};

export default function languageReducer(state = initialState, action) {
	if (action.type !== undefined) {
		switch (action.type) {
			case language.CHANGELANGUAGE:
				SaveCookies(COOKIES_CONSTANTS.CULTURE, action.lang);
				return {
					...state,
					lang: action.lang
				};
			case language.CHANGEFORMAT:
				SaveCookies(COOKIES_CONSTANTS.DATEFORMAT, action.format);
				return {
					...state,
					format: action.format
				};
			case language.CHANGEWEEKSTART:
				SaveCookies(COOKIES_CONSTANTS.WEEKSTART, action.weekStart);
				return {
					...state,
					weekStart: action.weekStart
				};
			default:
				return { ...state };
		}
	}
	return state;
}
