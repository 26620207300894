import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { closeModal } from '../../actions/popup';
import { hideFixedLabel } from '../../actions/fixedLabel';
import './CustomPopUp.scss';
import FixedLabel from '../FixedLabel/FixedLabel';

const CustomPopUp = () => {
	const dispatch = useDispatch();
	const popupReducer = useSelector((state) => state.popup);
	const [ popUpHtml, setPopUpHtml ] = useState([]);

	useEffect(
		() => {
			let popUpHtml = [];
			if (popupReducer.popUpCount > 0 && Array.isArray(popupReducer.popUps)) {
				popupReducer.popUps.forEach((popup) => {
					popUpHtml.push(
						<Modal
							id={popup.options.id}
							key={popup.options.id}
							data-testid={popup.options.dataTestId}
							size={popup.options.size || 'lg'}
							className={popup.hide ? 'd-none' : ''}
							backdrop={popup.options.backdrop || true}
							dialogClassName={`backoffice-modal ${popup.options.dialogClassName || ''}`}
							onHide={() => {
								dispatch(hideFixedLabel());
								dispatch(closeModal(popup));
							}}
							show={popup.show}
							centered={popup.options.centered}
						>
							{renderHeader(popup)}
							{renderBody(popup)}
							{renderFooter(popup)}
						</Modal>
					);
				});
			}
			setPopUpHtml(popUpHtml);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[ popupReducer ]
	);

	const renderHeader = (popup) => {
		if (popup.options.title) {
			return (
				<Modal.Header closeButton className={popup.options.cssClass || ''}>
					<Modal.Title>
						<h2>{popup.options.title}</h2>
						{popup.options.date && <h3>{popup.options.date}</h3>}
					</Modal.Title>
				</Modal.Header>
			);
		}
	};

	const renderBody = (popup) => {
		if (popup.options.body) {
			return (
				<Modal.Body>
					<FixedLabel parentType="popup" />
					{popup.options.body}
				</Modal.Body>
			);
		}
	};

	const renderFooter = (popup) => {
		if (popup.options.footer) {
			return <Modal.Footer>{popup.options.footer}</Modal.Footer>;
		}
	};

	return popUpHtml;
};

export default CustomPopUp;
