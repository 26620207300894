import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useDispatch } from 'react-redux';
import { GetSessionStorage } from '@backoffice/common/utils/storageManager';
import { Link } from 'react-router-dom';
import { startRequest } from '../../../../actions/apiCall';
import { getContactsParams } from './ContactsTenantCalls';
import { ROUTE } from '../../../../common/constants/routes';
import './ContactsTenant.scss';

const ContactsTenant = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const isMobile = useMediaQuery({ query: '(max-width: 450px)' });
	const [ buildingId, setBuildingId ] = useState(GetSessionStorage('PROFILE_').buildingId);

	const [ contacts, setContacts ] = useState([]);

	useEffect(() => {
		getContacts();
	}, []);

	const getContacts = () => {
		dispatch(startRequest(getContactsParams(buildingId, getContactsSuccess)));
	};

	const getContactsSuccess = (response) => {
		setContacts(response);
	};

	const renderContacts = (documents) => {
		let html = [];
		if (contacts.length > 0) {
			contacts.forEach((contact, i) => {
				html.push(
					<Link
						className="col-md-5 contact-tenant"
						key={i + '-contact'}
						to={ROUTE.CONTACTSTENANTDETAIL.replace(':id', contact.contactId)}
					>
						<div className="body-wrapper">
							<div className="contact-position">{t(contact.positionTypeName)}</div>
							<p className="contact-name">{contact.name}</p>
							<div className="contact-email" aria-label={t('Email') + ': ' + contact.email}>
								<span aria-hidden={true}>{contact.email}</span>
							</div>
							<div className="contact-phone" aria-label={t('Phone') + ': ' + contact.phoneNumber + '.'}>
								<span aria-hidden={true}>{contact.phoneNumber}</span>
							</div>
						</div>

						{!isMobile ? (
							<div className="arrow-wrapper">
								<span
									className="material-icons-round"
									data-testid={'btn-goback'}
									id={'btn-goback'}
									aria-hidden={true}
								>
									arrow_forward
								</span>
							</div>
						) : (
							''
						)}
					</Link>
				);
			});
			return html;
		}
	};

	return (
		<div className="contacts-tenant">
			<h1 className="title">{t('Contacts')}</h1>
			{/* <p className="description">{t('Description') + '...'}</p> */}
			<div className="contacts-wrapper">{renderContacts()}</div>
		</div>
	);
};

export default ContactsTenant;
