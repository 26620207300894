import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import ReactHtmlParser from 'react-html-parser';
import { parseDate } from '../../../../../common/utils/parseUtils';
import './PopUpPreview.scss';

const PopUpPreview = ({ newDetail, closePopUp }) => {
	const isSmallMobile = useMediaQuery({ query: '(max-width: 1500px)' });

	const [ isWeb, setIsWeb ] = useState(true);

	const renderNew = () => {
		return (
			<div className="row rowNew">
				<div className="container">
					<h1 className="title-new">{newDetail && newDetail.title && newDetail.title}</h1>
					<p className="date-card">{parseDate(new Date())}</p>
					{newDetail && newDetail.imgUrl ? (
						<img className="img-new" src={newDetail.imgUrl} alt="" />
					) : (
						<img className="img-new" src={process.env.PUBLIC_URL + '/empty-image.png'} alt="" />
					)}

					<div className="content-new">
						{ReactHtmlParser(newDetail && newDetail.content && newDetail.content)}
					</div>
				</div>
			</div>
		);
	};

	const renderMobile = () => {
		return (
			<div className="popUpPreview-content-image-mobile">
				<div className="popUpPreview-content-image-mobile-top">
					<img src="/top-mobile.png" alt="top-mobile" />
				</div>
				<div className="popUpPreview-content-image-mobile-midle">
					<img src="/left-mobile.png" alt="left-mobile" />
					<div className="popUpPreview-content-image-mobile-midle-content">{renderNew()}</div>
					<img src="/right-mobile.png" alt="right-mobile" />
				</div>
				<div className="popUpPreview-content-image-mobile-bottom">
					<img src="/bottom-mobile.png" alt="bottom-mobile" />
				</div>
			</div>
		);
	};

	const renderLaptop = () => {
		return (
			<div className="popUpPreview-content-image-laptop">
				<div className="popUpPreview-content-image-laptop-top">
					<img src="/top-laptop.png" alt="top-laptop" />
				</div>
				<div className="popUpPreview-content-image-laptop-midle">
					<img src="/left-laptop.png" alt="left-laptop" />
					<div className="popUpPreview-content-image-laptop-midle-content">{renderNew()}</div>
					<img src="/right-laptop.png" alt="right-laptop" />
				</div>
				<div className="popUpPreview-content-image-laptop-bottom">
					<img src="/bottom-laptop.png" alt="bottom-laptop" />
				</div>
			</div>
		);
	};

	return (
		<div className="popUpPreview">
			<div className="popUpPreview-closeTab">
				<span className="material-icons-round popUpPreview-closeTab-icon" onClick={closePopUp}>
					close
				</span>
			</div>
			<div className="popUpPreview-content">
				<div className="popUpPreview-content-chooseTabs">
					<div
						className={
							isWeb ? 'popUpPreview-content-chooseTabs-web active' : 'popUpPreview-content-chooseTabs-web'
						}
						onClick={() => setIsWeb(true)}
					>
						Web
					</div>
					<div
						className={
							!isWeb ? (
								'popUpPreview-content-chooseTabs-app active'
							) : (
								'popUpPreview-content-chooseTabs-app'
							)
						}
						onClick={() => setIsWeb(false)}
					>
						App
					</div>
				</div>
				<div className="popUpPreview-content-image">{isWeb ? renderLaptop() : renderMobile()}</div>
			</div>
		</div>
	);
};

export default PopUpPreview;
