import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Group,
  Resize,
  Inject,
  Page,
  Sort,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";
import {
  getContactListByIdParams,
  assignContactParams,
} from "../BuildingDetailCalls/BuildingDetailCalls";
import { useDispatch } from "react-redux";
import { Input } from "@backoffice/components/FormComponents/index";
import { cleanStringFromAccents } from "../../../../../common/utils/parseUtils";
import ResponsiveGridWrapper from "../../../../../shared/ResponsiveGridWrapper/ResponsiveGridWrapper";
import { startRequest } from "@backoffice/actions/apiCall";
import { Button } from "../../../../../components/FormComponents";
import { ROUTE } from "../../../../../common/constants/routes";
import { useHistory } from "react-router-dom";
import { showFixedLabel } from "../../../../../actions/fixedLabel";
import { closeModal } from "../../../../../actions/popup";
import "./PopUpAddPerson.scss";

const PopUpAddPerson = ({ buildingId, handleSubmit }) => {
  const pageSettings = { pageSize: 50 };
  const grid = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getAllContacts();
  }, []);

  useEffect(() => {
    filterUsers();
  }, [search]);

  const getAllContacts = () => {
    dispatch(
      startRequest(
        getContactListByIdParams(buildingId, getContactListByIdParamsSuccess)
      )
    );
  };

  const getContactListByIdParamsSuccess = (response) => {
    setUsers(response);
    setAllUsers(response);
  };

  const addContact = () => {
    let selectedContacts = grid.current.getSelectedRecords();
    selectedContacts.forEach((contact) => {
      contact.buildingId = buildingId;
      contact = { ...contact };
      dispatch(
        startRequest(assignContactParams(contact, assignContactParamsSuccess))
      );
    });
  };

  const assignContactParamsSuccess = (response) => {
    history.push(ROUTE.BUILDINGSDETAIL.replace(":id", buildingId));
    dispatch(closeModal());
    dispatch(showFixedLabel("ContactAddSuccess", "success"));
    handleSubmit();
  };

  const filterBySearch = (data = [], searching = "") => {
    const result = data.filter((element) => {
      return (
        cleanStringFromAccents(element.name.toLowerCase()).includes(
          searching.toLowerCase()
        ) ||
        cleanStringFromAccents(element.positionTypeName)
          .toLowerCase()
          .includes(searching.toLowerCase()) ||
        cleanStringFromAccents(element.phoneNumber)
          .toLowerCase()
          .includes(searching.toLowerCase()) ||
        cleanStringFromAccents(element.email)
          .toLowerCase()
          .includes(searching.toLowerCase())
      );
    });

    return result;
  };

  const filterUsers = () => {
    if (search === "") {
      setUsers(allUsers);
    } else {
      const newData = filterBySearch(allUsers, search);
      if (newData === "") {
        setUsers(allUsers);
      }
      setUsers(newData);
    }
  };

  // let searchBy = t('Search') + ' ' + t('by') + ' ' + t('Name') + ', ' + t('Phone') + ', ' + t('Email');
  let searchBy = t("Search") + " " + t("by") + " " + t("WordMin");
  return (
    <div className="add-person-popup">
      <div className="filters-wrapper">
        <div
          className="col-xl-3 search"
          data-bs-toggle="tooltip"
          title={searchBy}
        >
          <Input
            dataTestID="txtSearch"
            inputName="txtSearch"
            clearInput={true}
            inputPlaceholder={searchBy}
            inputType="text"
            value={search}
            inputOutline={true}
            icon={"search"}
            iconLeft={true}
            isRequired={false}
            onHandleChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>

      <ResponsiveGridWrapper>
        <GridComponent
          className="gridTable"
          dataSource={users.map((x) => {
            return { ...x, positionTypeName: t(x.positionTypeName) };
          })}
          width="100%"
          toolbar={false}
          ref={grid}
          allowExcelExport={true}
          allowPaging={true}
          enableAdaptiveUI={true}
          allowSorting={true}
          rowRenderingMode={"Vertical"}
          pageSettings={pageSettings}
        >
          <ColumnsDirective>
            <ColumnDirective field="id" width="0" visible={false} />

            <ColumnDirective field="checkbox" type="checkbox" width="50" />

            <ColumnDirective
              field="positionTypeName"
              headerText={t("Position")}
              textAlign="Left"
            />
            <ColumnDirective
              field="name"
              headerText={t("Name")}
              textAlign="Left"
              // valueAccessor={(field, data, column) => {
              // 	return data.name + ' ' + data.surnames;
              // }}
            />
            <ColumnDirective
              field="phoneNumber"
              headerText={t("Phone")}
              textAlign="Left"
              valueAccessor={(field, data, column) => {
                return data.phoneNumber;
              }}
            />
            <ColumnDirective
              field="email"
              headerText={t("Email")}
              textAlign="Left"
            />
            <ColumnDirective
              field="observations"
              headerText={t("Observations")}
              textAlign="Left"
            />
          </ColumnsDirective>
          <Inject services={[ExcelExport, Page, Sort, Filter, Group, Resize]} />
        </GridComponent>
      </ResponsiveGridWrapper>

      <div className="btn-end">
        <Button
          dataTestID="btnSaveContact"
          btnText={t("Add")}
          btnType="button"
          btnClass="btn-base_normal"
          onHandleBtnClick={() => addContact()}
        />
      </div>
    </div>
  );
};

export default PopUpAddPerson;
