import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ROUTE } from '../../../../common/constants/routes';
import './ServicesTenantDetail.scss';
import { getServiceByIdParams } from './ServicesTenantDetailCalls/ServicesTenantDetailCalls';
import { GetSessionStorage } from '@backoffice/common/utils/storageManager';
import { startRequest } from '../../../../actions/apiCall';

const ServicesTenantDetail = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { id } = useParams();
	const [ serviceId, setServiceId ] = useState(id);
	const [ buildingId, setBuildingId ] = useState(GetSessionStorage('PROFILE_').buildingId);
	const [ service, setService ] = useState('');

	useEffect(() => {
		getServiceById();
	}, []);

	const getServiceById = () => {
		dispatch(startRequest(getServiceByIdParams(buildingId, serviceId, getServiceByIdParamsSuccess)));
	};

	const getServiceByIdParamsSuccess = (response) => {
		setService(response);
	};

	return (
		<div className="services-tenant-detail">
			<div className="return">
				<div className="col-lg-2 offsett-lg-10">
					<Link className="go-back" to={ROUTE.SERVICESTENANT} role="link">
						<span
							className="material-icons-round"
							data-testid={'btn-goback'}
							id={'btn-goback'}
							aria-hidden={true}
						>
							arrow_back
						</span>
						<div className="go-back__text">{t('GoBack')}</div>
					</Link>
				</div>
			</div>
			<h2 className="title">{t('ServiceDetail')}</h2>
			{/* <p className="description">{t('Description') + '...'}</p> */}

			<div className="service-detail-wrapper">
				{service !== '' ? (
					<React.Fragment>
						<div className="img-service-wrapper">
							<img
								src={
									service && service.media && service.media.length > 0 && service.media[0].mediaUrl !== '' ? (
										service.media[0].mediaUrl
									) : (
										'/empty-image.png'
									)
								}
								alt="service-img"
							/>
						</div>

						<div className="service-detail-body">
							<p className="service-name">{service.name}</p>
							<p className="service-phone">
								{service.phoneNumber}
								<span className="icon">
									<span className="material-icons">phone</span>
								</span>
							</p>
							<p className="service-email">
								{service.email}
								<a className="icon" aria-hidden={true} href={'mailto: ' + service.email}>
									<span className="material-icons">email</span>
								</a>
							</p>
							<p className="service-address">
								{service.address}
								{service.address ? (
									<a
										className="icon"
										aria-hidden={true}
										target="_blank"
										rel="noreferrer"
										href={'https://www.google.es/maps/place/' + service.address}
									>
										<span className="material-icons">place</span>
									</a>
								) : (
									<span className="icon" aria-hidden={true}>
										<span className="material-icons">place</span>
									</span>
								)}
							</p>
							<p className="service-url">
								{service.url ? service.url : '-'}
								{service.url ? (
									<a
										className="icon"
										aria-hidden={true}
										target="_blank"
										rel="noreferrer"
										href={'https://' + service.url}
									>
										<span className="material-icons">public</span>
									</a>
								) : (
									<span className="icon" aria-hidden={true}>
										<span className="material-icons">public</span>
									</span>
								)}
							</p>
							<h4 className="description-title">{t('Description')}:</h4>
							<p className="description">{service.description}</p>
						</div>
					</React.Fragment>
				) : (
					''
				)}
			</div>
		</div>
	);
};

export default ServicesTenantDetail;
