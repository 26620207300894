import React, { useRef, useState, useEffect, memo } from 'react';
import { detach } from '@syncfusion/ej2-base';
import { useDispatch } from "react-redux";
import { useTranslation } from 'react-i18next';
import { UploaderComponent } from '@syncfusion/ej2-react-inputs';
import FilePreview from '../FilePreview/FilePreview';
import { formatBytes } from '../../common/utils/parseUtils';
import { showFixedLabel } from '../../actions/fixedLabel';
import './Uploader.scss';

const Uploader = memo(({ id, key, allowedExtensions, disabled, setUploader }) => {
	const uploader = useRef(null);
	const dropArea = useRef(null);
	const [ uploaderAllowedExtensions, setUploaderAllowedExtensions ] = useState('');
	const { t } = useTranslation();
	const dispatch = useDispatch();

	useEffect(() => {
		setUploader(uploader.current);
		let drop = document.getElementById('droparea-' + id);
		if (drop) {
			drop.addEventListener('dragenter', change, false);
			drop.addEventListener('dragleave', change_back, false);
		}
	}, []);

	useEffect(
		() => {
			setUploaderAllowedExtensions(allowedExtensions);
		},
		[ allowedExtensions ]
	);

	const onCreated = () => {
		uploader.current.dropArea = dropArea.current;
	};

	const uploaderTemplate = (data) => {
		
		let _rawFile = data.rawFile ? data.rawFile : data;

		return (
			<div className="file-wrapper">
				<div className="img-wrapper">
					<FilePreview file={_rawFile} url={''} fileType={'Image'} />
				</div>
				<div className="body-wrapper">
					<div className="file-text">
						<span className="file-name">{data.name}</span>
						<span className="file-size">{formatBytes(data.size)}</span>
					</div>
					<div className="file-icons">
						<span
							className="material-icons-round delete-btn"
							id={`btnDelete`}
							data-testid={`btnDelete`}
							type="button"
						>
							delete
						</span>
					</div>
				</div>
			</div>
		);
	};

	const dropAreaClick = (args) => {
		const target = args.target;
		if (target.classList.contains('delete-btn')) {
			for (const i of uploader.current.getFilesData()) {
				if (target.closest('li').getAttribute('data-file-name') === i.name) {
					uploader.current.remove(
						uploader.current.getFilesData()[uploader.current.getFilesData().indexOf(i)]
					);
				}
			}
		} else if (target.classList.contains('e-file-remove-btn')) {
			detach(target.closest('li'));
		}
	};

	const browseClick = (args) => {
		const fatherElement = document.getElementById('dropArea-' + id);
		const browserEle = fatherElement.getElementsByClassName('e-file-select-wrap');
		const buttonElement = browserEle[0].children[0];
		buttonElement.click();
		args.preventDefault();
	};

	const change = (dropAreaIn) => {
		dropArea.current.classList.add('drop-hover');
	};

	const change_back = () => {
		if (dropArea.current.classList.contains('drop-hover')) {
			dropArea.current.classList.remove('drop-hover');
		}
	};

	const onFileSelect = (args) => {
		let allowedExtensionsArr = allowedExtensions.split(',').map((x) => x.trim().replace('.', ''));

		let allExtensionsAreCorrect = true;
		args.filesData.forEach((x) => {
			if (!allowedExtensionsArr.includes(x.type)) {
				allExtensionsAreCorrect = false;
			}
		});

		if (allExtensionsAreCorrect === false) {
			args.cancel = true;
			dispatch(showFixedLabel(t('FileExtensionIncorrect'), 'error'));
		}
	};

	return (
		<div className="uploader">
			<div id={'dropArea-' + id} className="droparea" onClick={dropAreaClick} ref={dropArea}>
				<span id="drop" className="drop">
					{t('DropFilesHereOr')}{' '}
					<a href="" id={'browse-' + id} className="browse" onClick={browseClick}>
						<u>{t('Browse')}</u>
					</a>
				</span>
				<UploaderComponent
					id={id + '-component'}
					key={key + '-component'}
					ref={uploader}
					created={onCreated}
					template={uploaderTemplate}
					allowedExtensions={uploaderAllowedExtensions}
					disabled={disabled}
					selected={onFileSelect}
				/>
			</div>
		</div>
	);
});
export default Uploader;
