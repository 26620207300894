import moment from 'moment';
import { GetCookie_formatDate } from './cookiesManager';

const addDays = (date, days) => {
	let dateAdded = moment(date, GetCookie_formatDate()).add(days, 'days').toDate();
	return dateAdded;
};

const subtractDays = (date, days) => {
	let dateAdded = moment(date, GetCookie_formatDate()).subtract(days, 'days').toDate();
	return dateAdded;
};

const addMonths = (date, months) => {
	let dateAdded = moment(date, GetCookie_formatDate()).add(months, 'months').toDate();
	return dateAdded;
};

const subtractMonths = (date, months) => {
	let dateAdded = moment(date, GetCookie_formatDate()).subtract(months, 'months').toDate();
	return dateAdded;
};

const getDiffHours = (startDate, endDate) => {
	let duration = moment.duration(moment(endDate, 'YYYY-MM-DD HH:mm').diff(moment(startDate, 'YYYY-MM-DD HH:mm')));
	return duration.asHours();
};

const addHours = (initialHour, hour) => {
	if (initialHour && hour) {
		let newHour = moment(initialHour, 'HH:mm').add(hour, 'hours').format('HH:mm');
		return newHour;
	}
};

const removeHours = (initialHour, hour) => {
	if (initialHour && hour) {
		let newHour = moment(initialHour, 'HH:mm').subtract(hour, 'hours').format('HH:mm');
		return newHour;
	}
};

const addMinutes = (initialHour, minute) => {
	if (initialHour && minute) {
		let newHour = moment(initialHour, 'HH:mm').add(minute, 'minutes').format('HH:mm');
		return newHour;
	}
};

const removeMinutes = (initialHour, minute) => {
	if (initialHour && minute) {
		let newHour = moment(initialHour, 'HH:mm').subtract(minute, 'minutes').format('HH:mm');
		return newHour;
	}
};

export {
	addDays,
	subtractDays,
	addMonths,
	subtractMonths,
	getDiffHours,
	addHours,
	removeHours,
	addMinutes,
	removeMinutes
};
