import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { closeModal } from '../../../../../actions/popup';
import { startRequest } from '@backoffice/actions/apiCall';
import { showFixedLabel } from '../../../../../actions/fixedLabel';
import { postMediaParams } from '../SpacesDetailCalls/SpacesDetailCalls';
import { Button } from '@backoffice/components/FormComponents/index';
import Uploader from '../../../../../shared/Uploader/Uploader';
import './PopUpMedia.scss';

const PopUpMedia = ({ spaceId, handleSubmit }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [ uploader, setUploader ] = useState('');

	const getMedia = () => {
		let newMedia = [];
		uploader.filesData.forEach((file) => {
			newMedia.push({
				File: file.rawFile
			});
		});
		return {
			EntityId: spaceId,
			Files: newMedia
		};
	};

	const postMedia = () => {
		const mediaData = getMedia();
		dispatch(startRequest(postMediaParams(mediaData, mediaSuccess)));
	};

	const mediaSuccess = (response) => {
		dispatch(closeModal());
		dispatch(showFixedLabel('MediaSaveSuccess', 'success'));
		handleSubmit();
	};

	return (
		<div id={'media-popup' + Math.random()} className="popupmedia">
			<div className="uploader-wrapper">
				<Uploader
					id={"mediaUploader"}
					isDisabled={false}
					allowedExtensions={".jpg, .png, .bmp, .gif, .tif, .webp"}
					setUploader={setUploader}
				/>

			</div>
			<div className="btn-end">
				<Button
					btnText={t('Cancel')}
					btnType="button"
					btnClass="btn-base_outline"
					onHandleBtnClick={() => {
						dispatch(closeModal());
					}}
				/>
				<Button
					dataTestID="btnSaveSpace"
					btnText={t('SaveChanges')}
					btnType="button"
					btnClass="btn-base_normal"
					onHandleBtnClick={() => postMedia()}
				/>
			</div>
		</div>
	);
};

export default PopUpMedia;
