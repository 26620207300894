import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useHistory } from 'react-router-dom';
import { GetCookie_refreshToken } from '@backoffice/common/utils/cookiesManager';
import { GetCookie_culture } from '@backoffice/common/utils/cookiesManager';
import FixedLabel from '@backoffice/shared/FixedLabel';
import { hideFixedLabel } from '@backoffice/actions/fixedLabel';
import { showMenu } from '@backoffice/actions/menu';
import { createToken, createRefreshToken } from '@backoffice/actions/token';
import { startRequest } from '@backoffice/actions/apiCall';
import { showDialog, hideDialog } from '@backoffice/actions/loading';
import Header from '../../components/Header/Header';
import { refreshTokenParams } from './LayoutCalls';
import moment from 'moment';
import MenuLeft from '../../shared/MenuLeft';
import { ROUTE } from '../../common/constants/routes';
import './Layout.scss';
import { refreshAppearance } from '../../actions/appearance';

const Layout = ({ children, path }) => {
	const currentLanguage = useSelector((state) => state.profile.profile.currentLanguage);

	const tokenReducer = useSelector((state) => state.token);
	const appearanceReducer = useSelector((state) => state.appearance);
	const languageReducer = useSelector((state) => state.language);
	const [ hide, setHide ] = useState(false);

	const { t } = useTranslation();

	const dispatch = useDispatch();
	const [ sideBarOpen, setSideBarOpen ] = useState(false);
	const history = useHistory();

	const handleDrawerClose = () => {
		if (sideBarOpen) {
			setSideBarOpen(false);
		}
	};

	useEffect(
		() => {
			if (appearanceReducer.isDefault) {
				dispatch(refreshAppearance());
			}
		},
		[ appearanceReducer.isDefault ]
	);

	useEffect(() => {
		checkCookie();
		i18n.changeLanguage(currentLanguage);
	}, []);

	useEffect(
		() => {
			dispatch(hideFixedLabel());
			setTitle();
		},
		[ history.location.pathname ]
	);

	useEffect(
		() => {
			dispatch(showDialog());
			setHide(true);
			setTimeout(() => {
				setHide(false);
				dispatch(hideDialog());
			}, 500);
		},
		[ languageReducer.lang ]
	);

	const setTitle = () => {
		let showMenuVal = true;
		let title = 'Backoffice';
		switch (path) {
			case ROUTE.HOME:
				title = 'Home';
				break;
			case ROUTE.SPACES:
				title = 'Spaces';
				break;
			case ROUTE.SPACESDETAIL:
				title = history.location.pathname.includes('/0') ? 'CreateSpaces' : 'SpacesDetail';
				showMenuVal = false;
				break;
			case ROUTE.MYBOOKINGS:
				title = 'MyBookings';
				break;
			case ROUTE.BOOKINGS:
				title = 'Bookings';
				break;
			case ROUTE.BOOKINGDETAIL:
				title = history.location.pathname.includes('/0') ? 'AddBooking' : 'Booking';
				showMenuVal = false;
				break;
			case ROUTE.BUILDINGS:
				title = 'Buildings';
				break;
			case ROUTE.INCIDENTSTENANT:
				title = 'Incidents';
				break;
			case ROUTE.INCIDENTSTENANTDETAIL:
				title = 'IncidentsDetail';
				showMenuVal = false;
				break;
			case ROUTE.INCIDENTSTENANTCREATE:
			case ROUTE.INCIDENTSTENANTCREATECUSTOM:
				title = 'IncidentsDetail';
				showMenuVal = false;
				break;
			case ROUTE.BOOKINGSTENANT:
				title = 'MyBookings';
				break;
			case ROUTE.BOOKINGSTENANTDETAIL:
				title = 'Booking';
				showMenuVal = false;
				break;
			case ROUTE.BOOKINGSTENANTCREATE:
				title = 'AddBooking';
				showMenuVal = false;
				break;
			case ROUTE.BUILDINGSDETAIL:
				title = 'BuildingDetail';
				showMenuVal = false;
				break;
			case ROUTE.TENANTS:
				title = 'Tenants';
				break;
			case ROUTE.NEWSTENANT:
				title = 'NewsTenant';
				break;
			case ROUTE.NEWS:
				title = 'News';
				break;
			case ROUTE.NEWSDETAIL:
				title = history.location.pathname.includes('/0') ? 'CreateNew' : 'EditNew';
				showMenuVal = false;
				break;
			case ROUTE.USERS:
				title = 'Users';
				break;
			case ROUTE.USERDETAIL:
				title = history.location.pathname.includes('/0') ? 'CreateUser' : 'UserDetail';
				showMenuVal = false;
				break;
			case ROUTE.PROFILE:
				title = 'Profile';
				break;
			case ROUTE.SETTINGS:
				title = 'Settings';
				break;
			case ROUTE.TENANTSDETAIL:
				title = 'TenantFile';
				showMenuVal = false;
				break;
			case ROUTE.DOCUMENTSTENANT:
				title = 'BuildingDocumenTenant';
				break;
			case ROUTE.CONTACTSTENANT:
				title = 'Contacts';
				break;
			case ROUTE.CONFIGURATIONTENANT:
				title = 'Settings';
				break;
			case ROUTE.HOMETENANT:
				title = 'Home';
				break;
			case ROUTE.INCIDENTSPROVIDER:
				title = 'Incidents';
				break;
			case ROUTE.CONFIGURATIONPROVIDER:
				title = 'Settings';
				break;
			case ROUTE.CONTACTSTENANTDETAIL:
				title = 'ContactDetail';
				showMenuVal = false;
				break;
			case ROUTE.SERVICESTENANT:
				title = 'Services';
				break;
			case ROUTE.PROFILETENANT:
				title = 'Profile';
				break;
			case ROUTE.INCIDENTSPROVIDERDETAIL:
				title = 'IncidentsDetail';
				showMenuVal = false;
				break;
			case ROUTE.SERVICESTENANTDETAIL:
				title = 'ServiceDetail';
				showMenuVal = false;
				break;
			default:
				title = 'Bialto';
				break;
		}
		document.title = t(title);
		dispatch(showMenu(showMenuVal));
	};

	const checkCookie = () => {
		let token = tokenReducer.token;
		let token_exp = tokenReducer.tokenExp;
		if (token !== '' && token_exp !== '') {
			let token_exp_date = moment(token_exp);
			let today = moment().format();
			let refreshToken = GetCookie_refreshToken();
			if (token_exp_date.diff(today, 'hours') <= 10 && refreshToken !== '') {
				let data = {
					accessToken: token,
					refreshToken: refreshToken
				};
				dispatch(
					startRequest(
						refreshTokenParams(data, (response) => {
							dispatch(createToken(response.accessToken));
							dispatch(createRefreshToken(response.refreshToken));
						})
					)
				);
			}
		}
	};

	return (
		<div onKeyDown={handleDrawerClose} className="layout" lang={languageReducer.lang}>
			<Header />
			<main className="main">
				<MenuLeft />
				<FixedLabel parentType="general" />
				<section className="main-content">
					{hide === false ? (
						<React.Fragment>
							{children}
						</React.Fragment>
					) : (
						''
					)}
				</section>
			</main>
		</div>
	);
};

export default Layout;
