const getNewByIdParams = (id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/TenantNews/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export { getNewByIdParams };
