import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { startRequest } from "@backoffice/actions/apiCall";
import FilePreview from "../../../../shared/FilePreview/FilePreview";
import { Button } from "@backoffice/components/FormComponents/index";
import { changeAppearance } from '../../../../actions/appearance';
import {
  getNewsParams,
  getBookingsParams,
  getIncidentsParams,
  getAppearanceParams
} from "./HomeCalls/HomeCalls";
import { parseDate, parseDateTime } from "../../../..//common/utils/parseUtils";
import { ROUTE } from "../../../../common/constants/routes";
import "./HomeTenant.scss";



const HomeTenant = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [news, setNews] = useState([]);
  const [incidents, setIncidents] = useState([]);
  const appearanceReducer = useSelector((state) => state.appearance);
  const [backgroundImgUrl, setBackgroundImgUrl] = useState(
    appearanceReducer.appearance.backgroundUrl
  );
  const [bookings, setBookings] = useState([]);
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 450px)" });
  const isSmallMobile = useMediaQuery({ query: "(max-width: 300px)" });

  useEffect(() => {
    getNews();
    getBookings();
    getIncidents();

    

    getAppearance();
  }, []);

  const getNews = () => {
    dispatch(startRequest(getNewsParams(getNewsSuccess)));
  };

  const getNewsSuccess = (response) => {
    setNews(response.list);
  };

  const getBookings = () => {
    dispatch(startRequest(getBookingsParams(getBookingsSuccess)));
  };

  const getBookingsSuccess = (response) => {
    setBookings(response.list);
  };

  const getIncidents = () => {
    dispatch(startRequest(getIncidentsParams(getIncidentsSuccess)));
  };

  const getIncidentsSuccess = (response) => {
    setIncidents(response);
  };

  const getAppearance = () => {
    dispatch(startRequest(getAppearanceParams(getAppearanceSuccess)));
  };

  const getAppearanceSuccess = (response) => {
    dispatch(
      changeAppearance({
        backgroundUrl: response.mediaBackGround
          ? response.mediaBackGround.mediaUrl
          : process.env.PUBLIC_URL + '/login-bg.jpg',
        logoImgUrl: response.mediaLogo
          ? response.mediaLogo.mediaUrl
          : process.env.PUBLIC_URL + '/bialto_horizontal.png',
        faviconImgUrl: response.mediaFavicon
          ? response.mediaFavicon.mediaUrl
          : process.env.PUBLIC_URL + '/bialto-logo_white.png',
        colorPrimary: '#000000',
        colorPrimaryVariant: '#333333',
        colorPrimaryVariant2: '#777777',
        colorPrimaryHover: '#434747',
        colorPrimaryPressed: '#1F0F7B',
        colorPrimaryDisabled: '#d8d8d8',
        colorSecondary: response.colorSecondary ? response.colorSecondary : '#1F0F7B',
        colorSecondaryVariant: '#D64443',
        colorSecondaryVariant2:
          'background: linear-gradient(90deg, rgba(214,68,67,1) 0%, rgba(31,15,123,1) 100%);',
        colorText: '#000000',
        colorError: '#D64443',
        colorErrorBG: '#ccaca6',
        colorWarning: '#d9d2aa',
        colorWarningBG: '#dad3a8',
        colorSuccess: '#207E5D',
        colorSuccessBG: '#43D6A1',
        colorMenuBG: '#FFFFFF',
        colorMenu: '#000',
        colorHeader: '#ffffff',
        colorHeaderBG: response.colorHeaderBG ? response.colorHeaderBG : '#000000',
        colorMainBG: '#FFFFFF'
      })
    );
  }

  const renderNews = () => {
    let html = [];
    news.forEach((newDetail, i) => {
      html.push(
        <Link
          key={i + "-news"}
          className="card"
          to={ROUTE.NEWSTENANTDETAIL.replace(":id", newDetail.id)}
        >
          {newDetail.fixed ? (
            <div className="header-card-wrapper">
              <div className="fixed-new-wrapper">
                <div className="fixed">
                  <span
                    className="material-icons-round"
                    data-testid={"btn-goback"}
                    id={"btn-goback"}
                    aria-hidden={true}
                  >
                    push_pin
                  </span>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="card-wrapper news">
            {isSmallMobile ? (
              ""
            ) : (
              <div className="img-wrapper">
                <FilePreview
                  file={""}
                  url={
                    newDetail.mediaNameThumbnailURL
                      ? newDetail.mediaNameThumbnailURL
                      : "/empty-image.png"
                  }
                  fileType={"Image"}
                />
              </div>
            )}
            <div className="body-wrapper">
              <h3 className="body-card-title">{newDetail.title}</h3>
              <p className="date-card">
                {newDetail.date ? parseDate(newDetail.date, true) : ""}
              </p>
              <p className="description-card">{newDetail.shortDescription}</p>
            </div>
            {!isMobile ? (
              <div className="arrow-wrapper">
                <span
                  className="material-icons-round"
                  data-testid={"btn-goback"}
                  id={"btn-goback"}
                  aria-hidden={true}
                >
                  arrow_forward
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </Link>
      );
    });

    return html;
  };

  const renderIncidents = () => {
    let html = [];
    incidents.forEach((incident, i) => {
      html.push(
        <Link
          key={i + "-incidents"}
          className="card"
          to={"/incidentstenant/" + incident.id}
        >
          <div className="header-card-wrapper">
            <div className="title-wrapper">
              <h3
                className="title-card"
                aria-label={t("IncidentTitle") + ": " + incident.title + "."}
              >
                {incident.title}
              </h3>
            </div>
            <div className="status-wrapper">
              <div
                className={
                  incident.statusCodeValue === "InProgress"
                    ? "status progress"
                    : incident.statusCodeValue === "Resolved"
                    ? "status resolved"
                    : "status pending"
                }
                aria-label={
                  t("Status") + ": " + t(incident.statusCodeValue) + "."
                }
              >
                {t(incident.statusCodeValue)}
              </div>
            </div>
          </div>
          <div className="card-wrapper incidents">
            <div className="body-wrapper without-img">
              <p
                className="incident-type"
                aria-label={
                  t("IncidentType") + ": " + t(incident.incidentTypeValue) + "."
                }
              >
                {t(incident.incidentTypeValue)}
              </p>
              <p className="date-card">
                {t("OpenOn") + " " + parseDateTime(incident.creationDate, true)}
              </p>
              <p className="description-card">{incident.shortDescription}</p>
            </div>
            {!isMobile ? (
              <div className="arrow-wrapper">
                <span
                  className="material-icons-round"
                  data-testid={"btn-goback"}
                  id={"btn-goback"}
                  aria-hidden={true}
                >
                  arrow_forward
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </Link>
      );
    });

    html.push(
      <div className="col-xl-4 button-home" key="create-incident">
        <Button
          dataTestID="btnCreateIncident"
          icon="add"
          btnText={t("CreateIncident")}
          btnType="button"
          btnClass="btn-base_normal create-button"
          onHandleBtnClick={() => {
            history.push(ROUTE.INCIDENTSTENANTCREATE);
          }}
        />
      </div>
    );

    return html;
  };

  const renderBookings = () => {
    let html = [];

    bookings.forEach((booking, i) => {
      html.push(
        <Link
          key={i + "-bookings"}
          className="card"
          to={ROUTE.BOOKINGSTENANTDETAIL.replace(":id", booking.id)}
        >
          <div className="card-wrapper bookings">
            {isSmallMobile ? (
              ""
            ) : (
              <div className="img-wrapper">
                <FilePreview
                  file={""}
                  url={
                    booking.spaceMediaThumbnailURL
                      ? booking.spaceMediaThumbnailURL
                      : "/empty-image.png"
                  }
                  fileType={"Image"}
                />
              </div>
            )}
            <div className="body-wrapper">
              <h3 className="body-card-title">{booking.spaceName}</h3>
              <p className="date-card">{parseDateTime(booking.startDate, true)}</p>
              <p className="people-card">
                {booking.peopleNumber + " " + t("People")}
              </p>
            </div>
            {!isMobile ? (
              <div className="arrow-wrapper">
                <span
                  className="material-icons-round"
                  data-testid={"btn-goback"}
                  id={"btn-goback"}
                  aria-hidden={true}
                >
                  arrow_forward
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </Link>
      );
    });

    html.push(
      <div className="col-xl-4 button-home" key="create-booking">
        <Button
          dataTestID="btnCreateBooking"
          icon="add"
          btnText={t("Book")}
          btnType="button"
          btnClass="btn-base_normal create-button"
          onHandleBtnClick={() => {
            history.push(ROUTE.BOOKINGSTENANTCREATE);
          }}
        />
      </div>
    );

    return html;
  };

  return (
    <React.Fragment>
      <div className="image-header">
        <div className="col-lg-12">
          <div className="img-home-wrapper">
            <img
              src={
                backgroundImgUrl !== "" ? backgroundImgUrl : "/login-bg.svg"
              }
              alt=" "
              aria-hidden={true}
            />
          </div>
        </div>
      </div>
      <div className="home-tenant">
        <h1 style={{ display: "none" }}>{t("Home")} </h1>
        <div className="row">
          <div className="col-lg-6">
            {bookings.length > 0 && (
              <>
                <div className="header-wrapper">
                  <h2 className="subtitle">{t("MyBookings")}</h2>
                  <Link className="view-all" to={ROUTE.BOOKINGSTENANT}>
                    <span>{t("ViewAll")}</span>
                  </Link>
                </div>
                <div className="card-list-wrapper">{renderBookings()}</div>
              </>
            )}
            {incidents.length > 0 && (
              <>
                <div className="header-wrapper incidents">
                  <h2 className="subtitle">{t("Incidents")}</h2>
                  <Link className="view-all" to={ROUTE.INCIDENTSTENANT}>
                    <span>{t("ViewAll")}</span>
                  </Link>
                </div>
                <div className="card-list-wrapper">{renderIncidents()}</div>
              </>
            )}
          </div>
          <div className="col-lg-6">
            {news.length > 0 && (
              <div className="col-lg-12">
                <>
                  <div className="header-wrapper">
                    <h2 className="subtitle">{t("NewsTenant")}</h2>
                    <Link className="view-all" to={"/newstenant"}>
                      <span>{t("ViewAll")}</span>
                    </Link>
                  </div>
                  <article>
                    <div className="card-list-wrapper">{renderNews()}</div>
                  </article>
                </>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default HomeTenant;
