const getBookingsParams = (params, SuccessFn) => {
	let endpoint = '/TenantBookingSpace/GetMyBookings';
	if (params.filters) {
		endpoint =
			endpoint +
			'?Filters.BuildingId=' +
			params.filters.buildingId +
			'&Filters.SpaceTypeId=' +
			params.filters.spaceTypeId + 
			'&Filters.SpaceId=' +
			params.filters.spaceId + 
			'&Filters.StartDate=' +
			params.filters.startDate;
	}
	const options = {
		method: 'GET',
		endpoint: endpoint,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export { getBookingsParams };
