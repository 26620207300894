import React, { useState, useEffect } from "react";
import "./Switch.scss";

export default function Switch(props) {
  const {
    id,
    inputValue,
    inputLabel,
    handleChange,
    isDisabled,
    isRequired,
    className,
  } = props;
  // const [ valueSwitch, setValueSwitch ] = useState(inputValue);
  const [value, setValue] = useState(false);

  useEffect(() => {
    setValue(inputValue);
  }, [inputValue]);

  return (
    <div className="switch">
      {inputLabel}
      {isRequired ? <sup className="required">*</sup> : ""}
      <div
        className={"custom-control custom-switch switch-wrapper"}
        onClick={(e) => {
          handleChange(!value);
        }}
      >
        <input
          type="checkbox"
          className="custom-control-input"
          id={id}
          disabled={isDisabled}
          checked={value}
          onChange={()=>{}}
        />
        <label className={"custom-control-label " + className} htmlFor={id} />
      </div>
    </div>
  );
}
