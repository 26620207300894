import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { GetSessionStorage } from '@backoffice/common/utils/storageManager';
import { startRequest } from '../../../../actions/apiCall';
import { ROUTE } from '../../../../common/constants/routes';
import { getContactByIdParams } from './ContactsTenantDetailCalls';
import './ContactsTenantDetail.scss';

const ContactsTenantDetail = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { id } = useParams();
	const [ contactId, setContactId ] = useState(id);
	const [ buildingId, setBuildingId ] = useState(GetSessionStorage('PROFILE_').buildingId);
	const [ contact, setContact ] = useState('');

	useEffect(() => {
		getContactById();
	}, []);

	const getContactById = () => {
		dispatch(startRequest(getContactByIdParams(buildingId, contactId, getContactByIdSuccess)));
	};

	const getContactByIdSuccess = (response) => {
		setContact(response);
	};

	const getContactDays = () => {
		let days = '';

		if (contact.monday) {
			days = days + t('Monday') + ' ';
		}
		if (contact.tuesday) {
			days = days + t('Tuesday') + ' ';
		}
		if (contact.wednesday) {
			days = days + t('Wednesday') + ' ';
		}
		if (contact.thursday) {
			days = days + t('Thursday') + ' ';
		}
		if (contact.friday) {
			days = days + t('Friday') + ' ';
		}
		if (contact.saturday) {
			days = days + t('Saturday') + ' ';
		}
		if (contact.sunday) {
			days = days + t('Sunday');
		}

		return days;
	};

	return (
		<div className="contacts-tenant-detail">
			<div className="return">
				<div className="col-lg-2 offsett-lg-10">
					<Link className="go-back" to={ROUTE.CONTACTSTENANT} role="link">
						<span
							className="material-icons-round"
							data-testid={'btn-goback'}
							id={'btn-goback'}
							aria-hidden={true}
						>
							arrow_back
						</span>
						<div className="go-back__text">{t('GoBack')}</div>
					</Link>
				</div>
			</div>
			<h1 className="title">{t('ContactDetail')}</h1>
			{/* <p className="description">{t('Description') + '...'}</p> */}

			<div className="contact-detail-wrapper">
				{contact !== '' ? (
					<React.Fragment>
						<div className="col-md-3 contact-detail-card">
							<div className="contact-detail-header">
								<p className="position">
									{contact.positionTypeName ? t(contact.positionTypeName) : 'Position'}
								</p>
								<p className="name">{contact.name}</p>
							</div>

							<div className="contact-detail-body">
								<p className="phone">
									{contact.prefixPhoneNumber} {contact.phoneNumber}
									<span className="icon" aria-hidden={true}>
										<span className="material-icons">phone</span>
									</span>
								</p>
								<p className="email">
									{contact.email}
									<a className="icon" aria-hidden={true} href={'mailto: ' + contact.email}>
										<span className="material-icons">email</span>
									</a>
								</p>
								<p className="address">
									{contact.address ? contact.address : '-'}
									{contact.address ? (
										<a
											className="icon"
											aria-hidden={true}
											target="_blank"
											rel="noreferrer"
											href={'https://www.google.es/maps/place/' + contact.address}
										>
											<span className="material-icons">place</span>
										</a>
									) : (
										<span className="icon" aria-hidden={true}>
											<span className="material-icons">place</span>
										</span>
									)}
								</p>
								<p className="address">
									{contact.url ? contact.url : '-'}
									{contact.url ? (
										<a
											className="icon"
											href={'# '}
											onClick={() => window.open(contact.url, '_blank')}
											aria-hidden={true}
										>
											<span className="material-icons">public</span>
										</a>
									) : (
										<span className="icon" aria-hidden={true}>
											<span className="material-icons">public</span>
										</span>
									)}
								</p>
							</div>
						</div>
						<div className="col-md-3 contact-detail-card schedule">
							<p className="schedule-title">{t('ContactSchedule')}</p>
							<p className="schedule-text">{getContactDays()}</p>
							<p className="schedule-text">
								{contact.from + ' - ' + contact.to}{' '}
								{contact.usePartialTime ? contact.fromPartial + ' - ' + contact.toPartial : ''}
							</p>
							<p className="schedule-observations">{t('Observations')}</p>
							<p className="schedule-text">
								{contact.observations ? contact.observations : t('EmptyObservations')}
							</p>
						</div>
					</React.Fragment>
				) : (
					''
				)}
			</div>
		</div>
	);
};

export default ContactsTenantDetail;
