import fixedLabel from '../../actions/fixedLabel';
import popup from '../../actions/popup';

/* Redux state init */
const initialState = {
	showLabel: false,
	messageLabel: '',
	typeLabel: ''
};

export default function fixedLabelReducer(state = initialState, action) {
	if (action.type !== undefined) {
		switch (action.type) {
			case fixedLabel.SHOW_LABEL:
				return {
					...state,
					showLabel: true,
					messageLabel: action.messageLabel,
					typeLabel: action.typeLabel
				};
			case fixedLabel.HIDE_LABEL:
				return {
					...state,
					showLabel: false,
					messageLabel: '',
					typeLabel: ''
				};
			case popup.OPEN_MODAL:
				return {
					...state,
					showLabel: false,
					messageLabel: '',
					typeLabel: ''
				};
			case popup.CLOSE_MODAL:
				return {
					...state,
					showLabel: false,
					messageLabel: '',
					typeLabel: ''
				};

			default:
				return { ...state };
		}
	}
	return state;
}
