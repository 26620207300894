const getNewsParams = (SuccessFn) => {
	let endpoint = '/TenantNews/GetAllNews';
	endpoint = endpoint + '?Pagination.Skip=0&Pagination.Take=4';
	const options = {
		method: 'GET',
		endpoint: endpoint,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getBookingsParams = (SuccessFn) => {
	let endpoint = '/TenantBookingSpace/GetAllSpaceBooking';
	endpoint = endpoint + '?Pagination.Skip=0&Pagination.Take=2';
	const options = {
		method: 'GET',
		endpoint: endpoint,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getIncidentsParams = (SuccessFn) => {
	let endpoint = '/Incidents/GetAllIncidents';
	endpoint = endpoint + '?Pagination.Skip=0&Pagination.Take=1';
	const options = {
		method: 'GET',
		endpoint: endpoint,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};


const getAppearanceParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: "/TenantConfiguration/GetAppearance",
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};


export { getNewsParams, getBookingsParams, getIncidentsParams, getAppearanceParams };
