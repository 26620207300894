const getBookingsParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/BookingSpace/GetAllBookings',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getBookingsByIdParams = (id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/BookingSpace/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const postBookingParams = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		data,
		endpoint: '/BookingSpace',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const putBookingParams = (data, SuccessFn) => {
	const options = {
		method: 'PUT',
		data,
		endpoint: '/BookingSpace',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const deleteBookingsParams = (params, SuccessFn) => {
	const options = {
		method: 'DELETE',
		params,
		endpoint: '/Bookings',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export { getBookingsParams, getBookingsByIdParams, postBookingParams, putBookingParams, deleteBookingsParams };
