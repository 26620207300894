const CHANGELANGUAGE = 'CHANGELANGUAGE';
const CHANGEFORMAT = 'CHANGEFORMAT';
const CHANGEWEEKSTART = 'CHANGEWEEKSTART';

const defaultConst = {
	CHANGELANGUAGE,
	CHANGEFORMAT,
	CHANGEWEEKSTART
};

export default defaultConst;
export const changeLanguage = (lang) => ({ type: CHANGELANGUAGE, lang: lang });
export const changeFormat = (format) => ({ type: CHANGEFORMAT, format: format });
export const changeWeekStart = (weekStart) => ({ type: CHANGEWEEKSTART, weekStart: weekStart });
