const GET_MENU = 'GET_MENU';
const SHOW_MENU = 'SHOW_MENU';
const UPDATE_MENU = 'UPDATE_MENU';

const defaultConst = {
	GET_MENU,
	UPDATE_MENU,
	SHOW_MENU
};

export default defaultConst;
export const getMenu = () => ({ type: GET_MENU });
export const showMenu = (showMenuVal) => ({ type: SHOW_MENU, showMenu: showMenuVal });
export const updateMenu = (items) => ({ type: UPDATE_MENU, items: items });
