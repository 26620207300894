import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { startRequest } from '@backoffice/actions/apiCall';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import TabNextBookings from './TabNextBookings/TabNextBookings';
import TabPastBookings from './TabPastBookings/TabPastBookings';
import { Button } from '@backoffice/components/FormComponents/index';
import FilePreview from '../../../../shared/FilePreview/FilePreview';
import { parseDateTime } from '../../../../common/utils/parseUtils';
import { ROUTE } from '../../../../common/constants/routes';
import { getBookingsParams } from './BookingTenantCalls/BookingTenantCalls';
import './BookingTenant.scss';

const BookingTenant = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [ currentTab, setCurrentTab ] = useState('Next');
	const [ nextCurrentTab, setNextCurrentTab ] = useState('Next');
	const [ pastBookings, setPastBookings ] = useState([]);
	const [ nextBookings, setNextBookings ] = useState([]);
	const isSmallMobile = useMediaQuery({ query: '(max-width: 300px)' });
	const isMobile = useMediaQuery({ query: '(max-width: 450px)' });

	useEffect(() => {
		getBookings();
	}, []);

	useEffect(
		() => {
			setCurrentTab(nextCurrentTab);
		},
		[ nextCurrentTab ]
	);

	const getBookings = () => {
		dispatch(startRequest(getBookingsParams({}, getBookingsSuccess)));
	};

	const getBookingsSuccess = (response) => {
		filterBookings(response.list);
	};

	const renderBookings = (bookings) => {
		let html = [];
		if (bookings.length > 0) {
			bookings.forEach((booking, i) => {
				html.push(
					<Link
						key={i + '-bookings'}
						className="card booking-tenant"
						to={ROUTE.BOOKINGSTENANTDETAIL.replace(':id', booking.id)}
					>
						<div className="card-wrapper bookings">
							{isSmallMobile ? (
								''
							) : (
								<div className="img-wrapper">
									<FilePreview
										file={''}
										url={
											booking.spaceMediaThumbnailURL ? (
												booking.spaceMediaThumbnailURL
											) : (
												'/empty-image.png'
											)
										}
										fileType={'Image'}
									/>
								</div>
							)}
							<div className="body-wrapper">
								<h3 className="body-card-title">{booking.spaceName}</h3>
								<p className="date-card">{parseDateTime(booking.startDate, true)}</p>
								<p className="people-card">{booking.peopleNumber + ' ' + t('People')}</p>
							</div>
							{!isMobile ? (
								<div className="arrow-wrapper">
									<span className="material-icons-round" data-testid={'btn-goback'} id={'btn-goback'} aria-hidden={true}>
										arrow_forward
									</span>
								</div>
							) : (
								''
							)}
						</div>
					</Link>
				);
			});
		} else {
			html.push(
				<div className="empty-bookings" key="empty-bookings">
					<h2 className="empty-bookings-title">{t('EmptyBookings')}</h2>
				</div>
			);
		}
		return html;
	};

	const filterBookings = (bookings) => {
		let newNextBookings = [];
		let newPastBookings = [];
		if (bookings.length > 0) {
			bookings.forEach((booking) => {
				if (new Date(booking.startDate).getTime() > new Date().getTime()) {
					newNextBookings.push(booking);
				} else {
					newPastBookings.push(booking);
				}
			});
		}
		setNextBookings(newNextBookings);
		setPastBookings(newPastBookings);
	};

	return (
		<div className="booking-tenant">
			<h1 style={{ display: 'none' }}>{t('MyBookings')} </h1>
			<div className="tabs-wrapper">
				<div id="tabs" className="tabs">
					<button
						className={`tab-button ${currentTab === 'Next' ? 'active' : ''}`}
						data-testid="btnTabGeneral"
						onClick={() => {
							setNextCurrentTab('Next');
						}}
					>
						{t('NextBookings')}
					</button>
					<button
						className={`tab-button ${currentTab === 'Past' ? 'active' : ''}`}
						data-testid="btnHistoric"
						onClick={() => {
							setNextCurrentTab('Past');
						}}
					>
						{t('PastBookings')}
					</button>
					<div className='tabs-underline'/>
				</div>
				<div className="tabs_form">
					<div className="form-content">
						{currentTab === 'Next' ? (
							<div className="tab-content-item" id="TabNextBookings">
								<TabNextBookings bookings={nextBookings} renderBookings={renderBookings} />
							</div>
						) : currentTab === 'Past' ? (
							<div className="tab-content-item" id="TabPastBookings">
								<TabPastBookings bookings={pastBookings} renderBookings={renderBookings} />
							</div>
						) : (
							'no tab selected'
						)}
					</div>
				</div>
				<div className="btn-end">
					<Button
						dataTestID="btnCreateIncident"
						btnText={t('Book')}
						icon="add"
						btnType="button"
						btnClass="btn-base_normal create-button"
						onHandleBtnClick={() => {
							history.push(ROUTE.BOOKINGSTENANTCREATE);
						}}
					/>
				</div>
			</div>
		</div>
	);
};
export default BookingTenant;
