const getContactsParams = (id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/TenantBuildingContacts/GetContactList/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export { getContactsParams };
