import React, { useState } from 'react';
import ForgotPasswordForm from './ForgotPasswordForm';
import LoginForm from './LoginForm';
import FixedLabel from '../../../shared/FixedLabel';
import './Login.scss';

export default function Login() {
	const [ forgotPassword, setForgotPassword ] = useState(false);

	return (
		<div
			className="login"
			lang="es"
			style={{
				background: `url(login-bg.svg)`,
				objectFit: 'cover',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				backgroundSize: 'cover'
			}}
		>
			<div className="wrapper">
				<div className="white-wrapper">
					<div className="img-login">
						<img src="./bialto.svg" alt=" " aria-hidden={true} />
					</div>
					<FixedLabel parentType="general" />
					{forgotPassword ? (
						<ForgotPasswordForm setForgotPassword={setForgotPassword} />
					) : (
						<LoginForm
							setForgotPassword={setForgotPassword}
							redirect={true}
							hideRegister={false}
							fromBooking={false}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
