import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { startRequest } from "../../../../../actions/apiCall";
import { showFixedLabel } from "../../../../../actions/fixedLabel";
import { base64ToArrayBuffer } from "../../../../../common/utils/parseUtils";
import { Button } from "../../../../../components/FormComponents";
import Uploader from "../../../../../shared/Uploader/Uploader";
import {
  deleteDocumentParams,
  downloadDocumentByIdParams,
  getDocumentsByIdParams,
  postDocumentParams,
} from "../BuildingDetailCalls/BuildingDetailCalls";

import PopUpGenericDelete from "../../../../../components/PopUpGenericDelete/PopUpGenericDelete";
import { openModal, closeModal } from "../../../../../actions/popup";

import "./TabDocumentation.scss";

const TabDocumentation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [buildingId, setBuildingId] = useState(id);
  const [uploader, setUploader] = useState();
  const [dynamicsInformation, setDynamicsInformation] = useState([]);
  const [azureInformation, setAzureInformation] = useState([]);

  useEffect(() => {
    getDocumentsById();
  }, []);

  const getDocumentsById = () => {
    dispatch(
      startRequest(
        getDocumentsByIdParams(buildingId, getDocumentsByIdParamsSuccess)
      )
    );
  };

  const getDocumentsByIdParamsSuccess = (response) => {
    setDynamicsInformation(response.dynamicDocuments);
    setAzureInformation(response.azureDocuments);
  };

  const downloadDocuments = (document) => {
    dispatch(
      startRequest(
        downloadDocumentByIdParams(
          buildingId,
          document.documentId,
          (response) => downloadDocumentSuccess(response, document),
          downloadDocumentError
        )
      )
    );
  };

  const downloadDocumentError = (response) => {
    dispatch(showFixedLabel("DownloadDocumentError", "error"));
  };

  const downloadDocumentSuccess = (response, document) => {
    var sampleArr = base64ToArrayBuffer(response);
    openWindowByteArray(sampleArr, document);
  };

  const openWindowByteArray = (byte, document) => {
    var blob = new Blob([byte], { type: document.mimeType });
    window.open(window.URL.createObjectURL(blob), "_blank");
  };

  const getMedia = () => {
    let newMedia = [];
    uploader.filesData.forEach((file) => {
      newMedia.push({
        File: file.rawFile,
      });
    });
    return {
      EntityId: buildingId,
      Files: newMedia,
    };
  };

  const postMedia = () => {
    const mediaData = getMedia();
    dispatch(startRequest(postDocumentParams(mediaData, mediaSuccess)));
  };

  const mediaSuccess = (response) => {
    dispatch(showFixedLabel("DocumentSaveSuccess", "success"));
    getDocumentsById();
  };

  const openDeleteMedia = (documentAzure) => {
    const optionValues = {
      id: "delete-media-" + documentAzure.buildingId + "-" + Math.random(),
      cssClass: "modal-header__danger",
      title: t("Delete"),
    };

    const options = {
      ...optionValues,
      body: (
        <PopUpGenericDelete
          action={"delete"}
          id={optionValues.id}
          deleteMessage={"DeleteConfirmMessage"}
          handleButtonDelete={() => deleteMedia(documentAzure)}
          handleButtonCancel={() => dispatch(closeModal())}
        />
      ),
    };

    dispatch(openModal(options));
  };

  const deleteMedia = (documentAzure) => {
    dispatch(
      startRequest(
        deleteDocumentParams(
          {
            buildingId: documentAzure.buildingId,
            mediaId: documentAzure.documentId,
          },
          deleteMediaSuccess
        )
      )
    );
  };

  const deleteMediaSuccess = (response) => {
    dispatch(showFixedLabel("DeleteSuccess", "success"));
    getDocumentsById();
  };

  const renderAzureDocuments = () => {
    let html = [];
    if (azureInformation.length > 0) {
      azureInformation.forEach((documentAzure, i) => {
        html.push(
          <div className="container-document-azure row" key={i + "-document"}>
            <a
              className="download-link"
              id={i + "-link-document"}
              href={documentAzure.mediaURL}
              target="_blank"
              rel="noreferrer"
            >
              {documentAzure.fileName !== ""
                ? documentAzure.fileName
                : documentAzure.name}
            </a>
            <span
              className="material-icons-round icon"
              onClick={() => openDeleteMedia(documentAzure)}
            >
              delete
            </span>
          </div>
        );
      });
      return html;
    }
  };

  const renderDynamicsDocuments = () => {
    let html = [];
    if (dynamicsInformation.length > 0) {
      dynamicsInformation.forEach((documentDynamics, i) => {
        html.push(
          <div
            className="container-document-dynamic"
            key={i + "-document"}
            onClick={() => downloadDocuments(documentDynamics)}
          >
            <div className="download-link" id={i + "-link-document"} href={""}>
              {documentDynamics.fileName !== ""
                ? documentDynamics.fileName
                : documentDynamics.name}
            </div>
          </div>
        );
      });
      return html;
    }
  };

  return (
    <div className="tab-documentation">
      <div className="row">
        <div className="btn-grid-end">
          <Button
            btnText={t("DownloadAll")}
            dataTestID="btnDownloadAll"
            btnType="button"
            icon="download"
            btnClass="btn-base_outline export-excel-button"
            onHandleBtnClick={() => {}}
          />
        </div>
      </div>
      <div className="row mb-5">
        <div className="col-lg-6">
          <h3 className="title">
            {t("DynamicsDocumentation")}
            {" (" + dynamicsInformation.length + ")"}
          </h3>
          <div className="container-wrapper">
            <div className="row">{renderDynamicsDocuments()}</div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="w-100">
          <h3 className="title-uploader">
            {t("BackofficeDocumentation")}
            {" (" + azureInformation.length + ")"}
          </h3>
          <p className="subtitle-uploader">.pdf, .doc, .docx</p>
          <div className="row ">
            <div className="uploader-wrapper">
              <Uploader
                isDisabled={false}
                allowedExtensions={".pdf"}
                setUploader={setUploader}
              />
            </div>
            <div className="container">{renderAzureDocuments()}</div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="btn-end">
          <Button
            dataTestID="btnSaveService"
            btnText={t("SaveChanges")}
            btnType="button"
            btnClass="btn-base_normal"
            onHandleBtnClick={() => postMedia()}
          />
        </div>
      </div>
    </div>
  );
};

export default TabDocumentation;
