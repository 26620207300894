import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Input, Button } from '@backoffice/components/FormComponents/index';
import {
	ColumnDirective,
	ColumnsDirective,
	Filter,
	GridComponent,
	Group,
	Resize,
	Inject,
	Page,
	Sort,
	ExcelExport
} from '@syncfusion/ej2-react-grids';
import './PopUpAddService.scss';
import { getServicesListByIdParams, assignServiceParams } from '../../BuildingDetailCalls/BuildingDetailCalls';
import { startRequest } from '../../../../../../actions/apiCall';
import { useHistory } from 'react-router-dom';
import { ROUTE } from '../../../../../../common/constants/routes';
import { showFixedLabel } from '../../../../../../actions/fixedLabel';
import { closeModal } from '../../../../../../actions/popup';
import { cleanStringFromAccents } from '../../../../../../common/utils/parseUtils';
import ResponsiveGridWrapper from '../../../../../../shared/ResponsiveGridWrapper/ResponsiveGridWrapper';

const PopUpAddService = ({ buildingId, handleSubmit }) => {
	const pageSettings = { pageSize: 50 };
	const grid = useRef(null);
	const dispatch = useDispatch();
	const history = useHistory();
	const [ search, setSearch ] = useState('');
	const [ services, setServices ] = useState([]);
	const [ allServices, setAllServices ] = useState([]);
	const { t } = useTranslation();

	useEffect(() => {
		getAllServices();
	}, []);

	useEffect(
		() => {
			filterServices();
		},
		[ search ]
	);

	const getAllServices = () => {
		dispatch(startRequest(getServicesListByIdParams(buildingId, getServicesListByIdParamsSuccess)));
	};

	const getServicesListByIdParamsSuccess = (response) => {
		setServices(response);
		setAllServices(response);
	};

	const addService = () => {
		let selectedServices = grid.current.getSelectedRecords();
		selectedServices.forEach((service) => {
			service.buildingId = buildingId;
			service = { ...service };
			dispatch(startRequest(assignServiceParams(service, assignServiceParamsSuccess)));
		});
	};

	const assignServiceParamsSuccess = (response) => {
		dispatch(showFixedLabel('ServiceAddSuccess', 'success'));
		dispatch(closeModal());
		handleSubmit();
	};
	const filterBySearch = (data = [], searching = '') => {
		const result = data.filter((element) => {
			return (
				cleanStringFromAccents(element.name.toLowerCase()).includes(searching.toLowerCase()) ||
				cleanStringFromAccents(element.address).toLowerCase().includes(searching.toLowerCase()) ||
				cleanStringFromAccents(element.phoneNumber).toLowerCase().includes(searching.toLowerCase()) ||
				cleanStringFromAccents(element.email).toLowerCase().includes(searching.toLowerCase())
			);
		});

		return result;
	};

	const filterServices = () => {
		if (search === '') {
			setServices(allServices);
		} else {
			const newData = filterBySearch(allServices, search);
			if (newData === '') {
				setServices(allServices);
			}
			setServices(newData);
		}
	};

	// let searchBy =
	// 	t('Search') +
	// 	' ' +
	// 	t('by') +
	// 	' ' +
	// 	t('Name') +
	// 	', ' +
	// 	t('PhoneNumber') +
	// 	', ' +
	// 	t('Address') +
	// 	', ' +
	// 	t('Email');

	let searchBy = t('Search') + ' ' + t('by') + ' ' + t('WordMin');
	return (
		<div className="add-service-popup">
			<div className="filters-wrapper">
				<div className="col-xl-3 search" data-bs-toggle="tooltip" title={searchBy}>
					<Input
						dataTestID="txtSearch"
						inputName="txtSearch"
						clearInput={true}
						inputPlaceholder={searchBy}
						inputType="text"
						value={search}
						inputOutline={true}
						icon={'search'}
						iconLeft={true}
						isRequired={false}
						onHandleChange={(e) => setSearch(e.target.value)}
					/>
				</div>
			</div>
			<ResponsiveGridWrapper>
				<GridComponent
					className="gridTable"
					dataSource={services}
					width="100%"
					toolbar={false}
					ref={grid}
					allowExcelExport={true}
					allowPaging={true}
					enableAdaptiveUI={true}
					allowSorting={true}
					rowRenderingMode={'Vertical'}
					pageSettings={pageSettings}
				>
					<ColumnsDirective>
						<ColumnDirective field="id" width="0" visible={false} />

						<ColumnDirective field="checkbox" type="checkbox" width="50" />

						<ColumnDirective field="name" headerText={t('ServiceName')} textAlign="Left" />
						<ColumnDirective
							field="phoneNumber"
							headerText={t('Phone')}
							textAlign="Left"
							valueAccessor={(field, data, column) => {
								return data.phoneNumber;
							}}
						/>
						<ColumnDirective field="email" headerText={t('Email')} textAlign="Left" />
						<ColumnDirective field="address" headerText={t('Address')} textAlign="Left" />
					</ColumnsDirective>
					<Inject services={[ ExcelExport, Page, Sort, Filter, Group, Resize ]} />
				</GridComponent>
			</ResponsiveGridWrapper>

			<div className="btn-end">
				<Button
					dataTestID="btn-add-service"
					btnText={t('Add')}
					btnType="button"
					btnClass="btn-base_normal"
					onHandleBtnClick={() => addService()}
				/>
			</div>
		</div>
	);
};

export default PopUpAddService;
