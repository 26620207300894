import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useHistory } from 'react-router-dom';
import { startRequest } from '@backoffice/actions/apiCall';
import { ROUTE } from '../../../../common/constants/routes';
import TabGeneral from './TabGeneral/TabGeneral';
import TabHistoric from './TabHistoric/TabHistoric';
import { showFixedLabel } from  "../../../../actions/fixedLabel";
import TabComments from './TabComments/TabComments';
import { getIncidentByIdParams } from './IncidentsProviderDetailCalls/IncidentsProviderDetailCalls';
import './IncidentsProviderDetail.scss';

const IncidentsProviderDetail = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const { id } = useParams();
	const [ incidentId, setIncidentId ] = useState(id);
	const [ currentTab, setCurrentTab ] = useState('General');
	const [ nextCurrentTab, setNextCurrentTab ] = useState('General');
	const [ incident, setIncident ] = useState('');

	useEffect(() => {
		getIncidentById();
	}, []);

	useEffect(
		() => {
			setCurrentTab(nextCurrentTab);
		},
		[ nextCurrentTab ]
	);

	const getIncidentById = () => {
		dispatch(startRequest(getIncidentByIdParams(incidentId, getIncidentByIdSuccess, getIncidentByError)));
	};

	const getIncidentByIdSuccess = (response) => {
		setIncident(response);
	};

	const getIncidentByError = () => {
		history.goBack();
	};

	return (
		<div className="incidents-provider-detail">
			<div className="return">
				<div className="col-lg-2 offsett-lg-10">
					<Link className="go-back" to={ROUTE.INCIDENTSPROVIDER} role="link">
						<span className="material-icons-round" data-testid={'btn-goback'} id={'btn-goback'} aria-hidden={true}>
							arrow_back
						</span>
						<div className="go-back__text">{t('GoBack')}</div>
					</Link>
				</div>
			</div>
			<div className="tabs-wrapper">
				<div id="tabs" className="tabs">
					<button
						className={`tab-button ${currentTab === 'General' ? 'active' : ''}`}
						data-testid="btnTabGeneral"
						onClick={() => setNextCurrentTab('General')}
					>
						{t('General')}
					</button>
					<button
						className={`tab-button ${currentTab === 'Historic' ? 'active' : ''}`}
						data-testid="btnHistoric"
						onClick={() => {
							setNextCurrentTab('Historic');
						}}
					>
						{t('Historic')}
					</button>
					<button
						className={`tab-button ${currentTab === 'Comments' ? 'active' : ''}`}
						data-testid="btnComments"
						onClick={() => {
							setNextCurrentTab('Comments');
						}}
					>
						{t('Comments')}
					</button>
				</div>
				<div className="tabs_form">
					<div className="form-content">
						{currentTab === 'General' ? (
							<div className="tab-content-item" id="ContactTabContent" hidden={currentTab !== 'General'}>
								<TabGeneral
									incidentId={incidentId}
									incident={incident}
									handleSubmit={getIncidentById}
								/>
							</div>
						) : currentTab === 'Historic' ? (
							<div
								className="tab-content-item"
								id="HistoricTabContent"
								hidden={currentTab !== 'Historic'}
							>
								<TabHistoric incident={incident} handleSubmit={getIncidentById} />
							</div>
						) : currentTab === 'Comments' ? (
							<div
								className="tab-content-item"
								id="CommentsTabContent"
								hidden={currentTab !== 'Comments'}
							>
								<TabComments incident={incident} handleSubmit={getIncidentById} />
							</div>
						) : (
							'no tab selected'
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
export default IncidentsProviderDetail;
