const refreshTokenParams = (data, funcSucc) => {
	const options = {
		method: 'POST',
		data,
		endpoint: '/Account/refresh',
		onSuccess: (response) => {
			funcSucc(response.data.data);
		},
	};
	return options;
};

export { refreshTokenParams };
