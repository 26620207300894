import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateMenu } from "@backoffice/actions/menu";
import { useTranslation } from "react-i18next";
import { GetMenuSessionStorage } from "@backoffice/common/utils/storageManager";
import "./MenuLeft.scss";

function MenuLeft() {
  const history = useHistory();
  const { t } = useTranslation();
  const [menu, setMenu] = useState([]);
  const [hideMenu, sethideMenu] = useState(true);
  const dispatch = useDispatch();
  const menuReducer = useSelector((state) => state.menu);

  useEffect(() => {
    setMenu(GetMenuSessionStorage());
  }, []);

  const renderIcon = (item) => {
    return item.icon ? (
      <span className="icon">
        {/* <ion-icon name={item.icon} /> */}
        <span className="material-icons">{item.icon.toLowerCase()}</span>
      </span>
    ) : (
      ""
    );
  };

  const renderFather = (item, menu, index) => {
    return (
      <a
        href="# "
        onClick={(args) => {
          let newMenu = [...menu];
          newMenu[index].open =
            item.open !== undefined && item.open ? false : true;
          dispatch(updateMenu(newMenu));
        }}
      >
        <div className="item-row">
          {renderIcon(item)}
          <span>{t(item.name)}</span>
          <span className="expand" aria-hidden={true}>
            {item.open ? (
              <ion-icon name={"chevron-up-outline"} />
            ) : (
              <ion-icon name={"chevron-down-outline"} />
            )}
          </span>
        </div>
      </a>
    );
  };

  const renderChild = (item) => {
    return (
      <Link to={item.link.toLowerCase()}>
        <div className="item-row">
          <span aria-hidden={true}>{renderIcon(item)}</span>
          <span>{t(item.name)}</span>
        </div>
      </Link>
    );
  };

  const renderMenuElements = (menu) => {
    let htmlMenuElements = [];
    if (menu && menu.length > 0) {
      menu.forEach((element, index) => {
        if (element.menuType === "General") {
          htmlMenuElements.push(
            <li
              key={element.id + Math.random()}
              className={
                history.location.pathname === element.link &&
                element.subMenu.length === 0
                  ? "menu-left-list__item active"
                  : "menu-left-list__item noactive"
              }
            >
              {element.subMenu.length === 0
                ? renderChild(element)
                : renderFather(element, menu, index)}
            </li>
          );
          if (element.open && element.subMenu.length > 0) {
            htmlMenuElements.push(
              <div
                key={element.id + "general" + Math.random()}
                className="ident"
              >
                {renderMenuElements(element.subMenu)}
              </div>
            );
          }
        }
      });
    }

    return htmlMenuElements;
  };

  return (
    <aside className="menu-left" hidden={!menuReducer.showMenu}>
      <ul className="menu-left-list">
        <li
          key={"mini-menu"}
          className={"mini-menu"}
          onClick={() => sethideMenu(!hideMenu)}
        >
          <span className="icon" aria-hidden={true}>
            <ion-icon name={"menu-outline"} />
          </span>
          <span aria-label={t("Menu")}>{t("Menu")}</span>
          <span className="icon-float-left" aria-hidden={true}>
            <ion-icon
              name={hideMenu ? "caret-down-outline" : "caret-up-outline"}
            />
          </span>
        </li>
        <span className={hideMenu ? "display-none" : "display"}>
          {renderMenuElements(menu)}
        </span>
      </ul>
    </aside>
  );
}

export default MenuLeft;
