import styled, { keyframes } from 'styled-components';

const spin = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

export const Loader = styled.div`
    display: ${(props) => (props.loading === 'true' ? 'block' : 'none')};
    border: .5em solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    width: 5rem;
    height: 5rem;
    animation: ${spin} 0.6s linear infinite;
`;

export default Loader;
