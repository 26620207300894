const getNewURLImageParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/News/GetUrlImage',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getNewParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/News/GetAll',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getNewByIdParams = (id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/News/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const postNewParams = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		contentType: 'multipart/form-data',
		data,
		endpoint: '/News',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const putNewParams = (data, SuccessFn) => {
	
	const options = {
		method: 'PUT',
		contentType: 'multipart/form-data',
		data,
		endpoint: '/News',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const deleteNewParams = (params, SuccessFn) => {
	const options = {
		method: 'DELETE',
		params,
		endpoint: '/News',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export { getNewURLImageParams, getNewParams, getNewByIdParams, postNewParams, putNewParams, deleteNewParams };
