import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useParams } from 'react-router-dom';
import { startRequest } from '@backoffice/actions/apiCall';
import { ROUTE } from '../../../../common/constants/routes';
import { getIncidentByIdParams } from './IncidentsTenantDetailCalls/IncidentsTenantDetailCalls';
import TabGeneral from './TabGeneral/TabGeneral';
import TabHistoric from './TabHistoric/TabHistoric';
import TabComments from './TabComments/TabComments';
import './IncidentsTenantDetail.scss';

const IncidentsTenantDetail = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const history = useHistory();
	const { id } = useParams();
	const [ incidentId, setIncidentId ] = useState(id);
	const [ currentTab, setCurrentTab ] = useState('General');
	const [ nextCurrentTab, setNextCurrentTab ] = useState('General');
	const [ incident, setIncident ] = useState('');

	useEffect(() => {
		getIncidentById();
		getIncidentByIdWithInterval(5);
	}, []);

	useEffect(
		() => {
			setCurrentTab(nextCurrentTab);
		},
		[ nextCurrentTab ]
	);

	const getIncidentById = () => {
		dispatch(startRequest(getIncidentByIdParams(incidentId, getIncidentByIdSuccess)));
	};

	const getIncidentByIdSuccess = (response) => {
		setIncident(response);
	};

	const getIncidentByIdWithInterval = (min) => {
		setInterval(() => {
			getIncidentById();
		}, 60000 * min);
	};

	return (
		<div className="incidents-tenant-detail">
			<div className="return">
				<div className="col-lg-2 offsett-lg-10">
					<Link className="go-back" onClick={() => history.goBack()} role="link">
						<span
							className="material-icons-round"
							data-testid={'btn-goback'}
							id={'btn-goback'}
							aria-hidden={true}
						>
							arrow_back
						</span>
						<div className="go-back__text">{t('GoBack')}</div>
					</Link>
				</div>
			</div>
			<div className="content-title">
				<h2 className="title">{t('IncidentsDetail')}</h2>
				{/* <p className="description">{t('Description') + '...'}</p> */}
			</div>
			<div className="tabs-wrapper">
				<div id="tabs" className="tabs" aria-roledescription="tabpanel">
					<button
						className={`tab-button ${currentTab === 'General' ? 'active' : ''}`}
						data-testid="btnTabGeneral"
						aria-roledescription="tab"
						role="tab"
						aria-selected={currentTab === 'General'}
						onClick={() => setNextCurrentTab('General')}
					>
						{t('General')}
					</button>
					<button
						className={`tab-button ${currentTab === 'Historic' ? 'active' : ''}`}
						data-testid="btnHistoric"
						aria-roledescription="tab"
						role="tab"
						aria-selected={currentTab === 'Historic'}
						onClick={() => {
							setNextCurrentTab('Historic');
						}}
					>
						{t('Historic')}
					</button>
					<button
						className={`tab-button ${currentTab === 'Comments' ? 'active' : ''}`}
						data-testid="btnComments"
						aria-roledescription="tab"
						role="tab"
						aria-selected={currentTab === 'Comments'}
						onClick={() => {
							setNextCurrentTab('Comments');
						}}
					>
						{t('Comments')}
					</button>
				</div>
				<div className="tabs_form">
					<div className="form-content">
						{currentTab === 'General' ? (
							<div className="tab-content-item" id="ContactTabContent" hidden={currentTab !== 'General'}>
								<TabGeneral
									incident={incident}
									incidentId={incidentId}
									handleSubmit={getIncidentById}
								/>
							</div>
						) : currentTab === 'Historic' ? (
							<div
								className="tab-content-item"
								id="HistoricTabContent"
								hidden={currentTab !== 'Historic'}
							>
								<TabHistoric incident={incident} handleSubmit={getIncidentById} />
							</div>
						) : currentTab === 'Comments' ? (
							<div
								className="tab-content-item"
								id="CommentsTabContent"
								hidden={currentTab !== 'Comments'}
							>
								<TabComments incident={incident} handleSubmit={getIncidentById} />
							</div>
						) : (
							'no tab selected'
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
export default IncidentsTenantDetail;
