const CHANGE_APPEARANCE = 'CHANGE_APPEARANCE';
const RESET_APPEARANCE = 'RESET_APPEARANCE';
const REFRESH_APPEARANCE = 'REFRESH_APPEARANCE';

const defaultConst = {
	CHANGE_APPEARANCE,
	RESET_APPEARANCE,
	REFRESH_APPEARANCE
};

export default defaultConst;
export const changeAppearance = (appearance) => ({
	type: CHANGE_APPEARANCE,
	appearance: appearance
});

export const resetAppearance = (appearance) => ({
	type: RESET_APPEARANCE
});

export const refreshAppearance = (appearance) => ({
	type: REFRESH_APPEARANCE
});
