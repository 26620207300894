const getServiceTypesParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tipology/ServiceBuildingType',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getServicesParams = (buildingId, serviceTypeId, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/TenantBuildingService/GetServicesList/' + buildingId + '/' + serviceTypeId,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export { getServiceTypesParams, getServicesParams };
