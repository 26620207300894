const getIncidentsParams = (SuccessFn) => {
	let endpoint = '/Incidents/GetAllIncidents';
	endpoint = 	endpoint + '?Pagination.Skip=0&Pagination.Take=1000'
	const options = {
		method: 'GET',
		endpoint: endpoint,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};


const getIncidentsWithParams = (params,SuccessFn) => {
	let endpoint = '/Incidents/GetAllIncidents';
	endpoint = 	endpoint + '?Pagination.Skip=0&Pagination.Take=1000'
	
	if(params.filters.StartDate !== '' && params.filters.EndDate !== '') {
		endpoint = 
		endpoint +  
		'&Filters.StartDate=' + 
		params.filters.StartDate + 
		'&Filters.EndDate=' + 
		params.filters.EndDate;
	}	
	const options = {
		method: 'GET',
		endpoint: endpoint,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};


export { getIncidentsParams, getIncidentsWithParams };

