import React, { useState, useEffect } from 'react';
import { startRequest } from '@backoffice/actions/apiCall';
import { useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ROUTE } from '../../../../common/constants/routes';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import {
	Category,
	ChartComponent,
	BarSeries,
	LineSeries,
	DataLabel,
	Inject,
	Legend,
	SeriesCollectionDirective,
	SeriesDirective,
	Tooltip,
	AccumulationDataLabel,
	AccumulationChartComponent,
	AccumulationSeriesCollectionDirective,
	AccumulationSeriesDirective,
	PieSeries,
	AccumulationTooltip
} from '@syncfusion/ej2-react-charts';
import { useTranslation } from 'react-i18next';
import { Combo } from '@backoffice/components/FormComponents/index';
import { getBuildingsParams } from '../SpacesDetail/SpacesDetailCalls/SpacesDetailCalls';
import { GetCookie_culture, GetCookie_weekStart } from '../../../../common/utils/cookiesManager';
import { parseDate, parseDateBE } from '../../../../common/utils/parseUtils';
import {
	getAppAccessStatsParams,
	getHomeStatsParams,
	getHomeTotalStatsParams,
	getNewRegisterStatsParams,
	getNewsParams,
	getClickNewsStatsParams
} from './HomeCalls/HomeCalls';
import './Home.scss';

const Home = () => {
	const { t } = useTranslation();
	const history = useHistory();

	const [ buildings, setBuildings ] = useState([]);
	const [ building, setBuilding ] = useState({ id: '', name: t('All') });
	const [ news, setNews ] = useState([]);
	const [ newSelected, setNewSelected ] = useState({ id: '', name: t('All') });

	const [ showContent, setShowContent ] = useState(false);

	const [ profileTypeAccess, setProfileTypeAccess ] = useState({
		id: '',
		name: t('All')
	});
	const [ profileTypeRegister, setProfileTypeRegister ] = useState({
		id: '',
		name: t('All')
	});
	const [ profiles, setProfiles ] = useState([
		{ id: 'All', name: t('All') },
		{
			id: 'Admin',
			name: 'Admin'
		},
		{
			id: 'Tenant',
			name: 'Tenant'
		},
		{
			id: 'Provider',
			name: 'Provider'
		}
	]);

	let today = new Date();
	const [ startDate, setStartDate ] = useState(parseDate(new Date(today.setMonth(today.getMonth() - 6))));
	const [ endDate, setEndDate ] = useState(parseDate(new Date()));

	const [ homeStats, setHomeStats ] = useState('');

	const [ incidents, setIncidents ] = useState(23);
	const [ spaceBookings, setSpaceBookings ] = useState(10);

	const [ appAccess, setAppAccess ] = useState(12);
	const [ appAccessStats, setAppAccessStats ] = useState('');

	const [ newRegisters, setNewRegisters ] = useState(34);
	const [ newRegistersStats, setNewRegistersStats ] = useState('');

	const [ clicksNews, setClicksNews ] = useState(180);
	const [ clicksNewsStats, setClicksNewsStats ] = useState('');

	const [ tabSelected, setTabSelected ] = useState('Incidents');

	const [ categories, setCategories ] = useState([
		{ color: '#5E028A', type: 'Administrativa', total: 3 },
		{ color: '#A62CA9', type: 'Fontaneria', total: 3 },
		{ color: '#9F004D', type: 'Electricidad', total: 7 },
		{ color: '#00BBC7', type: 'Humedades', total: 13 },
		{ color: '#8C679A', type: 'Mobiliario', total: 19 },
		{ color: '#57558E', type: 'Suelo', total: 23 },
		{ color: '#03045E', type: 'Climatización', total: 26 },
		{ color: '#023E8A', type: 'Electrodomésticos', total: 25 },
		{ color: '#0077B6', type: 'Domótica', total: 21 },
		{ color: '#158C7E', type: 'Seguridad', total: 15 },
		{ color: '#5F9F0E', type: 'Zonas comunes', total: 15 },
		{ color: '#6F7267', type: 'Otros', total: 15 }
	]);

	const dispatch = useDispatch();

	useEffect(() => {
		setShowContent(false);
		getBuildings();
	}, []);

	useEffect(
		() => {
			setShowContent(false);
			if (tabSelected === 'AppAccess') {
				getAppAccessStats();
			} else if (tabSelected === 'NewRegisters') {
				getNewRegistersStats();
			} else if (tabSelected === 'ClicksNews') {
				getClickNewsStats();
			} else {
				setShowContent(true);
			}
		},
		[ tabSelected, profileTypeAccess, profileTypeRegister, newSelected ]
	);

	useEffect(
		() => {
			setShowContent(false);
			if (homeStats !== '') {
				getHomesStats();
			}
		},
		[ startDate, endDate, building ]
	);

	const getAppAccessStats = () => {
		dispatch(
			startRequest(
				getAppAccessStatsParams(
					{
						Filters: {
							StartDate: startDate !== '' ? parseDateBE(startDate) : '',
							RolId: profileTypeAccess !== '' ? profileTypeAccess.id : '',
							EndDate: endDate !== '' ? parseDateBE(endDate) : '',
							BuildingId: building !== '' ? building.id : ''
						}
					},
					getAppAccessStatsSuccess
				)
			)
		);
	};

	const getAppAccessStatsSuccess = (response) => {
		setAppAccessStats(response);
		setShowContent(true);
	};

	const getNewRegistersStats = () => {
		dispatch(
			startRequest(
				getNewRegisterStatsParams(
					{
						Filters: {
							StartDate: startDate !== '' ? parseDateBE(startDate) : '',
							RolId: profileTypeRegister !== '' ? profileTypeRegister.id : '',
							EndDate: endDate !== '' ? parseDateBE(endDate) : '',
							BuildingId: building !== '' ? building.id : ''
						}
					},
					getNewRegistersStatsSuccess
				)
			)
		);
	};

	const getNewRegistersStatsSuccess = (response) => {
		setNewRegistersStats(response);
		setShowContent(true);
	};

	const getClickNewsStats = () => {
		dispatch(
			startRequest(
				getClickNewsStatsParams(
					{
						Filters: {
							StartDate: startDate !== '' ? parseDateBE(startDate) : '',
							NewsId: newSelected !== '' ? newSelected.id : '',
							EndDate: endDate !== '' ? parseDateBE(endDate) : '',
							BuildingId: building !== '' ? building.id : ''
						}
					},
					getClickNewsStatsSuccess
				)
			)
		);
	};

	const getClickNewsStatsSuccess = (response) => {
		setClicksNewsStats(response);
		getNews();
	};

	const getHomesStats = () => {
		dispatch(
			startRequest(
				getHomeStatsParams(
					{
						Filters: {
							StartDate: startDate !== '' ? parseDateBE(startDate) : '',
							EndDate: endDate !== '' ? parseDateBE(endDate) : '',
							BuildingId: building !== '' ? building.id : ''
						}
					},
					getHomesStatsSuccess
				)
			)
		);
	};

	const getHomesStatsSuccess = (response) => {
		let newHomeStats = response;

		newHomeStats.incidentType.forEach((iType) => {
			iType.type = t(iType.type);
		});

		setHomeStats(newHomeStats);
		getHomeTotalStats();
	};

	const getHomeTotalStats = () => {
		dispatch(
			startRequest(
				getHomeTotalStatsParams(
					{
						Filters: {
							StartDate: startDate !== '' ? parseDateBE(startDate) : '',
							EndDate: endDate !== '' ? parseDateBE(endDate) : '',
							BuildingId: building !== '' ? building.id : ''
						}
					},
					getHomeTotalStatsSuccess
				)
			)
		);
	};

	const getHomeTotalStatsSuccess = (response) => {
		setAppAccess(response.access);
		setSpaceBookings(response.bookings);
		setIncidents(response.incidents);
		setClicksNews(response.news);
		setNewRegisters(response.registers);
		setShowContent(true);
	};

	const getBuildings = () => {
		dispatch(startRequest(getBuildingsParams(getBuildingsSuccess)));
	};

	const getBuildingsSuccess = (response) => {
		let newBuildings = [ { id: '', name: t('All') }, ...response ];
		setBuildings(newBuildings);
		getHomesStats();
	};

	const getNews = () => {
		dispatch(
			startRequest(
				getNewsParams(
					{
						Filters: {
							StartDate: startDate !== '' ? parseDateBE(startDate) : '',
							EndDate: endDate !== '' ? parseDateBE(endDate) : '',
							BuildingId: building !== '' ? building.id : ''
						}
					},
					getNewsSuccess
				)
			)
		);
	};

	const getNewsSuccess = (response) => {
		let newNews = [ { id: '', name: t('All') }, ...response ];
		setNews(newNews);
		setShowContent(true);
	};

	const renderLegendItem = (array) => {
		let html = [];
		array.forEach((item, index) => {
			html.push(
				<div className="legend-square-wrapper" key={'item-' + index + '-' + Math.random()}>
					<div className="legend-square" style={{ backgroundColor: item.color }} />
					<div className="legend-text">{t(item.type)}</div>
				</div>
			);
		});
		return html;
	};

	const renderIncidentsStats = () => {
		return (
			<React.Fragment key={Math.random()}>
				<div className="stats-container-bar">
					<h2 className="subtitle">{t('IncidentType')}</h2>
					<ChartComponent
						id="charts-incidents"
						tooltip={{ enable: true, header: '' }}
						primaryXAxis={{
							valueType: 'Category',
							title: '',
							visible: false
						}}
						primaryYAxis={{
							title: ''
						}}
						legendSettings={{ visible: false }}
						style={{ width: '100%' }}
					>
						<SeriesCollectionDirective>
							<SeriesDirective
								dataSource={showContent ? homeStats.incidentType : null}
								pointColorMapping="color"
								width={'100%'}
								xName="type"
								yName="total"
								type="Bar"
								name="total"
								marker={{
									dataLabel: {
										visible: true,
										name: 'type',
										position: 'Middle',
										font: {
											size: '16px',
											fontWeight: 'bolder',
											color: 'black'
										}
									}
								}}
							/>
						</SeriesCollectionDirective>
						<Inject services={[ BarSeries, Legend, Tooltip, DataLabel, Category ]} />
					</ChartComponent>
				</div>
				<div className="stats-container-circle">
					<h2 className="subtitle">{t('Categories')}</h2>
					<AccumulationChartComponent
						id="acumulation-chart"
						tooltip={{ enable: true, header: '' }}
						enableSmartLabels={false}
					>
						<AccumulationSeriesCollectionDirective>
							<AccumulationSeriesDirective
								dataSource={showContent ? homeStats.incidentCategory : null}
								enableTooltip={true}
								dataLabel={{
									visible: true,
									name: 'total',
									position: 'Outside',
									connectorStyle: { length: '4%', color: 'transparent' },
									font: {
										size: '16px',
										fontWeight: 'bolder',
										color: 'black'
									}
								}}
								pointColorMapping="color"
								xName="type"
								yName="total"
								type="Pie"
								radius="80%"
							/>
						</AccumulationSeriesCollectionDirective>
						<Inject services={[ PieSeries, AccumulationDataLabel, AccumulationTooltip ]} />
					</AccumulationChartComponent>
					<div className="legend-wrapper">{renderLegendItem(categories)}</div>
				</div>
			</React.Fragment>
		);
	};

	const renderSpaceBookingsStats = () => {
		return (
			<React.Fragment key={Math.random()}>
				<div className="stats-container-bar">
					<h2 className="subtitle">{t('SpaceType')}</h2>
					<ChartComponent
						id="chartsSpaceBooking"
						pointClick={(event) => {
							history.push(ROUTE.SPACESDETAIL.replace(':id',homeStats.bookingSpace[event.pointIndex].spaceId))
						}}
						tooltip={{ enable: true, header: '' }}
						primaryXAxis={{
							valueType: 'Category',
							title: '',
							visible: false
						}}
						primaryYAxis={{
							title: ''
						}}
						legendSettings={{ visible: false }}
						style={{ width: '100%' }}
					>
						<SeriesCollectionDirective>
							<SeriesDirective
								dataSource={showContent ? homeStats.bookingSpace : null}
								pointColorMapping="color"
								width={'100%'}
								xName="type"
								yName="total"
								type="Bar"
								name="total"
								marker={{
									dataLabel: {
										visible: true,
										name: 'type',
										position: 'Middle',
										font: {
											size: '16px',
											fontWeight: 'bolder',
											color: 'black'
										}
									}
								}}
							/>
						</SeriesCollectionDirective>
						<Inject services={[ BarSeries, Legend, Tooltip, DataLabel, Category ]} />
					</ChartComponent>
				</div>
			</React.Fragment>
		);
	};

	const renderAppAccessStats = () => {
		let markerProps = { dataLabel: { visible: true } };
		return (
			<React.Fragment key={Math.random()}>
				<div className="stats-container-line">
					<div className="row">
						<div className="col-xl-3">
							<Combo
								id="cmbProfileType"
								dataTestID="cmbProfileType"
								comboLabel={t('ProfileType')}
								inputValue={profileTypeAccess}
								comboPlaceholder={t('Select') + ' ' + t('Profile') + '...'}
								defaultInputValue=""
								isSearchable={false}
								getOptionValue={(option) => option['id']}
								getOptionLabel={(option) => t(option['name'])}
								options={profiles}
								isMultiple={false}
								handleChange={(e) => {
									setProfileTypeAccess(e);
								}}
							/>
						</div>
					</div>
					<div className="row">
						<ChartComponent
							id="charts"
							width="100%"
							primaryXAxis={{ valueType: 'Category' }}
							palettes={[ '#00BBC7', '#A62CA9', '#5E028A' ]}
						>
							<SeriesCollectionDirective>
								<SeriesDirective
									dataSource={appAccessStats !== '' && showContent ? appAccessStats.web : []}
									xName="month"
									yName="value"
									width={2}
									name=""
									type="Line"
									marker={markerProps}
								/>
								<SeriesDirective
									dataSource={appAccessStats !== '' && showContent ? appAccessStats.android : []}
									xName="month"
									yName="value"
									width={2}
									name=""
									type="Line"
									marker={markerProps}
								/>
								<SeriesDirective
									dataSource={appAccessStats !== '' && showContent ? appAccessStats.ios : []}
									xName="month"
									yName="value"
									width={2}
									name=""
									type="Line"
									marker={markerProps}
								/>
							</SeriesCollectionDirective>
							<Inject services={[ LineSeries, Legend, Tooltip, DataLabel, Category ]} />
						</ChartComponent>
					</div>
					<div className="legend-wrapper">
						{renderLegendItem([
							{ color: '#00BBC7', type: 'Web' },
							{ color: '#A62CA9', type: 'Android' },
							{ color: '#5E028A', type: 'IOS' }
						])}
					</div>
				</div>
			</React.Fragment>
		);
	};

	const renderNewRegistersStats = () => {
		let markerProps = { dataLabel: { visible: true } };
		return (
			<React.Fragment key={Math.random()}>
				<div className="stats-container-line">
					<div className="row">
						<div className="col-xl-3">
							<Combo
								id="cmbProfileType"
								dataTestID="cmbProfileType"
								comboLabel={t('ProfileType')}
								inputValue={profileTypeRegister}
								comboPlaceholder={t('Select') + ' ' + t('Profile') + '...'}
								defaultInputValue=""
								isSearchable={false}
								getOptionValue={(option) => option['id']}
								getOptionLabel={(option) => t(option['name'])}
								options={profiles}
								isMultiple={false}
								handleChange={(e) => {
									setProfileTypeRegister(e);
								}}
							/>
						</div>
					</div>
					<div className="row">
						<ChartComponent id="charts" width="100%" primaryXAxis={{ valueType: 'Category' }}>
							<SeriesCollectionDirective>
								<SeriesDirective
									dataSource={newRegistersStats !== '' && showContent ? newRegistersStats : []}
									xName="month"
									yName="value"
									width={2}
									name=""
									type="Line"
									marker={markerProps}
								/>
							</SeriesCollectionDirective>
							<Inject services={[ LineSeries, Legend, Tooltip, DataLabel, Category ]} />
						</ChartComponent>
					</div>
				</div>
			</React.Fragment>
		);
	};

	const renderClicksNewsStats = () => {
		let markerProps = { dataLabel: { visible: true } };
		return (
			<React.Fragment key={Math.random()}>
				<div className="stats-container-line news-graph">
					<div className="row">
						<div className="col-xl-3">
							<Combo
								id="cmbNews"
								dataTestID="cmbNews"
								comboLabel={t('NewsTenant')}
								inputValue={newSelected}
								comboPlaceholder={t('Select') + ' ' + t('NewsTenant') + '...'}
								defaultInputValue=""
								isSearchable={false}
								getOptionValue={(option) => option['id']}
								getOptionLabel={(option) => t(option['name'])}
								options={news}
								isMultiple={false}
								handleChange={(e) => {
									setNewSelected(e);
								}}
							/>
						</div>
						{newSelected.id !== '' ? (
							<div className="view-new">
								<Link to={'/news/' + newSelected.id}>
									<span className="material-icons-round" aria-hidden={true}>
										visibility
									</span>
								</Link>
							</div>
						) : (
							''
						)}
					</div>
					<div className="row">
						<ChartComponent
							id="charts"
							width="100%"
							primaryXAxis={{ valueType: 'Category' }}
							palettes={[ '#00BBC7', '#A62CA9' ]}
						>
							<SeriesCollectionDirective>
								<SeriesDirective
									dataSource={clicksNewsStats !== '' && showContent ? clicksNewsStats.unique : []}
									xName="month"
									yName="value"
									width={2}
									name=""
									type="Line"
									binInterval={1}
									marker={markerProps}
								/>
								<SeriesDirective
									dataSource={clicksNewsStats !== '' && showContent ? clicksNewsStats.total : []}
									xName="month"
									yName="value"
									width={2}
									binInterval={1}
									name=""
									type="Line"
									marker={markerProps}
								/>
							</SeriesCollectionDirective>
							<Inject services={[ LineSeries, Legend, Tooltip, DataLabel, Category ]} />
						</ChartComponent>
					</div>
					<div className="legend-wrapper">
						{renderLegendItem([
							{ color: '#00BBC7', type: t('UniqueClicks') },
							{ color: '#A62CA9', type: t('TotalClicks') }
						])}
					</div>
				</div>
			</React.Fragment>
		);
	};

	return (
		<div className="home">
			<div className="filters-wrapper">
				<div className="col-xl-3">
					<Combo
						id="cmbBuilding"
						dataTestID="cmbBuilding"
						comboLabel={t('Building')}
						inputValue={building}
						comboPlaceholder={t('Select') + ' ' + t('Building') + '...'}
						defaultInputValue=""
						isSearchable={false}
						getOptionValue={(option) => option['id']}
						getOptionLabel={(option) => t(option['name'])}
						options={buildings}
						isMultiple={false}
						handleChange={(e) => {
							setBuilding(e);
						}}
					/>
				</div>
				<div className="col-xl-3">
					<div className="date-wrapper">
						<div className="input-container">
							<label className="inputLabel label-date" htmlFor={'txtBookingDate'}>
								{t('Since') + ':'}
							</label>
							<DatePickerComponent
								showTodayButton={false}
								locale={GetCookie_culture()}
								firstDayOfWeek={GetCookie_weekStart()}
								dataTestID="txtStartDate"
								cssClass={'e-custom-datepicker'}
								value={startDate}
								allowEdit={true}
								readonly={false}
								openOnFocus={false}
								showClearButton={false}
								cleared={() => {
									setStartDate('');
								}}
								onChange={(e) => {
									if (e.value && e.value != '') {
										setStartDate(parseDate(e.value));
									}
								}}
							/>
						</div>
					</div>
				</div>
				<div className="col-xl-3">
					<div className="input-container">
						<label className="inputLabel label-date" htmlFor={'txtBookingDate'}>
							{t('Until') + ':'}
						</label>
						<div className="date-wrapper">
							<DatePickerComponent
								showTodayButton={false}
								locale={GetCookie_culture()}
								firstDayOfWeek={GetCookie_weekStart()}
								dataTestID="txtEndDate"
								cssClass={'e-custom-datepicker'}
								value={endDate}
								allowEdit={true}
								readonly={false}
								openOnFocus={false}
								showClearButton={false}
								cleared={() => {
									setEndDate('');
								}}
								onChange={(e) => {
									if (e.value && e.value != '') {
										if (e.value && e.value != '') {
											setEndDate(parseDate(e.value));
										}
									}
								}}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="col-square-row">
				<div className="col-square">
					<h2 className="subtitle">{t('Incidents')}</h2>
					<div
						className={tabSelected === 'Incidents' ? 'square-stats active' : 'square-stats'}
						onClick={() => setTabSelected('Incidents')}
					>
						{incidents}
					</div>
				</div>
				<div className="col-square">
					<h2 className="subtitle">{t('SpaceBookings')}</h2>
					<div
						className={tabSelected === 'SpaceBookings' ? 'square-stats active' : 'square-stats'}
						onClick={() => setTabSelected('SpaceBookings')}
					>
						{spaceBookings}
					</div>
				</div>
				<div className="col-square">
					<h2 className="subtitle">{t('AppAccess')}</h2>
					<div
						className={tabSelected === 'AppAccess' ? 'square-stats active' : 'square-stats'}
						onClick={() => setTabSelected('AppAccess')}
					>
						{appAccess}
					</div>
				</div>
				<div className="col-square">
					<h2 className="subtitle">{t('NewRegisters')}</h2>
					<div
						className={tabSelected === 'NewRegisters' ? 'square-stats active' : 'square-stats'}
						onClick={() => setTabSelected('NewRegisters')}
					>
						{newRegisters}
					</div>
				</div>
				<div className="col-square">
					<h2 className="subtitle">{t('ClicksNews')}</h2>
					<div
						className={tabSelected === 'ClicksNews' ? 'square-stats active' : 'square-stats'}
						onClick={() => setTabSelected('ClicksNews')}
					>
						{clicksNews}
					</div>
				</div>
			</div>
			{homeStats !== '' && (
				<div className="stats-container">
					{tabSelected === 'Incidents' ? (
						renderIncidentsStats()
					) : tabSelected === 'SpaceBookings' ? (
						renderSpaceBookingsStats()
					) : tabSelected === 'AppAccess' ? (
						renderAppAccessStats()
					) : tabSelected === 'NewRegisters' ? (
						renderNewRegistersStats()
					) : tabSelected === 'ClicksNews' ? (
						renderClicksNewsStats()
					) : (
						''
					)}
				</div>
			)}
		</div>
	);
};
export default Home;
