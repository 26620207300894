const getBuildingByIdParams = (id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Building/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getApperanceByIdParams = (id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Building/GetAppearance/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const postApperanceParams = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		data,
		contentType: 'multipart/form-data',
		endpoint: '/Building/SaveAppearance',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const deleteMediaAppearanceParams = (params, SuccessFn) => {
	const options = {
		method: 'DELETE',
		params,
		endpoint: '/Building/DeleteAppearance',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getContactsByIdParams = (id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Building/GetContactList/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};

	return options;
};

const getContactListByIdParams = (id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Building/GetAllContactList/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const postContactParams = (data, SuccessFn, ErrorFn) => {
	const options = {
		method: 'POST',
		data,
		endpoint: '/Building/SaveContact',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		},
		onError: (response) => {
			ErrorFn(response);
		}
	};

	return options;
};

const assignContactParams = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		data,
		endpoint: '/Building/AssingContact',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};

	return options;
};

const getContactDetailByIdParams = (buildingId, contactId, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Building/GetDetailContact/' + buildingId + '/' + contactId,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const deleteContactParams = (params, SuccessFn) => {
	const options = {
		method: 'DELETE',
		params,
		endpoint: '/Building/DeleteContact',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getAdminByIdParams = (id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Building/GetAdmin/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const postAdminParams = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		data,
		endpoint: '/Building/SaveAdmin',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getAdminListByIdParams = (id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Building/GetAllAdminList/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getRolesListParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tipology/GetAllNameId/PositionType',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getServicesTypeParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tipology/GetAllNameId/ServiceBuildingType',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getServicesListByIdParams = (id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Building/GetAllServicesList/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getServicesByIdParams = (id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Building/GetServicesList/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getServiceDetailByIdParams = (buildingId, serviceId, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Building/GetDetailService/' + buildingId + '/' + serviceId,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const assignServiceParams = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		data,
		endpoint: '/Building/AssingService',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};

	return options;
};

const postServiceParams = (data, funcSucc) => {
	const options = {
		method: 'POST',
		data,
		contentType: 'multipart/form-data',
		endpoint: '/Building/SaveService',
		onSuccess: (response) => {
			funcSucc(response.data.data);
		}
	};

	return options;
};

const deleteServiceParams = (params, SuccessFn) => {
	const options = {
		method: 'DELETE',
		params,
		endpoint: '/Building/DeleteService',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getDocumentsByIdParams = (id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Building/GetDocuments/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const downloadDocumentByIdParams = (documentId, buildingId, SuccessFn, ErrorFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Building/DownloadDocument/' + documentId + '/' + buildingId,
		onSuccess: (response) => {
			SuccessFn(response.data);
		},
		onError: (response) => {
			ErrorFn(response.data);
		}
	};
	return options;
};

const postDocumentParams = (data, funcSucc) => {
	const options = {
		method: 'POST',
		data,
		contentType: 'multipart/form-data',
		endpoint: '/Building/CreateMultipleDocuments',
		onSuccess: (response) => {
			funcSucc(response.data.data);
		}
	};

	return options;
};

const deleteDocumentParams = (params, funcSucc) => {
	const options = {
		method: 'DELETE',
		params,
		endpoint: '/Building/DeleteMedia',
		onSuccess: (response) => {
			funcSucc(response.data.data);
		}
	};

	return options;
};


export {
	getBuildingByIdParams,
	postApperanceParams,
	getApperanceByIdParams,
	deleteMediaAppearanceParams,
	getContactsByIdParams,
	getContactListByIdParams,
	postContactParams,
	getContactDetailByIdParams,
	getAdminByIdParams,
	postAdminParams,
	getAdminListByIdParams,
	getRolesListParams,
	getServicesListByIdParams,
	getServicesByIdParams,
	getServiceDetailByIdParams,
	assignServiceParams,
	postServiceParams,
	assignContactParams,
	deleteContactParams,
	deleteServiceParams,
	getServicesTypeParams,
	getDocumentsByIdParams,
	downloadDocumentByIdParams,
	postDocumentParams,
	deleteDocumentParams
};
