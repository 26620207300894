const getAccountParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Account',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const putAccountParams = (data, SuccessFn, ErrorFn) => {
	const options = {
		method: 'PUT',
		data,
		endpoint: '/UserManagement',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		},
		onError: (response) => {
			ErrorFn(response);
		}
	};
	return options;
};

const deleteAccountParams = (params, SuccessFn) => {
	const options = {
		method: 'DELETE',
		params,
		endpoint: '/UserManagement',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export { getAccountParams, putAccountParams, deleteAccountParams };
