import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	ColumnDirective,
	ColumnsDirective,
	Filter,
	GridComponent,
	Group,
	Resize,
	Inject,
	Page,
	Sort,
	ExcelExport,
	Selection
} from '@syncfusion/ej2-react-grids';
import { useDispatch } from 'react-redux';
import { Input } from '@backoffice/components/FormComponents/index';
import { cleanStringFromAccents } from '../../../../../common/utils/parseUtils';
import ResponsiveGridWrapper from '../../../../../shared/ResponsiveGridWrapper/ResponsiveGridWrapper';
import { startRequest } from '@backoffice/actions/apiCall';
import { ROUTE } from '../../../../../common/constants/routes';
import { useHistory } from 'react-router-dom';
import { showFixedLabel } from '../../../../../actions/fixedLabel';
import './PopUpAddAdmin.scss';
import { getAdminListByIdParams, postAdminParams } from '../BuildingDetailCalls/BuildingDetailCalls';
import { Button } from '../../../../../components/FormComponents';
import { closeModal } from '../../../../../actions/popup';

const PopUpAddAdmin = ({ buildingId, handleSubmit }) => {
	const { t } = useTranslation();
	const pageSettings = { pageSize: 50 };
	const grid = useRef(null);
	const dispatch = useDispatch();
	const history = useHistory();
	const [ admins, setAdmins ] = useState([]);
	const [ allAdmins, setAllAdmins ] = useState([]);
	const [ search, setSearch ] = useState('');
	const [ selectedAdmin, setSelectedAdmin ] = useState('');

	useEffect(() => {
		getAllAdmin();
	}, []);

	useEffect(
		() => {
			filterAdmins();
		},
		[ search ]
	);

	const getAllAdmin = () => {
		dispatch(startRequest(getAdminListByIdParams(buildingId, getAdminListByIdParamsSuccess)));
	};

	const getAdminListByIdParamsSuccess = (response) => {
		setAdmins(response);
		setAllAdmins(response);
	};

	const filterBySearch = (data = [], searching = '') => {
		const result = data.filter((element) => {
			return (
				cleanStringFromAccents(element.name.toLowerCase()).includes(searching.toLowerCase()) ||
				cleanStringFromAccents(element.phoneNumber).toLowerCase().includes(searching.toLowerCase()) ||
				cleanStringFromAccents(element.email).toLowerCase().includes(searching.toLowerCase())
			);
		});

		return result;
	};

	const filterAdmins = () => {
		if (search === '') {
			setAdmins(allAdmins);
		} else {
			const newData = filterBySearch(allAdmins, search);
			if (newData === '') {
				setAdmins(allAdmins);
			}
			setAdmins(newData);
		}
	};

	const actionSelectRow = (rowData) => {
		let radio = document.getElementsByClassName('radioGrid');
		for (let i = 0; i < radio.length; i++) {
			if (i === rowData.rowIndex) {
				radio[i].checked = true;
			} else {
				radio[i].checked = false;
			}
		}

		setSelectedAdmin(rowData.data);
	};

	const actionRadioButton = (rowData) => {
		return <input type="radio" id={'e-radioId-' + rowData.index} className="radioGrid" value={rowData.index} />;
	};

	const addAdmin = () => {
		let admin = selectedAdmin;
		admin.buildingId = buildingId;
		dispatch(startRequest(postAdminParams(admin, postAdminParamsSuccess)));
	};

	const postAdminParamsSuccess = (response) => {
		history.push(ROUTE.BUILDINGSDETAIL.replace(':id', buildingId));
		dispatch(closeModal());
		dispatch(showFixedLabel('AdminAddSuccess', 'success'));
		handleSubmit();
	};

	// let searchBy = t('Search') + ' ' + t('by') + ' ' + t('Name') + ', ' + t('Phone') + ', ' + t('Email');
	let searchBy = t('Search') + ' ' + t('by') + ' ' + t('WordMin');
	return (
		<div className="add-admin-popup">
			<div className="filters-wrapper">
				<div className="col-xl-3 search" data-bs-toggle="tooltip" title={searchBy}>
					<Input
						dataTestID="txtSearch"
						inputName="txtSearch"
						clearInput={true}
						inputPlaceholder={searchBy}
						inputType="text"
						value={search}
						inputOutline={true}
						icon={'search'}
						iconLeft={true}
						isRequired={false}
						onHandleChange={(e) => setSearch(e.target.value)}
					/>
				</div>
			</div>

			<ResponsiveGridWrapper>
				<GridComponent
					className="gridTable"
					dataSource={admins}
					width="100%"
					toolbar={false}
					ref={grid}
					allowExcelExport={true}
					allowPaging={true}
					enableAdaptiveUI={true}
					allowSorting={true}
					rowRenderingMode={'Vertical'}
					pageSettings={pageSettings}
					selectionSettings={{ type: 'Multiple' }}
					allowSelection={true}
					rowSelected={actionSelectRow}
				>
					<ColumnsDirective>
						<ColumnDirective field="id" width="0" visible={false} />
						<ColumnDirective field="" template={actionRadioButton} />
						<ColumnDirective
							field="name"
							headerText={t('Name')}
							textAlign="Left"
							// valueAccessor={(field, data, column) => {
							// 	return data.name + ' ' + data.surnames;
							// }}
						/>
						<ColumnDirective
							field="phoneNumber"
							headerText={t('Phone')}
							textAlign="Left"
							valueAccessor={(field, data, column) => {
								return data.phoneNumber;
							}}
						/>
						<ColumnDirective field="email" headerText={t('Email')} textAlign="Left" />
						<ColumnDirective field="url" headerText={t('URL')} textAlign="Left" />
					</ColumnsDirective>
					<Inject services={[ ExcelExport, Page, Sort, Filter, Group, Resize, Selection ]} />
				</GridComponent>
			</ResponsiveGridWrapper>

			<div className="btn-end">
				<Button
					dataTestID="btn-add-admin"
					btnText={t('Add')}
					btnType="button"
					btnClass="btn-base_normal"
					onHandleBtnClick={() => addAdmin()}
				/>
			</div>
		</div>
	);
};
export default PopUpAddAdmin;
