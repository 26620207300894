
const getNotifications = (SuccessFn) => {
  const options = {
    method: "GET",
    endpoint: "/TenantConfiguration/GetNotifications/",
    onSuccess: (response) => {
      SuccessFn(response.data.data);
    },
  };
  return options;
};

const postNotifications = (data, SuccessFn) => {
  const options = {
    method: "POST",
    data,
    endpoint: "/TenantConfiguration/SaveNotification",
    onSuccess: (response) => {
      SuccessFn(response.data.data);
    },
  };
  return options;
};

export {
  getNotifications,
  postNotifications,
};
