import React from 'react';
import { useTranslation } from 'react-i18next';
import './HomeProvider.scss';

const HomeProvider = () => {
	const { t } = useTranslation();

	return (
		<div className="home-provider">
			<h2 className="title">{t('HomeProvider')}</h2>
		</div>
	);
};

export default HomeProvider;
