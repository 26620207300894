const getContactByIdParams = (buildingId, contactId, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/TenantBuildingContacts/GetDetailContact/' + buildingId + '/' + contactId,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export { getContactByIdParams };
