import React from 'react';
import { useMediaQuery } from 'react-responsive';

import './Button.scss';

export default function Button(props) {
	const { dataTestID, id, btnText, btnType, btnClass, isDisabled, onHandleBtnClick, icon } = props;
	const isSmallMobile = useMediaQuery({ query: '(max-width: 400px)' });

	return (
		<button
			type={btnType}
			id={id}
			data-testid={dataTestID}
			onClick={onHandleBtnClick}
			className={`btn-base ${btnClass}`}
			disabled={isDisabled}
		>
			<span className="button-wrapper">
				{icon && !isSmallMobile ? <span className="icon material-icons-round" aria-hidden={true}>{icon}</span> : ''}
				<span className="text">{btnText}</span>
			</span>
		</button>
	);
}
