import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import './Combo.scss';

export default function Combo(props) {
	const {
		id,
		dataTestID,
		inputValue,
		defaultInputValue,
		options,
		isSearchable,
		comboLabel,
		handleChange,
		isDisabled,
		getOptionValue,
		getOptionLabel,
		isMultiple,
		isRequired,
		comboPlaceholder,
		onHandleClose,
		isClearable
	} = props;
	const { t } = useTranslation();

	const handleOpen = () => {
		const target = document.getElementById(dataTestID);
		if (target) {
			target.classList.add('isFocus');
		}
	};
	const handleClose = () => {
		const target = document.getElementById(dataTestID);
		if (target) {
			target.classList.remove('isFocus');
		}
		if (onHandleClose) {
			onHandleClose();
		}
	};

	return (
		<div data-testid={dataTestID} className={`combo-wrapper ${isDisabled ? 'isDisabled' : ''}`} id={dataTestID}>
			{comboLabel && comboLabel !== '' ? (
				<label htmlFor={id}>
					{comboLabel}
					{isRequired ? <sup className="required">*</sup> : ''}
				</label>
			) : (
				''
			)}
			<Select
				id={id}
				isDisabled={isDisabled}
				data-testid={dataTestID}
				getOptionValue={getOptionValue}
				getOptionLabel={getOptionLabel}
				placeholder={comboPlaceholder ? comboPlaceholder : t('Select') + '...'}
				defaultInputValue={defaultInputValue}
				options={options}
				isSearchable={isSearchable}
				noOptionsMessage={() => t('Empty')}
				className="comboBox"
				onChange={handleChange}
				value={inputValue}
				isMulti={isMultiple}
				isClearable={isClearable}
				onMenuOpen={handleOpen}
				tabSelectsValue={true}
				onMenuClose={handleClose}
			/>
		</div>
	);
}
