import React from 'react';
import { useTranslation } from 'react-i18next';
import './TabNextBookings.scss';

const TabNextBookings = ({ bookings, renderBookings }) => {
	const { t } = useTranslation();

	return (
		<div className="next-bookings">
			<h2 style={{ display: 'none' }}>{t('NextBookings')} </h2>
			<div className="card-list-wrapper">{renderBookings(bookings)}</div>
		</div>
	);
};
export default TabNextBookings;
