import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { startRequest } from "@backoffice/actions/apiCall";
import { useMediaQuery } from "react-responsive";
import Uploader from "../../../../../shared/Uploader/Uploader";
import { openModal } from "../../../../../actions/popup";
import { showFixedLabel } from "../../../../../actions/fixedLabel";
import {
  postMediaParams,
  getMediaByIdParams,
  getIncidentDownloadAllParams,
} from "../IncidentsTenantDetailCalls/IncidentsTenantDetailCalls";
import { Button } from "@backoffice/components/FormComponents/index";
import PopUpImage from "./PopUpImage/PopUpImage";
import "./TabGeneral.scss";
import {
  base64ToArrayBuffer,
  saveByteArray,
} from "../../../../../common/utils/parseUtils";

const TabGeneral = ({ incident, incidentId, handleSubmit }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [uploader, setUploader] = useState("");
  const [media, setMedia] = useState([]);
  const [mediaWithBinary, setMediaWithBinary] = useState([]);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1000px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 575px)" });

  useEffect(() => {
    if (incident) {
      setMedia(incident && incident.media ? incident.media : []);
    }
  }, [incident]);

  useEffect(() => {
    if (media.length > 0) {
      media.forEach((x) => {
        getMediaById(x.id);
      });
    }
  }, [media]);

  const getMediaById = (mediaId) => {
    dispatch(
      startRequest(
        getMediaByIdParams(mediaId, incidentId, (response) =>
          getMediaByIdSuccess(response)
        )
      )
    );
  };

  const getMediaByIdSuccess = (response) => {
    setMediaWithBinary((mediaWithBinary) => [...mediaWithBinary, response]);
  };

  const downloadAll = () => {
    dispatch(
      startRequest(
        getIncidentDownloadAllParams(incidentId, (response) =>
          downloadAllSuccess(response)
        )
      )
    );
  };

  const downloadAllSuccess = (response) => {
    var sampleArr = base64ToArrayBuffer(response);
    saveByteArray("zip.zip", sampleArr, "application/zip");
  };

  const getMedia = () => {
    let newMedia = [];
    uploader.filesData.forEach((file) => {
      newMedia.push({
        File: file.rawFile,
      });
    });
    return {
      Id: incident.dynamicId,
      Files: newMedia,
    };
  };

  const postMediaIncident = () => {
    if (uploader && uploader.filesData.length > 0) {
      const mediaData = getMedia();
      dispatch(startRequest(postMediaParams(mediaData, mediaSuccess)));
    }
  };

  const mediaSuccess = (response) => {
    dispatch(showFixedLabel("MediaSaveSuccess", "success"));
    setMediaWithBinary([]);
    handleSubmit();
  };

  const renderMedia = () => {
    let html = [];

    if (media && mediaWithBinary && media.length === mediaWithBinary.length) {
      mediaWithBinary.forEach((md, i) => {
        html.push(
          <div className="media-card" key={i}>
            <div
              className="img-wrapper"
              onClick={() => {
                imageBigger(md);
              }}
            >
              <img
                id={"render-file" + Math.random()}
                key={"render-file" + Math.random()}
                className="img-item-popup"
                aria-hidden={true}
                alt={" "}
                src={
                  md.mediaContentType !== "Document"
                    ? "data:image/jpg;base64," + md.documentBody
                    : md.commonDocumentURL
                }
              />
            </div>
            <div className="media-body-wrapper">
              <div className="media-name">{md.fileName}</div>
            </div>
          </div>
        );
      });
    }

    return html;
  };

  const imageBigger = (md) => {
    const optionValues = {
      id: "imageBigger" + Math.random(),
      dataTestId: "imageBigger" + Math.random(),
      cssClass: "modal-header__add",
      title: t("Multimedia"),
    };

    const options = {
      ...optionValues,
      body: (
        <PopUpImage
          action={"image"}
          optionValues={optionValues}
          md={md}
          incidentId={incidentId}
        />
      ),
    };

    dispatch(openModal(options));
  };

  const renderInfo = () => {
    return (
      <React.Fragment>
        <p className="incident-title">{t("IncidentTitle")}</p>
        <p className="incident-subtitle">{t(incident.title)}</p>
        <p className="incident-title">{t("IncidentType")}</p>
        <p className="incident-subtitle">{t(incident.incidentTypeValue)}</p>
        
        {incident.locationValue && incident.locationValue != "" && (
          <>
            <p className="incident-title">{t("AffectedArea")}</p>
            <p className="incident-subtitle">{incident.locationValue}</p>
          </>
        )}
        <p className="incident-title">{t("Location")}</p>
        <p className="incident-subtitle">{incident.buildingValue}</p>
        <p className="incident-title">{t("Description")}</p>
        <p className="incident-subtitle">{incident.description}</p>
      </React.Fragment>
    );
  };

  const renderStatus = () => {
    return (
      <div className="status-wrapper">
        <div
          className={
            incident.statusCodeValue === "InProgress"
              ? "status progress"
              : incident.statusCodeValue === "Resolved"
              ? "status resolved"
              : "status pending"
          }
          aria-label={t("Status") + ": " + t(incident.statusCodeValue) + "."}
        >
          {t(incident.statusCodeValue)}
        </div>
        <div
          className={
            incident.statusCodeValue === "Pending"
              ? "no-subStatus"
              : "subStatus"
          }
          aria-label={
            t("SubStatus") + ": " + t(incident.clientStatusCodeValue) + "."
          }
        >
          {t(incident.clientStatusCodeValue)}
        </div>
      </div>
    );
  };

  const renderContact = () => {
    return (
      <React.Fragment>
        <h3 className="incident-title">{t("Contact")}</h3>
        <p
          className="incident-subtitle"
          aria-label={t("Phone") + ": " + t(incident.phoneContact) + "."}
        >
          {incident.phoneContact}
        </p>
        <p
          className="incident-subtitle"
          aria-label={t("Email") + ": " + t(incident.emailContact) + "."}
        >
          {incident.emailContact}
        </p>
        <span
          aria-label={
            t("ContactSchedule") + ": " + t(incident.contactScheduleValue) + "."
          }
        >
          <p className="incident-subtitle">
            {t(incident.contactScheduleValue)}
          </p>
        </span>
      </React.Fragment>
    );
  };

  return (
    <div className="tab-general">
      <div className="incident-wrapper">
        {isMobile ? (
          <React.Fragment>
            <div className="col-sm-3">{renderStatus()}</div>
            <div className="col-sm-9">{renderInfo()}</div>
            <div className="col-lg-12">{renderContact()}</div>
          </React.Fragment>
        ) : isTabletOrMobile ? (
          <React.Fragment>
            <div className="row">
              <div className="col-sm-9">{renderInfo()}</div>
              <div className="col-sm-3">{renderStatus()}</div>
            </div>
            <div className="col-lg-12">{renderContact()}</div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="col-lg-3">{renderInfo()}</div>
            <div className="col-lg-3">{renderContact()}</div>
            <div className="right col-lg-3">{renderStatus()}</div>
          </React.Fragment>
        )}
      </div>

      <div className="row">
        <div className="col-lg-10">
          <p className="title">{t("Documents") + " (" + media.length + ")"}</p>
          <p className="subtitle-uploader">
            .pdf, .doc, .docx, .jpg, .png, .bmp, .svg, .tiff
          </p>
        </div>
        <div className="col-lg-2">
          <div className="btn-download-all">
            <Button
              btnText={t("DownloadAll")}
              dataTestID="btnDownloadAll"
              btnType="button"
              icon="download"
              btnClass="btn-base_outline export-excel-button"
              onHandleBtnClick={downloadAll}
            />
          </div>
        </div>
      </div>
      <div className="row">
        {incident.statusCodeValue !== "Resolved" ? (
          <div className="col-lg-6 p-0">
            <div className="uploader-wrapper">
              <Uploader
                media={media}
                isDisabled={false}
                setUploader={setUploader}
                allowedExtensions={
                  ".pdf, .doc, .docx, .jpg, .png, .bmp, .svg, .tiff"
                }
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="col-lg-6">
          <div className="media-wrapper">{renderMedia()}</div>
        </div>
      </div>

      {incident.statusCodeValue !== "Resolved" ? (
        <div className="btn-end">
          <Button
            dataTestID="btnSaveSpace"
            btnText={t("SaveChanges")}
            btnType="button"
            btnClass="btn-base_normal"
            onHandleBtnClick={() => {
              postMediaIncident();
            }}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default TabGeneral;
