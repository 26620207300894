import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Input, Button } from "@backoffice/components/FormComponents/index";
import { closeModal } from "../../../../../actions/popup";
import "./ModalCalendar.scss";

export default function ModalCalendar({ element, action, handleSubmit }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState("");
  const [times, setTimes] = useState({
    startTime: "",
    endTime: "",
    maxTime: "22:00",
  });

  useEffect(() => {
    setStartDate(element.startDate);

    setTimes({
      startTime: element.startTime,
      endTime: element.endTime,
      maxTime: sumHoursToTime(element.startTime, element.maxTime),
    });
  }, []);

  const sumHoursToTime = (time, sum) => {
    let hours = parseInt(time.split(":")[0]) + sum;
    let minutes = time.split(":")[1];

    if (parseInt(hours) > 22) {
      hours = "22";
      minutes = "00";
    } else if (parseInt(hours) == 22 && parseInt(minutes) > 0) {
      minutes = "00";
    }

    let res = hours + ":" + minutes;

    return res;
  };

  const handleStartTimeInput = (newStartTime) => {
    let newMaxTime = sumHoursToTime(newStartTime, element.maxTime);
    let newEndTime = sumHoursToTime(newStartTime, 1);
    
    //Syncfusion Datatimer updates first the value and then the max and the min, soo 
    //if the value is outside the range between the minimum and the maximum 
    //the components breaks, for this reason we initalize he maxTime first
    //to be secure that the components doesn't break
    setTimes((x) => ({ ...x, maxTime: "22:00" }));

    setTimes({
      startTime: newStartTime,
      endTime: newEndTime,
      maxTime: newMaxTime,
    });
  };

  return (
    <div className="modal-calendar">
      <div className="row">
        <p className="date-selected">{t("DateSelected") + ": "}</p>
        <p className="date-selected">{startDate}</p>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <div className="hours-wrapper">
            <Input
              dataTestID="eventTimeStart"
              inputID="eventTimeStart"
              inputName="eventTimeStart"
              inputOutline={true}
              inputType="time"
              inputLabel={t("StartTime")}
              isDisabled={false}
              isError={false}
              value={times.startTime}
              errorMessage={"Error"}
              inputStep={15}
              minTime="08:00"
              maxTime="22:00"
              onHandleChange={(e) => handleStartTimeInput(e.target.value)}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="hours-wrapper">
            <Input
              dataTestID="eventTimeEnd"
              inputID="eventTimeEnd"
              inputName="eventTimeEnd"
              inputOutline={true}
              inputType="time"
              inputLabel={t("EndTime")}
              isDisabled={false}
              isError={false}
              value={times.endTime}
              errorMessage={"Error"}
              inputStep={15}
              minTime={times.startTime}
              maxTime={times.maxTime}
              onHandleChange={(e) =>
                setTimes((x) => ({ ...x, endTime: e.target.value }))
              }
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="btn-end">
          <Button
            dataTestID="btnStep"
            btnText={t("Cancel")}
            btnType="button"
            btnClass="btn-base_outline"
            onHandleBtnClick={() => {
              dispatch(closeModal());
            }}
          />
          <Button
            dataTestID="btnStep"
            btnText={t("Confirm")}
            btnType="button"
            btnClass="btn-base_normal"
            onHandleBtnClick={() => {
              dispatch(closeModal());
              handleSubmit(times.startTime, times.endTime);
            }}
          />
        </div>
      </div>
    </div>
  );
}
