import { ROUTE } from '../common/constants/routes';
import Login from '../views/Pages/Login';
import Logout from '../views/Pages/Logout';
import ResetPassword from '../views/Pages/ResetPassword';
import ConfirmMail from '../views/Pages/ConfirmMail';
import Home from '../views/Layout/Backoffice/Home';
import Profile from '../views/Layout/Backoffice/Profile';
import Users from '../views/Layout/Backoffice/Users';
import UserDetail from '../views/Layout/Backoffice/UserDetail';
import Settings from '../views/Layout/Backoffice/Settings/Settings';
import Bookings from '../views/Layout/Backoffice/Bookings';
import BookingDetail from '../views/Layout/Backoffice/BookingDetail';
import MyBookings from '../views/Layout/Backoffice/MyBookings';
import Spaces from '../views/Layout/Backoffice/Spaces';
import SpacesDetail from '../views/Layout/Backoffice/SpacesDetail';
import Tenants from '../views/Layout/Backoffice/Tenants';
import TenantDetail from '../views/Layout/Backoffice/TenantDetail';
import Buildings from '../views/Layout/Backoffice/Buildings';
import BuildingDetail from '../views/Layout/Backoffice/BuildingDetail';
import News from '../views/Layout/Backoffice/News';
import NewDetail from '../views/Layout/Backoffice/NewDetail';

import HomeTenant from '../views/Layout/Tenant/HomeTenant';
import BuildingTenant from '../views/Layout/Tenant/BuildingTenant';
import IncidentsTenant from '../views/Layout/Tenant/IncidentsTenant';
import IncidentsTenantCreate from '../views/Layout/Tenant/IncidentsTenantCreate';
import IncidentsTenantDetail from '../views/Layout/Tenant/IncidentsTenantDetail';
import NewsTenant from '../views/Layout/Tenant/NewsTenant';
import NewsTenantDetail from '../views/Layout/Tenant/NewsTenantDetail/NewsTenantDetail';
import ConfigurationTenant from '../views/Layout/Tenant/ConfigurationTenant';
import BookingTenant from '../views/Layout/Tenant/BookingTenant/BookingTenant';
import BookingTenantDetail from '../views/Layout/Tenant/BookingTenantDetail/BookingTenantDetail';
import BookingTenantCreate from '../views/Layout/Tenant/BookingTenantCreate/BookingTenantCreate';
import ServicesTenant from '../views/Layout/Tenant/ServicesTenant/ServicesTenant';
import ServicesTenantDetail from '../views/Layout/Tenant/ServicesTenantDetail/ServicesTenantDetail';
import ContactsTenant from '../views/Layout/Tenant/ContactsTenant/ContactsTenant';
import ContactsTenantDetail from '../views/Layout/Tenant/ContactsTenantDetail/ContactsTenantDetail';
import ProfileTenant from '../views/Layout/Tenant/ProfileTenant/ProfileTenant';
import DocumentsTenant from '../views/Layout/Tenant/DocumentsTenant/DocumentsTenant';

import HomeProvider from '../views/Layout/Provider/HomeProvider';
import IncidentsProvider from '../views/Layout/Provider/IncidentsProvider/IncidentsProvider';
import IncidentsProviderDetail from '../views/Layout/Provider/IncidentsProviderDetail/IncidentsProviderDetail';
import ConfigurationProvider from '../views/Layout/Provider/ConfigurationProvider/ConfigurationProvider';

const publicRoutes = [];
publicRoutes[ROUTE.ROOT] = Login;
publicRoutes[ROUTE.RESETPASSWORD] = ResetPassword;
publicRoutes[ROUTE.LOGIN] = Login;
publicRoutes[ROUTE.LOGOUT] = Logout;
publicRoutes[ROUTE.CONFIRMEMAIL] = ConfirmMail;

const privateRoutes = [];
privateRoutes[ROUTE.HOME] = Home;
privateRoutes[ROUTE.USERS] = Users;
privateRoutes[ROUTE.USERDETAIL] = UserDetail;
privateRoutes[ROUTE.PROFILE] = Profile;
privateRoutes[ROUTE.SETTINGS] = Settings;
privateRoutes[ROUTE.NEWS] = News;
privateRoutes[ROUTE.NEWSDETAIL] = NewDetail;
privateRoutes[ROUTE.BUILDINGS] = Buildings;
privateRoutes[ROUTE.BUILDINGSDETAIL] = BuildingDetail;
privateRoutes[ROUTE.SPACES] = Spaces;
privateRoutes[ROUTE.SPACESDETAIL] = SpacesDetail;
privateRoutes[ROUTE.TENANTS] = Tenants;
privateRoutes[ROUTE.TENANTSDETAIL] = TenantDetail;
privateRoutes[ROUTE.BOOKINGS] = Bookings;
privateRoutes[ROUTE.MYBOOKINGS] = MyBookings;
privateRoutes[ROUTE.BOOKINGDETAIL] = BookingDetail;

privateRoutes[ROUTE.HOMETENANT] = HomeTenant;
privateRoutes[ROUTE.NEWSTENANT] = NewsTenant;
privateRoutes[ROUTE.NEWSTENANTDETAIL] = NewsTenantDetail;
privateRoutes[ROUTE.CONFIGURATIONTENANT] = ConfigurationTenant;
privateRoutes[ROUTE.BOOKINGSTENANT] = BookingTenant;
privateRoutes[ROUTE.BOOKINGSTENANTCREATE] = BookingTenantCreate;
privateRoutes[ROUTE.BOOKINGSTENANTDETAIL] = BookingTenantDetail;
privateRoutes[ROUTE.BUILDINGTENANT] = BuildingTenant;
privateRoutes[ROUTE.INCIDENTSTENANT] = IncidentsTenant;
privateRoutes[ROUTE.INCIDENTSTENANTCREATE] = IncidentsTenantCreate;
privateRoutes[ROUTE.INCIDENTSTENANTCREATECUSTOM] = IncidentsTenantCreate;
privateRoutes[ROUTE.INCIDENTSTENANTDETAIL] = IncidentsTenantDetail;
privateRoutes[ROUTE.CONTACTSTENANT] = ContactsTenant;
privateRoutes[ROUTE.CONTACTSTENANTDETAIL] = ContactsTenantDetail;
privateRoutes[ROUTE.SERVICESTENANT] = ServicesTenant;
privateRoutes[ROUTE.SERVICESTENANTDETAIL] = ServicesTenantDetail;
privateRoutes[ROUTE.PROFILETENANT] = ProfileTenant;
privateRoutes[ROUTE.DOCUMENTSTENANT] = DocumentsTenant;

privateRoutes[ROUTE.HOMEPROVIDER] = HomeProvider;
privateRoutes[ROUTE.INCIDENTSPROVIDER] = IncidentsProvider;
privateRoutes[ROUTE.INCIDENTSPROVIDERDETAIL] = IncidentsProviderDetail;
privateRoutes[ROUTE.CONFIGURATIONPROVIDER] = ConfigurationProvider;


export { publicRoutes, privateRoutes };
