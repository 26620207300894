const ApiVersion = process.env.REACT_APP_API_VERSION;
const StripePublishableKey = process.env.REACT_APP_STRIPE_KEY;

const getEnvWebUrl = () => {
	let envWebUrl = process.env.REACT_APP_WEB_URL;
	return envWebUrl;
};

const getEnvApiUrl = () => {
	let envApiUrl = process.env.REACT_APP_API_URL;
	return envApiUrl;
};

const initConsole = () => {
	if (process.env.NODE_ENV === 'development') {
		console.log('host::' + window.location.hostname);
		console.log('api::' + getEnvApiUrl());
		console.log('api_version::' + ApiVersion);
	}
};

export { getEnvWebUrl, getEnvApiUrl, initConsole, StripePublishableKey, ApiVersion };
