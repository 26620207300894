import Cookies from 'js-cookie';
import { COOKIES_CONSTANTS } from '../constants/cookies';
import moment from 'moment';

const SaveCookies = (cookieName, cookieValue) => {
	let expireDate = moment().add(2, 'days');
	Cookies.set(cookieName, cookieValue, { expires: 1 });
	Cookies.set(cookieName + 'EXP', expireDate, { expires: 1 });
};

const GetCookieAsParsedJSON = (cookieName, getDefaultValue) => {
	const cookieValue = Cookies.get(cookieName);
	if (cookieValue) {
		return JSON.parse(cookieValue);
	}
	return getDefaultValue();
};

const GetCookie = (cookieName) => {
	return Cookies.get(cookieName) ? Cookies.get(cookieName) : '';
};

const GetCookie_token = () => {
	return Cookies.get(COOKIES_CONSTANTS.TOKEN);
};

const GetCookie_token_exp = () => {
	return Cookies.get(COOKIES_CONSTANTS.TOKEN + 'EXP');
};

const GetCookie_formatDate = () => {
	return Cookies.get(COOKIES_CONSTANTS.DATEFORMAT) ? Cookies.get(COOKIES_CONSTANTS.DATEFORMAT) : 'DD/MM/YYYY';
};

const GetCookie_culture = () => {
	return Cookies.get(COOKIES_CONSTANTS.CULTURE) ? Cookies.get(COOKIES_CONSTANTS.CULTURE) : 'es';
};

const GetCookie_weekStart = () => {
	return Cookies.get(COOKIES_CONSTANTS.WEEKSTART) ? parseInt(Cookies.get(COOKIES_CONSTANTS.WEEKSTART)) : 1;
};

const GetCookie_refreshToken = () => {
	return Cookies.get(COOKIES_CONSTANTS.REFRESHTOKEN) ? Cookies.get(COOKIES_CONSTANTS.REFRESHTOKEN) : '';
};

const GetCookie_language = () => Cookies.get(COOKIES_CONSTANTS.LANG);

const DeleteCookies = (cookieName) => {
	Cookies.remove(cookieName);
};

const DeleteAllCookies = () => {
	Object.keys(Cookies.get()).forEach(function(cookieName) {
		if (cookieName !== COOKIES_CONSTANTS.CONSENT_COOKIES) {
			Cookies.remove(cookieName);
		}
	});
};

export {
	SaveCookies,
	GetCookie,
	GetCookie_weekStart,
	GetCookie_token,
	GetCookie_token_exp,
	GetCookie_formatDate,
	GetCookie_culture,
	GetCookie_refreshToken,
	GetCookie_language,
	DeleteAllCookies,
	DeleteCookies,
	GetCookieAsParsedJSON
};
