const getNewsParams = (SuccessFn) => {
	let endpoint = '/TenantNews/GetAllNews';
	endpoint = endpoint + '?Pagination.Skip=0&Pagination.Take=1000';
	const options = {
		method: 'GET',
		endpoint: endpoint,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export { getNewsParams };
