import React, { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Layout from '../../../views/Layout/Layout';
import ErrorPage from '../../../views/Pages/ErrorPage/ErrorPage';
import { GetCookie_token, DeleteAllCookies } from '../../../common/utils/cookiesManager';
import { DeleteSessionStorage } from '../../../common/utils/storageManager';
import { changeErrorPageNumber } from '../../../actions/error';
import { GetMenuSessionStorage } from '../../../common/utils/storageManager';
import { startRequest } from '../../../actions/apiCall';
import { getCheckAuthParams } from './PrivateRouteCalls';

const PrivateRoute = ({ path, Component, ...rest }) => {
	const dispatch = useDispatch();
	const [ authorized, setAuthorized ] = useState(false);

	useEffect(() => {
		dispatch(changeErrorPageNumber(0));
		checkAuth();
	}, []);

	const getPathId = () => {
		let foundId;
		const menuStorage = GetMenuSessionStorage();
		if (menuStorage !== null) {
			menuStorage.forEach((menuItem) => {
				if (menuItem.subMenus && menuItem.subMenus.length !== 0) {
					menuItem.subMenus.forEach((subMenuItem) => {
						if (path.toLowerCase().includes(subMenuItem.link.toLowerCase())) {
							foundId = subMenuItem.id;
						}
					});
				} else {
					if (path.toLowerCase().includes(menuItem.link.toLowerCase())) {
						foundId = menuItem.id;
					}
				}
			});
		}
		return foundId;
	};

	const checkAuth = () => {
		const cookie = GetCookie_token();
		
		setAuthorized(true); //testing remove commented code below before flight TODO

		// if (cookie !== null) {
		// 	const idOfLink = getPathId();
		// 	const data = { idOfLink };
		// 	if (data.idOfLink === undefined) {
		// 		myError({ status: 401 });
		// 	} else {
		// 		dispatch(startRequest(getCheckAuthParams(data, mySuccess, myError)));
		// 	}
		// } else {
		// 	myError({ status: 401 });
		// }
	};

	const mySuccess = (response) => {
		setAuthorized(response.data);
		if (!response.data) {
			DeleteAllCookies();
			DeleteSessionStorage();
		}
	};

	const myError = (error) => {
		setAuthorized(false);
		DeleteAllCookies();
		DeleteSessionStorage();
		if (error) {
			dispatch(changeErrorPageNumber(error.status));
		}
	};

	return (
		<Route
			{...rest}
			render={() => {
				if (authorized) {
					return (
						<Layout path={path}>
							<Component />
						</Layout>
					);
				} else {
					return (
						<Layout path={path}>
							<ErrorPage pageNotFound={false} />
						</Layout>
					);
				}
			}}
		/>
	);
};

export default PrivateRoute;
