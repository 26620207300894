const getProfileParams = (SuccessFn) => {
	let endpoint = '/TenantProfile';
	const options = {
		method: 'GET',
		endpoint,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const postProfileChangePassword = (data, ErrorFn) => {
	const options = {
		method: 'POST',
		data,
		endpoint : '/Account/ChangePassword',
		onError: (response) => {
			ErrorFn(response.data.data);
		}
	};
	return options;
};

export { getProfileParams, postProfileChangePassword };
