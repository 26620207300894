const getSpacesParams = (params, allowAll, SuccessFn) => {
  let endpoint = "/Space/GetAll";
  if (params.filters) {
    if (!allowAll) {
      endpoint =
        endpoint +
        "?Filters.BuildingId=" +
        params.filters.buildingId +
        "&Filters.ForBooking=true";
    } else {
      endpoint = endpoint + "?Filters.BuildingId=" + params.filters.buildingId;
    }
  }
  const options = {
    method: "GET",
    endpoint: endpoint,
    onSuccess: (response) => {
      SuccessFn(response.data.data);
    },
  };
  return options;
};

const getSpacesByIdParams = (id, SuccessFn) => {
  const options = {
    method: "GET",
    endpoint: "/Space/" + id,
    onSuccess: (response) => {
      SuccessFn(response.data.data);
    },
  };
  return options;
};

const getSpacesEventsByIdParams = (params, SuccessFn) => {
  const options = {
    method: "GET",
    params,
    endpoint: "/BookingSpace/GetEvents",
    onSuccess: (response) => {
      SuccessFn(response.data.data);
    },
  };
  return options;
};

const postSpaceParams = (data, SuccessFn) => {
  const options = {
    method: "POST",
    data,
    endpoint: "/Space",
    onSuccess: (response) => {
      SuccessFn(response.data.data);
    },
  };
  return options;
};

const putSpaceParams = (data, SuccessFn) => {
  const options = {
    method: "PUT",
    data,
    endpoint: "/Space",
    onSuccess: (response) => {
      SuccessFn(response.data.data);
    },
  };
  return options;
};

const getBuildingsParams = (SuccessFn) => {
  const options = {
    method: "GET",
    endpoint: "/Building/GetNameId",
    onSuccess: (response) => {
      SuccessFn(response.data.data);
    },
  };
  return options;
};

const getSpaceTypesParams = (SuccessFn) => {
  const options = {
    method: "GET",
    endpoint: "/Tipology/SpaceType",
    onSuccess: (response) => {
      SuccessFn(response.data.data);
    },
  };
  return options;
};

const deleteSpaceParams = (params, SuccessFn) => {
  const options = {
    method: "DELETE",
    params,
    endpoint: "/Space",
    onSuccess: (response) => {
      SuccessFn(response.data.data);
    },
  };
  return options;
};

const postMediaParams = (data, funcSucc) => {
  const options = {
    method: "POST",
    data,
    contentType: "multipart/form-data",
    endpoint: "/Space/CreateMultipleMedia",
    onSuccess: (response) => {
      funcSucc(response.data.data);
    },
  };
  return options;
};

const deleteMediaParams = (params, funcSucc, funcError) => {
  const options = {
    method: "DELETE",
    params,
    endpoint: "/Space/DeleteMedia",
    onSuccess: (response) => {
      funcSucc(response.data.data);
    },
  };
  return options;
};

export {
  getSpacesParams,
  postSpaceParams,
  putSpaceParams,
  postMediaParams,
  deleteMediaParams,
  getSpacesByIdParams,
  getSpacesEventsByIdParams,
  getBuildingsParams,
  getSpaceTypesParams,
  deleteSpaceParams,
};
