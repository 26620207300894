const postConfirmMailParams = (data, funcSucc, funcErr) => {
	const options = {
		method: 'POST',
		data,
		endpoint: '/Account/ConfirmEmailPassword',
		onSuccess: (response) => {
			funcSucc(response.data.data);
		},
		onError: (error) => {
			funcErr(error);
		}
	};
	return options;
};

export { postConfirmMailParams };
