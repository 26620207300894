const START_REQUEST = 'START_REQUEST';
const END_REQUEST = 'END_REQUEST';

let defaultConst = {
	START_REQUEST,
	END_REQUEST
};

export default defaultConst;
export const startRequest = (options) => ({
	type: START_REQUEST,
	requestOptions: options
});
export const endRequest = (options) => ({ type: END_REQUEST, requestOptions: options });
