import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { startRequest } from "@backoffice/actions/apiCall";
import { useTranslation } from "react-i18next";
import {
  Input,
  Button,
  Combo,
  Checkbox,
} from "@backoffice/components/FormComponents/index";
import { RichText } from "../../../../shared";
import { getBuildingsParams } from "../SpacesDetail/SpacesDetailCalls/SpacesDetailCalls";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  GetCookie_culture,
  GetCookie_formatDate,
  GetCookie_weekStart,
} from "../../../../common/utils/cookiesManager";
import {
  parseDate,
  parseDateTimeBE,
} from "../../../../common/utils/parseUtils";
import { ROUTE } from "../../../../common/constants/routes";
import FilePreview from "../../../../shared/FilePreview/FilePreview";
import { openModal, closeModal } from "../../../../actions/popup";
import { showFixedLabel } from "../../../../actions/fixedLabel";
import {
  getNewURLImageParams,
  getNewByIdParams,
  postNewParams,
  putNewParams,
} from "./NewDetailCalls/NewDetailCalls";
import { ApiVersion, getEnvApiUrl } from "../../../../services/config";
import PopUpImage from "./PopUpImage";
import PopUpPreview from "./PopUpPreview/PopUpPreview";
import "./NewDetail.scss";

const NewDetail = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [title, setTitle] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [newId, setNewId] = useState(id);
  const [building, setBuilding] = useState("");
  const [scheduleNew, setScheduleNew] = useState(false);
  const [fixedNew, setFixedNew] = useState(false);
  const [hideNew, setHideNew] = useState(false);
  const [buildingOptions, setBuildingOptions] = useState([]);
  const [startTime, setStartTime] = useState("");
  const [date, setDate] = useState("");
  const [content, setContent] = useState("");
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [thumbNailImgUrl, setThumbNailImgUrl] = useState("");
  const [newsUrlImage, setNewsUrlImage] = useState("");

  useEffect(() => {
    getNewsUrlImage();
  }, []);

  useEffect(() => {
    if (newId !== "0") {
      getNewById();
    }
  }, [buildingOptions]);

  const getNewsUrlImage = () => {
    dispatch(startRequest(getNewURLImageParams(getNewsUrlImageSuccess)));
  };

  const getNewsUrlImageSuccess = (response) => {
    setNewsUrlImage(response);
    getBuildings();
  };

  const getBuildings = () => {
    dispatch(startRequest(getBuildingsParams(getBuildingsSuccess)));
  };

  const getBuildingsSuccess = (response) => {
    setBuildingOptions(response);
  };

  const getNewById = () => {
    dispatch(startRequest(getNewByIdParams(newId, getNewByIdSuccess)));
  };

  const getNewByIdSuccess = (response) => {
    let buildingsSelected = buildingOptions.filter((x) =>
      response.buildings.includes(x.id)
    );
    setContent(response.content);
    setDate(parseDate(response.date));
    setFixedNew(response.fixNew);
    setHideNew(response.hideNew);
    setStartTime(response.time);
    setShortDescription(response.shortDescription);
    setTitle(response.title);
    setScheduleNew(response.scheduleNew);
    if (response.media) {
      setImgUrl(response.media.mediaUrl);
      setThumbNailImgUrl(response.media.mediaThumbnailUrl);
    }
    setBuilding(buildingsSelected);
  };

  const getNew = () => {
    let buildingIds = [];
    if (building.length > 0) {
      building.forEach((building) => {
        buildingIds.push(building.id);
      });
    }

    let dateMidleware = date == "Invalid date" ? "" : date;

    return {
      Id: newId,
      Buildings: buildingIds,
      Title: title,
      ShortDescription: shortDescription,
      Content: content,
      File: file,
      ScheduleNew: scheduleNew,
      FixNew: fixedNew,
      HideNew: hideNew,
      Date: parseDateTimeBE(dateMidleware + " " + startTime, true),
      // Time: startTime
    };
  };

  const postNew = () => {
    let newData = getNew();
    if (newId === "0") {
      dispatch(startRequest(postNewParams(newData, postNewSuccess)));
    } else {
      dispatch(startRequest(putNewParams(newData, postNewSuccess)));
    }
  };

  const postNewSuccess = (response) => {
    history.push(ROUTE.NEWS);
    if (newId === "0") {
      dispatch(showFixedLabel(t("NewAddSuccess"), "success"));
    } else {
      dispatch(showFixedLabel(t("NewEditSuccess"), "success"));
    }
  };

  const imageBigger = (file, url) => {
    const optionValues = {
      id: "imageBigger" + Math.random(),
      dataTestId: "imageBigger" + Math.random(),
      cssClass: "modal-header__add",
      title: t("Multimedia"),
    };

    const options = {
      ...optionValues,
      body: (
        <PopUpImage
          action={"image"}
          optionValues={optionValues}
          file={file}
          url={url}
        />
      ),
    };

    dispatch(openModal(options));
  };

  const preview = () => {
    let newDetail = {
      title: title,
      shortDescription: shortDescription,
      imgUrl: imgUrl,
      content: content,
    };

    const optionValues = {
      id: "preview-" + Math.random(),
      dataTestId: "preview-" + Math.random(),
      cssClass: "modal-header__add",
      // title: t('Preview')
    };

    const options = {
      ...optionValues,
      body: (
        <PopUpPreview
          optionValues={optionValues}
          newDetail={newDetail}
          closePopUp={() => dispatch(closeModal())}
        />
      ),
    };

    dispatch(openModal(options));
  };

  const validateForm = () => {
    let formOK = true;
    if (
      building === "" ||
      title === "" ||
      shortDescription === "" ||
      content === null ||
      (content !== null && content === "") ||
      (scheduleNew && startTime === "") ||
      (scheduleNew && date === "")
    ) {
      formOK = false;
    }
    return !formOK;
  };

  return (
    <div className="new-detail">
      <div className="return">
        <div className="col-lg-2 offsett-lg-10">
          <Link className="go-back" to={ROUTE.NEWS} role="link">
            <span
              className="material-icons-round"
              data-testid={"btn-goback"}
              id={"btn-goback"}
              aria-hidden={true}
            >
              arrow_back
            </span>
            <div className="go-back__text">{t("GoBack")}</div>
          </Link>
        </div>
      </div>
      <div className="new-detail-form">
        <h2 className="title">
          {newId === "0" ? t("CreateNew") : t("EditNew")}
        </h2>

        <div className="row">
          <div className="col-lg-6">
            <Combo
              id="cmbBuilding"
              dataTestID="cmbBuilding"
              comboLabel={t("Building")}
              inputValue={building}
              comboPlaceholder={t("Select") + " " + t("Building") + "..."}
              defaultInputValue=""
              isRequired={true}
              isSearchable={false}
              getOptionValue={(option) => option["id"]}
              getOptionLabel={(option) => t(option["name"])}
              options={buildingOptions}
              isMultiple={true}
              handleChange={(e) => {
                setBuilding(e);
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <Input
              dataTestID="txtTitle"
              inputName="txtTitle"
              inputType="text"
              inputLabel={t("Title")}
              inputID="new-title"
              isDisabled={false}
              onHandleChange={(e) => {
                let val = e.currentTarget.value;
                setTitle(val);
              }}
              value={title}
              isRequired={true}
            />
          </div>
          <div className="col-lg-6">
            <Input
              dataTestID="txtShortDescription"
              inputName="txtShortDescription"
              inputType="text"
              inputLabel={t("ShortDescription")}
              inputID="new-shortDescription"
              isDisabled={false}
              onHandleChange={(e) => {
                let val = e.currentTarget.value;
                setShortDescription(val);
              }}
              value={shortDescription}
              isRequired={true}
            />
          </div>
        </div>
        <h4 className="subtitle">
          {t("FeaturedImage")}
          <sup className="required">*</sup>
        </h4>
        <div className="filePreview-wrapper">
          {file !== "" || imgUrl !== "" ? (
            <div
              className="img-wrapper"
              onClick={() => imageBigger(file, imgUrl)}
            >
              <FilePreview
                file={file}
                url={thumbNailImgUrl}
                fileType={"Image"}
              />
            </div>
          ) : (
            <div className="img-wrapper empty">
              <FilePreview
                file={""}
                url={process.env.PUBLIC_URL + "/empty-image.png"}
                fileType={"Image"}
              />
            </div>
          )}
          <div className="button-upload m-2">
            <input
              type="file"
              id="btnLoadFile"
              data-testid="btnLoadFile"
              onChange={(e) => {
                setFileName(e.target.files[0].name);
                setFile(e.target.files[0]);
              }}
              accept="image/*"
              name="btnLoad"
              className="inputfile"
            />
            <div className="file-name text-center">{fileName}</div>
            <label htmlFor="btnLoadFile" className="btn-base_normal">
              <i className="material-icons-round">file_upload</i>
              <p>
                {file || imgUrl !== "" ? t("ChangeImage") : t("UploadImage")}
              </p>
            </label>
          </div>
        </div>
        <div className="rich-text">
          <div className="col-lg-12">
            {newsUrlImage !== "" ? (
              <RichText
                id={"new-content"}
                uploadImage={true}
                imageSettings={{
                  saveUrl: `${getEnvApiUrl()}${ApiVersion}/News/SaveFile`,
                  path: newsUrlImage,
                }}
                name={"new-content"}
                placeholder={t("Content")}
                enableResize={true}
                isRequired={false}
                isReadOnly={false}
                isDisabled={false}
                onChange={setContent}
                value={content}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="row">
          <div className="scheduleNew-check">
            <Checkbox
              inputID={"chck-schedule-new"}
              inputName={"chck-schedule-new"}
              inputLabel={t("ScheduleNew")}
              inputChecked={scheduleNew}
              onHandleChange={(e) => {
                setScheduleNew(e.target.checked);
                setDate("");
                setStartTime("");
              }}
            />
          </div>
          <div className="fixedNew-check">
            <Checkbox
              inputID={"chck-fixed-new"}
              inputName={"chck-fixed-new"}
              inputLabel={t("FixedNew")}
              inputChecked={fixedNew}
              onHandleChange={(e) => {
                setFixedNew(e.target.checked);
              }}
            />
          </div>
          <div className="hideNew-check">
            <Checkbox
              inputID={"chck-hide-new"}
              inputName={"chck-hide-new"}
              inputLabel={t("HideNew")}
              inputChecked={hideNew}
              onHandleChange={(e) => {
                setHideNew(e.target.checked);
              }}
            />
          </div>
        </div>
        {scheduleNew ? (
          <div className="row">
            <div className="col-lg-3">
              <div className="input-container">
                <label
                  className="inputLabel label-date"
                  htmlFor={"txtBookingDate"}
                >
                  {t("Date") + ":"}
                </label>
                <div
                  data-testid="txtBookingDate"
                  className="date-wrapper readonly"
                >
                  <DatePickerComponent
                    showTodayButton={false}
                    locale={GetCookie_culture()}
                    firstDayOfWeek={GetCookie_weekStart()}
                    dataTestID="txtBookingDate"
                    format={GetCookie_formatDate()}
                    value={date}
                    allowEdit={false}
                    readonly={false}
                    showClearButton={true}
                    openOnFocus={false}
                    cleared={() => {
                      setDate("");
                    }}
                    onChange={(e) => {
                      setDate(parseDate(e.value));
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="hours-wrapper">
                <Input
                  inputID="startTime"
                  inputName="startTime"
                  inputOutline={true}
                  inputType="time"
                  inputLabel={t("Hour")}
                  isRequired={true}
                  isDisabled={false}
                  isError={false}
                  value={startTime}
                  inputStep={30}
                  minTime={"8:00"}
                  maxTime={"22:00"}
                  onHandleChange={(e) => setStartTime(e.target.value)}
                />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="btn-end">
        <Button
          btnText={t("Preview")}
          btnType="button"
          btnClass="btn-base_outline export-excel-button"
          dataTestID="export-excel-button"
          onHandleBtnClick={() => {
            preview();
          }}
        />
        <Button
          dataTestID="btnStep"
          btnText={t("Publish")}
          btnType="button"
          btnClass="btn-base_normal"
          isDisabled={validateForm()}
          onHandleBtnClick={() => {
            postNew();
          }}
        />
      </div>
    </div>
  );
};
export default NewDetail;
