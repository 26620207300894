import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { GetSessionStorage } from '@backoffice/common/utils/storageManager';
import { startRequest } from '../../../../actions/apiCall';
import { getServiceTypesParams, getServicesParams } from './ServicesTenantCalls';
import './ServicesTenant.scss';
import { ROUTE } from '../../../../common/constants/routes';
import { Link } from 'react-router-dom';

const ServicesTenant = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [serviceTypeSelected, setServiceTypeSelected] = useState('');
	const [serviceTypes, setServiceTypes] = useState([]);
	const [services, setServices] = useState([]);
	const [buildingId, setBuildingId] = useState(GetSessionStorage('PROFILE_').buildingId);

	useEffect(() => {
		getServiceType();
	}, []);

	useEffect(() => {
		if (serviceTypeSelected !== '') {
			getServices();
		}
	}, [serviceTypeSelected]);

	const getServiceType = () => {
		dispatch(startRequest(getServiceTypesParams(getServiceTypeSuccess)));
	};

	const getServiceTypeSuccess = (response) => {
		setServiceTypes(response);
		getServices();
	};

	const getServices = () => {
		dispatch(startRequest(getServicesParams(buildingId, serviceTypeSelected.id, getServicesSuccess)));
	};

	const getServicesSuccess = (response) => {
		setServices(response);
	};

	const renderTypeCard = () => {
		let html = [];
		if (serviceTypes.length > 0) {
			serviceTypes.forEach((service, i) => {
				html.push(
					<a
						href="#"
						className="service-type-item-wrapper"
						key={i + '-service-type'}
						onClick={() => {
							setServiceTypeSelected(service);
						}}
					>
						<div
							className={service.name === 'ServiceBuildingTypeFeatured' ? 'service-type-item featured' : 'service-type-item'}
						>
							<span className="icon" aria-hidden={true}>
								<span className="material-icons">{service.value}</span>
							</span>
						</div>
						<div className="type-name">{t(service.name)}</div>
					</a>
				);
			});
			return html;
		}
	};

	const renderServices = () => {
		let html = [];
		if (services.length > 0) {
			services.forEach((service, i) => {
				html.push(
					<Link
						className="col-md-5-service-tenant"
						key={i + '-contact'}
						type={'button'}
						to={ROUTE.SERVICESTENANTDETAIL.replace(':id', service.serviceId)}
					>
						<div className="service-wrapper">
							{service.media ? (
								<div
									className="service-img"
									style={{
										background: 'url(' + service.media.mediaUrl + ')',
										backgroundPosition: 'center',
										backgroundRepeat: 'no-repeat',
										backgroundSize: 'cover'
									}}
								/>
							) : (
								''
							)}
							<div className="service-card">
								<h3 className="service-name">{service.name}</h3>
								<div
									className="service-address"
									aria-label={t('Address') + ': ' + service.address + '.'}
								>
									<span aria-hidden={true}>{service.address}</span>
								</div>
								<div
									className="service-phone"
									aria-label={t('Phone') + ': ' + service.phoneNumber + '.'}
								>
									<span aria-hidden={true}>{service.phoneNumber}</span>
								</div>
							</div>
						</div>
					</Link>
				);
			});
			return html;
		} else {
			html.push(
				<p className="description" key={'empty-service'}>
					{t('EmptyServicesCategory')}
				</p>
			);
			return html;
		}
	};

	return (
		<div className="services-tenant">
			{serviceTypeSelected === '' ? (
				<React.Fragment>
					<h1 className="title-type">{t('Services')}</h1>
					{/* <p className="description">{t('Description') + '...'}</p> */}
					<h2 aria-label={t('SelectServiceCategory')} style={{ display: 'none' }}>
						{t('SelectServiceCategory')}
					</h2>

					<div className="service-type-wrapper">{renderTypeCard()}</div>
				</React.Fragment>
			) : (
				<React.Fragment>
					<div className="return">
						<div className="col-lg-2 offsett-lg-10">
							<Link
								className="go-back"
								role="link"
								onClick={() => {
									setServices([]);
									setServiceTypeSelected('');
								}}
							>
								<span
									className="material-icons-round"
									data-testid={'btn-goback'}
									id={'btn-goback'}
									aria-hidden={true}
								>
									arrow_back
								</span>
								<div className="go-back__text">{t('GoBack')}</div>
							</Link>
						</div>
					</div>
					<h3 className="title">{t(serviceTypeSelected.name)}</h3>
					<div className="services-wrapper">{renderServices()}</div>
				</React.Fragment>
			)}
		</div>
	);
};

export default ServicesTenant;
