import React, { useState, useEffect } from "react";
import i18n from "i18next";
import { useDispatch, useSelector } from "react-redux";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { GetCookie_culture } from "../../common/utils/cookiesManager";
import { loadLanguage } from "../../locales/SyncfusionTranslationService";
import {
  changeLanguage,
  changeFormat,
  changeWeekStart,
} from "../../actions/language";
import { postChangeUserLanguageParams } from "./TranslationButtonCalls/TranslationButtonCalls";
import "./TranslationButton.scss";
import { startRequest } from "../../actions/apiCall";

export function TranslationButton({ language }) {
  useEffect(() => {
    if (language) {
      changeCulture(language);
    }
  }, []);

  const [anchorEl, setAnchorEl] = useState(false);

  const [menu, setMenu] = useState([
    { name: "Español", value: "es", flag: "spa.png" },
    { name: "English", value: "en", flag: "eng.png" },
    { name: "Català", value: "ca", flag: "cat.png" },
  ]);

  const [anchorElOpen, setAnchorElOpen] = useState(false);
  const dispatch = useDispatch();

  const handleChangeAnchor = (event) => {
    setAnchorEl(event.currentTarget);
    setAnchorElOpen(true);
  };

  const handleCloseAnchor = (lng) => {
    if (lng === "es" || lng === "en" || lng === "ca") {
      changeCulture(lng);
      dispatch(
        startRequest(
          postChangeUserLanguageParams({ value: lng })
        )
      );
    }
    setAnchorEl(null);
    setAnchorElOpen(false);
  };

  const changeCulture = (lng) => {
    i18n.changeLanguage(lng);
    dispatch(changeLanguage(lng));
    loadLanguage();
    let newFormat = "";
    let newWeekStart = "";
    switch (lng) {
      case "es":
        newFormat = "DD/MM/YYYY";
        newWeekStart = 1;
        break;
      case "en":
        newFormat = "MM/DD/YYYY";
        newWeekStart = 0;
        break;
      case "ca":
        newFormat = "DD/MM/YYYY";
        newWeekStart = 1;
        break;
      default:
        newFormat = "DD/MM/YYYY";
        break;
    }
    dispatch(changeFormat(newFormat));
    dispatch(changeWeekStart(newWeekStart));
  };

  const renderProfileMenuItems = () => {
    let html = [];
    menu.forEach((menuElement, i) => {
      html.push(
        <MenuItem
          key={"profileMenu-" + i}
          onClick={() => handleCloseAnchor(menuElement.value)}
          className={
            menuElement.value === GetCookie_culture() ? "active-menu" : ""
          }
        >
          {/* <img className="flag-img" src={'/' + menuElement.flag} alt={'flag-img'} aria-hidden={true}/> */}
          {menuElement.name}
        </MenuItem>
      );
    });

    return html;
  };

  const getLanguage = () => {
    let language = "Español";
    const lang = GetCookie_culture() ? GetCookie_culture() : "es";
    menu.forEach((element) => {
      if (element.value === lang) {
        language = element;
      }
    });
    return language;
  };

  return (
    <div className="translationButton">
      <p>{language}</p>
      <div
        className="translate-wrapper"
        type={"button"}
        onClick={handleChangeAnchor}
        id="translationButton"
      >
        {/* <img className="flag-img" src={'/' + getLanguage().flag} alt={'flag-img'} /> */}
        <span className="initials" aria-hidden={true}>
          {getLanguage().value.toUpperCase()}
        </span>
        <span className="value">{getLanguage().name}</span>
        <span className="material-icons-round" aria-hidden={true}>
          keyboard_arrow_down
        </span>
      </div>
      <Menu
        id="menu-appbar2"
        anchorEl={anchorEl}
        keepMounted
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={anchorElOpen}
        onClose={handleCloseAnchor}
      >
        {renderProfileMenuItems()}
      </Menu>
    </div>
  );
}

export default TranslationButton;
