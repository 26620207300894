const getBuildingDocumentsByIdParams = (id, SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Building/GetHomeDocuments/' + id,
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getHouseDocumentsByIdParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Building/GetHomeDocuments/',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const downloadDocumentByIdParams = (documentId, buildingId, SuccessFn, ErrorFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Building/DownloadDocument/' + documentId + '/' + buildingId,
		onSuccess: (response) => {
			SuccessFn(response.data);
		},
		onError: (response) => {
			ErrorFn(response.data);
		}
	};
	return options;
};

export { getBuildingDocumentsByIdParams, getHouseDocumentsByIdParams, downloadDocumentByIdParams };
