import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { changeErrorPageNumber } from '../../../actions/error';

import './ErrorPage.scss';

const ErrorPage = ({ pageNotFound }) => {
	const history = useHistory();
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const errorReducer = useSelector((state) => state.error);

	useEffect(
		() => {
			if (pageNotFound && errorReducer.errorPageNumber !== 404) {
				dispatch(changeErrorPageNumber(404));
				document.title = t(errorReducer.errorTitle);
			}
		},
		[ errorReducer.errorPageNumber, pageNotFound ]
	);

	return errorReducer.errorPageNumber !== 0 ? (
		<div id="notfound" lang="es">
			<div className="notfound">
				{/* <div className="notfound-404">
					<h1>{errorReducer.errorPageNumber}</h1>
				</div> */}
				<div className="col-xl-6 message">
					<h2>{t(errorReducer.errorTitle)}</h2>
					<p>{t(errorReducer.errorMsg)}</p>
					<button
						className="btn-base_danger"
						onClick={() => {
							history.push('/logout');
						}}
					>
						{t('GoBack')}
					</button>
				</div>
			</div>
		</div>
	) : (
		''
	);
};
export default ErrorPage;
