import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { startRequest } from "@backoffice/actions/apiCall";
import { Link } from "react-router-dom";
import { Button } from "@backoffice/components/FormComponents/index";
import FilePreview from "../../../../shared/FilePreview/FilePreview";
import { ROUTE } from "../../../../common/constants/routes";
import PopUpGenericDelete from "../../../../components/PopUpGenericDelete/PopUpGenericDelete";
import { openModal, closeModal } from "../../../../actions/popup";
import { showFixedLabel } from "../../../../actions/fixedLabel";
import {
  getBookingByIdParams,
  cancelBookingParams,
} from "./BookingTenantDetailCalls/BookingTenantDetailCalls";
import { parseDate, parseTime } from "../../../../common/utils/parseUtils";
import { getDiffHours } from "../../../../common/utils/dateUtils";
import "./BookingTenantDetail.scss";

const BookingTenantDetail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const { t } = useTranslation();
  const [bookingId, setBookingId] = useState(id);
  const [booking, setBooking] = useState("");

  useEffect(() => {
    if (bookingId !== "0") {
      getBookingById();
    }
  }, []);

  const getBookingById = () => {
    dispatch(
      startRequest(getBookingByIdParams(bookingId, getBookingByIdSuccess))
    );
  };

  const getBookingByIdSuccess = (response) => {
    setBooking(response);
  };

  const openCancelBooking = () => {
    const optionValues = {
      id: "delete-booking-" + booking.id + "-" + Math.random(),
      cssClass: "modal-header__danger",
      title: t("CancelBooking"),
    };

    const options = {
      ...optionValues,
      body: (
        <PopUpGenericDelete
          id={optionValues.id}
          deleteMessage={t("CancelBookingConfirmMessage")}
          buttonDeleteName={t("CancelBooking")}
          handleButtonDelete={() => deleteSpace()}
          handleButtonCancel={() => dispatch(closeModal())}
        />
      ),
    };

    dispatch(openModal(options));
  };

  const deleteSpace = () => {
    dispatch(startRequest(cancelBookingParams(bookingId, deleteSpaceSuccess)));
  };

  const deleteSpaceSuccess = (response) => {
    dispatch(showFixedLabel(t("CancelBookingSuccess"), "success"));
    history.push(ROUTE.BOOKINGSTENANT);
  };

  const detailInfo = () => {
    return (
      <React.Fragment key={Math.random()}>
        <div className="booking-tenant-detail-info">
          <div className="col-xl-4 booking-space-img">
            <div className="img-wrapper">
              <FilePreview
                file={""}
                url={
                  booking.spaceMediaThumbnailURL
                    ? booking.spaceMediaThumbnailURL
                    : "/empty-image.png"
                }
                fileType={booking.spaceMediaThumbnailURL ? "jpg" : ""}
              />
            </div>
          </div>
          <div className="col-xl-6 booking-info">
            <div className="icon-wrapper">
              <div className="icon-row">
                <div className="icon">
                  <span className="material-icons">place</span>
                </div>
                <p className="icon-text">{booking.spaceName}</p>
              </div>
              <div className="icon-row">
                <div className="icon">
                  <span className="material-icons">calendar_today</span>
                </div>
                <p className="icon-text">
                  {parseDate(booking.bookingStart, true)}
                </p>
              </div>
              <div className="icon-row">
                <div className="icon">
                  <span className="material-icons">schedule</span>
                </div>
                <p className="icon-text">
                  {parseTime(booking.bookingStart, true)}
                </p>
              </div>
              <div className="icon-row">
                <div className="icon">
                  <span className="material-icons">hourglass_bottom</span>
                </div>
                <p className="icon-text">{booking.duration + "h"}</p>
              </div>
              <div className="icon-row">
                <div className="icon">
                  <span className="material-icons">emoji_people</span>
                </div>
                <p className="icon-text">
                  {booking.peopleNumber + " " + t("People")}
                </p>
              </div>
            </div>
            <h4 className="title">{t("Description")}</h4>
            <p className="description">{booking.description}</p>
            <h4 className="title">{t("Instructions")}</h4>
            <p className="instructions">{booking.instructions}</p>
          </div>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className="booking-tenant-detail">
      <div className="return">
        <div className="col-lg-2 offsett-lg-10">
          <Link className="go-back" onClick={() => history.goBack()} role="link">
            <span
              className="material-icons-round"
              data-testid={"btn-goback"}
              id={"btn-goback"}
              aria-hidden={true}
            >
              arrow_back
            </span>
            <div className="go-back__text">{t("GoBack")}</div>
          </Link>
        </div>
      </div>
      <h1 className="title-detail">{t("BookingDetails")}</h1>
      {booking && detailInfo()}
      {new Date(booking.bookingStart).getTime() > new Date().getTime() ? (
        <div className="btn-end">
          <Button
            dataTestID="btnCancelBooking"
            btnText={t("CancelBooking")}
            btnType="button"
            btnClass="btn-base_danger"
            onHandleBtnClick={() => {
              openCancelBooking();
            }}
          />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};
export default BookingTenantDetail;
