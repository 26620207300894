import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	ColumnDirective,
	ColumnsDirective,
	Filter,
	GridComponent,
	Group,
	Resize,
	Inject,
	Page,
	Sort,
	ExcelExport
} from '@syncfusion/ej2-react-grids';
import { Button } from '@backoffice/components/FormComponents/index';
import ResponsiveGridWrapper from '../../../../../shared/ResponsiveGridWrapper/ResponsiveGridWrapper';
import { closeModal, openModal } from '../../../../../actions/popup';
import { getServicesByIdParams, deleteServiceParams } from '../BuildingDetailCalls/BuildingDetailCalls';
import { startRequest } from '../../../../../actions/apiCall';
import PopUpService from './PopUpService/PopUpService';
import PopUpAddService from './PopUpAddService/PopUpAddService';
import PopUpGenericDelete from '../../../../../components/PopUpGenericDelete/PopUpGenericDelete';
import { showFixedLabel } from '../../../../../actions/fixedLabel';
import './TabServices.scss';

const TabServices = () => {
	const { t } = useTranslation();
	const { id } = useParams();
	const [ services, setServices ] = useState([]);
	const pageSettings = { pageSize: 50 };
	const [ buildingId, setBuildingId ] = useState(id);
	const dispatch = useDispatch();
	const grid = useRef(null);

	useEffect(() => {
		getServicesBuilding();
	}, []);

	const getServicesBuilding = () => {
		dispatch(startRequest(getServicesByIdParams(buildingId, getServicesByIdParamsSuccess)));
	};

	const getServicesByIdParamsSuccess = (response) => {
		setServices(response);
	};

	const openDeleteService = (action, serviceId, buildingId) => {
		const optionValues = {
			id: 'delete-service-' + serviceId + '-' + Math.random(),
			cssClass: 'modal-header__danger',
			title: t('Delete')
		};

		const options = {
			...optionValues,
			body: (
				<PopUpGenericDelete
					action={action}
					id={optionValues.id}
					deleteMessage={'DeleteConfirmMessage'}
					handleButtonDelete={() => deleteService(serviceId, buildingId)}
					handleButtonCancel={() => dispatch(closeModal())}
				/>
			)
		};

		dispatch(openModal(options));
	};

	const deleteService = (serviceId, buildingId) => {
		dispatch(startRequest(deleteServiceParams({ buildingId : buildingId, serviceId: serviceId }, deleteServiceParamsSuccess)));
	};

	const deleteServiceParamsSuccess = (response) => {
		dispatch(showFixedLabel('ServiceDeleteSuccess', 'success'));
		getServicesBuilding();
	};

	const actionsTemplate = (rowData) => {
		return (
			<div className="action-template">
				<span
					className="material-icons-round"
					data-testid={`btnEditStandar_${rowData.index}`}
					id={`btnEditStandar_${rowData.index}`}
					onClick={() => {
						openService(rowData.serviceId, 'edit');
					}}
					type="button"
				>
					mode_edit
				</span>
				<span
					className="material-icons-round"
					id={`btnDeleteStandar_${rowData.index}`}
					data-testid={`btnDeleteStandar_${rowData.index}`}
					onClick={() => {
						openDeleteService('delete', rowData.serviceId, buildingId);
					}}
					type="button"
				>
					delete
				</span>
			</div>
		);
	};

	const openService = (serviceId, action) => {
		const optionValues = {
			id: 'edit-service' + Math.random(),
			dataTestId: 'edit-service' + Math.random(),
			cssClass: 'modal-header__add',
			title: action === 'add' ? t('AddService') : action === 'addExist' ? t('AddExistService') : t('EditService')
		};

		const options = {
			...optionValues,
			body: (
				<PopUpService
					action={action}
					buildingId={buildingId}
					serviceId={serviceId}
					handleSubmit={getServicesBuilding}
					optionValues={optionValues}
				/>
			)
		};
		dispatch(openModal(options));
	};

	const addExistingService = () => {
		const optionValues = {
			id: 'add-admin' + Math.random(),
			dataTestId: 'add-admin' + Math.random(),
			cssClass: 'modal-header__add',
			title: t('AddExistService')
		};

		const options = {
			...optionValues,
			body: (
				<PopUpAddService
					buildingId={buildingId}
					optionValues={optionValues}
					handleSubmit={getServicesBuilding}
				/>
			)
		};

		dispatch(openModal(options));
	};

	return (
		<div className="tab-services">
			<div className="btn-grid-end">
				<Button
					btnText={t('ExportExcel')}
					btnType="button"
					icon="download"
					btnClass="btn-base_outline export-excel-button"
					dataTestID="export-excel-button"
					onHandleBtnClick={() => {
						grid.current.excelExport({ includeHiddenColumn: true });
					}}
				/>
				<Button
					btnText={t('AddExistService')}
					btnType="button"
					icon="add"
					btnClass="btn-base_outline add-service-button"
					dataTestID="add-service-button"
					onHandleBtnClick={() => {
						addExistingService('', 'addExist');
					}}
				/>
				<Button
					btnText={t('AddService')}
					btnType="button"
					icon="add"
					btnClass="btn-base add-service-button"
					dataTestID="add-service-button"
					onHandleBtnClick={() => {
						openService('', 'add');
					}}
				/>
			</div>
			<ResponsiveGridWrapper>
				<GridComponent
					className="gridTable"
					dataSource={services}
					width="100%"
					toolbar={false}
					ref={grid}
					allowExcelExport={true}
					allowPaging={true}
					enableAdaptiveUI={true}
					allowSorting={true}
					rowRenderingMode={'Vertical'}
					pageSettings={pageSettings}
				>
					<ColumnsDirective>
						<ColumnDirective field="id" width="0" visible={false} />
						<ColumnDirective field="name" headerText={t('Name')} textAlign="Left" />
						<ColumnDirective
							field="serviceTypeName"
							headerText={t('ServiceType')}
							valueAccessor={(field, data, column) => {
								return t(data.serviceTypeName);
							}}
							textAlign="Left"
						/>
						<ColumnDirective field="phoneNumber" headerText={t('Phone')} textAlign="Left" />
						<ColumnDirective field="address" headerText={t('Address')} textAlign="Left" />
						<ColumnDirective field="email" headerText={t('Email')} textAlign="Left" />
						<ColumnDirective
							field="actions"
							headerText={''}
							template={actionsTemplate}
							textAlign="Right"
							width="60"
						/>
					</ColumnsDirective>
					<Inject services={[ ExcelExport, Page, Sort, Filter, Group, Resize ]} />
				</GridComponent>
			</ResponsiveGridWrapper>
		</div>
	);
};
export default TabServices;
