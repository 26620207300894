const postIncidentsTenantParams = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		data,
		endpoint: '/Incidents',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getIncidentCaseTypesParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tipology/GetAllNameId/IncidentCaseTypeCodeType',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getIncidentUbicationTypesParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Incidents/GetBuildingAndSpaceNameId',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};
const getIncidentScheduleTypesParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/Tipology/GetAllNameId/IncidentHorarioContactoType',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const getTenantCreateIncident = (SuccessFn) =>{
	const options = {
		method: 'GET',
		endpoint: '/Tenant/GetTenantCreateIncident',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options
}


const postMediaParams = (data, funcSucc) => {
	const options = {
		method: 'POST',
		data,
		contentType: 'multipart/form-data',
		endpoint: '/Incidents/CreateMultipleMedia',
		onSuccess: (response) => {
			funcSucc(response.data.data);
		}
	};

	return options;
};

export { postIncidentsTenantParams, postMediaParams, getIncidentCaseTypesParams, getIncidentUbicationTypesParams, getIncidentScheduleTypesParams, getTenantCreateIncident};
