import React from 'react';
import { Route } from 'react-router-dom';
import { publicRoutes, privateRoutes } from './index';
import PrivateRoute from './Root/PrivateRoute';

const getPrivateRoutes = (store) => {
	const routes = [];
	for (const key in privateRoutes) {
		routes.push(privateRoute(key, privateRoutes[key], store));
	}
	return routes;
};

const getPublicRoutes = () => {
	const routes = [];
	for (const key in publicRoutes) {
		routes.push(publicRoute(key, publicRoutes[key]));
	}
	return routes;
};

const privateRoute = (route, Component) => {
	return <PrivateRoute key={route} exact path={route} Component={Component} route={route} />;
};

const publicRoute = (route, Component) => {
	return (
		<Route
			key={route}
			exact
			path={route}
			render={() => {
				return <Component />;
			}}
		/>
	);
};

export { getPrivateRoutes, getPublicRoutes };
