import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { startRequest } from "../../../../actions/apiCall";
import { ROUTE } from "../../../../common/constants/routes";
import FilePreview from "../../../../shared/FilePreview/FilePreview";
import ReactHtmlParser from "react-html-parser";
import { getNewByIdParams } from "./NewsTenantDetailCalls/NewsTenantDetailCalls";
import { parseDate } from "../../../../common/utils/parseUtils";
import "./NewsTenantDetail.scss";

const NewsTenantDetail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
	const history = useHistory();

  const [newId, setNewId] = useState(id);
  const [newTitle, setNewTitle] = useState("");
  const [newDate, setNewDate] = useState("");
  const [newContent, setNewContent] = useState("");
  const [file, setFile] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [thumbNailImgUrl, setThumbNailImgUrl] = useState("");

  useEffect(() => {
    getNewById();
  }, []);

  const getNewById = () => {
    dispatch(startRequest(getNewByIdParams(newId, getNewByIdSuccess)));
  };

  const getNewByIdSuccess = (response) => {
    setNewTitle(response.title);
    setNewContent(response.content);
    setNewDate(response.date);
    setImgUrl(response.media.mediaUrl);
    setThumbNailImgUrl(response.media.mediaThumbnailUrl);
    document.title = t(response.title);
  };

  return (
    <div className="new-tenant-detail">
      <div className="return">
        <div className="col-lg-2 offsett-lg-10">
          <Link className="go-back" onClick={() => history.goBack()}  role="link">
            <span
              className="material-icons-round"
              data-testid={"btn-goback"}
              id={"btn-goback"}
              aria-hidden={true}
            >
              arrow_back
            </span>
            <div className="go-back__text">{t("GoBack")}</div>
          </Link>
        </div>
      </div>

      <div className="row">
        <div className="container">
          <h1 className="title-new">{newTitle}</h1>
          <p className="date-card">
            {newDate && parseDate(newDate, true)}
          </p>
          {file !== "" || imgUrl !== "" ? (
            <img className="img-new" src={thumbNailImgUrl}></img>
          ) : (
            <img
              className="img-new"
              src={process.env.PUBLIC_URL + "/empty-image.png"}
            ></img>
          )}
          <div className="content-new">{ReactHtmlParser(newContent)}</div>
        </div>
      </div>
    </div>
  );
};

{
  /* <div className="img-wrapper-new">
							{file !== '' || imgUrl !== '' ? (
								<div className="img-wrapper">
									<FilePreview file={file} url={thumbNailImgUrl} fileType={'Image'} />
								</div>
							) : (
								<div className="img-wrapper empty">
									<FilePreview
										file={''}
										url={process.env.PUBLIC_URL + '/empty-image.png'}
										fileType={'Image'}
									/>
								</div>
							)}
						</div> */
}

export default NewsTenantDetail;
