const postBookingParams = (data, SuccessFn) => {
	const options = {
		method: 'POST',
		data,
		endpoint: '/TenantBookingSpace',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const putBookingParams = (data, SuccessFn) => {
	const options = {
		method: 'PUT',
		data,
		endpoint: '/TenantBookingSpace',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export { postBookingParams, putBookingParams };