import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	ColumnDirective,
	ColumnsDirective,
	Filter,
	GridComponent,
	Group,
	Resize,
	Inject,
	Page,
	Sort,
	ExcelExport
} from '@syncfusion/ej2-react-grids';
import { startRequest } from '@backoffice/actions/apiCall';
import { Input, Button } from '@backoffice/components/FormComponents/index';
import ResponsiveGridWrapper from '../../../../shared/ResponsiveGridWrapper/ResponsiveGridWrapper';
import { getNewsParams, deleteNewsParams } from './NewsCalls/NewsCalls';
import { cleanStringFromAccents, parseDateTime } from '../../../../common/utils/parseUtils';
import { ROUTE } from '../../../../common/constants/routes';
import PopUpGenericDelete from '../../../../components/PopUpGenericDelete/PopUpGenericDelete';
import { openModal, closeModal } from '../../../../actions/popup';
import { showFixedLabel } from '../../../../actions/fixedLabel';
import './News.scss';

const News = () => {
	const { t } = useTranslation();
	const pageSettings = { pageSize: 15 };
	const history = useHistory();
	const grid = useRef(null);
	const dispatch = useDispatch();
	const [ news, setNews ] = useState([]);
	const [ search, setSearch ] = useState('');
	const [ allNews, setAllNews ] = useState([]);

	useEffect(() => {
		getNews();
	}, []);

	useEffect(
		() => {
			filterNews();
		},
		[ search ]
	);

	const getNews = () => {
		dispatch(startRequest(getNewsParams(getNewsSuccess)));
	};

	const getNewsSuccess = (response) => {
		setNews(response.list);
		setAllNews(response.list);
	};

	const filterBySearch = (data = [], searching = '') => {
		const result = data.filter((element) => {
			return cleanStringFromAccents(element.title.toLowerCase()).includes(searching.toLowerCase());
		});
		return result;
	};

	const filterNews = () => {
		if (search === '') {
			setNews(allNews);
		} else {
			const newData = filterBySearch(allNews, search);
			if (newData === '') {
				setNews(allNews);
			}
			setNews(newData);
		}
	};

	const actionsTemplate = (rowData) => {
		return (
			<div className="action-template">
				<span
					className="material-icons-round"
					data-testid={`btnEditStandar_${rowData.index}`}
					id={`btnEditStandar_${rowData.index}`}
					onClick={() =>
						history.push({
							pathname: ROUTE.NEWSDETAIL.replace(':id', rowData.id)
						})}
					type="button"
				>
					mode_edit
				</span>
				{rowData.rolName !== 'Tenant' ? (
					<span
						className="material-icons-round"
						id={`btnDeleteStandar_${rowData.index}`}
						data-testid={`btnDeleteStandar_${rowData.index}`}
						onClick={() => {
							openDeleteNews('delete', rowData);
						}}
						type="button"
					>
						delete
					</span>
				) : (
					''
				)}
			</div>
		);
	};

	const openDeleteNews = (action, elm) => {
		const optionValues = {
			id: 'delete-news-' + elm.id + '-' + Math.random(),
			dataTestId: 'delete-new',
			cssClass: 'modal-header__danger',
			title: t('Delete')
		};

		const options = {
			...optionValues,
			body: (
				<PopUpGenericDelete
					action={action}
					id={optionValues.id}
					deleteMessage={'DeleteConfirmMessage'}
					handleButtonDelete={() => deleteNews(elm)}
					handleButtonCancel={() => dispatch(closeModal())}
				/>
			)
		};

		dispatch(openModal(options));
	};

	const deleteNews = (elm) => {
		dispatch(startRequest(deleteNewsParams({ id: elm.id }, deleteNewsSuccess)));
	};

	const deleteNewsSuccess = (response) => {
		dispatch(showFixedLabel('NewDeleteSuccess', 'success'));
		getNews();
	};

	// let searchBy = t('Search') + ' ' + t('by') + ' ' + t('Title');
	let searchBy = t('Search') + ' ' + t('by') + ' ' + t('WordMin');
	return (
		<div className="news">
			<h2 className="title">{t('News')}</h2>
			<div className="filters-wrapper">
				<div className="col-xl-3 new-search" data-bs-toggle="tooltip" title={searchBy}>
					<Input
						dataTestID="txtSearch"
						inputName="txtSearch"
						clearInput={true}
						inputPlaceholder={searchBy}
						inputType="text"
						value={search}
						inputOutline={true}
						icon={'search'}
						iconLeft={true}
						isRequired={false}
						onHandleChange={(e) => setSearch(e.target.value)}
					/>
				</div>
			</div>
			<div className="btn-grid-end">
				<Button
					btnText={t('ExportExcel')}
					btnType="button"
					icon="download"
					btnClass="btn-base_outline export-excel-button"
					dataTestID="export-excel-button"
					onHandleBtnClick={() => {
						grid.current.excelExport({ includeHiddenColumn: true });
					}}
				/>
				<Button
					btnText={t('CreateNew')}
					icon="add"
					btnType="button"
					btnClass="btn-base_normal add-button"
					dataTestID="users-add-button"
					onHandleBtnClick={() => history.push(ROUTE.NEWSDETAIL.replace(':id', 0))}
				/>
			</div>

			<ResponsiveGridWrapper>
				<GridComponent
					className="gridTable"
					dataSource={news}
					width="100%"
					toolbar={false}
					ref={grid}
					allowExcelExport={true}
					allowPaging={true}
					enableAdaptiveUI={true}
					allowSorting={true}
					rowRenderingMode={'Vertical'}
					pageSettings={pageSettings}
				>
					<ColumnsDirective>
						<ColumnDirective field="id" width="0" visible={false} />
						<ColumnDirective field="title" headerText={t('Title')} textAlign="Left" />
						<ColumnDirective field="buildingName" headerText={t('Building')} textAlign="Left" />
						<ColumnDirective
							field="creationDate"
							headerText={t('CreationDate')}
							valueAccessor={(field, data, column) => {
								return parseDateTime(data.creationDate, true);
							}}
							textAlign="Left"
						/>
						<ColumnDirective
							field="fixed"
							headerText={t('Fixed')}
							textAlign="Left"
							displayAsCheckBox={true}
						/>
						<ColumnDirective
							field="hidden"
							headerText={t('Hidden')}
							textAlign="Left"
							displayAsCheckBox={true}
						/>
						<ColumnDirective
							field="schedule"
							headerText={t('Schedule')}
							textAlign="Left"
							displayAsCheckBox={true}
						/>
						<ColumnDirective
							field="actions"
							headerText={''}
							template={actionsTemplate}
							textAlign="Right"
							width="60"
						/>
					</ColumnsDirective>
					<Inject services={[ ExcelExport, Page, Sort, Filter, Group, Resize ]} />
				</GridComponent>
			</ResponsiveGridWrapper>
		</div>
	);
};

export default News;
