import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
	ColumnDirective,
	ColumnsDirective,
	Filter,
	GridComponent,
	Group,
	Resize,
	Inject,
	Page,
	Sort,
	ExcelExport
} from '@syncfusion/ej2-react-grids';
import { Input, Button, Combo } from '@backoffice/components/FormComponents/index';
import { startRequest } from '@backoffice/actions/apiCall';
import { openModal, closeModal } from '../../../../actions/popup';
import { cleanStringFromAccents, parseDate } from '../../../../common/utils/parseUtils';
import { showFixedLabel } from '../../../../actions/fixedLabel';
import PopUpGenericDelete from '../../../../components/PopUpGenericDelete/PopUpGenericDelete';
import { ROUTE } from '../../../../common/constants/routes';
import { getUsersParams, getRolesParams, deleteUserParams } from '../UserDetail/UserDetailCalls';
import ResponsiveGridWrapper from '../../../../shared/ResponsiveGridWrapper/ResponsiveGridWrapper';
import './Users.scss';

const Users = () => {
	const pageSettings = { pageSize: 15 };
	const grid = useRef(null);
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();
	const [ search, setSearch ] = useState('');
	const [ users, setUsers ] = useState([]);
	const [ allUsers, setAllUsers ] = useState([]);
	const [ role, setRole ] = useState({ id: 'All', name: 'All' });
	const [ roleOptions, setRoleOptions ] = useState([]);

	useEffect(() => {
		getUsers();
		getRoles();
	}, []);

	useEffect(
		() => {
			filterUsers();
		},
		[ search, role ]
	);

	const getRoles = () => {
		dispatch(startRequest(getRolesParams(getRolesSuccess)));
	};

	const getRolesSuccess = (response) => {
		response.unshift({ id: 'All', name: 'All' });
		setRoleOptions(response);
	};

	const getUsers = () => {
		dispatch(startRequest(getUsersParams(getUsersSuccess)));
	};

	const getUsersSuccess = (response) => {
		setAllUsers(response.list);
		setUsers(response.list);
	};

	const filterBySearch = (data = [], searching = '') => {
		const result = data.filter((element) => {
			return (
				cleanStringFromAccents(element.name.toLowerCase()).includes(searching.toLowerCase()) ||
				cleanStringFromAccents(element.surnames).toLowerCase().includes(searching.toLowerCase()) ||
				cleanStringFromAccents(element.documentId).toLowerCase().includes(searching.toLowerCase()) ||
				cleanStringFromAccents(element.email).toLowerCase().includes(searching.toLowerCase())
			);
		});
		if (role.name !== 'All') {
			const resultFiltered = result.filter((element) => {
				return element.rolName === role.name;
			});
			return resultFiltered;
		} else {
			return result;
		}
	};

	const filterUsers = () => {
		if (search === '' && role.name === 'All') {
			setUsers(allUsers);
		} else {
			const newData = filterBySearch(allUsers, search);
			if (newData === '') {
				setUsers(allUsers);
			}
			setUsers(newData);
		}
	};

	const actionsTemplate = (rowData) => {
		return (
			<div className="action-template">
				<span
					className="material-icons-round"
					data-testid={`btnEditStandar_${rowData.index}`}
					id={`btnEditStandar_${rowData.index}`}
					onClick={() =>
						history.push({
							pathname: ROUTE.USERDETAIL.replace(':id', rowData.id)
						})}
					type="button"
				>
					mode_edit
				</span>
				{rowData.rolName !== 'Tenant' ? (
					<span
						className="material-icons-round"
						id={`btnDeleteStandar_${rowData.index}`}
						data-testid={`btnDeleteStandar_${rowData.index}`}
						onClick={() => {
							openDeleteUsers('delete', rowData);
						}}
						type="button"
					>
						delete
					</span>
				) : (
					''
				)}
			</div>
		);
	};

	const openDeleteUsers = (action, user) => {
		const optionValues = {
			id: 'delete-users-' + user.id + '-' + Math.random(),
			dataTestId: 'delete-user',
			cssClass: 'modal-header__danger',
			title: t('Delete')
		};

		const options = {
			...optionValues,
			body: (
				<PopUpGenericDelete
					action={action}
					id={optionValues.id}
					deleteMessage={'DeleteConfirmMessage'}
					handleButtonDelete={() => deleteUsers(user)}
					handleButtonCancel={() => dispatch(closeModal())}
				/>
			)
		};

		dispatch(openModal(options));
	};

	const deleteUsers = (user) => {
		dispatch(startRequest(deleteUserParams({ id: user.id }, deleteUsersSuccess)));
	};

	const deleteUsersSuccess = (response) => {
		dispatch(showFixedLabel('UserDeleteSuccess', 'success'));
		getUsers();
	};

	// let searchBy = t('Search') + ' ' + t('by') + ' ' + t('Name') + ', ' + t('Surnames') + ', ' + t('Email') + ', ' + t('NIF');
	let searchBy = t('Search') + ' ' + t('by') + ' ' + t('WordMin');

	return (
		<div className="users">
			<h2 className="title">{t('Users')}</h2>
			<div className="filters-wrapper">
				<div className="col-xl-3 search" data-bs-toggle="tooltip" title={searchBy}>
					<Input
						dataTestID="txtSearch"
						inputName="txtSearch"
						clearInput={true}
						inputPlaceholder={searchBy}
						inputType="text"
						value={search}
						inputOutline={true}
						icon={'search'}
						iconLeft={true}
						isRequired={false}
						onHandleChange={(e) => setSearch(e.target.value)}
					/>
				</div>
				<div className="col-xl-3 roles">
					<Combo
						id="cmbRol"
						dataTestID="cmbRol"
						inputValue={role}
						comboLabel={t('Role')}
						comboPlaceholder={t('Select') + ' ' + t('Rol') + '...'}
						defaultInputValue=""
						isSearchable={false}
						getOptionValue={(option) => option['id']}
						getOptionLabel={(option) => t(option['name'])}
						options={roleOptions}
						isMultiple={false}
						handleChange={(e) => {
							setRole(e);
						}}
					/>
				</div>
			</div>
			<div className="btn-grid-end">
				<Button
					btnText={t('ExportExcel')}
					btnType="button"
					icon="download"
					btnClass="btn-base_outline export-excel-button"
					dataTestID="export-excel-button"
					onHandleBtnClick={() => {
						grid.current.excelExport({ includeHiddenColumn: true });
					}}
				/>
				<Button
					btnText={t('Add')}
					icon="add"
					btnType="button"
					btnClass="btn-base_normal add-button"
					dataTestID="users-add-button"
					onHandleBtnClick={() => history.push(ROUTE.USERDETAIL.replace(':id', 0))}
				/>
			</div>
			<ResponsiveGridWrapper>
				<GridComponent
					className="gridTable"
					dataSource={users}
					width="100%"
					toolbar={false}
					ref={grid}
					allowExcelExport={true}
					allowPaging={true}
					enableAdaptiveUI={true}
					allowSorting={true}
					rowRenderingMode={'Vertical'}
					pageSettings={pageSettings}
				>
					<ColumnsDirective>
						<ColumnDirective field="id" width="0" visible={false} />
						<ColumnDirective
							field="name"
							headerText={t('Name')}
							textAlign="Left"
							valueAccessor={(field, data, column) => {
								return data.name + ' ' + data.surnames;
							}}
						/>
						<ColumnDirective field="email" headerText={t('Email')} textAlign="Left" />
						<ColumnDirective
							field="phoneNumber"
							headerText={t('Phone')}
							textAlign="Left"
							visible={false}
							valueAccessor={(field, data, column) => {
								return data.prefixPhoneNumber + ' ' + (data.phoneNumber ? data.phoneNumber : '');
							}}
						/>
						<ColumnDirective field="documentId" headerText={t('NIF')} textAlign="Left" />
						<ColumnDirective
							field="rolName"
							headerText={t('Rol')}
							textAlign="Left"
							valueAccessor={(field, data, column) => {
								return t(data['rolName']);
							}}
						/>
						<ColumnDirective
							field="creationDate"
							headerText={t('CreationDate')}
							textAlign="Left"
							valueAccessor={(field, data, column) => {
								return parseDate(data.creationDate, true);
							}}
						/>
						<ColumnDirective
							field="actions"
							headerText={''}
							template={actionsTemplate}
							textAlign="Right"
							width="60"
						/>
					</ColumnsDirective>
					<Inject services={[ ExcelExport, Page, Sort, Filter, Group, Resize ]} />
				</GridComponent>
			</ResponsiveGridWrapper>
		</div>
	);
};
export default Users;
