import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Input, Button, Checkbox } from '@backoffice/components/FormComponents/index';
import { openModal } from '../../../../../actions/popup';
import { getAdminByIdParams, postAdminParams } from '../BuildingDetailCalls/BuildingDetailCalls';
import { startRequest } from '../../../../../actions/apiCall';
import { useHistory, useParams } from 'react-router-dom';
import { showFixedLabel } from '../../../../../actions/fixedLabel';
import { ROUTE } from '../../../../../common/constants/routes';
import PopUpAddAdmin from '../PopUpAddAdmin/PopUpAddAdmin';
import { Textarea } from '../../../../../components/FormComponents';
import { validateEmail } from '../../../../../common/validations/common';
import './TabAdministrator.scss';

const TabAdministrator = ({ building }) => {
	const { t } = useTranslation();
	const { id } = useParams();
	const [ buildingId, setBuildingId ] = useState(id);
	const [ name, setName ] = useState('');
	const [ phoneCountry, setPhoneCountry ] = useState('');
	const [ phone, setPhone ] = useState('');
	const [ email, setEmail ] = useState('');
	const [ emailError, setEmailError ] = useState(false);
	const [ url, setUrl ] = useState('');
	const [ observations, setObservations ] = useState('');
	const dispatch = useDispatch();
	const history = useHistory();

	const [ weekdaysCheck, setWeekdaysCheck ] = useState([
		{ name: 'Monday', checked: false },
		{ name: 'Tuesday', checked: false },
		{ name: 'Wednesday', checked: false },
		{ name: 'Thursday', checked: false },
		{ name: 'Friday', checked: false },
		{ name: 'Saturday', checked: false },
		{ name: 'Sunday', checked: false }
	]);
	const [ startTime, setStartTime ] = useState('');
	const [ endTime, setEndTime ] = useState('');
	const [ isPartialTime, setIsPartialTime ] = useState(false);
	const [ partialStartTime, setPartialStartTime ] = useState('');
	const [ partialEndTime, setPartialEndTime ] = useState('');

	useEffect(() => {
		getAdminById();
	}, []);

	const getAdminById = () => {
		dispatch(startRequest(getAdminByIdParams(buildingId, getAdminByIdParamsSuccess)));
	};

	const getAdminByIdParamsSuccess = (response) => {
		setName(response.name);
		setPhone(response.phoneNumber);
		setEmail(response.email);
		let weekdays = [
			{ name: 'Monday', checked: response.monday },
			{ name: 'Tuesday', checked: response.tuesday },
			{ name: 'Wednesday', checked: response.wednesday },
			{ name: 'Thursday', checked: response.thursday },
			{ name: 'Friday', checked: response.friday },
			{ name: 'Saturday', checked: response.saturday },
			{ name: 'Sunday', checked: response.sunday }
		];
		setWeekdaysCheck(weekdays);
		setStartTime(response.from);
		setEndTime(response.to);
		setIsPartialTime(response.usePartialTime);
		setPartialStartTime(response.fromPartial);
		setPartialEndTime(response.toPartial);
		setUrl(response.url);
		setObservations(response.observations);
	};

	const validateForm = () => {
		let formOK = true;
		if (
			name === '' ||
			phone === '' ||
			emailError ||
			email === '' ||
			startTime === '' ||
			endTime === '' ||
			((isPartialTime && partialStartTime === '') || (isPartialTime && partialEndTime === ''))
		) {
			formOK = false;
		}
		return !formOK;
	};

	const getAdminForm = () => {
		return {
			buildingId: buildingId,
			name: name,
			email: email,
			prefixPhoneNumber: phoneCountry,
			phoneNumber: phone,
			observations: observations,
			monday: weekdaysCheck[0].checked,
			tuesday: weekdaysCheck[1].checked,
			wednesday: weekdaysCheck[2].checked,
			thursday: weekdaysCheck[3].checked,
			friday: weekdaysCheck[4].checked,
			saturday: weekdaysCheck[5].checked,
			sunday: weekdaysCheck[6].checked,
			usePartialTime: isPartialTime,
			fromPartial: partialStartTime,
			toPartial: partialEndTime,
			from: startTime,
			to: endTime,
			uRL: url
		};
	};

	const editAdmin = () => {
		let admin = getAdminForm();
		dispatch(startRequest(postAdminParams(admin, postAdminParamsSuccess)));
	};

	const postAdminParamsSuccess = (response) => {
		// history.push(ROUTE.BUILDINGS);
		dispatch(showFixedLabel('EditAdminSuccess', 'success'));
	};

	const handleWeekdaysChecks = (e, el) => {
		const newWeekdaysCheck = [ ...weekdaysCheck ];
		newWeekdaysCheck.forEach((check) => {
			if (check.name === el.name) {
				check.checked = e.target.checked;
			}
		});
		setWeekdaysCheck(newWeekdaysCheck);
	};

	const addAdministrator = () => {
		const optionValues = {
			id: 'add-admin' + Math.random(),
			dataTestId: 'add-admin' + Math.random(),
			cssClass: 'modal-header__add',
			title: t('AddExistAdministrator')
		};

		const options = {
			...optionValues,
			body: <PopUpAddAdmin buildingId={buildingId} handleSubmit={getAdminById} optionValues={optionValues} />
		};

		dispatch(openModal(options));
	};

	const renderWeekdaysCheck = () => {
		let html = [];

		weekdaysCheck.forEach((check, i) => {
			html.push(
				<div className="weekdays-check" key={'chk-' + i}>
					<Checkbox
						inputID={'chck-' + check.name}
						inputName={'chck-' + check.name}
						inputLabel={t(check.name)}
						inputChecked={check.checked}
						onHandleChange={(e) => {
							handleWeekdaysChecks(e, check);
						}}
					/>
				</div>
			);
		});

		return html;
	};

	return (
		<div className="tab-administrator">
			<div className="end-button-wrapper">
				<Button
					btnText={t('AddExistAdministrator')}
					btnType="button"
					icon="add"
					btnClass="btn-base_outline add-administrator-button"
					dataTestID="add-administrator-button"
					onHandleBtnClick={() => {
						addAdministrator();
					}}
				/>
			</div>
			<div className="administrator-form">
				<div className="row">
					<div className="col-lg-3">
						<Input
							dataTestID="txtName"
							inputName="txtName"
							inputType="text"
							inputLabel={t('Name')}
							inputID="admin-name"
							isDisabled={false}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setName(val);
							}}
							value={name}
							isRequired={true}
						/>
					</div>
					<div className="col-xl-3">
						<Input
							dataTestID="txtPhone"
							inputName="txtPhone"
							inputType="text"
							inputLabel={t('Phone')}
							inputID="admin-phone"
							isDisabled={false}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setPhone(val);
							}}
							value={phone}
							isRequired={true}
						/>
					</div>
					<div className="col-lg-3">
						<Input
							dataTestID="txtEmail"
							inputName="txtEmail"
							inputType="text"
							inputLabel={t('Email')}
							inputID="admin-email"
							isDisabled={false}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setEmail(val);
								setEmailError(validateEmail(val).error);
							}}
							value={email}
							errorMessage={email === '' ? t('IsRequired') : t('EmailFormatIncorrect')}
							isRequired={true}
						/>
					</div>
					<div className="col-lg-3">
						<Input
							dataTestID="txtUrl"
							inputName="txtUrl"
							inputType="text"
							inputLabel={t('URL')}
							inputID="admin-url"
							isDisabled={false}
							onHandleChange={(e) => {
								let val = e.currentTarget.value;
								setUrl(val);
							}}
							value={url}
							isRequired={false}
						/>
					</div>
				</div>
				<div className="weekdays-checbox-wrapper">{renderWeekdaysCheck()}</div>
				<div className="row">
					<div className="col-lg-3">
						<div className="hours-wrapper">
							<Input
								inputID="startTime"
								inputName="startTime"
								inputOutline={true}
								inputType="time"
								inputLabel={t('Since')}
								isRequired={true}
								isDisabled={false}
								isError={false}
								value={startTime}
								inputStep={15}
								minTime={'08:00'}
								maxTime={endTime !== '' ? endTime : '22:00'}
								onHandleChange={(e) => setStartTime(e.target.value)}
							/>
						</div>
					</div>
					<div className="col-lg-3">
						<div className="hours-wrapper">
							<Input
								inputID="endTime"
								inputName="endTime"
								inputOutline={true}
								inputType="time"
								inputLabel={t('Until')}
								isRequired={true}
								isDisabled={false}
								isError={false}
								value={endTime}
								inputStep={15}
								minTime={startTime !== '' ? startTime : '08:00'}
								maxTime="22:00"
								onHandleChange={(e) => setEndTime(e.target.value)}
							/>
						</div>
					</div>
					<div className="col-lg-3">
						<div className="partialTime-check">
							<Checkbox
								inputID={'chck-partialTime'}
								inputName={'chck-partialTime'}
								inputLabel={t('PartialTime')}
								inputChecked={isPartialTime}
								onHandleChange={(e) => {
									setIsPartialTime(e.target.checked);
									setPartialStartTime('');
									setPartialEndTime('');
								}}
							/>
						</div>
					</div>
				</div>
				{isPartialTime ? (
					<div className="row">
						<div className="col-lg-3">
							<div className="hours-wrapper">
								<Input
									inputID="partialStartTime"
									inputName="partialStartTime"
									inputOutline={true}
									inputType="time"
									inputLabel={t('Since')}
									isRequired={true}
									isDisabled={false}
									isError={false}
									value={partialStartTime}
									inputStep={15}
									minTime={endTime}
									maxTime={partialEndTime !== '' ? partialEndTime : '22:00'}
									onHandleChange={(e) => setPartialStartTime(e.target.value)}
								/>
							</div>
						</div>
						<div className="col-lg-3">
							<div className="hours-wrapper">
								<Input
									inputID="partialEndTime"
									inputName="partialEndTime"
									inputOutline={true}
									inputType="time"
									inputLabel={t('Until')}
									isRequired={true}
									isDisabled={false}
									isError={false}
									value={partialEndTime}
									inputStep={15}
									minTime={partialStartTime !== '' ? partialStartTime : '08:00'}
									maxTime="22:00"
									onHandleChange={(e) => setPartialEndTime(e.target.value)}
								/>
							</div>
						</div>
					</div>
				) : (
					''
				)}
			</div>
			<div className="row">
				<div className="col-xl-6">
					<Textarea
						dataTestID="txtObservations"
						inputName="txtObservations"
						inputLabel={t('Observations')}
						inputType="text"
						inputValue={observations}
						isDisabled={false}
						inputOutline={true}
						value={observations}
						onHandleChange={(e) => {
							setObservations(e.currentTarget.value);
						}}
					/>
				</div>
			</div>
			<div className="btn-end">
				<Button
					dataTestID="btnSaveSpace"
					btnText={t('SaveChanges')}
					btnType="button"
					btnClass="btn-base_normal"
					isDisabled={validateForm()}
					onHandleBtnClick={() => editAdmin()}
				/>
			</div>
		</div>
	);
};
export default TabAdministrator;
