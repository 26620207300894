import React from "react";
import { Button } from "@backoffice/components/FormComponents/index";
import "./PopUpDeclineIncident.scss";
import { useTranslation } from "react-i18next";

const PopUpDeclineIncident = ({ declineIncident, handleButtonCancel }) => {
  const { t } = useTranslation();

  return (
    <div className="decline-incident-popup">
      <Button
        btnText={t("Yes")}
        btnType="button"
        btnClass="btn-base_danger"
        onHandleBtnClick={declineIncident}
      />
      <Button
        btnText={t("Nope")}
        btnType="button"
        btnClass="btn-base_normal"
        onHandleBtnClick={handleButtonCancel}
      />
    </div>
  );
};

export default PopUpDeclineIncident;
