import React from 'react';
import './Textarea.scss';

export default function Textarea(props) {
	const { inputID, inputLabel, inputName, inputValue, isDisabled, onHandleChange, dataTestID, isRequired } = props;
	return (
		<div className={`textarea-wrapper ${isDisabled ? 'isDisabled' : ''}`}>
			<label className="textarea-label" htmlFor={inputID}>
				{inputLabel}
				{isRequired ? <sup className="required">*</sup> : ''}
			</label>
			<textarea
				className="textarea-control"
				aria-label={inputLabel}
				name={inputName}
				data-testid={dataTestID}
				id={inputID}
				rows={5}
				onChange={onHandleChange}
				value={inputValue}
				autoComplete="off"
				disabled={isDisabled}
			/>
		</div>
	);
}
