const getBookingsParams = (SuccessFn) => {
	const options = {
		method: 'GET',
		endpoint: '/BookingSpace/GetAllBookings',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

const deleteBookingsParams = (params, SuccessFn) => {
	const options = {
		method: 'DELETE',
		params,
		endpoint: '/Bookings',
		onSuccess: (response) => {
			SuccessFn(response.data.data);
		}
	};
	return options;
};

export { getBookingsParams, deleteBookingsParams };
