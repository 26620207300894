const getSyncfusionTranslations = () => {
	return {
		es: {
			calendar: {
				today: 'Hoy'
			},
			richtexteditor: {
				alignments: 'Alineamiento',
				justifyleft: 'Justificar a la izquierda',
				justifycenter: 'Justificar al centro',
				justifyright: 'Justificar a la derecha',
				justifyfull: 'Justificar',
				formats: 'Formatos',
				fontname: 'Nombre de la fuente',
				fontsize: 'Tamaño de la fuente',
				fontcolor: 'Color de la fuente',
				backgroundcolor: 'Color de fondo',
				bold: 'Negrita',
				italic: 'Italic',
				underline: 'Subrallado',
				strikethrough: 'Tachado',
				clearall: 'Limpiar todo',
				clearformat: 'Limpiar formato',
				cut: 'Cortar',
				copy: 'Copiar',
				paste: 'Pegar',
				unorderedlist: 'unordered list',
				orderedlist: 'ordered list',
				indent: 'Increase Indent',
				outdent: 'Decrease Indent',
				undo: 'Undo',
				redo: 'Redo',
				superscript: 'Superscript',
				subscript: 'Subscript',
				createlink: 'Insert link',
				removelink: 'remove link',
				openlink: 'Open link',
				editlink: 'Edit link',
				image: 'Insert image',
				replace: 'Replace',
				align: 'Align',
				caption: 'Caption',
				remove: 'Remove',
				insertlink: 'insertlink',
				display: 'Display',
				alttext: 'Alternative Text',
				dimension: 'Dimension',
				fullscreen: 'Full Screen',
				maximize: 'Maximize',
				minimize: 'Minimize',
				zoomin: 'Zoom In',
				zoomout: 'Zoom Out',
				uppercase: 'Upper Case',
				lowercase: 'Lower Case',
				print: 'Print',
				sourcecode: 'Source Code',
				preview: 'Preview',
				viewside: 'View Side',
				insertcode: 'Insert Code',
				linkText: 'Display Text',
				linkTooltipLabel: 'Title',
				linkWebUrl: 'Web Address',
				linkTitle: 'Enter a title',
				linkOpenInNewWindow: 'Open Link in New Window',
				linkHeader: 'Insert Link',
				dialogInsert: 'Insert',
				dialogCancel: 'Cancel',
				dialogUpdate: 'Update',
				imageHeader: 'Insert Image',
				imageLinkHeader: 'You can also provide a link from the web',
				mdimageLink: 'Please provide a URL for your image',
				imageUploadMessage: 'Drop image here or browse to upload',
				imageDeviceUploadMessage: 'Click here to upload',
				imageAlternateText: 'Alternate Text',
				alternateHeader: 'Alternative Text',
				browse: 'Browse',
				imageCaption: 'Caption',
				imageSizeHeader: 'Image Size',
				imageHeight: 'Height',
				imageWidth: 'Width',
				textPlaceholder: 'Enter Text',
				inserttablebtn: 'Insert Table',
				tabledialogHeader: 'Insert Table',
				tableWidth: 'Width',
				cellpadding: 'Cell Padding',
				cellspacing: 'Cell Spacing',
				columns: 'Number of columns',
				rows: 'Number of rows',
				tableRows: 'Table Rows',
				tableColumns: 'Table Columns',
				tableCellHorizontalAlign: 'Table Cell Horizontal Align',
				tableCellVerticalAlign: 'Table Cell Vertical Align',
				createTable: 'Create Table',
				removeTable: 'Remove Table',
				tableHeader: 'Table Header',
				tableRemove: 'Table Remove',
				tableCellBackground: 'Table Cell Background',
				tableEditProperties: 'Table Edit Properties',
				styles: 'Styles',
				insertColumnLeft: 'Insert Column Left',
				insertColumnRight: 'Insert Column Right',
				deleteColumn: 'Delete Column',
				insertRowBefore: 'Insert Row Before',
				insertRowAfter: 'Insert Row After',
				deleteRow: 'Delete Row',
				tableEditHeader: 'Edit Table',
				TableHeadingText: 'Heading',
				TableColText: 'Col',
				imageInsertLinkHeader: 'Insert Link',
				editImageHeader: 'Edit Image',
				alignmentsDropDownLeft: 'Align Left',
				alignmentsDropDownCenter: 'Align Center',
				alignmentsDropDownRight: 'Align Right',
				alignmentsDropDownJustify: 'Align Justify',
				imageDisplayDropDownInline: 'Inline',
				imageDisplayDropDownBreak: 'Break',
				tableInsertRowDropDownBefore: 'Insert row before',
				tableInsertRowDropDownAfter: 'Insert row after',
				tableInsertRowDropDownDelete: 'Delete row',
				tableInsertColumnDropDownLeft: 'Insert column left',
				tableInsertColumnDropDownRight: 'Insert column right',
				tableInsertColumnDropDownDelete: 'Delete column',
				tableVerticalAlignDropDownTop: 'Align Top',
				tableVerticalAlignDropDownMiddle: 'Align Middle',
				tableVerticalAlignDropDownBottom: 'Align Bottom',
				tableStylesDropDownDashedBorder: 'Dashed Borders',
				tableStylesDropDownAlternateRows: 'Alternate Rows',
				pasteFormat: 'Paste Format',
				pasteFormatContent: 'Choose the formatting action',
				plainText: 'Plain Text',
				cleanFormat: 'Clean',
				keepFormat: 'Keep',
				formatsDropDownParagraph: 'Paragraph',
				formatsDropDownCode: 'Code',
				formatsDropDownQuotation: 'Quotation',
				formatsDropDownHeading1: 'Heading 1',
				formatsDropDownHeading2: 'Heading 2',
				formatsDropDownHeading3: 'Heading 3',
				formatsDropDownHeading4: 'Heading 4',
				fontNameSegoeUI: 'Segoe UI',
				fontNameArial: 'Arial',
				fontNameGeorgia: 'Georgia',
				fontNameImpact: 'Impact',
				fontNameTahoma: 'Tahoma',
				fontNameTimesNewRoman: 'Times New Roman',
				fontNameVerdana: 'Verdana'
			},
			datepicker: {
				today: 'Avui'
			},
			schedule: {
				day: 'Día',
				week: 'Semana',
				workWeek: 'Semana laboral',
				month: 'Mes',
				year: 'Año',
				agenda: 'Agenda',
				weekAgenda: 'Agenda semanal',
				workWeekAgenda: 'Agenda de trabajo semanal',
				monthAgenda: 'Agenda Mensual',
				today: 'Hoy',
				noEvents: 'No hay eventos',
				emptyContainer: 'No hay eventos programados para este día.',
				allDay: 'Todo el día',
				start: 'Inicio',
				end: 'Fin',
				more: 'más',
				close: 'Cerrar',
				cancel: 'Cancelar',
				noTitle: '(Sin título)',
				delete: 'Eliminar',
				deleteEvent: 'Eliminar Evento',
				deleteMultipleEvent: 'Eliminar Multiples Eventos',
				selectedItems: 'Items seleccionados',
				deleteSeries: 'Eliminar series',
				edit: 'Editar',
				editSeries: 'Editar Series',
				editEvent: 'Editar Eventos',
				createEvent: 'Crear',
				subject: 'Tema',
				addTitle: 'Añadir título',
				moreDetails: 'Más detalles',
				save: 'Guardar',
				editContent: 'Do you want to edit only this event or entire series?',
				deleteRecurrenceContent: 'Do you want to delete only this event or entire series?',
				deleteContent: 'Are you sure you want to delete this event?',
				deleteMultipleContent: 'Are you sure you want to delete the selected events?',
				newEvent: 'New Event',
				title: 'Title',
				location: 'Location',
				description: 'Description',
				timezone: 'Timezone',
				startTimezone: 'Start Timezone',
				endTimezone: 'End Timezone',
				repeat: 'Repeat',
				saveButton: 'Save',
				cancelButton: 'Cancel',
				deleteButton: 'Delete',
				recurrence: 'Recurrence',
				wrongPattern: 'The recurrence pattern is not valid.',
				seriesChangeAlert:
					'The changes made to specific instances of this series will be cancelled and those events will match the series again.',
				createError:
					'The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.',
				recurrenceDateValidation:
					'Some months have fewer than the selected date. For these months, the occurrence will fall on the last date of the month.',
				sameDayAlert: 'Two occurrences of the same event cannot occur on the same day.',
				editRecurrence: 'Edit Recurrence',
				repeats: 'Repeats',
				alert: 'Alert',
				startEndError: 'The selected end date occurs before the start date.',
				invalidDateError: 'The entered date value is invalid.',
				ok: 'Ok',
				occurrence: 'Occurrence',
				series: 'Series',
				previous: 'Previous',
				next: 'Next',
				timelineDay: 'Timeline Day',
				timelineWeek: 'Timeline Week',
				timelineWorkWeek: 'Timeline Work Week',
				timelineMonth: 'Timeline Month',
				expandAllDaySection: 'Expandir',
				collapseAllDaySection: 'Collapse'
			},
			colorpicker: {
				Apply: 'Aplicar',
				Cancel: 'Cancelar',
				ModeSwitcher: 'Cambiar modo'
			},
			grid: {
				EmptyRecord: 'Vacío',
				EditOperationAlert: 'No hay registros seleccionados para la operación de edición',
				DeleteOperationAlert: 'No hay registros seleccionados para la operación de eliminación',
				GroupDropArea: 'Arrastre el encabezado de una columna aquí para agrupar su columna',
				Add: 'Añadir',
				Edit: 'Editar',
				Cancel: 'Cancelar',
				Update: 'Guardar',
				Delete: 'Eliminar',
				Save: 'Guardar',
				SaveButton: 'Guardar',
				OKButton: 'Vale',
				CancelButton: 'Cancelar',
				Item: 'Item',
				Items: 'Items',
				UnGroup: 'Haga clic aquí para desagrupar',
				True: 'Activo',
				False: 'Inactivo'
			},
			formValidator: {
				required: 'Campo requerido'
			},
			pager: {
				pagerInfo: '{0} de {1} páginas ({2} páginas)',
				nextPagerTooltip: 'Ir al siguiente Pager',
				previousPagerTooltip: 'Ir a Pager anterior',
				currentPageInfo: '{0} de {1} páginas',
				firstPageTooltip: 'Primera página',
				lastPageTooltip: 'Última página',
				nextPageTooltip: 'Siguiente',
				previousPageTooltip: 'Anterior',
				totalItemsInfo: '({0} Total)'
			}
		},
		ca: {
			calendar: {
				today: 'Avui'
			},
			richtexteditor: {
				alignments: 'Alineamiento',
				justifyleft: 'Justificar a la izquierda',
				justifycenter: 'Justificar al centro',
				justifyright: 'Justificar a la derecha',
				justifyfull: 'Justificar',
				formats: 'Formatos',
				fontname: 'Nombre de la fuente',
				fontsize: 'Tamaño de la fuente',
				fontcolor: 'Color de la fuente',
				backgroundcolor: 'Color de fondo',
				bold: 'Negrita',
				italic: 'Italic',
				underline: 'Subrallado',
				strikethrough: 'Tachado',
				clearall: 'Limpiar todo',
				clearformat: 'Limpiar formato',
				cut: 'Cortar',
				copy: 'Copiar',
				paste: 'Pegar',
				unorderedlist: 'unordered list',
				orderedlist: 'ordered list',
				indent: 'Increase Indent',
				outdent: 'Decrease Indent',
				undo: 'Undo',
				redo: 'Redo',
				superscript: 'Superscript',
				subscript: 'Subscript',
				createlink: 'Insert link',
				removelink: 'remove link',
				openlink: 'Open link',
				editlink: 'Edit link',
				image: 'Insert image',
				replace: 'Replace',
				align: 'Align',
				caption: 'Caption',
				remove: 'Remove',
				insertlink: 'insertlink',
				display: 'Display',
				alttext: 'Alternative Text',
				dimension: 'Dimension',
				fullscreen: 'Full Screen',
				maximize: 'Maximize',
				minimize: 'Minimize',
				zoomin: 'Zoom In',
				zoomout: 'Zoom Out',
				uppercase: 'Upper Case',
				lowercase: 'Lower Case',
				print: 'Print',
				sourcecode: 'Source Code',
				preview: 'Preview',
				viewside: 'View Side',
				insertcode: 'Insert Code',
				linkText: 'Display Text',
				linkTooltipLabel: 'Title',
				linkWebUrl: 'Web Address',
				linkTitle: 'Enter a title',
				linkOpenInNewWindow: 'Open Link in New Window',
				linkHeader: 'Insert Link',
				dialogInsert: 'Insert',
				dialogCancel: 'Cancel',
				dialogUpdate: 'Update',
				imageHeader: 'Insert Image',
				imageLinkHeader: 'You can also provide a link from the web',
				mdimageLink: 'Please provide a URL for your image',
				imageUploadMessage: 'Drop image here or browse to upload',
				imageDeviceUploadMessage: 'Click here to upload',
				imageAlternateText: 'Alternate Text',
				alternateHeader: 'Alternative Text',
				browse: 'Browse',
				imageCaption: 'Caption',
				imageSizeHeader: 'Image Size',
				imageHeight: 'Height',
				imageWidth: 'Width',
				textPlaceholder: 'Enter Text',
				inserttablebtn: 'Insert Table',
				tabledialogHeader: 'Insert Table',
				tableWidth: 'Width',
				cellpadding: 'Cell Padding',
				cellspacing: 'Cell Spacing',
				columns: 'Number of columns',
				rows: 'Number of rows',
				tableRows: 'Table Rows',
				tableColumns: 'Table Columns',
				tableCellHorizontalAlign: 'Table Cell Horizontal Align',
				tableCellVerticalAlign: 'Table Cell Vertical Align',
				createTable: 'Create Table',
				removeTable: 'Remove Table',
				tableHeader: 'Table Header',
				tableRemove: 'Table Remove',
				tableCellBackground: 'Table Cell Background',
				tableEditProperties: 'Table Edit Properties',
				styles: 'Styles',
				insertColumnLeft: 'Insert Column Left',
				insertColumnRight: 'Insert Column Right',
				deleteColumn: 'Delete Column',
				insertRowBefore: 'Insert Row Before',
				insertRowAfter: 'Insert Row After',
				deleteRow: 'Delete Row',
				tableEditHeader: 'Edit Table',
				TableHeadingText: 'Heading',
				TableColText: 'Col',
				imageInsertLinkHeader: 'Insert Link',
				editImageHeader: 'Edit Image',
				alignmentsDropDownLeft: 'Align Left',
				alignmentsDropDownCenter: 'Align Center',
				alignmentsDropDownRight: 'Align Right',
				alignmentsDropDownJustify: 'Align Justify',
				imageDisplayDropDownInline: 'Inline',
				imageDisplayDropDownBreak: 'Break',
				tableInsertRowDropDownBefore: 'Insert row before',
				tableInsertRowDropDownAfter: 'Insert row after',
				tableInsertRowDropDownDelete: 'Delete row',
				tableInsertColumnDropDownLeft: 'Insert column left',
				tableInsertColumnDropDownRight: 'Insert column right',
				tableInsertColumnDropDownDelete: 'Delete column',
				tableVerticalAlignDropDownTop: 'Align Top',
				tableVerticalAlignDropDownMiddle: 'Align Middle',
				tableVerticalAlignDropDownBottom: 'Align Bottom',
				tableStylesDropDownDashedBorder: 'Dashed Borders',
				tableStylesDropDownAlternateRows: 'Alternate Rows',
				pasteFormat: 'Paste Format',
				pasteFormatContent: 'Choose the formatting action',
				plainText: 'Plain Text',
				cleanFormat: 'Clean',
				keepFormat: 'Keep',
				formatsDropDownParagraph: 'Paragraph',
				formatsDropDownCode: 'Code',
				formatsDropDownQuotation: 'Quotation',
				formatsDropDownHeading1: 'Heading 1',
				formatsDropDownHeading2: 'Heading 2',
				formatsDropDownHeading3: 'Heading 3',
				formatsDropDownHeading4: 'Heading 4',
				fontNameSegoeUI: 'Segoe UI',
				fontNameArial: 'Arial',
				fontNameGeorgia: 'Georgia',
				fontNameImpact: 'Impact',
				fontNameTahoma: 'Tahoma',
				fontNameTimesNewRoman: 'Times New Roman',
				fontNameVerdana: 'Verdana'
			},
			schedule: {
				day: 'Dia',
				week: 'Setmana',
				year: 'Any',
				workWeek: 'Work Week',
				month: 'Mes',
				agenda: 'Agenda',
				weekAgenda: 'Agenda semanal',
				workWeekAgenda: 'Work Week Agenda',
				monthAgenda: 'Month Agenda',
				today: 'Avui',
				noEvents: 'No events',
				emptyContainer: 'There are no events scheduled on this day.',
				allDay: 'All day',
				start: 'Start',
				end: 'End',
				more: 'more',
				close: 'Close',
				cancel: 'Cancel',
				noTitle: '(No Title)',
				delete: 'Delete',
				deleteEvent: 'Delete Event',
				deleteMultipleEvent: 'Delete Multiple Events',
				selectedItems: 'Items selected',
				deleteSeries: 'Delete Series',
				edit: 'Edit',
				editSeries: 'Edit Series',
				editEvent: 'Edit Event',
				createEvent: 'Create',
				subject: 'Subject',
				addTitle: 'Add title',
				moreDetails: 'More Details',
				save: 'Save',
				editContent: 'Do you want to edit only this event or entire series?',
				deleteRecurrenceContent: 'Do you want to delete only this event or entire series?',
				deleteContent: 'Are you sure you want to delete this event?',
				deleteMultipleContent: 'Are you sure you want to delete the selected events?',
				newEvent: 'New Event',
				title: 'Title',
				location: 'Location',
				description: 'Description',
				timezone: 'Timezone',
				startTimezone: 'Start Timezone',
				endTimezone: 'End Timezone',
				repeat: 'Repeat',
				saveButton: 'Save',
				cancelButton: 'Cancel',
				deleteButton: 'Delete',
				recurrence: 'Recurrence',
				wrongPattern: 'The recurrence pattern is not valid.',
				seriesChangeAlert:
					'The changes made to specific instances of this series will be cancelled and those events will match the series again.',
				createError:
					'The duration of the event must be shorter than how frequently it occurs. Shorten the duration, or change the recurrence pattern in the recurrence event editor.',
				recurrenceDateValidation:
					'Some months have fewer than the selected date. For these months, the occurrence will fall on the last date of the month.',
				sameDayAlert: 'Two occurrences of the same event cannot occur on the same day.',
				editRecurrence: 'Edit Recurrence',
				repeats: 'Repeats',
				alert: 'Alert',
				startEndError: 'The selected end date occurs before the start date.',
				invalidDateError: 'The entered date value is invalid.',
				ok: 'Ok',
				occurrence: 'Occurrence',
				series: 'Series',
				previous: 'Previous',
				next: 'Next',
				timelineDay: 'Timeline Day',
				timelineWeek: 'Timeline Week',
				timelineWorkWeek: 'Timeline Work Week',
				timelineMonth: 'Timeline Month',
				expandAllDaySection: 'Expand',
				collapseAllDaySection: 'Collapse'
			},
			colorpicker: {
				Apply: 'Aplicar',
				Cancel: 'Cancel·lar',
				ModeSwitcher: 'Canviar mode'
			},
			grid: {
				EmptyRecord: 'Buit',
				EditOperationAlert: "No hi ha registres seleccionats per a l'operació d'edició",
				DeleteOperationAlert: "No hi ha registres seleccionats per a loperació d'eliminació",
				GroupDropArea: "Arrossegueu la capçalera d'una columna aquí per agrupar la seva columna",
				Add: 'Afegir',
				Edit: 'Editar',
				Cancel: 'Cancel·lar',
				Update: 'Guardar',
				Delete: 'Eliminar',
				Save: 'Guardar',
				SaveButton: 'Guardar',
				OKButton: 'Vale',
				CancelButton: 'Cancel·lar',
				Item: 'Article',
				Items: 'Articles',
				UnGroup: 'Fes clic aquí per desagrupar',
				True: 'Actiu',
				False: 'Inactiu'
			},
			formValidator: {
				required: 'Camp requerit'
			},
			pager: {
				pagerInfo: '{0} de {1} pàgines ({2} pàgines)',
				nextPagerTooltip: 'Anar al següent Pager',
				previousPagerTooltip: 'Anar a Pager anterior',
				currentPageInfo: '{0} de {1} páginas',
				firstPageTooltip: 'Primera página',
				lastPageTooltip: 'Última página',
				nextPageTooltip: 'Seguent',
				previousPageTooltip: 'Anterior',
				totalItemsInfo: '({0} Total)'
			}
		}
	};
};

export { getSyncfusionTranslations };
