import React from 'react';
import { useTranslation } from 'react-i18next';
import './TabPastBookings.scss';

const TabPastBookings = ({ bookings, renderBookings }) => {
	const { t } = useTranslation();

	return (
		<div className="past-bookings">
			<h2 style={{ display: 'none' }}>{t('NextBookings')} </h2>
			<div className="card-list-wrapper">{renderBookings(bookings)}</div>
		</div>
	);
};
export default TabPastBookings;
