import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { TranslationButton } from "../../shared/index";
import { ROUTE } from "../../common/constants/routes";
import { useMediaQuery } from "react-responsive";
import "./Header.scss";

export default function Header() {
  const history = useHistory();
  const profileReducer = useSelector((state) => state.profile);
  const appearanceReducer = useSelector((state) => state.appearance);
  const [profileName, setProfileName] = useState("");
  const [currentLanguage, setCurrentLanguage] = useState("");
  const [logoImgUrl, setLogoImgUrl] = useState(
    appearanceReducer.appearance.logoImgUrl
  );
  const [smallImgUrl, setSmallImgUrl] = useState(
    appearanceReducer.appearance.faviconImgUrl
  );
  const [profileImgUrl, setProfileImgUrl] = useState("");
  const isSmallMobile = useMediaQuery({ query: "(max-width: 350px)" });

  useEffect(() => {
    let name = profileReducer.profile.name;
    let surname = profileReducer.profile.firstSurname;
    setProfileName(name + " " + surname);
    setLogoImgUrl(appearanceReducer.appearance.logoImgUrl);
    setProfileImgUrl(
      profileReducer.profile.imgUrl !== "" ? profileReducer.profile.imgUrl : ""
    );
    
    setCurrentLanguage(
      profileReducer.profile.currentLanguage
        ? profileReducer.profile.currentLanguage
        : ""
    );
  }, [
    profileReducer.profile.buildingId,
    profileReducer.profile.name,
    profileReducer.profile.firstSurname,
    appearanceReducer.appearance.imgUrl,
    profileReducer.profile.imgUrl,
    profileReducer.profile.currentLanguage
  ]);

  return (
    <div className="header">
      <div className="header-wrapper">
        <div
          className="logo-container"
          onClick={(x) => {
            if (profileReducer.profile.isTenant) {
              history.push(ROUTE.HOMETENANT);
            } else if (profileReducer.profile.isProvider) {
              history.push(ROUTE.INCIDENTSPROVIDER);
            } else {
              history.push(ROUTE.HOME);
            }
          }}
        >
          {isSmallMobile ? (
            <img className="img-logo" src={smallImgUrl} alt=" " />
          ) : (
            <img className="img-logo" src={logoImgUrl} alt="Logo de Bialto" />
          )}
        </div>
        <div className="col-lg">
          <div className="translations-user-wrapper">
            <div className="translationButton-wrapper">
              <TranslationButton language={currentLanguage}/>
            </div>
            <div className="link">
              <div className="user-wrapper">
                {/* {profileImgUrl !== '' ? (
									<span className="profile-img-wrapper">
										<FilePreview file={''} url={profileImgUrl} fileType={'Image'} />
									</span>
								) : ( */}
                <span className="icon" aria-hidden={true}>
                  <ion-icon name={"person-circle-outline"} />{" "}
                </span>
                {/* )} */}
                <span className="user-name">{profileName}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
