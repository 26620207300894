import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { startRequest } from "@backoffice/actions/apiCall";
import { getProfileParams } from "./ProfileTenantCalls/ProfileTenantsCalls";
import { getContactSchedule, postContactSchedule } from "../ConfigurationTenant/ConfigurationTenantCalls/ConfigurationTenantCalls";
import { Combo} from "@backoffice/components/FormComponents/index";
import {
  parseDate,
  parsePriceCurrency,
} from "../../../../common/utils/parseUtils";
import { ROUTE } from "../../../../common/constants/routes";
import { openModal } from "../../../../actions/popup";
import PopUpChangePass from "./PopUpChangePass/PopUpChangePass";

import "./ProfileTenant.scss";

const ProfileTenant = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [profile, setProfile] = useState({
        id: "",
        dynamicId: "",
        fullName: "",
        email: "",
        documentId: "",
        phone: "",
        address: "",
        contractStartDate: null,
        contractEndDate: null,
        monthlyFee: 0,
        bankAccount: "",
        hasPendingDebt: false,
        pendingDebt: 0
  });

  const [tipologies, setTipologies] = useState([]);
  const [tipologyValue, setTipologyValue] = useState("");

  useEffect(() => {
    getProfile();
    dispatch(startRequest(getContactSchedule(getContactScheduleSuccess)));
  }, []);

  const getContactScheduleSuccess = (response) => {
    if (response) {
      setTipologyValue(response.value);
      setTipologies(response.tipology);
    }
  };

  const getProfile = () => {
    dispatch(startRequest(getProfileParams(getProfileSuccess)));
  };

  const getProfileSuccess = (response) => {
    setProfile(response);
  };

  const comboHandleChange = async (e) => {
    if (tipologyValue != e.value) {
      let data = {
        value: e.value,
      };
      await dispatch(startRequest(postContactSchedule(data)));
      setTipologyValue(e.value);
    }
  };

  const renderChangePassCard = (name, icon) => {
    let html = [];
    html.push(
      <div className="col-md-3 profile-card" key={"profile-tenant"}>
        <div className="profile-header">
          {icon ? (
            <div className="icon" aria-hidden={true}>
              <span className="material-icons">{icon}</span>
            </div>
          ) : (
            ""
          )}
          <div className="card-body">
            <p
              className="card-changePass-name"
              aria-label={name}
              onClick={() => {
                const optionValues = {
                  id: name + Math.random(),
                  cssClass: "modal-header__blue",
                  dialogClassName: "change-pass",
                  title: name,
                };

                const options = {
                  ...optionValues,
                  body: <PopUpChangePass name={name} />,
                };

                dispatch(openModal(options));
              }}
            >
              {name}
            </p>
          </div>
        </div>
      </div>
    );
    return html;
  };

  const renderProfileCard = (name, value, icon, edit) => {
    let html = [];
    html.push(
      <div className="col-md-3 profile-card" key={"profile-tenant"}>
        <div className="profile-header">
          {icon ? (
            <div className="icon" aria-hidden={true}>
              <span className="material-icons">{icon}</span>
            </div>
          ) : (
            ""
          )}
          <div className="card-body">
            <p className="card-name" aria-label={name}>
              {name}
            </p>
          </div>
          {edit ? (
            <a className="edit" aria-hidden={true} href={edit}>
              <span className="material-icons">{"edit"}</span>
            </a>
          ) : (
            ""
          )}
        </div>
        <div className="profile-body">
          <p className="card-value" aria-label={value}>
            {value ? value : "-"}
          </p>
        </div>
      </div>
    );
    return html;
  };

  return (
    <div className="profile-tenant">
      <h1 className="title">{t("Profile")}</h1>
      
      {profile.hasPendingDebt ? (
        <div className="fixed-label-profile">
          <ion-icon name={"alert-circle-outline"} />
          <p>
            {t("YourPendingDebt") +
              ": " +
              parsePriceCurrency(profile.pendingDebt)}
          </p>
        </div>
      ) : (
        ""
      )}

      <p className="name">{profile.fullName}</p>

      <div className="content">
        {tipologies  && (
          <Combo
            id="cmbTipologies"
            dataTestID="cmbTipologies"
            comboLabel={t("ContactSchedule")}
            inputValue={tipologyValue}
            comboPlaceholder={t(
              tipologies.find((x) => x.value == tipologyValue)?.name
            )}
            defaultInputValue=""
            isSearchable={false}
            getOptionValue={(option) => option.value}
            getOptionLabel={(option) => t(option.name)}
            options={tipologies}
            isMultiple={false}
            handleChange={comboHandleChange}
          />
        )}
      </div>
      <div className="divider" />

      <div className="row">
        {renderProfileCard(
          t("Email"),
          profile.email,
          "mail",
          ROUTE.INCIDENTSTENANTCREATECUSTOM.replace(":type", "email")
        )}
        {renderProfileCard(t("DNI"), profile.documentId, "assignment_ind")}
        {renderProfileCard(
          t("Phone"),
          profile.phone,
          "phone",
          ROUTE.INCIDENTSTENANTCREATECUSTOM.replace(":type", "phone")
        )}
      </div>
      <div className="row">
        {renderProfileCard(t("Address"), profile.address, "place")}
        {renderProfileCard(
          t("ContractLength"),
          profile.contractStartDate !== "" && profile.contractEndDate !== "" && profile.contractStartDate !== null && profile.contractEndDate !== null
            ? parseDate(profile.contractStartDate) +
                " - " +
                parseDate(profile.contractEndDate)
            : "",
          "date_range"
        )}
        {renderProfileCard(
          t("RentalFee"),
          profile.monthlyFee
            ? parsePriceCurrency(profile.monthlyFee)
            : parsePriceCurrency(0),
          "euro"
        )}
      </div>
      <div className="row">
        {renderProfileCard(
          t("BankAccount"),
          profile.bankAccount,
          "credit_card",
          ROUTE.INCIDENTSTENANTCREATECUSTOM.replace(":type", "bank")
        )}
        {renderChangePassCard(t("ChangePassword"), "lock")}
      </div>
    </div>
  );
};

export default ProfileTenant;
